import React from 'react';
import * as actionTypes from "../actionTypes";
import { gql } from "apollo-boost";
import { Encrypt } from "../../shared/encrpt";
import * as actions from "../index";
import notificationMsg  from '../../../App/view/Layout/NotificationMessage';
import * as ChangeAppLanguageFront from '../../../App/view/Layout/ChangeAppLanguageFront';
let tlang = ChangeAppLanguageFront.translateLanguage;

const langupdatemsg = tlang('notificationSuccess_langupdates') ? tlang('notificationSuccess_langupdates') : 'Language Updated Successfully';
const langadd = tlang('notificationSuccess_langadd') ? tlang('notificationSuccess_langadd') : 'Language Added Successfully';
const error_something_wrong = tlang('error_something_wrong') ? tlang('error_something_wrong') : 'Something Went Wrong!';
const msg_language_lable_updated = tlang('msg_language_lable_updated') ? tlang('msg_language_lable_updated') : 'Language Label Updated Successfully';
const msg_language_lable_added = tlang('msg_language_lable_added') ? tlang('msg_language_lable_added') : 'Language Label Added Successfully';
const msg_language_lable_deleted = tlang('msg_language_lable_deleted') ? tlang('msg_language_lable_deleted') : 'Language Label Deleted Successfully';

const notificationSuccess = (msg) => {
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_SUCCESS,
    state: msg,
  }
}

// notification Fail
const notificationFail = err => {
  let msg = error_something_wrong;
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    // msg = err.graphQLErrors[0].message;
    let translateMsg =notificationMsg(err.graphQLErrors[0].statusCode)
    msg = translateMsg;
  } else if (err.message) {
    msg = err.message;
  }
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_FAIL,
    state: msg
  };
};

// get Language Master List Success
const getLanguageMasterListSuccess = data => {
  return {
    type: actionTypes.GET_LANGUAGE_LABEL_LIST,
    state: data
  };
};

// get Language Code Master List Success
const getLanguageCodeMasterListSuccess = data => {
  return {
    type: actionTypes.GET_LANGUAGE_CODE_LABEL_LIST,
    state: data
  };
};

// get Lang New Master List Success
export const getLangNewMasterListSuccess = data => {
  return {
    type: actionTypes.GET_LANGUAGE_NEW_LABEL_LIST,
    state: data
  };
};


// set user preferred language
export const changeUserLangPref = (client, request) => {
  return dispatch => {
    const mutation = gql`
      mutation change_user_lang_pref($lang_code: String!, $userid: String!) {
        change_user_lang_pref(lang_code: $lang_code, userid: $userid) {
            message
            error
            success
          }
        }
          `;
    client.mutate({
      mutation: mutation,
      variables: {
        lang_code: Encrypt(request.lang_code),
        userid: Encrypt(request.userid),
      }
    })
      .then(response => {
        
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}



//front side local storage
export const getLanguageMasterListFront = (client, request) => {
  return dispatch => {
    const query = gql`{
      get_language_master_list {
            _id
            key
            language
          }
        }
        `;
    client.query({
      query: query,
    }).then(response => {
      localStorage.removeItem("language_data");
      localStorage.setItem('language_data', JSON.stringify(response.data.get_language_master_list));

      dispatch(getLanguageMasterListSuccess(response.data.get_language_master_list))
      
      // if topbar change language 
      if(request.isreload){
          window.location.reload()
      }
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// get language master list
export const getLanguageMasterList = (client, request) => {
  return dispatch => {
    const query = gql`{
      get_language_master_list {
            _id
            key
            language
          }
        }
        `;
    client.query({
      query: query,
    }).then(response => {
      dispatch(getLanguageMasterListSuccess(response.data.get_language_master_list))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// get Language New Master List
export const getLanguageNewMasterList = (client, request) => {
  return dispatch => {
    const query = gql`{
      new_get_language_master_list($pageno: !int, $limit: !int) {
        pageno
        limit
      }
    }
        `;
    client.query({
      query: query,
    }).then(response => {
      dispatch(getLangNewMasterListSuccess(response.data.new_get_language_master_list
      ))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// get Language Code Master List
export const getLanguageCodeMasterList = (client, request) => {
  return dispatch => {
    const query = gql`{
      get_language_code_master_list {
            _id
            code
            language
            isvisible
          }
        }
        `;
    client.query({
      query: query,
      fetchPolicy: 'no-cache',
    }).then(response => {
      localStorage.removeItem("language_codes");
      localStorage.setItem('language_codes', JSON.stringify(response.data.get_language_code_master_list));
      dispatch(getLanguageCodeMasterListSuccess(response.data.get_language_code_master_list))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// add language code
export const addLanguageCode = (client, request) => {
  return dispatch => {
    const mutation = gql`
      mutation add_language_code_master($id: String, $icon: String!, $code: String!, $language: String!, $isvisible: Boolean!) {
        add_language_code_master(id: $id, icon: $icon, code: $code, language: $language, isvisible: $isvisible) {
            _id
            icon
            code
            language
            isvisible
          }
        }
          `;
    client.mutate({
      mutation: mutation,
      variables: {
        id: Encrypt(request.id),
        icon: Encrypt(request.icon),
        code: Encrypt(request.code),
        language: Encrypt(request.language),
        isvisible: request.isvisible
      }
    })
      .then(response => {
        dispatch(actions.getLanguageCodeMasterList(client, []));
        dispatch(notificationSuccess(langadd));
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}

// update Language Code
export const updateLanguageCode = (client, request) => {
  return dispatch => {
    const mutation = gql`
      mutation add_language_code_master($id: String, $icon: String!, $code: String!, $language: String!, $isvisible: Boolean!) {
        add_language_code_master(id: $id, icon: $icon, code: $code, language: $language, isvisible: $isvisible) {
            _id
            icon
            code
            language
            isvisible
          }
        }
          `;
    client.mutate({
      mutation: mutation,
      variables: {
        id: Encrypt(request.id),
        icon: Encrypt(request.icon),
        code: Encrypt(request.code),
        language: Encrypt(request.language),
        isvisible: request.isvisible
      }
    })
      .then(response => {
        dispatch(actions.getLanguageCodeMasterList(client, []));
        dispatch(notificationSuccess(langupdatemsg));
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}


// add language label
export const addLanguageLabel = (client, request) => {
  console.log(request)
  return dispatch => {
    const mutation = gql`
      mutation add_language_master($id: String, $key: String!, $language: String!, $page_name: String!, $description: String ) {
          add_language_master(id: $id, key: $key, language: $language, page_name: $page_name, description: $description) {
            _id
            page_name
            key
            description
            language
          }
        }
          `;
    client.mutate({
      mutation: mutation,
      variables: {
        id: Encrypt(request.id),
        key: Encrypt(request.key),
        description: Encrypt(request.description),
        language: Encrypt(request.language),
        page_name: Encrypt(request.page_name)
      }
    })
      .then(response => {
        dispatch(actions.getLanguageMasterListFront(client, []));
        dispatch(actions.getLanguageMasterList(client, []));
        dispatch(notificationSuccess(msg_language_lable_added));
        // window.location.reload();
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}


//delete language label model
export const deleteLanguageLabel = (client, request) => {
  return dispatch => {
    const mutation = gql`
      mutation delete_language_master($id: String!) {
          delete_language_master(id: $id) {
            message
            error
            success
          }
        }
          `;
    client.mutate({
      mutation: mutation,
      variables: {
        id: Encrypt(request.id)
      }
    })
      .then(response => {
        dispatch(actions.getLanguageMasterList(client, []));
        dispatch(notificationSuccess(msg_language_lable_deleted));
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}

//upadte language label model
export const updateLanguageLabel = (client, request) => {
  return dispatch => {
    const mutation = gql`
      mutation add_multiple_language_master($language_data: String!) {
          add_multiple_language_master(language_data: $language_data) {
            _id
            key
            language
          }
        }
          `;
    client.mutate({
      mutation: mutation,
      variables: {
        language_data: Encrypt(request.language_data)
      }
    })
      .then(response => {
        dispatch(actions.getLanguageMasterListFront(client, []));
        dispatch(actions.getLanguageMasterList(client, []));
        dispatch(notificationSuccess(msg_language_lable_updated));
        window.location.reload();
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}

// get Page wise Language Master List Success
export const getPagewiseLanguageMasterListSuccess = data => {
  return {
    type: actionTypes.GET_PAGE_WISE_LANGUAGE_LIST,
    state: data
  };
};

//get Page wise Language MasterList
export const getPagewiseLanguageMasterList = (client, request) => {
  return dispatch => {
    const query = gql`
      query get_pagewise_language_master_list(
        $page_name: String!
        $isblank: Boolean!
        $lang_code: String
        $search_text:String
        ) {
        get_pagewise_language_master_list(
          page_name: $page_name
          isblank: $isblank
          lang_code: $lang_code
          search_text:$search_text
          ) {
          _id 
          page_name 
          key 
          description 
          language 
          created_at 
          updated_at 
        }
      }
    `;
    client.query({
      query: query,
      variables: {
        page_name: Encrypt(request.page_name),
        lang_code: Encrypt(request.lang_code),
        isblank: request.isblank,
        search_text: Encrypt(request.search_text),
      }
    }).then(response => {
      dispatch(getPagewiseLanguageMasterListSuccess(response.data.get_pagewise_language_master_list))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}
