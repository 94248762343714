import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../shared/utility';
let da=localStorage.getItem("language_codes")?JSON.parse(localStorage.getItem("language_codes")):[]

const initialState = {
    languagecodeLabelList :da

};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_LANGUAGE_CODE_LABEL_LIST:
            return updateObject(state, { languagecodeLabelList: action.state })
        case actionTypes.GET_PAGE_WISE_LANGUAGE_LIST:
            return updateObject(state, { pageWiseLanguageList: action.state })
        default:
            return state;
    }
}

export default reducer
