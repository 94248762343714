/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { Col, Row, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import scrollToComponent from 'react-scroll-to-component';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TopbarLanguage from './components/TopbarLanguage';
import { ThemeProps } from '../../assets/shared/prop-types/ReducerProps';
import ChangeAppLanguage from '../Layout/ChangeAppLanguage';

import * as ChangeAppLanguageFront from '../Layout/ChangeAppLanguageFront';
let tlang  = ChangeAppLanguageFront.translateLanguage;


const logo = `${process.env.PUBLIC_URL}/img/landing/aexdo.svg`;
const background = `${process.env.PUBLIC_URL}/img/landing/header_bg.png`;
const img = `${process.env.PUBLIC_URL}/img/landing/macbook.png`;

class Landing extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    theme: ThemeProps.isRequired,
    i18n: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { t } = this.props;

    const landing_page_home = tlang('landing_page_home')?tlang('landing_page_home'):'Accueil';
    const landing_page_contact = tlang('landing_page_contact')?tlang('landing_page_contact'):'Contact';
    const landing_page_team = tlang('landing_page_team')?tlang('landing_page_team'):'Équipe';
    const landing_page_about = tlang('landing_page_about')?tlang('landing_page_about'):"ÆXDO propose une solution de numérisation de vos projets pour un accès simplifié, une visualisation plus facile, interactive et améliorée qui vous permet d'optimiser, de simuler, de gagner du temps et de limiter la production de documents papier.";
    const landing_page_signin = tlang('landing_page_signin')?tlang('landing_page_signin'):"Accéder à la connexion";
    const landing_page_signup = tlang('landing_page_signup')?tlang('landing_page_signup'):"Accéder à l'inscription";

    return (
      <div className="landing">
        <div className="landing__menu">
          <Container>
            <Row>
              <Col md={12}>
                <div className="landing__menu-wrap">
                  <p className="landing__menu-logo">
                    <img src={logo} alt="" />
                  </p>
                  <nav className="landing__menu-nav">
                    <button
                      onClick={() => scrollToComponent(this.About, { offset: -50, align: 'top', duration: 1000 })}
                      type="button"
                    >
                      {landing_page_home}

                    </button>
                    <button
                      onClick={() => scrollToComponent(this.Features, {
                        offset: -50,
                        align: 'top',
                        duration: 1500,
                      })}
                      type="button"
                    >
                      {landing_page_contact}

                    </button>
                    <button
                      onClick={() => scrollToComponent(this.Demos, { offset: -50, align: 'top', duration: 2000 })}
                      type="button"
                    >
                      {landing_page_team}

                    </button>
                    <TopbarLanguage />
                  </nav>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="landing__header" style={{ backgroundImage: `url(${background})` }}>
          <Container>
            <Row>
              <Col md={12}>
                <p className="landing__header-subhead">
                  {landing_page_about}
                </p>
                <Link className="landing__btn landing__btn--header" to="/signin">
                  {landing_page_signin}

                </Link>
                <Link className="landing__btn landing__btn--header" to="/register">
                  {landing_page_signup}

                </Link>
                <img className="landing__header-img" src={img} alt="macbook" />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default connect(state => ({ theme: state.theme }))( withTranslation('common') (Landing));
