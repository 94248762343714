import ApolloClient from 'apollo-boost';
import Config from '../../Common/Server';
import { setContext } from 'apollo-link-context';
import fingerprint from 'fingerprintjs';
import jwt from 'jsonwebtoken';

var credential = localStorage.getItem("_user_");
var client = new ApolloClient({
    uri: Config.API,
})
var language = new ApolloClient({
        
    uri: Config.MASTER_API,
    
})

var project = {};
var masters = {};
var campaign = {};
var probing = {};

if (credential) {
    let finderprint = new fingerprint().get();
    credential = jwt.verify(credential, finderprint.toString());
    const token = credential.token;
    client = new ApolloClient({
        uri: Config.API,
        request: (operation) => {
            operation.setContext({
                headers: {
                    authorization: token ? `Bearer ${token}` : ''
                }
            })
        }
    })

    project = new ApolloClient({
        uri: Config.PROJECT_API,
        request: (operation) => {
            operation.setContext({
                headers: {
                    authorization: token ? `Bearer ${token}` : ''
                }
            })
        }
    })

    masters = new ApolloClient({
        
        uri: Config.MASTER_API,
        request: (operation) => {
            operation.setContext({
                headers: {
                    authorization: token ? `Bearer ${token}` : ''
                }
            })
        }
    })

    campaign = new ApolloClient({
        uri: Config.CAMPAIGN_API,
        request: (operation) => {
            operation.setContext({
                headers: {
                    authorization: token ? `Bearer ${token}` : ''
                }
            })
        }
    })

    probing = new ApolloClient({
        uri: Config.PROBING_API,
        request: (operation) => {
            operation.setContext({
                headers: {
                    authorization: token ? `Bearer ${token}` : ''
                }
            })
        }
    })

}

const initialState = {
    client: client,
    project: project,
    masters: masters,
    campaign: campaign,
    probing: probing,
    language:language
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
}

export default reducer