import React from 'react';
import Table from '@material-ui/core/Table';
import {
	StyleSheet
} from "@react-pdf/renderer";
import __ from 'underscore';
import * as ChangeAppLanguageFront from '../../../Layout/ChangeAppLanguageFront';

const styles = StyleSheet.create({
	page: {
		backgroundColor: "#ffffff",
		padding: 20
	},
	headingTexts: {
		textAlign: "center",
		fontSize: "40px",
		marginBottom: "10px"
	},
	headerColoredBox: {
		backgroundColor: "#0d6dbd",
		fontSize: "20px",
		color: "#FFFFFF",
		border: "1px solid #000",
		width: 200,
		padding: "5px",
		textAlign: "center"
	},
	headerWhitedBox: {
		fontSize: "20px",
		color: "#000",
		border: "1px solid #000",
		width: 500,
		padding: "5px",
	},
	headerProbingSampleColoredBox: {
		backgroundColor: "#0d6dbd",
		flexDirection: "row",
		color: "#FFFFFF",
		border: "1px solid #000",
		width: 300,
		textAlign: "center",
		margin: "0px",
		fontSize: 14,
		padding: "2px 5px 1px 5px",
	},
	headerProbingSampleColoredBoxLithology: {
		backgroundColor: "#0d6dbd",
		flexDirection: "row",
		color: "#FFFFFF",
		border: "1px solid #000",
		width: 300,
		height: 40,
		textAlign: "center",
		margin: "0px",
		fontSize: 14,
		padding: "10px 5px 1px 5px",
	},
	testFamilyName: {
		width: 300,
		flexDirection: "row",
		fontSize: 14,
		border: "1px solid #000",
		padding: "2px 5px 1px 5px",
		textAlign: "center",
		margin: "0px",
	},
	disposalNameAtLast: {
		width: 300,
		flexDirection: "row",
		fontSize: 17,
		border: "1 solid #000",
		padding: "4px 5px 1px 5px",
		textAlign: "center",
		margin: "0px",
		backgroundColor: "#0d6dbd",
		color: "#FFFFFF",
	},
	section: {
		margin: 10,
		padding: 10,
		flexGrow: 1
	},
	coloumn: {
		flexDirection: "row",
		marginTop: 10
	},
	fullWidth: {
		flexDirection: "row"
	},
	row: {
		width: 200,
		flexDirection: "row",
		fontSize: 14,
		border: "1 solid #000",
		padding: "2px 5px 1px 5px"
	},
	header: {
		width: 200,
		flexDirection: "row",
		fontSize: 14,
		border: "1 solid #000",
		padding: "2px 5px 1px 5px",
		backgroundColor: "#ff8100"
	},
	campaign_name: {
		fontSize: 14
	},
	headerDetails: {
		paddingBottom: 10
	},
	disposal_header: {
		width: 150,
		flexDirection: "row",
		fontSize: 14,
		border: "1px solid #000",
		padding: "2px 5px 1px 5px",
		textAlign: "center",
		margin: "0px",
	},
	disposal_header_lithology: {
		width: 150,
		height: "auto",
		minHeight: 40,
		flexDirection: "row",
		fontSize: 14,
		border: "1px solid #000",
		padding: "2px 5px 1px 5px",
		textAlign: "center",
		margin: "0px",
	},
	probingReportHeader: {
		flexDirection: "row",
		textAlign: "center",
		backgroundColor: "#6da8cf",
		color: "#000",
		fontSize: "20px"
	},
	probingReportHeaderData: {
		flexDirection: "row",
		textAlign: "center",
	},
	probingReportNotesHeading: {
		flexDirection: "row",
		textAlign: "center",
		backgroundColor: "#6da8cf",
		color: "#000",
		fontSize: "20px",
		padding: "20px",
		border: "1px soled #000"
	},
	probingReportNotesBox: {
		flexDirection: "row",
		textAlign: "center",
		color: "#000",
		fontSize: "20px",
		padding: "20px",
		border: "1px soled #000"
	},
	width_100: {
		width: 100, border: "1px solid #000", padding: "20px", textAlign: "center"
	},
	width_150: {
		width: 150, border: "1px solid #000", padding: "20px", textAlign: "center"
	},
	width_200: {
		width: 200, border: "1px solid #000", padding: "20px", textAlign: "center"
	},
	width_250: {
		width: 250, border: "1px solid #000", padding: "20px", textAlign: "center"
	},
	width_300: {
		width: 300, border: "1px solid #000", padding: "20px", textAlign: "center"
	},
	width_400: {
		width: 400, border: "1px solid #000", padding: "20px", textAlign: "center"
	},
	width_500: {
		width: 500, border: "1px solid #000", padding: "20px", textAlign: "center"
	},
	width_600: {
		width: 600, border: "1px solid #000", padding: "20px", textAlign: "center"
	},
	width_900: {
		width: 900, border: "1px solid #000", padding: "20px", textAlign: "center"
	}
});

const styleshtml = StyleSheet.create({
	heightthirty: {
		height: "30px"
	},

	page: {
		backgroundColor: "#ffffff",
		padding: 20,
		borderCollapse: "collapse",
		//border: "none !important",
	},

	headingTexts: {
		textAlign: "center",
		fontWeight: "bold",
		fontSize: "16px",
		//marginBottom:"10px"
	},
	headingTexts1: {
		textAlign: "center",
		marginBottom: "10px"
	},

	headerColoredBox: {
		backgroundColor: "#0d6dbd",
		fontSize: "13px",
		color: "#FFFFFF",
		border: "1px solid #000",
		width: 200,
		//padding:"5px",
		textAlign: "center"
	},
	headerWhitedBox: {
		fontSize: "13px",
		color: "#000",
		border: "1px solid #000",
		width: 500,
		//padding:"5px",
		textAlign: "center"
	},


	testFamilyName: {
		backgroundColor: "#0d6dbd",
		color: "#FFFFFF",
		width: 300,
		flexDirection: "row",
		fontSize: 13,
		border: "1px solid #000",
		padding: "2px 5px 1px 5px",
		textAlign: "center",
		margin: "0px",
	},

	disposalNameAtLast: {
		width: 300,
		flexDirection: "row",
		fontSize: 16,
		border: "1px solid #000",
		padding: "4px 5px 1px 5px",
		textAlign: "center",
		margin: "0px",
		backgroundColor: "#0d6dbd",
		color: "#FFFFFF",
	},

});


const tlang = ChangeAppLanguageFront.translateLanguage;
const client_report_aexdo = tlang('client_report_aexdo') || 'Aexdo';
const client_report_analytical_results = tlang('client_report_analytical_results') || 'Analytical results';
const client_campaign_project = tlang('client_campaign_project') || 'Project';
const client_project_address = tlang('client_project_address') || 'Address';
const dashboard_btn_origin = tlang('dashboard_btn_origin') || 'Origin';
const dashboard_btn_destination = tlang('dashboard_btn_destination') || 'Destination';
const analysis_pollution = tlang('analysis_pollution') || 'Pollution';
const client_probing_campaign = tlang('client_probing_campaign') || 'Campaign';
const client_probing_txt = tlang('clientmenu_probing') || 'Probing';
const client_Depth = tlang('client_Depth') || 'Depth';
const client_sample = tlang('client_sample') || 'Sample';
const client_Observation = tlang('client_Observation') || 'Observation';
const client_disposal_process = tlang('client_disposal_process') || 'Disposal process';
const client_probing_layer_lithology = tlang('client_probing_lithology') || 'Lithology';
const client_probing_layer_colour = tlang('client_campaign_color') || 'Colour';
const dashboard_log_date = tlang('dashboard_log_date') || 'Date';

export default function AnalysisReportsTable({ reportData }) {

	let heigth = 1000
	let width = 2000
	let total_sample_per_row = 0;
	let total_selected_desposal = 0;
	let totalColspan = 0;
	let hideDisposalInReport = false;

	if (reportData) {
		if (reportData.heigth) {
			heigth = reportData.heigth
		}
		if (reportData.width) {
			width = reportData.width
		}

		total_sample_per_row = reportData?.dataToPrint && reportData?.dataToPrint[0][0].samples.length;
		total_selected_desposal = reportData?.desposal_list.length;
		totalColspan = parseInt(total_sample_per_row) + parseInt(total_selected_desposal) + 1;
		hideDisposalInReport = reportData?.hideDisposalInReport;
	}

	return (
		<>
			{reportData && (
				<>
					{reportData != "" && !__.isEmpty(reportData) && reportData.result_data.length && reportData.sample_list.length ? (
						<>
							<div style={{ display: "grid" }}>
								<div id="analysisReport" className="table-responsive reportanalysisResult reportHeight">

									{reportData ? (
										<>
											{reportData.dataToPrintPdf && reportData.dataToPrintPdf.map((maintest, key) => {
												//var headingRow = []
												var allRows = []
												let lastWhitedBox = {
													fontSize: "20px",
													color: "#FFFFFF",
													border: "1px solid #000",
													padding: "5px",
													textAlign: "center",
													width: 1000 + (reportData.desposal_list.length * 200)
												}
												var headerDetails = []
												var probingArr = []
												var sampleArr = []
												var sampleObservationArr = []
												var depthArr = []
												var lithologyArr = []
												var colourArr = []
												headerDetails.push(<tr className="reportFirstrow" style={styleshtml.headingTexts} key={`${Math.random()}`}><td colspan={totalColspan}>{client_report_aexdo} <br></br> {client_report_analytical_results}</td></tr>)
												//headerDetails.push(<tr style={styleshtml.headingTexts} key={`${Math.random()}`}><td colspan={totalColspan}>{client_report_analytical_results}</td></tr>)
												headerDetails.push(
													<tr style={styles.fullWidth} key={`${Math.random()}`}>
														<td style={styleshtml.headerColoredBox}>{client_campaign_project}</td>
														<td colspan={2} style={styleshtml.headerWhitedBox}>{reportData.project_name}</td>
														<td style={styleshtml.headerColoredBox}>{client_project_address}</td>
														<td colspan={5} style={styleshtml.headerWhitedBox}>{reportData.project_address} </td>
														<td style={styleshtml.headerColoredBox}>{dashboard_btn_origin}</td>
														<td colspan={totalColspan - 10} style={styleshtml.headerWhitedBox}>{reportData.origin_name}</td>
													</tr>
												)
												headerDetails.push(
													<tr style={styles.fullWidth} key={`_${Math.random()}_`}>
														<td style={styleshtml.headerColoredBox}>{dashboard_log_date}</td>
														<td colspan={2} style={styleshtml.headerWhitedBox}>{reportData.project_date}</td>
														<td style={styleshtml.headerColoredBox}>{client_probing_campaign}</td>
														<td colspan={2} style={styleshtml.headerWhitedBox}>{reportData.campaign_name}</td>
														<td style={styleshtml.headerColoredBox}>{analysis_pollution}</td>
														<td colspan={2} style={styleshtml.headerWhitedBox}>{reportData.pollutionzone_name}</td>
														<td style={styleshtml.headerColoredBox}>{dashboard_btn_destination}</td>
														<td colspan={totalColspan - 10} style={styleshtml.headerWhitedBox}>{reportData.destination_name}</td>
													</tr>
												)
												reportData.dataToPrint[key][0].samples.map((s, smkye) => {
													let header_css = {
														width: 150,
														flexDirection: "row",
														fontSize: 13,
														border: "1px solid #000",
														padding: "2px 5px 1px 5px",
														backgroundColor: "#FFF",
														textAlign: "center",
													}
													let header_css_no_border = {
														width: 150,
														flexDirection: "row",
														fontSize: 13,
														//border: "1px solid #000",
														padding: "2px 5px 1px 5px",
														backgroundColor: "#FFF",
														textAlign: "center",
													}
													if (s.sample_name) {

														let lowerSampleLength = '';
														let upperSampleLength = '';
														if(s.sample_length){
															let sample_length_arr = s.sample_length.split(" - ");
															lowerSampleLength = Number(sample_length_arr[0]).toFixed(2); 
															upperSampleLength = Number(sample_length_arr[1]).toFixed(2);
														}

														// headingRow.push(
														// 	<tr key={`${smkye}__${Math.random()}__${s.sample_name}`}>
														// 		<td style={styles.disposal_header} key={`${s.probingcode}_${Math.random()}`}>
														// 			{s.probingcode}
														// 		</td>
														// 		<td style={styles.disposal_header} key={`${s.sample_name_range}__${Math.random()}`}>
														// 			{s.sample_name_range}
														// 		</td>
														// 		<td style={styles.disposal_header} key={`${s.sample_observation}__${Math.random()}`}>
														// 			{s.sample_observation}
														// 		</td>
														// 		<td style={styles.disposal_header} key={`${s.sample_length}___${Math.random()}`}>
														// 			{/* {s.sample_length?(Number(s.sample_length)/100).toFixed(2):"-"} */}
														// 			{s.sample_length ? s.sample_length : "-"}
														// 		</td>
														// 		<td style={styles.disposal_header_lithology} key={`${s.lithology}__${Math.random()}`}>
														// 			{s.lithology}
														// 		</td>

														// 		<td style={styles.disposal_header} key={`${s.colourname}__${Math.random()}`}>
														// 			{s.colourname}
														// 		</td>
														// 	</tr>
														// )
														if (smkye == 0) {
															probingArr.push(
																<td style={styleshtml.testFamilyName}>{client_probing_txt}</td>
															)
															sampleArr.push(
																<td style={styleshtml.testFamilyName}>{client_sample}</td>
															)
															sampleObservationArr.push(
																<td style={styleshtml.testFamilyName}>{client_Observation}</td>
															)
															depthArr.push(
																<td style={styleshtml.testFamilyName}>{client_Depth}</td>
															)
															lithologyArr.push(
																<td style={styleshtml.testFamilyName}>{client_probing_layer_lithology}</td>
															)
															colourArr.push(
																<td style={styleshtml.testFamilyName}>{client_probing_layer_colour}</td>
															)
														}
														probingArr.push(
															<td style={header_css} key={`${s.probingcode}_${Math.random()}`}>
																{s.probingcode}
															</td>
														)
														sampleArr.push(
															<td style={header_css} key={`${s.sample_name_range}__${Math.random()}`}>
																{s.sample_name_range}
															</td>
														)
														sampleObservationArr.push(
															<td style={header_css} key={`${s.sample_observation}__${Math.random()}`}>
																{s.sample_observation}
															</td>
														)
														depthArr.push(
															<td style={header_css} key={`${s.sample_length}___${Math.random()}`}>
																{/* {s.sample_length?(Number(s.sample_length)/100).toFixed(2):"-"} */}
																{/* {s.sample_length ? s.sample_length : "-"} */}
																{s.sample_length ? (lowerSampleLength + ' - ' + upperSampleLength) : "-"}
															</td>
														)
														lithologyArr.push(
															<td style={styles.disposal_header_lithology} key={`${s.lithology}__${Math.random()}`}>
																{s.lithology}
															</td>
														)
														colourArr.push(
															<td style={styles.disposal_header} key={`${s.colourname}__${Math.random()}`}>
																{s.colourname}
															</td>
														)
													} else {
														// headingRow.push(
														// 	<tr key={`${smkye}__${Math.random()}__${s.sample_name}`}>
														// 		<td style={{ width: 150 }}></td>
														// 		<td style={{ width: 150 }}></td>
														// 		<td style={{ width: 150 }}></td>
														// 		<td style={{ width: 150 }}></td>
														// 		<td style={{ width: 150 }}></td>
														// 	</tr>
														// )
														probingArr.push(
															<th style={header_css_no_border}></th>
														)
														sampleArr.push(
															<th style={header_css_no_border}></th>
														)
														sampleObservationArr.push(
															<th style={header_css_no_border}></th>
														)
														depthArr.push(
															<th style={header_css_no_border}></th>
														)
														lithologyArr.push(
															<th style={header_css_no_border}></th>
														)
														colourArr.push(
															<th style={header_css_no_border}></th>
														)
													}
												})

												{
													hideDisposalInReport == false && reportData.desposal_list.length !== 0 && reportData.desposal_list.map((disposal) => {
														let header_css = {
															//width: 200,
															width: 100,
															flexDirection: "row",
															fontSize: 16,
															border: "1px solid #000",
															padding: "5px",
															textAlign: "center",
															backgroundColor: "" + disposal.color + ""
														}
														// headingRow.push(
														// 	<td style={header_css} key={`${disposal.disposal_id}__${Math.random()}`}>{disposal.name}</td>
														// )

														probingArr.push(
															<td rowspan="6" style={header_css} key={`${disposal.disposal_id}__${Math.random()}`}>{disposal.name}</td>
														)
													})
												}
												let test_header_css = {
													flexDirection: "row",
													fontSize: 14,
													border: "1px solid #000",
													padding: "3px",
													textAlign: "center",
													backgroundColor: "#0d6dbd",
													width: "100%",
													color: "#FFF"
												}
												maintest.map((result, k) => {
													allRows.push(<tr style={test_header_css} key={`${Math.random()}`}><td colspan={totalColspan}>{result.family_name}</td></tr>)
													result.tests_list.map((res, tk) => {
														let singleRow = []
														singleRow.push(
															<td style={styles.testFamilyName} key={`${tk}__${res.testFamily}`}>{res.testFamily}</td>
														)
														res.samples.map((s, smplkey) => {
															let header_css = {
																width: 150,
																flexDirection: "row",
																fontSize: 14,
																border: "1px solid #000",
																padding: "2px 5px 1px 5px",
																backgroundColor: "#FFF",
																textAlign: "center",
															}
															if (s.hasOwnProperty("matched_desp")) {
																if (s.matched_desp.hasOwnProperty("color")) {
																	header_css.backgroundColor = s.matched_desp.color
																}
															}
															if (s.hasOwnProperty("stringnotmatched")) {
																if (s.stringnotmatched == true) {
																	header_css.backgroundColor = "lightgrey";
																}
															}

															if (s.sample_name) {
																singleRow.push(
																	<td style={header_css} key={`${tk}__${smplkey}`}>{s.value}</td>
																)
															} else {
																singleRow.push(
																	<td style={header_css} key={`${tk}__${smplkey}`}></td>
																)
															}
														})

														{
															hideDisposalInReport == false && reportData.desposal_list.length !== 0 && reportData.desposal_list.map((disposal, dkey) => {
																let header_css = {
																	//width: 200,
																	width: 100,
																	flexDirection: "row",
																	fontSize: 14,
																	border: "1px solid #000",
																	padding: "2px 5px 1px 5px",
																	textAlign: "center",
																}
																let checkDisposal = res.disposals.filter(d => d.desposal_id === disposal.desposal_id)
																if (checkDisposal.length !== 0) {
																	return singleRow.push(
																		<td style={header_css} key={`${disposal.disposal_id}_${Math.random()}_`}>{checkDisposal[0].rules_val ? checkDisposal[0].rules_val : ""}</td>
																	)
																} else {
																	return singleRow.push(
																		<td style={header_css} key={`${disposal.disposal_id}_${Math.random()}_`}>{ }</td>
																	)
																}
															})
														}
														allRows.push(<tr style={styles.fullWidth} key={`${Math.random()}`}>{singleRow}</tr>)
													})
												})
												let matchedDisposal = []
												reportData.dataToPrint[key][0].samples.map((ss) => {
													let temmMatched = reportData.higestMatchedDisposals[key].filter(dd => dd.sample_name == ss.sample_name)
													let bg_color = ""
													let d_name = ""

													if (temmMatched.length !== 0) {
														if (temmMatched[0].last_matched_desposal.hasOwnProperty("color")) {
															bg_color = temmMatched[0].last_matched_desposal.color
															d_name = temmMatched[0].last_matched_desposal.name
															let header_css = {
																width: 150,
																flexDirection: "row",
																fontSize: 17,
																padding: "4px 5px 1px 5px",
																backgroundColor: bg_color,
																border: "2px solid #000",
																textAlign: "center",
															}
															matchedDisposal.push(<td style={header_css} key={`${ss.sample_name}_${Math.random()}`} >{d_name}</td>)
														} else {
															matchedDisposal.push(<td style={{ width: 150, border: "2px solid #000" }} key={`${ss.sample_name}_${Math.random()}`}></td>)
														}
													} else {
														matchedDisposal.push(<th style={{ width: 150, border: "none !important" }} key={`${ss.sample_name}_${Math.random()}`}></th>)
													}
												})
												/* */
												{
													hideDisposalInReport == false && reportData.desposal_list.length !== 0 && reportData.desposal_list.map((disposal) => {
														let header_css = {
															//width: 200,
															width: 100,
															flexDirection: "row",
															border: "none !important"
														}
														return matchedDisposal.push(
															<th style={header_css} key={`${Math.random()}`}> </th>
														)
													})
												}

												/* */
												return (
													<>
														<Table id={"reporttbl_" + key} size={[width, heigth]} style={styleshtml.page} wrap key={`page__${Math.random()}__`}>
															{headerDetails}

															{/* <tr>
                                            <td style={styles.headerProbingSampleColoredBox}>{client_probing_txt}</td>
                                            <td style={styles.headerProbingSampleColoredBox}>{client_sample}</td>
                                            <td style={styles.headerProbingSampleColoredBox}>{client_Depth}</td>
                                        </tr> */}

															<tr style={styles.fullWidth} key={`${Math.random()}`}>
																{probingArr}
															</tr>
															<tr style={styles.fullWidth} key={`${Math.random()}`}>
																{sampleArr}
															</tr>
															<tr style={styles.fullWidth} key={`${Math.random()}`}>
																{sampleObservationArr}
															</tr>
															<tr style={styles.fullWidth} key={`${Math.random()}`}>
																{depthArr}
															</tr>
															<tr style={styles.fullWidth} key={`${Math.random()}`}>
																{lithologyArr}
															</tr>
															<tr style={styles.fullWidth} key={`${Math.random()}`}>
																{colourArr}
															</tr>

															{/* {headingRow} */}

															{allRows}

															{!reportData.selectedPollutantFamilies.length &&
																<tr style={styles.fullWidth}>
																	<td style={styleshtml.disposalNameAtLast}>{client_disposal_process}</td>
																	{matchedDisposal}
																</tr>
															}

															{reportData.dataToPrintPdf.length != key + 1 && (<tr style={styleshtml.heightthirty} break></tr>)}
														</Table>
													</>
												)
											})}
										</>
									) : ""
									}

								</div>
							</div>
						</>
					) : ""
					}
				</>
			)}
		</>
	);
}
