import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import fingerprint from 'fingerprintjs';
import jwt from 'jsonwebtoken';

var credential = localStorage.getItem("_user_")
if (credential) {
    try{
        let finderprint = new fingerprint().get();
        credential = jwt.verify(credential, finderprint.toString());
    }catch{
        localStorage.clear();
        window.location.href = "/";
    }    
}

var userDetail = localStorage.getItem("_userDetail_")
if (userDetail) {
    try{
        let finderprint = new fingerprint().get();
        userDetail = jwt.verify(userDetail, finderprint.toString());
    }catch{    
        localStorage.clear();
        window.location.href = "/"; 
    }    
}

const initialState = {
    userData: credential,
    userDetail: userDetail
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCEED:
            return updateObject(state, { userData: action.state })
        case actionTypes.LOGIN_FAILED:
            return updateObject(state, { error: 'Invalid Credentials' })
        case actionTypes.COMPANY_PROJECT_LIST_LOGIN:
            return updateObject(state, { companyList: action.state })
        case actionTypes.COMPANY_PROJECT_LIST_FOR_NOTFOUND:
            return updateObject(state, { allProjectListForNotfound: action.state })
        default:
            return state;
    }
}

export default reducer