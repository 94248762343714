import proj4 from 'proj4';

proj4.defs([
	// Lambert 93 2154
	[
		'EPSG:2154',
		'+title=Lambert 93 +proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
	],
	// lcc42
	[
		'EPSG:3942',
		'+title=LCC42 +proj=lcc +lat_1=41.25 +lat_2=42.75 +lat_0=42 +lon_0=3 +x_0=1700000 +y_0=1200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
	],
	// lcc43
	[
		'EPSG:3943',
		'+title=LCC43 +proj=lcc +lat_1=42.25 +lat_2=43.75 +lat_0=43 +lon_0=3 +x_0=1700000 +y_0=2200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
	],
	// lcc44 3944
	[
		'EPSG:3944',
		'+title=LCC44 +proj=lcc +lat_1=43.25 +lat_2=44.75 +lat_0=44 +lon_0=3 +x_0=1700000 +y_0=3200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
	],
	// lcc 45
	[
		'EPSG:3945',
		'+title=LCC45 +proj=lcc +lat_1=44.25 +lat_2=45.75 +lat_0=45 +lon_0=3 +x_0=1700000 +y_0=4200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
	],
	// lcc46
	[
		'EPSG:3946',
		'+title=LCC46 +proj=lcc +lat_1=45.25 +lat_2=46.75 +lat_0=46 +lon_0=3 +x_0=1700000 +y_0=5200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
	],
	// lcc47
	[
		'EPSG:3947',
		'+title=LCC47 +proj=lcc +lat_1=46.25 +lat_2=47.75 +lat_0=47 +lon_0=3 +x_0=1700000 +y_0=6200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
	],
	// lcc48
	[
		'EPSG:3948',
		'+title=LCC48 +proj=lcc +lat_1=47.25 +lat_2=48.75 +lat_0=48 +lon_0=3 +x_0=1700000 +y_0=7200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
	],
	// lcc49
	[
		'EPSG:3949',
		'+title=LCC49 +proj=lcc +lat_1=48.25 +lat_2=49.75 +lat_0=49 +lon_0=3 +x_0=1700000 +y_0=8200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
	],
	// lcc50
	[
		'EPSG:3950',
		'+title=LCC50 +proj=lcc +lat_1=49.25 +lat_2=50.75 +lat_0=50 +lon_0=3 +x_0=1700000 +y_0=9200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
	],
]);

export default proj4;

const CoordsByCode = [
	{ number: '01', name: 'AIN', coords: 'EPSG:3946' },
	{ number: '02', name: 'AISNE', coords: 'EPSG:3949' },
	{ number: '03', name: 'ALLIER', coords: 'EPSG:3946' },
	{ number: '04', name: 'ALPES DE HAUTE PROVENCE', coords: 'EPSG:3944' },
	{ number: '05', name: 'HAUTES ALPES', coords: 'EPSG:3945' },
	{ number: '06', name: 'ALPES MARITIMES', coords: 'EPSG:3944' },
	{ number: '07', name: 'ARDÈCHE', coords: 'EPSG:3945' },
	{ number: '08', name: 'ARDENNES', coords: 'EPSG:3950' },
	{ number: '09', name: 'ARIÈGE', coords: 'EPSG:3943' },
	{ number: '10', name: 'AUBE', coords: 'EPSG:3948' },
	{ number: '11', name: 'AUDE', coords: 'EPSG:3943' },
	{ number: '12', name: 'AVEYRON', coords: 'EPSG:3944' },
	{ number: '13', name: 'BOUCHES DU RHÔNE', coords: 'EPSG:3944' },
	{ number: '14', name: 'CALVADOS', coords: 'EPSG:3949' },
	{ number: '15', name: 'CANTAL', coords: 'EPSG:3945' },
	{ number: '16', name: 'CHARENTE', coords: 'EPSG:3946' },
	{ number: '17', name: 'CHARENTE MARITIME', coords: 'EPSG:3946' },
	{ number: '18', name: 'CHER', coords: 'EPSG:3947' },
	{ number: '19', name: 'CORRÈZE', coords: 'EPSG:3945' },
	{ number: '2A', name: 'CORSE DU SUD', coords: 'EPSG:3942' },
	{ number: '2B', name: 'HAUTE CORSE', coords: 'EPSG:3942' },
	{ number: '21', name: "COTE D'OR", coords: 'EPSG:3947' },
	{ number: '22', name: "COTES D'ARMOR", coords: 'EPSG:3948' },
	{ number: '23', name: 'CREUSE', coords: 'EPSG:3946' },
	{ number: '24', name: 'DORDOGNE', coords: 'EPSG:3945' },
	{ number: '25', name: 'DOUBS', coords: 'EPSG:3947' },
	{ number: '26', name: 'DROME', coords: 'EPSG:3945' },
	{ number: '27', name: 'EURE', coords: 'EPSG:3949' },
	{ number: '28', name: 'EURE ET LOIR', coords: 'EPSG:3948' },
	{ number: '29', name: 'FINISTÈRE', coords: 'EPSG:3948' },
	{ number: '30', name: 'GARD', coords: 'EPSG:3944' },
	{ number: '31', name: 'HAUTE GARONNE', coords: 'EPSG:3943' },
	{ number: '32', name: 'GERS', coords: 'EPSG:3944' },
	{ number: '33', name: 'GIRONDE', coords: 'EPSG:3945' },
	{ number: '34', name: 'HÉRAULT', coords: 'EPSG:3943' },
	{ number: '35', name: 'ILE ET VILAINE', coords: 'EPSG:3948' },
	{ number: '36', name: 'INDRE', coords: 'EPSG:3947' },
	{ number: '37', name: 'INDRE ET LOIRE', coords: 'EPSG:3947' },
	{ number: '38', name: 'ISÈRE', coords: 'EPSG:3945' },
	{ number: '39', name: 'JURA', coords: 'EPSG:3947' },
	{ number: '40', name: 'LANDES', coords: 'EPSG:3944' },
	{ number: '41', name: 'LOIR ET CHER', coords: 'EPSG:3948' },
	{ number: '42', name: 'LOIRE', coords: 'EPSG:3946' },
	{ number: '43', name: 'HAUTE LOIRE', coords: 'EPSG:3945' },
	{ number: '44', name: 'LOIRE ATLANTIQUE', coords: 'EPSG:3947' },
	{ number: '45', name: 'LOIRET', coords: 'EPSG:3948' },
	{ number: '46', name: 'LOT', coords: 'EPSG:3945' },
	{ number: '47', name: 'LOT ET GARONNE', coords: 'EPSG:3944' },
	{ number: '48', name: 'LOZÈRE', coords: 'EPSG:3944' },
	{ number: '49', name: 'MAINE ET LOIRE', coords: 'EPSG:3947' },
	{ number: '50', name: 'MANCHE', coords: 'EPSG:3949' },
	{ number: '51', name: 'MARNE', coords: 'EPSG:3949' },
	{ number: '52', name: 'HAUTE MARNE', coords: 'EPSG:3948' },
	{ number: '53', name: 'MAYENNE', coords: 'EPSG:3948' },
	{ number: '54', name: 'MEURTHE ET MOSELLE', coords: 'EPSG:3949' },
	{ number: '55', name: 'MEUSE', coords: 'EPSG:3949' },
	{ number: '56', name: 'MORBIHAN', coords: 'EPSG:3948' },
	{ number: '57', name: 'MOSELLE', coords: 'EPSG:3949' },
	{ number: '58', name: 'NIÈVRE', coords: 'EPSG:3947' },
	{ number: '59', name: 'NORD', coords: 'EPSG:3950' },
	{ number: '60', name: 'OISE', coords: 'EPSG:3949' },
	{ number: '61', name: 'ORNE', coords: 'EPSG:3949' },
	{ number: '62', name: 'PAS DE CALAIS', coords: 'EPSG:3950' },
	{ number: '63', name: 'PUY DE DÔME', coords: 'EPSG:3946' },
	{ number: '64', name: 'PYRÉNÉES ATLANTIQUES', coords: 'EPSG:3943' },
	{ number: '65', name: 'HAUTES PYRÉNÉES', coords: 'EPSG:3943' },
	{ number: '66', name: 'PYRÉNÉES ORIENTALES', coords: 'EPSG:3943' },
	{ number: '67', name: 'BAS RHIN', coords: 'EPSG:3949' },
	{ number: '68', name: 'HAUT RHIN', coords: 'EPSG:3948' },
	{ number: '69', name: 'RHÔNE', coords: 'EPSG:3946' },
	{ number: '70', name: 'HAUTE SAÔNE', coords: 'EPSG:3948' },
	{ number: '71', name: 'SAÔNE ET LOIRE', coords: 'EPSG:3947' },
	{ number: '72', name: 'SARTHE', coords: 'EPSG:3948' },
	{ number: '73', name: 'SAVOIE', coords: 'EPSG:3945' },
	{ number: '74', name: 'HAUTE SAVOIE', coords: 'EPSG:3946' },
	{ number: '75', name: 'PARIS', coords: 'EPSG:3949' },
	{ number: '76', name: 'SEINE MARITIME', coords: 'EPSG:3950' },
	{ number: '77', name: 'SEINE ET MARNE', coords: 'EPSG:3949' },
	{ number: '78', name: 'YVELINES', coords: 'EPSG:3949' },
	{ number: '79', name: 'DEUX SÈVRES', coords: 'EPSG:3947' },
	{ number: '80', name: 'SOMME', coords: 'EPSG:3950' },
	{ number: '81', name: 'TARN', coords: 'EPSG:3944' },
	{ number: '82', name: 'TARN ET GARONNE', coords: 'EPSG:3944' },
	{ number: '83', name: 'VAR', coords: 'EPSG:3943' },
	{ number: '84', name: 'VAUCLUSE', coords: 'EPSG:3944' },
	{ number: '85', name: 'VENDEE', coords: 'EPSG:3947' },
	{ number: '86', name: 'VIENNE', coords: 'EPSG:3947' },
	{ number: '87', name: 'HAUTE VIENNE', coords: 'EPSG:3946' },
	{ number: '88', name: 'VOSGES', coords: 'EPSG:3948' },
	{ number: '89', name: 'YONNE', coords: 'EPSG:3948' },
	{ number: '90', name: 'TERRITOIRE DE BELFORT', coords: 'EPSG:3948' },
	{ number: '91', name: 'ESSONNE', coords: 'EPSG:3949' },
	{ number: '92', name: 'HAUTS DE SEINE', coords: 'EPSG:3949' },
	{ number: '93', name: 'SEINE SAINT DENIS', coords: 'EPSG:3949' },
	{ number: '94', name: 'VAL DE MARNE', coords: 'EPSG:3949' },
	{ number: '95', name: "VAL D'OISE", coords: 'EPSG:3949' },
];

export function getCoordsFromAddress(county, address) {
	if (county && !isNaN(county)) {
		const entry = CoordsByCode.find(c => c.number === county.toString());
		if (entry) return entry.coords;
	}
	const numbers = /\d{5}/.exec(address);
	if (numbers?.length) {
		const zipcode = numbers[0];
		const number = zipcode.slice(0, 2);
		const entry = CoordsByCode.find(c => c.number === number);
		if (!entry) return 'WGS84';
		return entry.coords;
	}
}
