import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Spinner } from 'reactstrap';
import * as actions from '../../../../Store/actions/index';
import { getMaterialListfilter } from '../../Common/materialList';

export default function SelectProbingMethod({ probingMethod, onChange }) {
	const [probingMethods, setProbingMethods] = useState([]);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const masterReducer = useSelector(state => state.masterReducer);
	const apolloClient = useSelector(state => state.apolloClient);

	useEffect(() => {
		dispatch(actions.getProbingMethod(apolloClient.masters, []));
		setLoading(true);
	}, []);

	useEffect(() => {
		if (!masterReducer.probingMethodList) return;
		setLoading(false);

		const probingmethodArray = getMaterialListfilter(masterReducer.probingMethodList);
		setProbingMethods(probingmethodArray.map(pm => ({ value: pm._id, label: pm.name })));
	}, [masterReducer.probingMethodList]);

	if (loading) return <Spinner size="sm" className="btn-spinner" />;
	return (
		<Select
			value={probingMethods.find(m => m.value === probingMethod)}
			onChange={pm => onChange(pm)}
			options={probingMethods}
			className="react-select"
		/>
	);
}
