import React, { useState } from 'react';
import { Star } from '@material-ui/icons';
import styled from 'styled-components';
// import { Button } from 'reactstrap';
import { Icon } from '@material-ui/core';

const LegendItem = function({ value, dragButton, onCheck }) {
	//index, onNewIdx, maxIndex,

	const [checked, setChecked] = useState(true);

	function handleCheck() {
		const newValue = !checked;
		setChecked(newValue);
		onCheck(newValue);
	}

	return (
		<LegendItemContainer dragButton={dragButton}>
			{Array.isArray(value.colors) && value.colors.length > 1 ? (
				<Colors colors={value.colors} />
			) : (
				<Color color={value.color} />
			)}
			{value.label || value.name}
			{value.standard ? <Star /> : null}
			{dragButton && (
				<div className="arrows">
					<Icon style={{ color: '#aaa' }}>menu</Icon>
				</div>
			)}
			{typeof onCheck === 'function' && (
				<div className="arrows pointer" onClick={handleCheck}>
					<Icon style={{ color: '#aaa' }}>{checked ? 'visibility' : 'visibility_off'}</Icon>
				</div>
			)}
		</LegendItemContainer>
	);
};

export default LegendItem;

const Colors = ({ colors }) => {
	return (
		<div
			style={{
				width: '1rem',
				height: '1rem',
				display: 'grid',
				gridTemplateColumns: 'auto',
				border: '1px solid black',
			}}
		>
			{colors.map(color => (
				<div key={color} style={{ backgroundColor: color, height: '100%' }}></div>
			))}
		</div>
	);
};

const LegendItemContainer = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid #ddd;
	padding: ${props => (props.dragButton ? '0.5rem' : '0')};
	padding-inline: 0.25em;
	gap: 0.25em;
	background-color: white;
	svg {
		width: 16px;
		fill: yellow;
		stroke: var(--clr-aexdo);
	}
	.arrows {
		display: flex;
		margin-left: auto;
		svg {
			width: 16px;
			stroke: var(--clr-aexdo);
			margin: 0;
		}
		button {
			margin: 0;
		}
	}
	.pointer {
		cursor: pointer;
	}
`;

const Color = styled.div`
	background-color: ${props => props.color};
	width: 1rem;
	height: 1rem;
	border: 1px solid black;
`;
