
export const  matchWithNextDisposals = (request) =>{


    const checkInnerRule2 = (rules,sample_val,sheet_data,fsampl,desposal_list,auto_true_arr,tst_child_id,inr_tst_child_id_1) => {

        //Here in above argument tst_child_id is the child id of pollutant beign checked.
        //so if child id of pollutant being checked found in rule-2 test pollutants rules then pollutant beign checked getting false automatically. 
        //inr_tst_child_id_1 is the childid of the testname that is selected in the dropdown in rule 2
        //so solve the Infinite loops error in Circulr Rule-2 and page getting stuck error.

        let flag = false;
        let condition = rules.formula.condition;

        let my_rules_val = rules.value;
        if (typeof sample_val == "number") {
            if (!my_rules_val) {
                flag = false;
            } else if (condition == "less") {
                if (sample_val <= my_rules_val) {
                    flag = true;
                }
            } else if (condition == "greter") {
                if (sample_val >= my_rules_val) {
                    flag = true;
                }
            }
        }

        if(flag == false){

            let all_test_truefalse_arr = [];

            let checkpollutantbeingcheckedexist = rules.formula.test.filter((l) => l.id == tst_child_id).length; //Check this to solve the infinite loop issue
            let checkpollutantbeingcheckedexist1 = rules.formula.test.filter((l) => l.id == inr_tst_child_id_1).length; //Check this to solve the infinite loop issue
            if(checkpollutantbeingcheckedexist > 0 || checkpollutantbeingcheckedexist1 > 0){
                flag = false;
            }
            else{

                if (typeof sample_val == "number") {
                    for (let inr_tst of rules.formula.test) {
                        let inr_flag = false;
                        let find_fil = sheet_data.filter(t=>t.Test_Child_Id == inr_tst.id && t.Test_Parent_Id == inr_tst.Test_Parent_Id);
                        if (find_fil[0] && find_fil[0][fsampl]) {
                            
                            let inr_val = find_fil[0][fsampl];
                            let inr_Test_Parent_Id = find_fil[0].Test_Parent_Id;
                            let inr_tst_child_id = find_fil[0].Test_Child_Id;
                        
                            if ((typeof inr_val == "number" || typeof inr_val == "string") && inr_val != "-") {

                                for (let fil_desp1 of desposal_list) {
                                    let rules1 = this.state.alldesposalRuleList.filter(t=>t.desposal_id == fil_desp1.desposal_id && t.test_id == inr_Test_Parent_Id && t.child_id == inr_tst_child_id)[0];
                                    
                                    if (typeof inr_val == "string" && inr_val != "-" && rules1) {
                                        for (let fil_text of auto_true_arr) {
                                            if (inr_val.includes(fil_text) && inr_flag == false) {
                                            inr_flag = true;
                                            all_test_truefalse_arr.push(inr_flag);
                                            }
                                        }
                                    }

                                    if (!inr_flag && rules1 && rules1.option_id == "1") {
                                        let condition = rules1.formula.condition;
                                        //rules_val = rules1.value;
                                        let my_rules_val = rules1.value;
                                        if (typeof inr_val == "number") {
                                            if (!my_rules_val) {
                                            inr_flag = false;
                                            all_test_truefalse_arr.push(inr_flag);
                                            } else if (condition == "less") {
                                                if (inr_val <= my_rules_val) {
                                                    inr_flag = true;
                                                    all_test_truefalse_arr.push(inr_flag);
                                                }
                                            } else if (condition == "greter") {
                                                if (inr_val >= my_rules_val) {
                                                    inr_flag = true;
                                                    all_test_truefalse_arr.push(inr_flag);
                                                }
                                            }
                                        }
                                    }

                                    else if (!inr_flag && rules1 && rules1.option_id == "2") {

                                        inr_flag = checkInnerRule2(rules1,inr_val,sheet_data,fsampl,desposal_list,auto_true_arr,tst_child_id,inr_tst_child_id_1);
                                        all_test_truefalse_arr.push(inr_flag);

                                    }


                                    else if (!inr_flag && rules1 && rules1.option_id == "3") {
                                        if (typeof inr_val == "number") {
                                            //rules_val = rules.value;
                                            //rules_val = "No Limit";
                                            inr_flag = true;
                                            all_test_truefalse_arr.push(inr_flag);
                                        }
                                    }

                                    else if (!inr_flag && rules1 && rules1.option_id == "4") {
                                        let condition = rules1.formula.condition;
                                        //rules_val = rules.value;
                                        //rules_val = (condition == "greter" ? ">" : "<") + "" + rules1.formula.percentage + "% of " + rules.formula.test[0].name;
                                        if (typeof inr_val == "number") {
                                            for (let inr_tst2 of rules1.formula.test) {
                                                let find_fil2 = sheet_data.filter(t=>t.Test_Child_Id == inr_tst2.id && t.Test_Parent_Id == inr_tst2.Test_Parent_Id);
                                                if (find_fil2[0] && find_fil2[0][fsampl]) {
                                                    let inr_val2 = find_fil2[0][fsampl];
                                                    if (typeof inr_val2 == "number") {//New added line condition
                                                        let calc_val = inr_val2 * rules1.formula.percentage / 100;
                                                        if (condition == "less" && inr_val < calc_val) {
                                                            inr_flag = true;
                                                            all_test_truefalse_arr.push(inr_flag);
                                                        }
                                                        else if (condition == "greter" && inr_val > calc_val) {
                                                            inr_flag = true;
                                                            all_test_truefalse_arr.push(inr_flag);
                                                        }
                                                    }
        
                                                }
                                            }
                                        }
                                    }





                                }

                            }
                        }
                    }
                }

            }

            
            let trueCount = all_test_truefalse_arr.filter(Boolean).length;
            let totalAddedTest = rules.formula.test.length;
            //All added test pollutats must be satisfied to approve this current pollutant
            if(totalAddedTest == trueCount){
                flag = true;
            }
            
        }

        return flag;
    }



    // ========================== Code Start ==========================
    
    let excelDataList =  request.excelDataList
    let selectedDisposalFamily = request.selectedDisposalFamily
    let alldesposalRuleList =  request.alldesposalRuleList
    //let activedesposalList = [request.activedesposalList]
    let sampleData = request.sample_data
    let allProbingSamples = request.allProbingSamples
    let allPollutants = request.allPollutants
    let exceptionConst = request.exceptionConst
    let problematicPollutant = request.allPollutantMatchedWithDisposal
    let allDisposalList = request.allDisposalList

    //let selectedDisposalFamily = []; //My code

    let lastMatchedDisposalIndex = sampleData.matched_desp.disposal_key;
    //let nextDisposal = allDisposalList.filter((l,i) => i > lastMatchedDisposalIndex);
    let nextDisposal = allDisposalList.filter((l,i) => l.disposal_key > lastMatchedDisposalIndex);

    let problematicTestChildId = problematicPollutant.problematicPollutants.map(value => value.tst_child_id);

    let activedesposalList = nextDisposal;
    //let excelDataList = this.state.excelDataList;

    let probingSamplesToIterate = allProbingSamples.filter((s) => s.sampleNo == sampleData.sample_name);
    
    //let temp_tst_arr = [];
    //let test_list = [];
    //let matched_sample_list = [];
    //let tempAllSample = [];
    let sampl_finl1 = []; // I move here in this function.
    for (let sht_tst_data_1 of excelDataList) {
        //access selected campaign uploaded sheet
        let sheet_data = sht_tst_data_1.sheet_resp_data;
        for (let sht_tst_data of sheet_data) {
        let tst_name = sht_tst_data.Testfamily;
        let tst_child_id = sht_tst_data.Test_Child_Id;
        let Test_Parent_Id = sht_tst_data.Test_Parent_Id;

        //pollutant family should be exist in the Selected Disposal family.
        let isFamilyexistinSelDispo = selectedDisposalFamily.filter((h) => h == Test_Parent_Id).length;
        //let allPollutants = this.state.allPollutants;
            
        //if (Test_Parent_Id && isFamilyexistinSelDispo > 0) {
        //if (Test_Parent_Id && (allPollutants == false ? isFamilyexistinSelDispo > 0 : allPollutants)) {
        if (selectedDisposalFamily.length <= 0 || (selectedDisposalFamily.length > 0 && isFamilyexistinSelDispo > 0)) {
            //Loop through only problematic pollutants
            if(problematicTestChildId.includes(tst_child_id)){
            //let sampl_finl1 = [];
            //Loop through all probings samples of selected campaign 
            for (let fil_smpl_coll of probingSamplesToIterate) {
                //console.log("fil_smpl_coll = ", fil_smpl_coll);
                //let lithology = fil_smpl_coll.lithology;
                //let colourname = fil_smpl_coll.colourname;
                //let colourcode = fil_smpl_coll.colourcode;                    
                //let probingcode = fil_smpl_coll.probingcode;
                //let campaignid = fil_smpl_coll.campaignid;
                //let middel_depth = fil_smpl_coll.middel_depth;
                //let sample_observation = fil_smpl_coll.observation;
                //let sample_depth_in_probing = fil_smpl_coll.sample_depth_in_probing;
                //let sample_uplowrange = fil_smpl_coll.uplowrange;
                let fsampl = fil_smpl_coll.sampleNo;
                let smpl_val = sht_tst_data[fsampl]; //Get the sample value from excel sheet

                //If we have sample no, its value and parent id then
                if (fsampl && (smpl_val || smpl_val == "") && Test_Parent_Id) {
                    //matched_sample_list.push(fsampl);

                    let test_done = false;
                    let temp_desp_res = {};
                    let desposal_list = activedesposalList;
                    //let auto_true_arr = ["<LQ", "<", "-", "n.d.", "nd"];
                    let auto_true_arr = exceptionConst;
                    //let all_desp_data = [];
                    //let stringnotmatched = false;
                    let rulesExistForPollutant = false;
                    //Loop through all disposal list
                    for (let fil_desp of desposal_list) {
                        
                        //Fetch the rule of testname
                        let rules = alldesposalRuleList.filter(t=>t.desposal_id == fil_desp.desposal_id && t.test_id == Test_Parent_Id && t.child_id == tst_child_id)[0];
                        let flag = false;
                        let rules_val = 0;
                        let sample_val = smpl_val;

                        if(rules){
                            rulesExistForPollutant = true;
                        }

                        if (typeof sample_val == "string" && sample_val != "-" && rules) {
                            for (let fil_text of auto_true_arr) {
                                if (sample_val.includes(fil_text)) {
                                flag = true;

                                /*New added code start */
                                if (rules.option_id == "1") {
                                    rules_val = rules.value;
                                }
                                else if (rules.option_id == "2") {
                                    rules_val = rules.value + "*";
                                }
                                else if (rules.option_id == "3") {
                                    rules_val = "No Limit";
                                }
                                else if (rules.option_id == "4") {
                                    rules_val = (rules.formula.condition == "greter" ? ">" : "<") + "" + rules.formula.percentage + "% of " + rules.formula.test[0].name;
                                }
                                /*New added code end */

                                }
                            }
                        }


                        if (!flag && rules && rules.option_id == "1") {
                        let condition = rules.formula.condition;
                        rules_val = rules.value;
                            if (typeof sample_val == "number") {
                                if (!rules_val) {
                                flag = false;
                                } else if (condition == "less") {
                                if (sample_val <= rules_val) {
                                    flag = true;
                                }
                                } else if (condition == "greter") {
                                if (sample_val >= rules_val) {
                                    flag = true;
                                }
                                }
                            }
                        }

                        else if(!flag && rules && rules.option_id == "2") {
                            let condition = rules.formula.condition;

                            rules_val = rules.value + "*";

                            let my_rules_val = rules.value;
                            if (typeof sample_val == "number") {
                                if (!my_rules_val) {
                                    flag = false;
                                } else if (condition == "less") {
                                    if (sample_val <= my_rules_val) {
                                        flag = true;
                                    }
                                } else if (condition == "greter") {
                                    if (sample_val >= my_rules_val) {
                                        flag = true;
                                    }
                                }
                            }

                            if(flag == false){
                                let all_test_truefalse_arr = [];
                                if (typeof sample_val == "number") {
                                    //Loop through all added pollutants
                                    for (let inr_tst of rules.formula.test) {
                                        let inr_flag = false;
                                        //Check Added pollutant is exist in the uploaded excelsheet
                                        let find_fil = sheet_data.filter(t=>t.Test_Child_Id == inr_tst.id && t.Test_Parent_Id == inr_tst.Test_Parent_Id);
                                        if (find_fil[0] && find_fil[0][fsampl]) {
                                            let inr_val = find_fil[0][fsampl]; //Get the value of added pollutant sample
                                            let inr_Test_Parent_Id = find_fil[0].Test_Parent_Id; //Get added pollutant parent id
                                            let inr_tst_child_id = find_fil[0].Test_Child_Id; //Get added pollutant child id
                                            
                                            let mainLoopDisposal = [fil_desp];
                                            if ((typeof inr_val == "number" || typeof inr_val == "string") && inr_val != "-") {
                                                //Loop in main parent single disposal array
                                                for (let fil_desp1 of mainLoopDisposal) {
                                                    //Get the Rules of Added Pollutant
                                                    let rules1 = alldesposalRuleList.filter(t=>t.desposal_id == fil_desp1.desposal_id && t.test_id == inr_Test_Parent_Id && t.child_id == inr_tst_child_id)[0];
                                                    
                                                                if (typeof inr_val == "string" && inr_val != "-" && rules1) {
                                                                    for (let fil_text of auto_true_arr) {
                                                                        if (inr_val.includes(fil_text) && inr_flag == false) {
                                                                        inr_flag = true;
                                                                        all_test_truefalse_arr.push(inr_flag);
                                                                        }
                                                                    }
                                                                }

                                                                if (!inr_flag && rules1 && rules1.option_id == "1") {
                                                                    let condition = rules1.formula.condition;
                                                                    //rules_val = rules1.value;
                                                                    let my_rules_val = rules1.value;
                                                                    if (typeof inr_val == "number") {
                                                                        if (!my_rules_val) {
                                                                        inr_flag = false;
                                                                        all_test_truefalse_arr.push(inr_flag);
                                                                        } else if (condition == "less") {
                                                                            if (inr_val <= my_rules_val) {
                                                                                inr_flag = true;
                                                                                all_test_truefalse_arr.push(inr_flag);
                                                                            }
                                                                        } else if (condition == "greter") {
                                                                            if (inr_val >= my_rules_val) {
                                                                                inr_flag = true;
                                                                                all_test_truefalse_arr.push(inr_flag);
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                else if (!inr_flag && rules1 && rules1.option_id == "2") {
                                                                    inr_flag = checkInnerRule2(rules1,inr_val,sheet_data,fsampl,mainLoopDisposal,auto_true_arr,tst_child_id,inr_tst_child_id);
                                                                    all_test_truefalse_arr.push(inr_flag);
                                                                }


                                                                else if (!inr_flag && rules1 && rules1.option_id == "3") {
                                                                    if (typeof inr_val == "number") {
                                                                       
                                                                        inr_flag = true;
                                                                        all_test_truefalse_arr.push(inr_flag);
                                                                    }
                                                                }

                                                                else if (!inr_flag && rules1 && rules1.option_id == "4") {
                                                                    let condition = rules1.formula.condition;
                                                                   
                                                                    if (typeof inr_val == "number") {
                                                                        for (let inr_tst2 of rules1.formula.test) {

                                                                            let find_fil2 = sheet_data.filter(t=>t.Test_Child_Id == inr_tst2.id && t.Test_Parent_Id == inr_tst2.Test_Parent_Id);
                                                                            if (find_fil2[0] && find_fil2[0][fsampl]) {
                                                                                let inr_val2 = find_fil2[0][fsampl];
                                                                                if (typeof inr_val2 == "number") {//New added line condition
                                                                                    let calc_val = inr_val2 * rules1.formula.percentage / 100;
                                                                                    if (condition == "less" && inr_val < calc_val) {
                                                                                        inr_flag = true;
                                                                                        all_test_truefalse_arr.push(inr_flag);
                                                                                    }
                                                                                    else if (condition == "greter" && inr_val > calc_val) {
                                                                                        inr_flag = true;
                                                                                        all_test_truefalse_arr.push(inr_flag);
                                                                                    }
                                                                                }
                                    
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                }

                                            }
                                        }
                                    }
                                }

                                let trueCount = all_test_truefalse_arr.filter(Boolean).length;
                                let totalAddedTest = rules.formula.test.length;
                                
                                //All added test pollutats must be satisfied to approve this current pollutant
                                if(totalAddedTest == trueCount){
                                    flag = true;
                                }
                                
                            }

                        }

                        else if (!flag && rules && rules.option_id == "3") {
                            rules_val = "No Limit";
                            if (typeof sample_val == "number") {
                                flag = true;
                            }
                        }

                        else if (!flag && rules && rules.option_id == "4") {
                            let condition = rules.formula.condition;
                            //rules_val = rules.value;
                            rules_val = (condition == "greter" ? ">" : "<") + "" + rules.formula.percentage + "% of " + rules.formula.test[0].name;
                            if (typeof sample_val == "number") {
                                for (let inr_tst of rules.formula.test) {
                                    let find_fil = sheet_data.filter(t=>t.Test_Child_Id == inr_tst.id && t.Test_Parent_Id == inr_tst.Test_Parent_Id);
                                    if (find_fil[0] && find_fil[0][fsampl]) {
                                        let inr_val = find_fil[0][fsampl];
                                        if (typeof inr_val == "number") {//New added line condition
                                            let calc_val = inr_val * rules.formula.percentage / 100;
                                            if (condition == "less" && sample_val < calc_val) {
                                                flag = true;
                                            }
                                            else if (condition == "greter" && sample_val > calc_val) {
                                                flag = true;
                                            }
                                        }

                                    }
                                }
                            }
                        }


                        if (flag && !test_done) {//push true desposal into array
                            Object.assign(temp_desp_res, {
                                desposal_id: fil_desp.desposal_id,
                                name: fil_desp.name,
                                color: fil_desp.color,
                                rules_val: rules_val,
                            });
                        }

                        if (flag) {
                            test_done = true;
                        }
                        
                        // all_desp_data.push({
                        //     desposal_id: fil_desp.desposal_id,
                        //     color: fil_desp.color,
                        //     //rules_val: (rules) ? rules.value : 0,
                        //     rules_val: (rules) ? rules_val : '',
                        // });
                    }
                    
                    //Only store if pollutants rules exist in selected disposal
                    //if(rulesExistForPollutant){
                        //prepare all sample list against all testnames
                        sampl_finl1.push({
                            //probingcode: probingcode,
                            //campaignid: campaignid,
                            //sample_length: sample_uplowrange,
                            //sample_observation: sample_observation,
                            //value: smpl_val,
                            sample_name: fsampl,                                
                            matched_desp: temp_desp_res,
                            tst_child_id: tst_child_id,
                            tst_name: tst_name,
                            parent_testfamily_id : Test_Parent_Id,
                            // lithology: lithology,
                            // colourname: colourname,
                            // colourcode: colourcode,
                            // stringnotmatched: stringnotmatched,
                            //all_desp_data: all_desp_data,
                        });
                    //}
                   
                }
            }
        }


        }

        }
    }


        //console.log("sampl_finl1 ", sampl_finl1);
        return sampl_finl1;


   
    
    }  //End of export