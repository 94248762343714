import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    getEmailTemplateList :[],
    userNotificationList :[],
    getNotificationTemplateList:[],
    getEmailSettingsList:[],
    getNotificationSettingsList:[]
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_EMAIL_TEMPLATE_LIST:
            return updateObject(state, { emailTemplateList: action.state })
        case actionTypes.GET_EMAIL_TEMPLATE_DETAILS:
            return updateObject(state, { getEmailTemplateDetail: action.state })
        case actionTypes.GET_EMAIL_SETTINGS_LIST:
            return updateObject(state, { getEmailSettingsList: action.state })
        case actionTypes.GET_NOTIFICATION_SETTINGS_LIST:
                return updateObject(state, { getNotificationSettingsList: action.state })
        case actionTypes.GET_USER_NOTIFICATION_LIST:
            return updateObject(state, { userNotificationList: action.state })
        case actionTypes.GET_USER_NOTIFICATION_UNREAD_COUNT:
            return updateObject(state, { userNotificationUnreadCount: action.state })
        case actionTypes.GET_USERS_LIST_TO_SEND_NOTIFICATION:
            return updateObject(state, { usersList: action.state })
        case actionTypes.GET_NOTIFICATION_TEMPLATE_LIST:
            return updateObject(state, { notificationTemplateList: action.state })
        case actionTypes.GET_NOTIFICATION_TEMPLATE_DETAILS:
            return updateObject(state, { getNotificationTemplateDetail: action.state })
        case actionTypes.GET_SEND_NOTIFICATION_FILTERS:
            return updateObject(state, { notificationFilters: action.state })
        case actionTypes.GET_NOTIFICATION_FILTERS_WISE_USER:
                return updateObject(state, { notificationFiltersWiseUser: action.state })
        default:
            return state;
    }
}

export default reducer
