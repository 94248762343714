import React from 'react';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import * as ChangeAppLanguageFront from '../../../../Layout/ChangeAppLanguageFront';
import moment from 'moment';
import styled from 'styled-components';

const tlang = ChangeAppLanguageFront.translateLanguage;
const client_renew_btn = tlang('client_renew_btn') || 'Renew';
const client_close_btn = tlang('client_close_btn') || 'Close';
const licence_exp_prewarning = tlang('licence_exp_prewarning') || 'Licence expired pre-warning';
const licence_exp_soon_msg = tlang('licence_exp_soon_msg') || 'Your licence will expire soon. Please renew it as soon as possible';
const licence_details_below = tlang('licence_details_below') || 'Your licence details are as below';
const client_modal_licence_id = tlang('client_modal_licence_id') || 'Licence Id';
const client_modal_licence_name = tlang('client_modal_licence_name') || 'Licence Name';
const client_modal_licence_amount = tlang('client_modal_licence_amount') || 'Licence Amount';
const client_modal_licence_exp_date = tlang('client_modal_licence_exp_date') || 'Licence Expired Date';

export default function ModalLicenceExpirePreWarning({ isOpen, onClose, licence, onSave }) {

	return (
		<Modal
			isOpen={isOpen}
			toggle={onClose}
			className={`modal-dialog--success modal-dialog--header`}
		>
			<div className="modal__header">
				<button className="lnr lnr-cross modal__close-btn" type="button" onClick={onClose} />
				<h4 className="bold-text  modal__title">{licence_exp_prewarning}</h4>
			</div>
			<div className="modal__body">
				<DetailsContainer>
					<h5 className="bold-text orange_color">{licence_exp_soon_msg}</h5>
					<h5 className="bold-text orange_color">{licence_details_below}</h5>
					<div className="detail">
						<p>{client_modal_licence_id}</p>
						<p>{licence.licenceid} </p>
					</div>
					<div className="detail">
						<p>{client_modal_licence_name}</p>
						<p>{licence.licencename} </p>
					</div>
					<div className="detail">
						<p>{client_modal_licence_amount}</p>
						<p>${licence.cost} </p>
					</div>
					<div className="detail">
						<p>{client_modal_licence_exp_date}</p>
						<p>{moment.unix(licence.expirydate).format('DD/MM/YYYY HH:mm')} </p>
					</div>
				</DetailsContainer>
			</div>

			<ButtonToolbar className="modal__footer">
				<Button color={'success'} outline={true} onClick={onClose}>
					{client_close_btn}
				</Button>
				<Button color={'success'} onClick={() => onSave()}>
					{client_renew_btn}
				</Button>
			</ButtonToolbar>
		</Modal>
	);
}


const DetailsContainer = styled.div`
	text-align: center;
	border: 1px solid var(--clr-aexdo);
	border-radius: 5px;
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
	gap: 5px;
	.detail {
		display: flex;
		justify-content: space-between;
		p {
			margin: 0;
		}
		p:first-child {
			font-weight: 700;
			color: var(--clr-aexdo);
		}
		p:last-child {
			font-size: 1.2em;
		}
	}
`;