import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { FormGroup, Label } from 'reactstrap';
import styled from 'styled-components';

export default function ColorPicker({
	label,
	value,
	onChange,
	errorMessage,
	small = false,
	presetColors,
	disabled = false,
}) {
	const [showPicker, setShowPicker] = useState(false);
	if (small) {
		return (
			<div style={{ width: '60px' }}>
				<ColorContainer
					onClick={() => {
						if (!disabled) setShowPicker(true);
					}}
				>
					<Color value={value} />
				</ColorContainer>
				{showPicker && !disabled && (
					<Popover>
						<Cover onClick={() => setShowPicker(false)} />
						<SketchPicker
							color={value}
							onChange={e => onChange(e.hex)}
							presetColors={presetColors}
						/>
					</Popover>
				)}
			</div>
		);
	}

	return (
		<FormGroup>
			<Label>{label}</Label>
			<div>
				<ColorContainer
					onClick={() => {
						if (!disabled) setShowPicker(true);
					}}
				>
					<Color value={value} />
				</ColorContainer>
				{showPicker && !disabled && (
					<Popover>
						<Cover onClick={() => setShowPicker(false)} />
						<SketchPicker
							color={value}
							onChange={e => onChange(e.hex)}
							presetColors={presetColors}
						/>
					</Popover>
				)}
			</div>
			{errorMessage !== '' && <span className="error-message">{errorMessage}</span>}
		</FormGroup>
	);
}

const Popover = styled.div`
	position: absolute;
	z-index: 2;
`;

const ColorContainer = styled.div`
	padding: 5px;
	background: white;
	border-radius: 1px;
	border: 1px solid #ced4da;
	cursor: pointer;
	width: 100%;
`;

const Color = styled.div`
	height: 20px;
	border-radius: 2px;
	background: ${props => props.value};
`;

const Cover = styled.div`
	position: fixed;
	inset: 0;
`;
