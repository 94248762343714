import React from 'react';

export default function BoxColor({ properties }) {
	const { color = 'black', strokecolor = 'red', fillopacity = 0.5, fillstatus = 'no' } = properties;
	return (
		<div style={{ display: 'inline-block', width: '12px', height: '12px', position: 'relative' }}>
			<div
				style={{
					border: `2px solid ${strokecolor}`,
					opacity: 1,
					position: 'absolute',
					inset: 0,
					backgroundColor: 'white',
				}}
			></div>
			{fillstatus !== 'no' ? (
				<div
					style={{
						position: 'absolute',
						inset: 0,
						backgroundColor: color,
						opacity: fillopacity,
						border: '2px solid transparent',
					}}
				></div>
			) : null}
		</div>
	);
}
