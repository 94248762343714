import React from 'react';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import * as ChangeAppLanguageFront from '../../../../Layout/ChangeAppLanguageFront';

const tlang = ChangeAppLanguageFront.translateLanguage;
const client_mapbox_delete_modal_map_export_data_header =
	tlang('client_mapbox_delete_modal_map_export_data_header') || 'Delete Confirmation';
const mapbox_are_you_sure_delete_warning =
	tlang('mapbox_are_you_sure_delete_warning') || 'Are you sure to delete this ';
const cancel_btn = tlang('cancel_btn') || 'Cancel';
const common_btn_yes = tlang('common_btn_yes') || 'Yes';

export default function ModalDeleteConfirmation({ isOpen, onClose, onConfirm, deleteData }) {
	return (
		<Modal
			isOpen={isOpen}
			toggle={onClose}
			className={`modal-dialog--success modal-dialog--header`}
		>
			<div className="modal__header">
				<button className="lnr lnr-cross modal__close-btn" type="button" onClick={onClose} />
				<h4 className="bold-text  modal__title">
					{deleteData.title || client_mapbox_delete_modal_map_export_data_header}
				</h4>
			</div>
			<div className="modal__body">
				{`${deleteData.deleteMessage || mapbox_are_you_sure_delete_warning} ${
					deleteData.actionName
				} (${deleteData.objectName}) ?`}
			</div>
			<ButtonToolbar className="modal__footer">
				<Button color={'success'} outline={true} onClick={onClose}>
					{deleteData.buttonCancelText || cancel_btn}
				</Button>
				<Button color={'success'} onClick={onConfirm}>
					{deleteData.buttonConfirmText || common_btn_yes}
				</Button>
			</ButtonToolbar>
		</Modal>
	);
}
