import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../shared/utility';
let da=localStorage.getItem("language_data")?JSON.parse(localStorage.getItem("language_data")):[]
const initialState = {
    languageLabelList :da

};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_LANGUAGE_LABEL_LIST:
            return updateObject(state, { languageLabelList: action.state })
        case actionTypes.GET_LANGUAGE_NEW_LABEL_LIST:
            return updateObject(state, { languagenewLabelList: action.state })
        default:
            return state;
    }
}

export default reducer
