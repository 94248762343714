const randomString = (len = 30, charSet) => {
	charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var randomString = '';
	for (var i = 0; i < len; i++) {
		var randomPoz = Math.floor(Math.random() * charSet.length);
		randomString += charSet.substring(randomPoz, randomPoz + 1);
	}
	return randomString;
};

export default randomString;

export const randomId = (prefix = '', base = 0, max = 10000000) => {
	return `${prefix}${Math.floor(base + Math.random() * max)}`;
};
