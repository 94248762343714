import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    // getCreateProbingList :{}
    probingTodoLogList: [],
    campignTodoLogList: [],
    getProbingListTableCsv: [],
    mapWiseProbingList: [],
    isLoadingReportData:true,
    hasloadData:false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CREATE_PROBING_LIST:
            return updateObject(state, { getCreateProbingList: action.state })
        case actionTypes.GET_PROBING_LIST:
            return updateObject(state, { getProbingList: action.state })
        case actionTypes.GET_PROBING_LIST_TABLE_CSV:
            return updateObject(state, { getProbingListTableCsv: action.state })
        case actionTypes.GET_PROBING_DETAIL:
            return updateObject(state, { getProbingDetail: action.state })
        case actionTypes.GET_PROBING_TODO_LOG_LIST:
            return updateObject(state, { probingTodoLogList: action.state })
        case actionTypes.GET_CAMPAIGN_TODO_LOG_LIST:
            return updateObject(state, { campignTodoLogList: action.state })
        case actionTypes.UPLOAD_PROBING_EXCEL:
            return updateObject(state, { datalist: action.state })
        case actionTypes.SAVE_IMPORT_PROBING_EXCEL:
            return updateObject(state, { importProbingExcelDataSaved: action.state })
        case actionTypes.GET_MAPWISE_PROBING_LIST_REPORT:
            return updateObject(state, { mapWiseProbingList: action.state,hasloadData:true})
        default:
            return state;
    }
}

export default reducer
