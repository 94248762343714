import React, { PureComponent } from 'react';
import {
    Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane, Button, Row, Spinner
} from 'reactstrap';
import classnames from 'classnames';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import MUIDataTable from "mui-datatables";
import * as actions from '../../../Store/actions/index';
import { withTranslation } from 'react-i18next';
import Modals from './modals/modals';
import { Icon, Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Chips from 'react-chips';
import Input from '@material-ui/core/TextField';
import moment from 'moment';

import * as ChangeAppLanguageFront from '../Layout/ChangeAppLanguageFront';
let tlang  = ChangeAppLanguageFront.translateLanguage;

const sort_text = tlang('sort_text')?tlang('sort_text'):'Sort';
const rows_per_page_text = tlang('rows_per_page_text')?tlang('rows_per_page_text'):'Rows per page:';
const no_match_record_found = tlang('no_match_record_found')?tlang('no_match_record_found'):'Sorry, no matching records found';
const search_text = tlang('search_text')?tlang('search_text'):'Search';
const LICENSE_REQID_KEY = tlang('licence_request_request_id')?tlang('licence_request_request_id'):'Request Id';
const LICENSE_REQCMPNAME_KEY = tlang('licence_request_company_name')?tlang('licence_request_company_name'):'Company Name';
const LICENSE_REQCOST_KEY = tlang('licence_request_cost')?tlang('licence_request_cost'):'Cost';
const LICENSE_REQNOLICENCE_KEY = tlang('licence_request_number_of_licence')?tlang('licence_request_number_of_licence'):'Number of Licence';
const LICENSE_REQ_REQDATE_KEY = tlang('licence_request_request_date')?tlang('licence_request_request_date'):'Request Date';
const MASTERS_ACTIONS_KEY = tlang('masters_actions')?tlang('masters_actions'):'Actions';
const LICENSE_RENEWLIST_SRNO_KEY = tlang('licence_renewlist_srno')?tlang('licence_renewlist_srno'):'Sr.No.';
const LICENSE_RENEWLIST_LICENAME_KEY = tlang('licence_renewlist_licename')?tlang('licence_renewlist_licename'):'Licence Name';
const LICENSE_RENEWLIST_LICECODE_KEY = tlang('licence_renewlist_licecode')?tlang('licence_renewlist_licecode'):'Licence Code';
const LICENSE_RENEWLIST_COMMENT_KEY = tlang('licence_renewlist_comment')?tlang('licence_renewlist_comment'):'Comment';
const LICENSE_RENEWLIST_EXPDATE_KEY = tlang('licence_renewlist_expirydate')?tlang('licence_renewlist_expirydate'):'Expiry Date';
const LICENSE_RENEWLIST_CMPNAME_KEY = tlang('licence_renewlist_cmpname')?tlang('licence_renewlist_cmpname'):'Company Name';
const RENEWBTN_KEY = tlang('renew_btn')?tlang('renew_btn'):'Renew';
const EXTENDBTN_KEY = tlang('extend_btn')?tlang('extend_btn'):'Extend';
const LICENCEREQ_KEY = tlang('licence_request')?tlang('licence_request'):'Licence Request';
const LICENCERENEW_KEY = tlang('licence_renew_list')?tlang('licence_renew_list'):'Licence Renew List';
const tooltip_accept = tlang('tooltip_accept')?tlang('tooltip_accept'):'Accept';
const tooltip_delete = tlang('tooltip_delete')?tlang('tooltip_delete'):'Delete';       
const project_companytxt = tlang('project_companytxt')?tlang('project_companytxt'):'Company';       
const LICENCE_RENEW_REQUEST_KEY = tlang('licence_renew_request')?tlang('licence_renew_request'):'License Renew Request';
const NEW_LICENCEREQ_KEY = tlang(' new_licence_request')?tlang(' new_licence_request'):' New License Request';
const licence_history = tlang('licence_history')?tlang('licence_history'):'Licence History';   
const client_profile_licence_id = tlang('client_profile_licence_id') ? tlang('client_profile_licence_id') : 'Licence Id';
const profile_price =  tlang('profile_price')?tlang('profile_price'):'Price';
const client_invoice_count = tlang('client_invoice_count')?tlang('client_invoice_count'):'Count';
const client_month = tlang('client_month') || 'Month';

const options = {
    filterType: 'checkbox',
    selectableRows: "none",
    print: false,
    download: false,
    viewColumns: false,
    filter: false,
    responsive: "stacked",
    textLabels: {
        body: {
          noMatch: no_match_record_found,
          toolTip: sort_text
        },
        pagination: {
          rowsPerPage: rows_per_page_text,
        },
        toolbar: {
          search: search_text
        },
    },
};

//master data module component
class Masters extends PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    constructor() {
        super();
        this.state = {
            activeTab : "1",
            licenceEdit: {
                cost: 0,
                licenceid: "",
                licencename: ""
            },
            renewExtend: {
                type: "",
                companyid: "",
                price: "",
                date: moment(),
                comment: "",
                error: []
            },
            licenceRenewalList: [],
            acceptModal: false,
            rejectModal: false,
            licenceRenewExtendModal: false,
            companyname:"",
            requestList:[],
            licenceHistory:[],
            licenceRenewRequestList:[]
        };
    }

    componentDidMount() {
        if(this.props.location.state && this.props.location.state.requestKey ){
            const requestKey=this.props.location.state.requestKey   
            let request_tab=localStorage.getItem("licence_request_tab");
            if(request_tab!==requestKey ){
             localStorage.setItem("licence_request_tab",requestKey)
             this.setState({
                activeTab: requestKey
            },()=>this.forceUpdate())
            }
        }
        //call initial actions

        // Licence Request for false
        let reqParam1 = {
            renew : false
        }
        this.props.getAllLicenceReq(this.props.apolloClient.masters, reqParam1)

        // Licence Renew Request for true
        let reqParam2 = {
            renew : true
        }
        this.props.getAllLicenceReq(this.props.apolloClient.masters, reqParam2)
        this.setState({ 
            loading: true
        })
        this.props.getLicenceRenewalList(this.props.apolloClient.client, [])

        if (localStorage.getItem("licence_request_tab")) {
            this.setState({
                activeTab: localStorage.getItem("licence_request_tab")
            });
        }

        let request = {
            status: "",
            confirm: true,
        }
        this.props.getUserRequest(this.props.apolloClient.client, request) 
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.commonReducer) {
          if (this.props.commonReducer.refreshNotificationRedirectPage != "") {
            if(this.props.commonReducer.refreshNotificationRedirectPage == "/admin/licenceRequest"){
                this.componentDidMount();
                this.props.currentNotificationRedirectPageUrl('');
            }
          }
        }
    }

    componentWillReceiveProps(props) {

        if (props.masterReducer.licenceRequest) {
            this.setState({
                licenceRequest: props.masterReducer.licenceRequest
            })
        }

        if (props.masterReducer.licenceRenewRequestList) {
            this.setState({
                licenceRenewRequestList: props.masterReducer.licenceRenewRequestList
            },()=>this.forceUpdate())
        }

        if (props.masterReducer.licenceRequestDetails) {
            this.setState({
                licenceRequestDetails: props.masterReducer.licenceRequestDetails
            })
        }

        this.setState({
            userAccess: props.authReducer.userData.accesspages.user_request
        })
       
        //recive licence renew list
        if (props.masterReducer.licenceRenewalList) {
            this.setState({
                licenceRenewalList: props.masterReducer.licenceRenewalList
            })
        }

        if (props.userReducer.requestList) {
            this.setState({
                requestList: props.userReducer.requestList
            })
        }

        // will recieve notifications data
        if (props.notification) {
            this.setState({
                loading: false
            })
            if (props.notification.success) {
                this.setState({
                    acceptModal: false,
                    rejectModal: false,
                    licenceRenewExtendModal: false
                })
            }
        }
        if(props.masterReducer.licenceHistory){
            this.setState({
                licenceHistory:props.masterReducer.licenceHistory
            })
        }
    }

    componentWillUnmount () {
        this.setState({
            licenceHistory:[]
        })
        this.props.masterReducer.licenceHistory = []
    }

    toggle = tab => {
        const { activeTab } = this.state;
        if (activeTab !== tab) {
          this.setState(
            {
              activeTab: tab
            },
            () => localStorage.setItem("licence_request_tab", tab)
          );
        }
      };


    renderLicenceList = () => {
        let data = [];

        if (this.state.licenceRequest) {
            this.state.licenceRequest.map(val => {
                data.push([
                    val.requestid,
                    val.displayname,
                    '$' + val.price,
                    val.month,
                    val.count,
                    moment.unix(val.requesteddate).format("DD/MM/YYYY HH:mm"),
                    val
                ])
            })
        }

        return data
    }

    renderLicenseRenewRequestList = () => {
        let data = [];

        if (this.state.licenceRenewRequestList) {
            this.state.licenceRenewRequestList.map(val => {
                data.push([
                    val.requestid,
                    val.displayname,
                    '$' + val.price,
                    val.month,
                    val.count,
                    moment.unix(val.requesteddate).format("DD/MM/YYYY HH:mm"),
                    val
                ])
            })
        }

        return data
    }

    toggleAcceptLicenceReqModal = (value) => {

        this.setState({
            acceptModal: !this.state.acceptModal
        })
        if (value) {
            if(value.requestid){
                let req = {
                    requestid: value.requestid
                }
                this.props.getLicenceReqDetails(this.props.apolloClient.masters, req);
                this.setState({
                    requestStatusData: value
                })
            }
        }
    }

    toggleRejectLicenceReqModal = (value) => {
        this.setState({
            rejectModal: !this.state.rejectModal
        })
        if (value) {
            this.setState({
                requestStatusData: value
            })
        }
    }

    approveLicenceRequest = () => {

        let renew = this.state.activeTab == '4' ? true : false
        let req = {
            requestid: this.state.requestStatusData.requestid,
            status: true,
            renew : renew
        }

        this.props.acceptLicenceRequest(this.props.apolloClient.masters, req);
        this.setState({ 
            loading: true
        })
    }


    rejectLicenceRequest = () => {
        let renew = this.state.activeTab == '4' ? true : false
        let req = {
            requestid: this.state.requestStatusData.requestid,
            status: false,
            renew : renew
        }

        this.props.acceptLicenceRequest(this.props.apolloClient.masters, req);
        this.setState({ 
            loading: true
        })
    }

     //render table data
     renderLicenceRenewalList = (data) => {
        let renderData = [];
        data.map((val, index) => {
            let setDate = moment.unix(val.expirydate)

            var str = val.comment;
            var n = str.length;
            var comment = n > 30 ? str.substring(0,30)+"..." : str;
            renderData.push([
                index + 1 ,
                val.company_name,
                val.licencename,
                val.companylicenceid,
                comment,
                moment(setDate).format("DD/MM/YYYY HH:mm"),
                val
            ])
        })
        return renderData
    }


    handleChange = (name) => event => {

        let value = ""; 
        if(name === "date"){
            value = event
        }else{
            value = event.target.value
        }
        let renewExtend = this.state.renewExtend
        renewExtend[name] = value
        
        this.setState({
            renewExtend
        }, () => this.forceUpdate())
    }

    toggleLicenceRenewExtendModal = (value,type) => {

        let price = ""
        if(type === "renew"){
            price = value.cost
        } 

        let renewExtend = this.state.renewExtend
        renewExtend['price'] = price
        renewExtend['comment'] = ""
        renewExtend['error'] = []
        renewExtend['date'] = moment()
        
        if (value) {
            renewExtend['companyid'] = value.company_id
            renewExtend['company_name'] = value.company_name
            renewExtend['licencename'] = value.licencename
            renewExtend['licenceid'] = value.licenceid
            renewExtend['type'] = type
            renewExtend['companylicenceid'] = value.companylicenceid
        }
        this.setState({
            renewExtend,
            licenceRenewExtendModal: !this.state.licenceRenewExtendModal
        },()=>this.forceUpdate())
    }

    validateForm = () => {
        let renewExtend = this.state.renewExtend;
        let error = []
        if(renewExtend.type === "extend"){
            if (!renewExtend.price || renewExtend.price < 0) {
                error.push('price');
            }
        }
        
        renewExtend['error'] = error
        this.setState({
            error
        })
        return error;
    }


    saveRenewExtendLicenceRequest = () => {
        let error = this.validateForm()
        let renewExtend = this.state.renewExtend;
    
        if(error.length == 0){

            let request = {
                type: renewExtend.type,
                companyid: renewExtend.companyid,
                licencename: renewExtend.licencename,
                company_name: renewExtend.company_name,
                licenceid: renewExtend.licenceid,
                price: renewExtend.price,
                date: renewExtend.type === "renew" ? "" : moment(renewExtend.date).format("X"),
                comment: renewExtend.comment,
                companylicenceid: renewExtend.companylicenceid != null ? renewExtend.companylicenceid : "",
            }
            this.props.renewExtendLicenceRequest(this.props.apolloClient.client, request)
            this.setState({ 
                loading: true
            })
        }else{
            console.log("error ", error);
        }
    }

    handleCompanyChange = () => event => {
        this.setState({
            companyname: event.target.value
        }, () => {
            let request = {
                companyname: event.target.value
            }
            this.props.masterReducer.licenceHistory = []
            this.props.getCompanyWiseLicenceHistory(this.props.apolloClient.masters, request);
            this.setState({
                loading:true,
                licenceHistory:[]
            },()=>this.forceUpdate())
        })
    }
    renderLicenceHistory = () => {
        let data = [];
        if (this.state.licenceHistory) {
            let display_name = ""
            let companyName = this.state.requestList.filter(c=>c.company_name==this.state.companyname)
            if(companyName.length != 0){
                display_name = companyName[0].display_name
            }
            this.state.licenceHistory.map(val => {
                data.push([
                    display_name,
                    val.licenceid,
                    val.licencename,
                    '$' + val.price,
                    val.count,
                    moment.unix(val.requesteddate).format("DD/MM/YYYY HH:mm"),
                ])
            })
        }
        return data
    }

    render() {
        const { t } = this.props;
        const activeTab  = this.state.activeTab;

        const licenceColumns = [
            { name: LICENSE_REQID_KEY },
            { name: LICENSE_REQCMPNAME_KEY },
            { name: LICENSE_REQCOST_KEY },
            { name: client_month },
            { name: LICENSE_REQNOLICENCE_KEY },
            { name: LICENSE_REQ_REQDATE_KEY },
            {
                name: MASTERS_ACTIONS_KEY,
                options: {
                    filter: false,
                    customBodyRender: (value) => {
                        return (<div>
                            <Button className={"system-btn clear-border"} outline={true} color={'success'} onClick={() => this.toggleAcceptLicenceReqModal(value)}>
                                <Tooltip title={tooltip_accept}><Icon>done</Icon></Tooltip>
                            </Button>
                            <Button className={"system-btn clear-border"} outline={true} color={'success'} onClick={() => this.toggleRejectLicenceReqModal(value)}>
                                <Tooltip title={tooltip_delete}><Icon>delete</Icon></Tooltip>
                            </Button></div>);

                    }
                }
            }
        ];

        const renewalColumns = [
            { name: LICENSE_RENEWLIST_SRNO_KEY },
            { name: LICENSE_RENEWLIST_CMPNAME_KEY },
            { name: LICENSE_RENEWLIST_LICENAME_KEY },
            { name: LICENSE_RENEWLIST_LICECODE_KEY },
            { name: LICENSE_RENEWLIST_COMMENT_KEY },
            { name: LICENSE_RENEWLIST_EXPDATE_KEY },
            {
                name: MASTERS_ACTIONS_KEY,
                options: {
                    filter: false,
                    sort: false,    
                    customBodyRender: (value) => {
                        return ( 
                            <div>
                                <Button
                                    className={"add-new-btn"}
                                    color={"success"}
                                    onClick={() => this.toggleLicenceRenewExtendModal(value, "renew")}
                                >
                                    {RENEWBTN_KEY}
                                </Button>
                                <Button
                                    className={"add-new-btn"}
                                    color={"success"}
                                    onClick={() => this.toggleLicenceRenewExtendModal(value, "extend")}
                                >
                                    {EXTENDBTN_KEY}
                                </Button>
                            </div>
                        );

                    }
                }
            }
        ];

        const licenceHistoryColumns = [    
            { name: LICENSE_RENEWLIST_CMPNAME_KEY },
            { name: LICENSE_RENEWLIST_LICECODE_KEY },
            // { name: LICENSE_RENEWLIST_COMMENT_KEY },
            // { name: LICENSE_RENEWLIST_EXPDATE_KEY },
            
            // { name: client_profile_licence_id },
            { name: LICENSE_RENEWLIST_LICENAME_KEY },
            { name: profile_price },
            { name:client_invoice_count},
            { name: LICENSE_REQ_REQDATE_KEY}
        ];

        const optionsLicense = {
          filterType: "checkbox",
          selectableRows: "none",
          print: false,
          download: false,
          viewColumns: false,
          filter: false,
          textLabels: {
            body: {
            noMatch: no_match_record_found,
            toolTip: sort_text
            },
            pagination: {
            rowsPerPage: rows_per_page_text,
            },
            toolbar: {
            search: search_text
            },
        },
          searchText:this.state.muiSearchTextLicense,
          responsive: "stacked",
          onTableChange: (action, tableState) => {
            if(action === 'search'){
                this.state.muiSearchTextLicense = tableState.searchText
            }
          }
        };

        const renewalOptions = {
          filterType: "checkbox",
          selectableRows: "none",
          print: false,
          download: false,
          viewColumns: false,
          textLabels: {
            body: {
            noMatch: no_match_record_found,
            toolTip: sort_text
            },
            pagination: {
            rowsPerPage: rows_per_page_text,
            },
            toolbar: {
            search: search_text
            },
        },
          filter: false,
          searchText:this.state.muiSearchTextRenewval,
          responsive: "stacked",
          onTableChange: (action, tableState) => {
            if(action === 'search'){
                this.state.muiSearchTextRenewval = tableState.searchText
            }
          }
        };

        const licenceHistoryOptions = {
            filterType: 'checkbox',
            selectableRows: "none",
            print: false,
            download: false,
            viewColumns: false,
            filter: false,
            responsive: "stacked",
            textLabels: {
                body: {
                    noMatch: no_match_record_found,
                    toolTip: sort_text
                },
                pagination: {
                    rowsPerPage: rows_per_page_text,
                },
                toolbar: {
                    search: search_text
                },
            },
            searchText:this.state.muiSearchTextLicenceHistory,
            onTableChange: (action, tableState) => {
                if(action === 'search'){
                    this.state.muiSearchTextLicenceHistory = tableState.searchText
                }
              },
            customToolbar: () => {
                return (
                    <React.Fragment>
                        <TextField
                            select
                            id="standard-select-currency"
                            SelectProps={{
                                MenuProps: {
                                    className: "select-menu-width",
                                },
                            }}
                            label={project_companytxt}
                            className="material-form__field width__150"
                            value={this.state.companyname}
                            onChange={this.handleCompanyChange('company')}
                        >
                            {this.state.requestList.map(option => (
                                <MenuItem key={option.company_name} value={option.company_name}>
                                    {option.display_name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </React.Fragment>
                );
            }
        };

        return (
            <Col xs={12} md={12} lg={12} xl={12}>
                <h3 className="page-title" style={{ marginBottom: "20px" }}>
                  {LICENCEREQ_KEY}
                </h3>
                <Card>
                    <CardBody>
                    <div className="tabs tabs--bordered-top">
                            <div className="tabs__wrap">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => {
                                                this.toggle('1');
                                            }}
                                        >
                                            {NEW_LICENCEREQ_KEY}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '4' })}
                                            onClick={() => {
                                                this.toggle('4');
                                            }}
                                        >
                                            {LICENCE_RENEW_REQUEST_KEY}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => {
                                                this.toggle('2');
                                            }}
                                        >
                                            {LICENCERENEW_KEY}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '3' })}
                                            onClick={() => {
                                                this.toggle('3');
                                            }}
                                        >
                                            {licence_history }
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <div className="div-spinner">{this.state.loading && <Spinner size="500" className="success" />}</div>
                                            {!this.state.loading && <MUIDataTable
                                                data={this.renderLicenceList()}
                                                columns={licenceColumns}
                                                options={optionsLicense}
                                            />}
                                    </TabPane>
                                    <TabPane tabId="4">
                                        <div className="div-spinner">{this.state.loading && <Spinner size="500" className="success" />}</div>
                                            {!this.state.loading && <MUIDataTable
                                                data={this.renderLicenseRenewRequestList()} 
                                                columns={licenceColumns}
                                                options={optionsLicense}
                                            />}
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <div className="div-spinner">{this.state.loading && <Spinner size="500" className="success" />}</div>
                                        {!this.state.loading && <MUIDataTable
                                            data={this.renderLicenceRenewalList(this.state.licenceRenewalList)}
                                            columns={renewalColumns}
                                            options={renewalOptions}
                                        />}  
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <div className="div-spinner">{this.state.loading && <Spinner size="500" className="success" />}</div>
                                        <MUIDataTable
                                            data={this.renderLicenceHistory()}
                                            columns={licenceHistoryColumns}
                                            options={licenceHistoryOptions}
                                        />
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                {this.state.acceptModal &&
                    Modals.acceptLicenceReqModal(this)
                }
                {this.state.rejectModal &&
                    Modals.rejectLicenceReqModal(this)
                }
                {this.state.editPlanModal &&
                    Modals.updatePlan(this)
                }
                {this.state.licenceRenewExtendModal &&
                    Modals.licenceRenewExtendModal(this)
                }
            </Col>
        );
    }
}


Masters.propTypes = {
    t: PropTypes.func.isRequired,
};

//load reducers
const mapStateToProps = state => {
    return {
        authReducer: state.authReducer,
        apolloClient: state.apolloClient,
        masterReducer: state.masterReducer,
        notification: state.notification,
        userReducer: state.userReducer,
        commonReducer: state.commonReducer,
    }
}

//Load actions
const mapDispatchToProps = dispatch => {
    return {
        getAllLicenceReq: (client, request) => { dispatch(actions.getAllLicenceReq(client, request)) },
        getLicenceReqDetails: (client, request) => { dispatch(actions.getLicenceReqDetails(client, request)) },
        acceptLicenceRequest: (client, request) => { dispatch(actions.acceptLicenceRequest(client, request)) },
        renewExtendLicenceRequest: (client, request) => { dispatch(actions.renewExtendLicenceRequest(client, request)) },
        getLicenceRenewalList: (client, request) => { dispatch(actions.getLicenceRenewalList(client, request)) },
        getUserRequest: (client, request) => { dispatch(actions.getUserRequest(client, request)) },
        getCompanyWiseLicenceHistory: (client, request) => { dispatch(actions.getCompanyWiseLicenceHistory(client, request)) },
        currentNotificationRedirectPageUrl: (data) => {
            dispatch(actions.currentNotificationRedirectPageUrl(data));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Masters)); //export component
