import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as actions from "../../../Store/actions/index";
import { withTranslation } from "react-i18next";
import invoiceSubComponent from "./invoiceSubComponent"
import moment from "moment";

class Invoice extends PureComponent {
  static propTypes = {
    i18n: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired,
    t: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      screenOne : true,
      screenTwo : false,
      invoiceList:[],
      invoiceDetail : [],
      getListLoading:true
    };
  }

  componentDidMount() {
    //Load init actions
    this.props.getInvoiceList(this.props.apolloClient.client, []);
  }

  componentWillReceiveProps(props) {
   
    // will recieve notifications data
    if (props.notification) {
      this.setState({
        loading: false
      });
      if (props.notification.success) {
      }else{
      }
    }
    if(props.invoiceReducer.invoiceList){
      this.setState({
        getListLoading:false
      })
    }
  }

  /* render Invoice Request List */
  renderInvoiceRequestList = () => {
    let data = this.props.invoiceReducer.invoiceList ? this.props.invoiceReducer.invoiceList : [];

    let dataAS = [{
      title : "test ",
      status : "tetett",
      val : "sdfsdfsdfsdf",
    }]
      return data.map((val, index) => {

        const date = moment(new Date(val.created_at) / 1000).format("DD/MM/YYYY HH:mm");
        var invoice_date = moment(date, "DD/MM/YYYY HH:mm", true).isValid();

          return ([
              val.title,
              invoice_date ? moment.unix(new Date(val.created_at) / 1000).format("DD/MM/YYYY HH:mm"): moment.unix(val.created_at / 1000).format("DD/MM/YYYY HH:mm"),
              val.status,
              val,
          ])
      })
  } 

  /* view Invoice Details */
  viewInvoiceDetails = (data) => {
    if(data){
      data.company_email = this.props.authReducer.userData.email
      data.company_name = this.props.authReducer.userData.display_name
    }
      this.setState({
        screenOne : !this.state.screenOne,
        screenTwo : !this.state.screenTwo,
        invoiceDetail : data
      },()=>this.forceUpdate())
  }

  /* close Screen Two */
  closeScreenTwo = () =>{
    this.setState({
      screenOne : true,
      screenTwo : false,
      invoiceDetail : []
    },()=>this.forceUpdate())
  }

  /* get Total Amount */
  getTotalAmount = () => {
    let total = 0;
    let  invoiceDetail = this.state.invoiceDetail ? this.state.invoiceDetail.invoice_detail : []
    invoiceDetail.map((row) => {
      if(this.state.invoiceDetail.category_name === "TOKEN" ){
          total += row.price;
      }else if(this.state.invoiceDetail.category_name === "SIGNUP"){
        if(row.price){
            total += row.count ? row.count * row.price : row.price; 
        }
        if(row.plan){
            total += row.plan.count ? row.plan.count * row.plan.price : row.plan.price;
        }
        if(row.licence){
            row.licence.filter((value,index1) => value.count != 0 ).map((val,index)=>{
                total += val.count ? val.count * val.price : val.price
            })
        }
      }else if(this.state.invoiceDetail.category_name === "LICENCE"){
        total += row.count || row.count == 0 ? row.count * row.price : row.price;
      }
    })
      return parseInt(total);
  }

  render() {
    let template = this.state.template;
    let state = this.state;
    const { t } = this.props;

        return (
            <>
                {(() => {
                    /* invoices list */
                    if (this.state.screenOne) {
                        return (
                            <>
                                {invoiceSubComponent.invoiceSubComponentOne(this)}
                            </>
                        )
                    }
                    /* invoice details */
                    if (this.state.screenTwo) {
                        return (
                            <>
                                {invoiceSubComponent.invoiceSubComponentTwo(this)}
                            </>
                        )
                    }
                })()}
            </>
        );
  }
}

//load reducers
const mapStateToProps = state => {
  return {
    authReducer: state.authReducer,
    apolloClient: state.apolloClient,
    masterReducer: state.masterReducer,
    userReducer: state.userReducer,
    memberReducer: state.memberReducer,
    projectReducer: state.projectReducer,
    notification: state.notification,
    invoiceReducer: state.invoiceReducer,
  };
};

//Load actions
const mapDispatchToProps = dispatch => {
  return {
    getInvoiceList: (client, request) => {
      dispatch(actions.getInvoiceList(client, request));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(Invoice)); //export component
