import { Radio } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, ButtonToolbar, Col, Row, Spinner } from 'reactstrap';
import * as ChangeAppLanguageFront from '../../../Layout/ChangeAppLanguageFront';

let tlang = ChangeAppLanguageFront.translateLanguage;
const client_master_disposal = tlang('client_master_disposal') || 'Master Disposal';
const client_company_disposal = tlang('client_company_disposal') || 'Company Disposal';
const client_project_disposal = tlang('client_project_disposal') || 'Project Disposal';
const client_account_disposal = tlang('client_account_disposal') || 'Account Disposal';
const common_btn_save = tlang('common_btn_save') || 'Save';
const client_select_disposal_list = tlang('client_select_disposal_list') || 'Select Disposal List';
const warning_select_disposal_list =
	tlang('warning_select_disposal_list') || 'Please select disposal list';
const warning_select_disposal_list_isempty =
	tlang('warning_select_disposal_list_isempty') || 'Selected disposal list is empty';

const initialState = {
	activeDisposalCategory: '',
	activeDisposalList: '',
};

function reducer(state, action) {
	switch (action.type) {
		default:
			const upd = {};
			for (const key of Object.keys(action)) {
				upd[key] = action[key];
			}
			// console.log(`upd`, { upd });
			return { ...state, ...upd };
	}
}

export default function ActiveDisposalCategory({ saveBtnLoader, onSave }) {
	const authReducer = useSelector(state => state.authReducer);
	const [state, localDispatch] = useReducer(reducer, initialState);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		let default_disposal_type = authReducer.userData?.default_disposal_type || 'master';
		let default_disposal_list = authReducer.userData?.default_disposal_list || '';
		localDispatch({
			activeDisposalCategory: default_disposal_type,
			activeDisposalList: default_disposal_list,
		});
	}, [authReducer.userData?.default_disposal_list, authReducer.userData?.default_disposal_type]);

	const changeActiveDisposalCategory = e => {
		localDispatch({
			activeDisposalCategory: e.target.value,
			activeDisposalList: '',
		});

		setErrors(e => ({ ...e, activeDisposalList: false, activeDisposalListEmpty: false }));
	};

	const handleChangeDisposalList = e => {
		localDispatch({ activeDisposalList: e?.value || '' });
		setErrors(e => ({ ...e, activeDisposalList: false, activeDisposalListEmpty: false }));
	};

	function checkDisposalListEmpty(activeDisposalCategory, activeDisposalList) {
		if (Array.isArray(disposal_data) && disposal_data.length > 0) {
			let selAllDispListData = disposal_data.filter(l => l.disposalType === activeDisposalCategory);
			if (selAllDispListData.length > 0) {
				let parseDispListData = JSON.parse(selAllDispListData[0].disposalData);
				if (
					Array.isArray(parseDispListData[activeDisposalList]) &&
					parseDispListData[activeDisposalList].length <= 0
				) {
					setErrors(e => ({ ...e, activeDisposalListEmpty: true }));
					return true;
				} else {
					return false;
				}
			}
		}
	}

	function handleSave() {
		let activeDisposalCategory = state.activeDisposalCategory;
		let activeDisposalList = state.activeDisposalList;

		//Check Selected disposal list is empty or not, if empty then raise error
		let emptyStatus = checkDisposalListEmpty(activeDisposalCategory, activeDisposalList);
		if (emptyStatus === true) {
			return;
		}

		if (activeDisposalCategory !== 'master' && activeDisposalCategory !== '') {
			let checkDisposalList = disposalCatList[activeDisposalCategory].find(
				m => m.value === activeDisposalList
			);
			if (activeDisposalCategory !== 'master') {
				if (
					activeDisposalList === '' ||
					checkDisposalList === 'undefined' ||
					checkDisposalList === ''
				) {
					setErrors(e => ({ ...e, activeDisposalList: true }));
					return;
				}
			}
			onSave(state);
		} else {
			onSave(state);
		}
		setErrors(e => ({ ...e, activeDisposalList: false, activeDisposalListEmpty: false }));
	}

	let disposal_data = authReducer.userData.disposal_data;
	let disposalCatList = { company: [], project: [], account: [] };
	if (disposal_data.length > 0) {
		for (const l of disposal_data) {
			if (
				(l.disposalType === 'company' ||
					l.disposalType === 'project' ||
					l.disposalType === 'account') &&
				l.disposalData !== ''
			) {
				let disposalData = JSON.parse(l.disposalData);
				let allDisposalList = Object.keys(disposalData);
				const allList = allDisposalList.map(d => ({ value: d, label: d }));
				disposalCatList[l.disposalType] = allList;
			}
		}
	}

	return (
		<>
			<Row style={{ marginTop: '16px', marginBottom: '25px', marginLeft: '1%' }}>
				<Col style={{ float: 'left' }} xs={12} sm={12} md={12} className="">
					<FormControlLabel
						control={
							<Radio
								style={{ padding: '0px 10px', color: 'var(--clr-aexdo)' }}
								color="primary"
								checked={state.activeDisposalCategory === 'master'}
								onChange={e => changeActiveDisposalCategory(e)}
								value="master"
								name="discat"
							/>
						}
						label={client_master_disposal}
						className={''}
					/>
				</Col>

				<Col style={{ float: 'left' }} xs={12} sm={12} md={12} className="">
					<FormControlLabel
						control={
							<Radio
								style={{ padding: '0px 10px', color: 'var(--clr-aexdo)' }}
								color="primary"
								checked={state.activeDisposalCategory === 'company'}
								onChange={e => changeActiveDisposalCategory(e)}
								value="company"
								name="discat"
							/>
						}
						label={client_company_disposal}
						className={''}
					/>

					{state.activeDisposalCategory === 'company' && (
						<div style={select}>
							<Select
								name={'menu'}
								value={disposalCatList.company.find(m => m.value === state.activeDisposalList)}
								onChange={e => handleChangeDisposalList(e)}
								options={disposalCatList.company}
								isClearable={false}
								isDisabled={false}
								className="react-select"
								styles={{ container: provided => ({ ...provided, width: '100%' }) }}
								placeholder={client_select_disposal_list}
							/>
						</div>
					)}
					{state.activeDisposalCategory === 'company' && errors.activeDisposalList && (
						<span className="error-message" style={select}>
							{warning_select_disposal_list}
						</span>
					)}
					{state.activeDisposalCategory === 'company' && errors.activeDisposalListEmpty && (
						<span className="error-message" style={select}>
							{warning_select_disposal_list_isempty}
						</span>
					)}
				</Col>

				<Col style={{ float: 'left' }} xs={12} sm={12} md={12} className="">
					<FormControlLabel
						control={
							<Radio
								style={{ padding: '0px 10px', color: 'var(--clr-aexdo)' }}
								color="primary"
								checked={state.activeDisposalCategory === 'project'}
								onChange={e => changeActiveDisposalCategory(e)}
								value="project"
								name="discat"
							/>
						}
						label={client_project_disposal}
						className={''}
					/>

					{state.activeDisposalCategory === 'project' && (
						<div style={select}>
							<Select
								name={'menu'}
								value={disposalCatList.project.find(m => m.value === state.activeDisposalList)}
								onChange={e => handleChangeDisposalList(e)}
								options={disposalCatList.project}
								isClearable={false}
								isDisabled={false}
								className="react-select"
								styles={{ container: provided => ({ ...provided, width: '100%' }) }}
								placeholder={client_select_disposal_list}
							/>
						</div>
					)}
					{state.activeDisposalCategory === 'project' && errors.activeDisposalList && (
						<span className="error-message" style={select}>
							{warning_select_disposal_list}
						</span>
					)}
					{state.activeDisposalCategory === 'project' && errors.activeDisposalListEmpty && (
						<span className="error-message" style={select}>
							{warning_select_disposal_list_isempty}
						</span>
					)}
				</Col>

				<Col style={{ float: 'left' }} xs={12} sm={12} md={12} className="">
					<FormControlLabel
						control={
							<Radio
								style={{ padding: '0px 10px', color: 'var(--clr-aexdo)' }}
								color="primary"
								checked={state.activeDisposalCategory === 'account'}
								onChange={e => changeActiveDisposalCategory(e)}
								value="account"
								name="discat"
							/>
						}
						label={client_account_disposal}
						className={''}
					/>

					{state.activeDisposalCategory === 'account' && (
						<div style={select}>
							<Select
								name={'menu'}
								value={disposalCatList.account.find(m => m.value === state.activeDisposalList)}
								onChange={e => handleChangeDisposalList(e)}
								options={disposalCatList.account}
								isClearable={false}
								isDisabled={false}
								className="react-select"
								styles={{ container: provided => ({ ...provided, width: '100%' }) }}
								placeholder={client_select_disposal_list}
							/>
						</div>
					)}
					{state.activeDisposalCategory === 'account' && errors.activeDisposalList && (
						<span className="error-message" style={select}>
							{warning_select_disposal_list}
						</span>
					)}
					{state.activeDisposalCategory === 'account' && errors.activeDisposalListEmpty && (
						<span className="error-message" style={select}>
							{warning_select_disposal_list_isempty}
						</span>
					)}
				</Col>
			</Row>
			<ButtonToolbar className="" style={{ marginLeft: '2%' }}>
				<Button color={'success'} disabled={saveBtnLoader} onClick={handleSave}>
					{common_btn_save}
					{saveBtnLoader && (
						<Spinner
							size="sm"
							style={{ marginLeft: '10px', color: 'var(--clr-aexdo)' }}
							className="btn-spinner"
						/>
					)}
				</Button>
			</ButtonToolbar>
		</>
	);
}

const select = {
	marginLeft: '4%',
	marginBottom: '1%',
	width: '30%',
};
