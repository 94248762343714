import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button,
	ButtonToolbar,
	Col,
	Container,
	FormGroup,
	Input,
	Label,
	Modal,
	Row,
	Spinner,
} from 'reactstrap';
import __ from 'underscore';
import * as actions from '../../../../Store/actions/index';
import checkEmailFormat from '../../../lib/checkEmailFormat';
import randomString from '../../../lib/randomString';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import DatePicker from '../components/DatePicker';
import Form from '../components/form';
import FormGroupRequired from '../components/formGroupRequired';
import MapComponent from '../components/viewMap';
import FileDropZone from './fileDropZone';
import InviteUsers from './inviteUsers';
import ProjectAddress from './projectAddress';
import useProjectMembers, { ProjectMembers } from './projectMembers';
import TokenList from './tokenList';

const tlang = ChangeAppLanguageFront.translateLanguage;

const project_edit_project_details =
	tlang('project_edit_project_details') || 'Edit Project Details';

const client_project_title = tlang('client_project_title') || 'Project Title';
const client_project_purpose = tlang('client_project_purpose') || 'Project Purpose';
const client_project_pls_enter_title =
	tlang('client_project_pls_enter_title') || 'Please Add Project Name';
const client_project_pls_enter_purpose =
	tlang('client_project_pls_enter_purpose') || 'Please Add Project Purpose';
const client_project_start_date = tlang('client_project_start_date') || 'Start Date';
const client_project_end_date = tlang('client_project_end_date') || 'End Date';
const client_project_longtitude = tlang('client_project_longtitude') || 'Longitude';
const client_project_latitude = tlang('client_project_latitude') || 'Latitude';
const client_project_pls_enter_longtitude =
	tlang('client_project_pls_enter_longtitude') || 'Please Add Project Longitude';
const client_project_pls_enter_latitude =
	tlang('client_project_pls_enter_latitude') || 'Please Add Project Latitude';
const client_project_member = tlang('client_project_member') || 'Project Member';
const cancel_btn = tlang('cancel_btn') || 'Cancel';
const create_update_staff_modal_update = tlang('create_update_staff_modal_update') || 'Update';
const client_project_county_number = tlang('client_project_county_number') || 'County Number';
const client_project_pls_enter_county =
	tlang('client_project_pls_enter_county') || 'Please Add Project County Number';
	
const initialState = {
	title: '',
	purpose: '',
	origintokentype: '',
	tokentype: '',
	companytokenid: '',
	origintcompanytokenid: '',
	address: '',
	county: '',
	start: Date.now(),
	end: Date.now(),
	lng: '2',
	lat: '44',
	docs: [],
	members: [],
	invitedUsers: [],
	invitedExt: [],
	roleList: [],
};

function reducer(state, action) {
	switch (action.type) {
		default:
			const upd = {};
			for (const key of Object.keys(action)) {
				upd[key] = action[key];
			}
			// console.log(`upd`, { upd });
			return { ...state, ...upd };
	}
}

export default function ModalEditProject({ isOpen, onClose, projectId, onSave }) {
	const dispatch = useDispatch();
	const apolloClient = useSelector(state => state.apolloClient);
	const authReducer = useSelector(state => state.authReducer);
	const projectReducer = useSelector(state => state.projectReducer);
	const masterReducer = useSelector(state => state.masterReducer);
	const memberReducer = useSelector(state => state.memberReducer);
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [state, localDispatch] = useReducer(reducer, initialState);
	const {
		addMembers,
		getMemberById,
		getMemberByEmail,
		memberIds,
		allMembers,
		members,
		setMembers,
		actionButton,
	} = useProjectMembers();

	useEffect(() => {
		if (!projectId) return;
		const req = {
			id: projectId,
			company: authReducer.userData.company_name,
		};
		dispatch(actions.getprojectDetails(apolloClient.project, req));
		dispatch(actions.getAvailableLicenceList(apolloClient.client, []));
		dispatch(actions.getProjectTokenList(apolloClient.project, []));
		dispatch(actions.getMemberList(apolloClient.client, []));
		dispatch(actions.getRoleList(apolloClient.client, []));
		setIsLoading(true);
	}, [projectId]);

	useEffect(() => {
		if (!memberReducer.memberList?.length) return;
		const ml = memberReducer.memberList.map(m => ({
			value: m.userid,
			label: `${m.name} (${m.email})`,
		}));
		localDispatch({ memberList: ml });
	}, [memberReducer.memberList?.length]);

	useEffect(() => {
		if (!masterReducer.roleList?.length) return;
		const rl = masterReducer.roleList
			.filter(r => r.roleid !== 'AXD_RL_02')
			.map(r => ({
				value: r.roleid,
				label: r.rolename,
			}));
		localDispatch({ roleList: rl });
	}, [masterReducer.roleList?.length]);

	useEffect(() => {
		if (!projectReducer.projectDetails || !projectReducer.projectDetails.length) return;
		const project = projectReducer.projectDetails[0];
 console.log("project ", project);
		setIsLoading(false);
		const attachments = JSON.parse(project.attachments);
		const docs = attachments.map(att => ({
			name: Object.keys(att)[0],
			url: Object.values(att)[0],
			rev_id: att.rev_id,
		}));

		localDispatch({
			title: project.name,
			purpose: project.purpose,
			address: project.address,
			county: project.county,
			lat: project.latitude,
			lng: project.longitude,
			origintokentype: project.tokentype,
			tokentype: project.tokentype,
			origincompanytokenid: project.companytokenid,
			companytokenid: project.companytokenid,
			start: new Date(project.startDate),
			end: project.endDate !== '' && project.endDate !== null ? new Date(project.endDate) : '',
			members: project.active_member.concat(project.invite_member),
			projectid: project.projectid,
			docs,
		});
		let members = addMembers([], project.active_member);
		members = addMembers(members, project.invite_member, true);
		setMembers(members);
	}, [projectReducer?.projectDetails]);

	function validate(fld, value) {
		localDispatch({ [fld]: value });
		if (Array.isArray(value)) {
			if (!value.length) {
				setErrors(e => ({ ...e, [fld]: true }));
				return;
			}
		} else {
			if (value === '' || value === null || value === undefined) {
				setErrors(e => ({ ...e, [fld]: true }));
				return;
			}
		}
		setErrors(e => ({ ...e, [fld]: false }));
	}

	function handleSave() {
		const errs = {};
		if (!state.title) errs.title = true;
		if (!state.purpose) errs.purpose = true;
		if (!state.tokentype) errs.tokentype = true;
		if (!state.address) errs.address = true;
		if (!state.county) errs.county = true;
		for (const doc of state.docs) if (!doc.name) errs.files = true;
		for (let u = 0; u < state.invitedUsers.length; u++) {
			if (state.invitedUsers[u].id === '') errs[`user-${u}`] = true;
			if (state.invitedUsers[u].role === '') errs[`role-user-${u}`] = true;
			const member = getMemberById(state.invitedUsers[u].id);
			if (member) errs[`user-exist-${u}`] = true;
		}
		for (let u = 0; u < state.invitedExt.length; u++) {
			if (state.invitedExt[u].email === '') errs[`email-${u}`] = true;
			if (!checkEmailFormat(state.invitedExt[u].email)) errs[`email-${u}`] = true;
			if (state.invitedExt[u].role === '') errs[`role-ext-${u}`] = true;
			const member = getMemberByEmail(state.invitedExt[u].email);
			if (member) errs[`email-exist-${u}`] = true;
		}

		setErrors(errs);
		if (!__.isEmpty(errs)) return;

		// existing members with maybe deleted flag or new role
		const members = allMembers.map(member => ({
			name: member.name,
			companyid: member.companyid,
			userid: member.userid,
			firbaseid: member.firebaseid,
			roleid: member.roleid,
			rolename: member.rolename,
			licenceId: member.licenceid,
			licencename: member.licencename,
			displayname: member.displayname,
			companyname: member.companyname,
			companylicenceid: member.companylicenceid,
			delete: !!member.delete,
			projectid: state.projectid,
		}));

		// new invited members from the team
		const invited = state.invitedUsers.map(u => {
			const user = memberReducer.memberList.find(m => m.userid === u.id);
			const role = masterReducer.roleList.find(r => r.roleid === u.role);
			return {
				name: user.name,
				companyid: user.company_id,
				userid: user.userid,
				firbaseid: user.firebase_id || '',
				roleid: role.roleid,
				rolename: role.rolename,
				licenceid: '',
				companylicenceid: '',
				licencename: '',
				displayname: authReducer.userData.display_name,
				companyname: authReducer.userData.company_name,
				delete: !!user.delete,
				projectid: state.projectid,
			};
		});
		const inviteExtWithAccount = state.invitedExt
			.filter(i => i.company_name)
			.map(u => {
				const role = masterReducer.roleList.find(r => r.roleid === u.role);
				return {
					email: u.email.toLowerCase(),
					roleid: role.roleid,
					rolename: role.rolename,
					companyid: u.company_id,
					status: 'request',
					inviteid: randomString(20),
					companyname: u.company_name,
					displayname: u.display_name,
					delete: false,
					projectid: state.projectid,
					senderdisplayname: authReducer.userData.display_name,
					sendercompanyname: authReducer.userData.company_name,
				};
			});

		// invited members from outside
		const inviteExt = state.invitedExt
			.filter(i => !i.company_name)
			.map(u => {
				const role = masterReducer.roleList.find(r => r.roleid === u.role);
				return {
					email: u.email.toLowerCase(),
					inviteid: randomString(20),
					roleid: role.roleid,
					rolename: role.rolename,
					senderdisplayname: authReducer.userData.display_name,
					sendercompanyname: authReducer.userData.company_name,
				};
			});

		const requestParams = {
			projectid: state.projectid,
			name: state.title,
			purpose: state.purpose,
			address: state.address || '',
			county: state.county || '',
			latitude: state.lat.toString(),
			longitude: state.lng.toString(),
			company: authReducer.userData.company_name,
			startDate: moment(state.start),
			endDate: state.end !== '' && state.end !== null ? moment(state.end) : '',
			projectmembers: JSON.stringify(members.concat(invited)),
			teammembers: JSON.stringify(inviteExtWithAccount),
			projectOwner: authReducer.userData.name,
			files: state.docs.filter(d => d.preview),
			outsidemembers: JSON.stringify(inviteExt),
			isadmin: authReducer.userData.email === authReducer.userData.owner,
			tokentype: state.tokentype,
			companytokenid: state.companytokenid,
		};

		const deleteArray = state.docs
			.filter(d => d.delete)
			.map(d => ({
				filename: d.name,
				filerevisionid: d.rev_id,
			}));
		if (deleteArray.length > 0) {
			const docRequestParams = {
				deletedoc: JSON.stringify(deleteArray),
				id: state.projectid,
				userid: authReducer.userData.userid,
			};
			dispatch(actions.deleteDocument(apolloClient.client, docRequestParams, apolloClient.project));
		}
		dispatch(actions.editProject(apolloClient.project, requestParams));
		onSave();
	}

	return (
		<Modal
			isOpen={isOpen}
			toggle={() => onClose}
			className={`modal-dialog--success modal-dialog--header width__70`}
		>
			<div className="modal__header">
				<button
					className="lnr lnr-cross modal__close-btn"
					type="button"
					onClick={() => onClose()}
				/>
				<h4 className="bold-text  modal__title">
					{`${project_edit_project_details} - ${state.title}`}
				</h4>
			</div>
			<div className="modal__body">
				<Form>
					<Container>
						{isLoading ? (
							<Row>
								<Col xs={12} className="margin__top text-center">
									<Spinner size="sm" className="btn-spinner spinner_color spinner_size" />
								</Col>
							</Row>
						) : (
							<Row>
								<Col xs={12} md={6} className="margin__top">
									<FormGroupRequired>
										<Label>{client_project_title}</Label>
										<Input
											onChange={e => validate('title', e.target.value)}
											type="text"
											value={state.title}
										/>
										{errors.title && (
											<span className="error-message">{client_project_pls_enter_title}</span>
										)}
									</FormGroupRequired>

									<FormGroupRequired>
										<Label>{client_project_purpose}</Label>
										<Input
											onChange={e => validate('purpose', e.target.value)}
											type="textarea"
											value={state.purpose}
										/>
										{errors.purpose && (
											<span className="error-message">{client_project_pls_enter_purpose}</span>
										)}
									</FormGroupRequired>
									<FormGroup>
										<TokenList
											initialTokenType={state.tokentype}
											onChange={(newTokenType, companyTokenId) => {
												validate('tokentype', newTokenType);
												validate('companytokenid', companyTokenId);
											}}
											origincompanytokenid={state.companytokenid}
										/>
									</FormGroup>
								</Col>
								<Col xs={12} md={6}>
									<FileDropZone
										errors={errors}
										initialDocs={state.docs}
										onErrors={newErrors => setErrors(newErrors)}
										onNewDocs={docs => localDispatch({ docs })}
									/>
								</Col>
								<Col xs={12} md={6}>
									<MapComponent projectId={projectId} showControl={false} />
								</Col>
								<Col xs={12} md={6}>
									<FormGroup>
										<Label>{client_project_start_date}</Label>
										<DatePicker
											startDate={state.start}
											onChange={date => validate('start', date)}
										/>
									</FormGroup>
									<FormGroup>
										<Label>{client_project_end_date}</Label>
										<DatePicker
											startDate={state.end}
											onChange={date => validate('end', date)}
											minDate={state.start}
										/>
									</FormGroup>
									<ProjectAddress
										error={errors.address}
										initialAdress={state.address}
										onChange={values => localDispatch(values)}
									/>
									<FormGroupRequired>
										<Label>{client_project_county_number}</Label>
										<Input
											onChange={e => validate('county', e.target.value)}
											type="number"
											value={state.county}
										/>
										{errors.county && (
											<span className="error-message">{client_project_pls_enter_county}</span>
										)}
									</FormGroupRequired>
									<FormGroupRequired>
										<Label>{client_project_longtitude}</Label>
										<Input
											onChange={e => validate('lng', e.target.value)}
											type="text"
											value={state.lng}
										/>
										{errors.lng && (
											<span className="error-message">{client_project_pls_enter_longtitude}</span>
										)}
									</FormGroupRequired>
									<FormGroupRequired>
										<Label>{client_project_latitude}</Label>
										<Input
											onChange={e => validate('lat', e.target.value)}
											type="text"
											value={state.lat}
										/>
										{errors.lat && (
											<span className="error-message">{client_project_pls_enter_latitude}</span>
										)}
									</FormGroupRequired>
								</Col>

								<Col xs={12}>
									<FormGroup className="margin__top">
										<Label>{client_project_member}</Label>
										<ProjectMembers
											members={members}
											projectId={projectId}
											projectName={state.title}
											setMembers={setMembers}
											actionButton={actionButton}
										/>
									</FormGroup>
								</Col>
								<InviteUsers
									tokenType={state.tokentype}
									errors={errors}
									onChange={(invitedUsers, invitedExt) =>
										localDispatch({ invitedUsers, invitedExt })
									}
									memberIds={memberIds}
								/>
							</Row>
						)}
					</Container>
				</Form>
			</div>
			<ButtonToolbar className="modal__footer">
				<Button color={'success'} outline={true} onClick={() => onClose()}>
					{cancel_btn}
				</Button>
				<Button color={'success'} onClick={() => handleSave()} disabled={isLoading}>
					{create_update_staff_modal_update}
					{isLoading && <Spinner size="sm" className="btn-spinner" />}
				</Button>
			</ButtonToolbar>
		</Modal>
	);
}
