import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import PropTypes, { array } from 'prop-types';
import * as actions from '../../../Store/actions/index';
import { withTranslation } from 'react-i18next';
import topbar from "./topbar";

class ChangeAppLanguage extends PureComponent {

    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    constructor() {
        super();
        this.state = {
            languageArray : [],
            error : []
        };
    }

    componentDidMount() {
        //call initial actions
 
    if(!(localStorage.getItem("language_data"))){
        this.props.getLanguageMasterList(this.props.apolloClient.language, []);
    }
}

    componentWillReceiveProps(props) {
        let languageLabelList = props.languageReducer.languageLabelList
        if(languageLabelList){
            this.setState({
                 languageArray : languageLabelList
            },()=>this.forceUpdate())
        }
     }

    render() {
        let  lable_text = this.props.lable_key;
        let languagedata =this.props.languageReducer.languageLabelList;
        if(languagedata){
            let currentLanguage = window.localStorage.getItem('language');
            let languageArray = languagedata.filter((lan) => {
                return (lan.key === lable_text);
            });
            if(languageArray[0]){
                let labelLan = languageArray[0]['language']['en'];
                if(languageArray[0]['language'][currentLanguage] && languageArray[0]['language'][currentLanguage] != ""){
                        labelLan = languageArray[0]['language'][currentLanguage];
                }
                return labelLan;
            }else{
                return null;
            }
        }else{
            return null;
        }
      }
  }

  //load reducers
const mapStateToProps = state => {
    return {
        authReducer: state.authReducer,
        apolloClient: state.apolloClient,
        masterReducer: state.masterReducer,
        languageReducer: state.languageReducer,
    }
}

//Load actions
const mapDispatchToProps = dispatch => {
    return {
          getLanguageMasterList: (master, request) => { dispatch(actions.getLanguageMasterList(master, request)) }, 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ChangeAppLanguage)); //export component