import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React from 'react';

const RADIO_STYLE = { padding: '0px 10px', color: 'var(--clr-aexdo)' };

export default function RadioButtons({
	value,
	onChange,
	options = [],
	row = false,
	disabled = false,
}) {
	return (
		<RadioGroup name="chartType" value={value} onChange={e => onChange(e.target.value)} row={row}>
			{options.map(option => (
				<FormControlLabel
					key={option.label}
					value={option.value}
					control={<Radio style={RADIO_STYLE} />}
					label={option.label}
					disabled={disabled}
				/>
			))}
		</RadioGroup>
	);
}
