import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import __ from 'underscore';
import ChangeAppLanguage from './Layout/ChangeAppLanguage';
import * as ChangeAppLanguageFront from './Layout/ChangeAppLanguageFront';
import * as actions from '../../Store/actions/index';
import Particles from 'react-particles-js';
import { Button, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

let tlang = ChangeAppLanguageFront.translateLanguage;
const go_to_dashboard = tlang('go_to_dashboard') || 'Go to Dashboard';
const common_company = tlang('common_company') || 'Company';
const common_choose = tlang('common_choose') || 'Choose';
const topbar_link_logout = tlang('topbar_link_logout') || 'Logout';
const for_support = tlang('for_support') || 'for Support';

const SUPPORT_ADMIN_COMPANY = 'support'

const initialState = {
  company: null,
  companyList: [],
  projectList: [],
  project: null,
  error: [],
  loading: false,
  supportInvitationList: [],
};

function reducer(state, action) {
  switch (action.type) {
    default:
      const upd = {};
      for (const key of Object.keys(action)) {
        upd[key] = action[key];
      }
      // console.log(`upd`, { upd });
      return { ...state, ...upd };
  }
}

function Supportcompany() {
  const [state, localDispatch] = useReducer(reducer, initialState);
  const dispatch = useDispatch();
  const apolloClient = useSelector(state => state.apolloClient);
  const authReducer = useSelector(state => state.authReducer);
  const projectReducer = useSelector(state => state.projectReducer);
  const masterReducer = useSelector(state => state.masterReducer);

  useEffect(() => {

    const requestParams = {
      isApprove: true
    }

    dispatch(actions.getSupportInvitationRequest(apolloClient.masters, requestParams));

  }, []);

  useEffect(() => {
    if (!masterReducer?.supportInvitationRequestList?.length) return;

    let invitationList = masterReducer?.supportInvitationRequestList;
    let companyList = [];
    invitationList.map((l) => {
      companyList.push({ label: l.displayName, value: l.companyName })
    })

    companyList = [...new Map(companyList.map((item) => [item["value"], item])).values()];

    localDispatch({ companyList: companyList, supportInvitationList: invitationList });

  }, [masterReducer?.supportInvitationRequestList]);


  const changeProject = (event) => {
    localDispatch({
      project: event.target.value
    })
  }
  const changeCompany = (event) => {

    let filterProjects = state.supportInvitationList.filter((l) => l.companyName === event.target.value);
    let projectList = [];
    filterProjects.map((l) => {
      projectList.push({ label: l.projectName, value: l.projectId })
    })

    projectList = [...new Map(projectList.map((item) => [item["value"], item])).values()];

    localDispatch({
      company: event.target.value,
      projectList: projectList
    })
  }
  const logInAccount = () => {

    let error = [];
    // if (!state.company) {
    //   error.push('company')
    // }
    // if (!state.project) {
    //   error.push('project')
    // }

    if (error.length == 0) {
      localDispatch({ error: [] })
      let requestParams = {};
      if (!__.isEmpty(state.company) && !__.isEmpty(state.project)) {
        requestParams = {
          email: authReducer.userData.email,
          company: state.company,
          projectid: state.project
        }
      } else {
        requestParams = {
          email: authReducer.userData.email,
          company: SUPPORT_ADMIN_COMPANY,
          projectid: ""
        }
      }
      dispatch(actions.companyLogIn(apolloClient.client, requestParams));
      localDispatch({
        loading: true
      })
    } else {
      localDispatch({ error: error })
    }
  }
  const logoutUser = () => {
    // localStorage.clear();
    localStorage.removeItem("_user_");
    localStorage.removeItem("_userDetail_");
    window.location = "/signin";
  }

  return (
    <>
      <Particles
        style={{ position: "absolute" }}
        params={{
          "particles": {
            "number": {
              "value": 70
            },
            "size": {
              "value": 5
            }
          },
          "interactivity": {
            "events": {
              "onhover": {
                "enable": true,
                "mode": "repulse"
              }
            }
          }
        }}
      />

      <div className="theme-light">
        <div className="wrapper">
          <main>
            <div className="account sign-in-backround">
              <div className="account__wrapper">
                <div className="account__card sign-in-card">
                  <div className="account__head">
                    <h3 className="account__title">{common_choose}
                      <span className="account__logo"> {common_company} </span>
                      <span className="account__logo"> {for_support} </span>
                    </h3>
                  </div>
                  <form className="form">
                    <div className="form__form-group mt-2">
                      <div className="form__form-group-field">
                        <form className="">
                          <div className="form__form-group">
                            <TextField
                              select
                              error={state.error.includes('company')}
                              id="standard-select-currency"
                              SelectProps={{
                                MenuProps: {
                                  className: "select-menu-width",
                                },
                              }}
                              style={{ width: "350px" }}
                              label={<ChangeAppLanguage lable_key="sign_in_up_company" />}
                              className="material-form__field"
                              value={state.company}
                              onChange={event => changeCompany(event)}
                              InputLabelProps={{ shrink: state.company ? true : false }}
                            >
                              {state.companyList.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                          <div className="form__form-group">
                            <TextField
                              select
                              error={state.error.includes('project')}
                              id="standard-select-currency"
                              SelectProps={{
                                MenuProps: {
                                  className: "select-menu-width",
                                },
                              }}
                              style={{ width: "350px" }}
                              label={<ChangeAppLanguage lable_key="sign_in_up_project" />}
                              className="material-form__field"
                              value={state.project}
                              onChange={event => changeProject(event)}
                              InputLabelProps={{ shrink: state.project ? true : false }}
                            >
                              {state.projectList.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="account__btns">

                      <Button className="btn account__btn system-btn" style={{ marginRight: "5px" }} color="success" outline={true} type="button" onClick={e => logoutUser()} disabled={state.loading}>
                        {topbar_link_logout}
                      </Button>

                      <button style={{ marginRight: "5px" }} className="btn btn-success account__btn system-btn" type="button" onClick={e => logInAccount()} disabled={state.loading}>
                        {go_to_dashboard}
                        {state.loading ? <Spinner size="sm" style={{ marginLeft: "10px", color: "#fff" }} /> : null}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default Supportcompany;