import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    campaignDetails: [],
    getNotProjectMemberList: [],
    campaignApprovedList:[],
    allCampaignListDashboard:[],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CAMPAIGN_LIST:
            return updateObject(state, { campaignList: action.state })
        case actionTypes.GET_CAMPAIGN_DETAILS:
            return updateObject(state, { campaignDetails: action.state })
        case actionTypes.GET_NOT_PROJECT_MEMBER_LIST:
            return updateObject(state, { getNotProjectMemberList: action.state })
        case actionTypes.GET_CLOSED_CAMPAIGN_LIST:
            return updateObject(state, { campaignClosedList: action.state })
        case actionTypes.GET_APPROVED_CAMPAIGN_LIST:
            return updateObject(state, { campaignApprovedList: action.state })
        case actionTypes.GET_CAMPAIGN_LIST_DASHBOARD:
            return updateObject(state, { allCampaignListDashboard: action.state })
        default:
            return state;
    }
}

export default reducer