import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    probing3dAnalysisGraphData : {},
    testWiseRuleList : [],
    terrainAnalysisGraphData : [],
    pollutantAnalysisGraphData : [],
    pollutionList:[],
    StandardDesposalWithRules:[]
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_3D_PROBING_ANALYSIS_GRAPH:
            return updateObject(state, { probing3dAnalysisGraphData: action.state })
        case actionTypes.GET_TEST_WISE_RULE_LIST:
            return updateObject(state, { testWiseRuleList: action.state })
        case actionTypes.GET_3D_TERRAIN_ANALYSIS_GRAPH:
            return updateObject(state, { terrainAnalysisGraphData: action.state })
        case actionTypes.GET_3D_POLLUTANT_ANALYSIS_GRAPH:
            return updateObject(state, { pollutantAnalysisGraphData: action.state })
        case actionTypes.GET_POLLUTION_LIST:
            return updateObject(state, { pollutionList: action.state })
        case actionTypes.GET_STANDARD_DESPOSAL_RULE_LIST:
            return updateObject(state, { StandardDesposalWithRules: action.state })
            
            default:
            return state;
    }
}

export default reducer