import React, { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from "reactstrap";
import * as actions from "../../../../Store/actions/index";
import { Link } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import { Icon, Checkbox, Tooltip } from "@material-ui/core";
import '../../../assets/scss/component/excelUpload.scss';
import {supremeRole} from '../../Common/commonFunctions';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import ModalDeleteConfirmation from '../components/dataVisualization/modals/deleteConfirmation';
import ModalViewExcelDetails from '../components/excel/modals/viewExcelDetails';

let tlang = ChangeAppLanguageFront.translateLanguage;
const analysis_view_details = tlang('analysis_view_details') || 'View details';
const analysis_view_analysis_reports = tlang('analysis_view_analysis_reports') || 'View analysis reports';
const material_list_sr_no = tlang('material_list_sr_no') || 'Sr.No';
const analysis_file_name = tlang('analysis_file_name') || 'File name';
const analysis_sheet_name = tlang('analysis_sheet_name') || 'Sheet name';
const profile_campaign_name = tlang('profile_campaign_name') || 'Campaign Name';
const sort_text = tlang('sort_text') || 'Sort';
const rows_per_page_text = tlang('rows_per_page_text') || 'Rows per page:';
const no_match_record_found1 = tlang('no_match_record_found') || 'Sorry, no matching records found';
const search_text = tlang('search_text') || 'Search';

const initialState = {
  excelDataList: [],
  campaignList: [],
  detailsModal: false,
  selectedRecord: false,
  addAnalysisModal: false,
  analysis: {
    name: "",
    desposals: []
  },
  isclosed: false,
  getListLoading: true,
  tableApproxWidth: 0,
  totalMatchedTestFamily: 0,
  totalUnMatchedTestFamily: 0,
  totalDuplicateTestFamily: 0,
  deleteExcelsheetModal: false,
  deleteExcelsheetData: "",
  exceptionConst: [],
  delete: {},
}

function reducer(state, action) {
  switch (action.type) {
    default:
      const upd = {};
      for (const key of Object.keys(action)) {
        upd[key] = action[key];
      }
      // console.log(`upd`, { upd });
      return { ...state, ...upd };
  }
}

function ViewExcelData() {
  const [state, localDispatch] = useReducer(reducer, initialState);
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.authReducer);
  const campaignReducer = useSelector(state => state.campaignReducer);
  const excelUploadReducer = useSelector(state => state.excelUploadReducer);
  const notification = useSelector(state => state.notification);
  const apolloClient = useSelector(state => state.apolloClient);

  useEffect(() => {

    if (authReducer.userData.projectid) {
      let isadmin = authReducer.userData.email == authReducer.userData.owner ? true : false;
      let request = {
        companyname: authReducer.userData.company_name,
        projectid: authReducer.userData.projectid,
        other_company: authReducer.userData.other_company,
        isadmin: isadmin,
      };
      /* get Campaign List */
      dispatch(actions.getCampaignList(apolloClient.campaign, request));

      /* get Excel Data List */
      dispatch(actions.getExcelDataList(apolloClient.probing, request));

      //Get all Exceptions list
      let requestData = {
        company_id: authReducer.userData.company_id
      };
      dispatch(actions.getExceptionSetting(apolloClient.masters, requestData));
    }

  }, []);

  useEffect(() => {
    if (campaignReducer.campaignList) {
      localDispatch({ campaignList: campaignReducer.campaignList });
    }

    if (notification) {
      let analysis = state.analysis
      analysis.name = ""
      analysis.desposals = []
      if (notification.success) {
        localDispatch({
          addAnalysisModal: false,
          deleteExcelsheetModal: false,
          deleteExcelsheetData: "",
          analysis: analysis,
        })
      }
    }
  }, [notification?.success]);

  useEffect(() => {
    if (campaignReducer.campaignList) {
      localDispatch({ campaignList: campaignReducer.campaignList });
    }
  }, [campaignReducer?.campaignList?.length]);

  useEffect(() => {
    //if (!excelUploadReducer?.excelDataList?.length) {console.log(33333); localDispatch({ getListLoading: false }); return } ;
    //if (excelUploadReducer.excelDataList) {
      localDispatch({ excelDataList: excelUploadReducer.excelDataList, getListLoading: false });
    //}
  }, [excelUploadReducer?.excelDataList]);

  useEffect(() => {
    if (excelUploadReducer.exceptionSettingsList && excelUploadReducer.exceptionSettingsList != null) {
      if (excelUploadReducer.exceptionSettingsList.exception_strings.length > 0) {
        let allexceptionlist = excelUploadReducer.exceptionSettingsList.exception_strings;
        localDispatch({ exceptionConst: allexceptionlist });
      }
    }
  }, [excelUploadReducer?.exceptionSettingsList]);

  useEffect(() => {
    if (authReducer.companyList && authReducer.companyList.length > 0) {
      let activeProject = authReducer.companyList.filter((val) => val.projectid === authReducer.userData.projectid)
      if (activeProject.length > 0) {
        localDispatch({ isclosed: activeProject[0].isclosed ? activeProject[0].isclosed : false });
      }
    }
  }, [authReducer?.companyList?.length]);

  useEffect(() => {

    if (excelUploadReducer.selectedExcelDataList != "" && excelUploadReducer.selectedExcelDataList != undefined) {

      localDispatch({ detailsModal: !state.detailsModal });

      let value = excelUploadReducer.selectedExcelDataList;
      let sheet_resp_header_length = value.sheet_resp_header.length;
      let totalMatchedTestFamily = value.sheet_resp_data.filter(l => l['Test_Match_Status'] == "Match").length;
      let totalUnMatchedTestFamily = value.sheet_resp_data.filter(l => l['Test_Match_Status'] == "UnMatch").length;
      let totalDuplicateTestFamily = value.sheet_resp_data.filter(l => l['Test_Match_Status'] == "Duplicate").length;
      let fixWidth = 600;
      let increaseWidth = 108 * sheet_resp_header_length;
      fixWidth = fixWidth + increaseWidth;

      localDispatch({ selectedRecord: value, totalMatchedTestFamily: totalMatchedTestFamily, totalUnMatchedTestFamily: totalUnMatchedTestFamily, totalDuplicateTestFamily: totalDuplicateTestFamily, tableApproxWidth: fixWidth });

    } else {
      localDispatch({ selectedRecord: false });
    }
  }, [excelUploadReducer?.selectedExcelDataList]);

  /* render campaign list */
  const renderExceldataList = () => {
    let exceldataList = [];
    if (state.excelDataList) {
      state.excelDataList.map((val, index) => {
        let campaignName = "";
        state.campaignList.map((campaign, i) => {
          if (campaign.campaignid == val.campaignid) {
            campaignName = campaign.title;
          }
        })
        exceldataList.push([
          index + 1,
          val.file_name,
          val.sheet_name,
          campaignName,
          val,
          // val,
          // val
        ]);
      });
    }
    return exceldataList;
  };

  const getExcelUploadDetails = (event, value = false) => {
    let request = {
      id: value._id,
      projectid: value.projectid
    };
    /* get selected excel data list */
    dispatch(actions.getSelectedExcelData(apolloClient.probing, request));
    localDispatch({ getListLoading: true })
  }

  const toggleDeleteExcelsheet = (event, value = false) => {
    let objectName = value?.file_name;
    let actionName = 'excelsheet';
    localDispatch({
      delete: { actionName, objectName, value },
      deleteExcelsheetModal: !state.deleteExcelsheetModal,
      deleteExcelsheetData: value ? value : ""
    })
  }

  const deleteExcelsheet = () => {
    let request = {
      id: state.deleteExcelsheetData._id,
      campaignid: state.deleteExcelsheetData.campaignid,
      projectid: authReducer.userData.projectid
    }
    dispatch(actions.deleteTestAnalysisData(apolloClient.probing, request));

    //Refresh the Listing by calling the getExcelDataList API again
    setTimeout(() => {
      let isadmin = authReducer.userData.email == authReducer.userData.owner ? true : false;
      let requestData = {
        companyname: authReducer.userData.company_name,
        projectid: authReducer.userData.projectid,
        other_company: authReducer.userData.other_company,
        isadmin: isadmin,
      };
      /* get Excel Data List */
      dispatch(actions.getExcelDataList(apolloClient.probing, request));
    }, 3000);
  }

  let isDisabled = authReducer.userData.plan.planid == "AXD_PL_01" ? true : false;

  const columns = [
    {
      name: material_list_sr_no
    },
    {
      name: analysis_file_name
    },
    {
      name: analysis_sheet_name
    },
    {
      name: profile_campaign_name
    },
    {
      name: analysis_view_details,
      options: {
        filter: false,
        customBodyRender: value => {
          if (value) {
            return (
              <>
                <Button
                  title={analysis_view_details}
                  className={"system-btn clear-border"}
                  outline={true}
                  color={"success"}
                  onClick={(event) => getExcelUploadDetails(event, value)}
                >
                  <Icon>remove_red_eye</Icon>
                </Button>
                {state.isclosed == false &&
                  <Button
                    title={"Edit"}
                    className={"system-btn clear-border"}
                    outline={true}
                    color={"success"}
                    disabled={isDisabled}
                  >
                    <Link id="editexcellink" style={{ color: "#E64823" }} to={"/client/excelUpload/edit/" + value._id} ><Tooltip title={"Edit"}><Icon>edit</Icon></Tooltip></Link>
                  </Button>
                }
                {state.isclosed == false && ((authReducer.userData.email == authReducer.userData.owner) || supremeRole.includes(authReducer.userData.role_id)) &&
                  <Button
                    title={"Delete"}
                    className={"system-btn clear-border"}
                    outline={true}
                    color={"success"}
                    onClick={(event) => toggleDeleteExcelsheet(event, value)}
                  >
                    <Icon>delete</Icon>
                  </Button>
                }

              </>
            );
          }
        }
      }
    },
  ];

  const options = {
    filterType: 'checkbox',
    selectableRows: "none",
    print: false,
    download: false,
    viewColumns: false,
    filter: false,
    responsive: "stacked",
    textLabels: {
      body: {
        noMatch: no_match_record_found1,
        toolTip: sort_text
      },
      pagination: {
        rowsPerPage: rows_per_page_text,
      },
      toolbar: {
        search: search_text
      },
    },
  };

  return (
    <>
      <div>
        <h3 className="page-title" style={{ marginBottom: "20px" }}>
          {analysis_view_analysis_reports}
        </h3>
        <div>
          {state.getListLoading && (
            <div className="div-spinner"><Spinner size="sm" className="btn-spinner spinner_color spinner_size" /></div>
          )}
          {!state.getListLoading && (
            <MUIDataTable
              data={renderExceldataList()}
              columns={columns}
              options={options}
            />
          )}
        </div>

        <div>
          {state.detailsModal && (
            <ModalViewExcelDetails
              isOpen={state.detailsModal}
              onClose={() => localDispatch({ detailsModal: false, selectedRecord: false, getListLoading: false })}
              selectedRecord={state.selectedRecord}
              tableApproxWidth={state.tableApproxWidth}
              totalMatchedTestFamily={state.totalMatchedTestFamily}
              totalUnMatchedTestFamily={state.totalUnMatchedTestFamily}
              totalDuplicateTestFamily={state.totalDuplicateTestFamily}
              exceptionConst={state.exceptionConst}
            />
          )}

          {state.deleteExcelsheetModal && (
            <ModalDeleteConfirmation
              isOpen={state.deleteExcelsheetModal}
              onClose={() => localDispatch({ deleteExcelsheetModal: false, deleteExcelsheetData: "" })}
              onConfirm={() => deleteExcelsheet()}
              deleteData={state.delete}
            />
          )}
        </div>
      </div>
    </>
  );

}

export default ViewExcelData;