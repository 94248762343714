import React from 'react';
import * as actionTypes from '../actionTypes';
import { gql } from "apollo-boost";
import { Encrypt } from "../../shared/encrpt";
import * as types from '../types';
import axios from 'axios';
import Server from '../../../Common/Server';
import * as actions from '../index';
import firebase from 'firebase/app';
import 'firebase/database';
import moment from 'moment';
import notificationMsg  from '../../../App/view/Layout/NotificationMessage';
import * as ChangeAppLanguageFront from '../../../App/view/Layout/ChangeAppLanguageFront';
let tlang = ChangeAppLanguageFront.translateLanguage;

const projectcreate = tlang('notificationSuccess_projectcreate') ? tlang('notificationSuccess_projectcreate') : 'Project Created Successfully';
const projectupdate = tlang('notificationSuccess_projectupdate') ? tlang('notificationSuccess_projectupdate') : 'Project Updated Successfully';
const projectremove = tlang('notificationSuccess_projectremove') ? tlang('notificationSuccess_projectremove') : 'Project Removed Successfully';
const projectUserAssigned = tlang('notificationSuccess_projectUserAssigned') ? tlang('notificationSuccess_projectUserAssigned') : 'User Assigned Successfully';
const projectUserReqRejected = tlang('notificationSuccess_projectUserReqRejected') ? tlang('notificationSuccess_projectUserReqRejected') : 'User Request Rejected Successfully';
const projectInviteReqRejected = tlang('notificationSuccess_projectInviteReqRejected') ? tlang('notificationSuccess_projectInviteReqRejected') : 'Invite Request Rejected Successfully';
const projectUpdate = tlang('notificationSuccess_projectUpdate') ? tlang('notificationSuccess_projectUpdate') : 'Project Updated Successfully';
const error_something_wrong = tlang('error_something_wrong') ? tlang('error_something_wrong') : 'Something Went Wrong!';
const email_sent_successfully = tlang('email_sent_successfully') ? tlang('email_sent_successfully') : 'Email Sent successfully';
const creating_account_reviewed_msg = tlang('other_notification_sucess_your_request_for_creating_account_has_been_reviewed') ? tlang('other_notification_sucess_your_request_for_creating_account_has_been_reviewed') : 'Your request for creating account has been received. You will get an email on your email from registration@aexdo.com once the request has been reviewed';
const client_add_promotor = tlang('client_add_promotor') ? tlang('client_add_promotor') : 'Please add promotor member';
const licence_removed_successfully = tlang('licence_removed_successfully') ? tlang('licence_removed_successfully') : 'Licence Removed Successfully';
const excelsheet_deleted_successfully = tlang('notificationSuccess_delete_excelsheet') ? tlang('notificationSuccess_delete_excelsheet') : 'Data Deleted Successfully';

// notification Success
const notificationSuccess = (msg) => {
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_SUCCESS,
    state: msg,
  }
}

// notification Fail
const notificationFail = (err) => {
  let msg = error_something_wrong;
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    // msg = err.graphQLErrors[0].message;
    let translateMsg =notificationMsg(err.graphQLErrors[0].statusCode)
    msg = translateMsg;
  } else if (err.message) {
    msg = err.message;
  }
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_FAIL,
    state: msg
  }
}

//upload file
const uploadFile = async (data, method) => {
  return new Promise(async (resolve, reject) => {

    axios.post(Server.ImageAPI + method, data)
      .then(function (response) {
        resolve(creating_account_reviewed_msg);
      })
      .catch(function (error) {
        resolve(creating_account_reviewed_msg);
        // reject(error);
      })
  })
}

// get List Success
const getListSuccess = (data) => {
  return {
    type: actionTypes.GET_PROJECT_LIST,
    state: data
  }
}

// get Document List
const getDocumentList = (data) => {
  return {
    type: actionTypes.USER_DOCUMENT_LIST,
    state: data
  }
}

// get staff list action
export const getProjectList = (client, request) => {
  return dispatch => {
    const query = gql`
        query get_project_list($company: String!, $isadmin: Boolean) {
              get_project_list(company: $company, isadmin: $isadmin) {
              _id
              projectid
              name
              purpose
              address
              latitude
              longitude
              company
              companyname
              displayname
              startDate
              endDate
              status
              projectOwner
              ownerid
              isclosed
              tokentype
              support_request_status
              created_at
          }
        }
        `;
    client.query({
      query: query,
      variables: {
        company: Encrypt(request.company),
        isadmin: request.isadmin
      }
    }).then(response => {
      dispatch(getListSuccess(response.data.get_project_list))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}
// get staff list action
export const getWorkingProjectList = (client, request) => {

  return dispatch => {
    const query = gql`
        query get_working_project_list($projectid: String!) {
          get_working_project_list(projectid: $projectid) {
              _id
              projectid
              name
              purpose
              address
              latitude
              longitude
              company
              startDate
              endDate
              status
              projectOwner
              isclosed
          }
        }
        `;
    client.query({
      query: query,
      variables: {
        projectid: Encrypt(request.projectid)
      }
    }).then(response => {
      dispatch(getListSuccess(response.data.get_working_project_list))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// Create new project
export const createNewProject = (project, client, request) => {
  return dispatch => {
    const mutation = gql`
          mutation create_project($name: String!, $purpose: String!, $address: String, $county: String!, $latitude: String!, $longitude: String!, $company: String!, $startDate: String!, $endDate: String, $projectmembers: String!, $teammembers: String!, $projectOwner: String!, $outsidemembers: String!, $ownerid: String!, $companytokenid: String, $tokentype: String) {
            create_project(name: $name, purpose: $purpose, address: $address, county: $county, latitude: $latitude, longitude: $longitude, company: $company, startDate: $startDate, endDate: $endDate, projectmembers: $projectmembers, teammembers: $teammembers, projectOwner: $projectOwner, outsidemembers: $outsidemembers, ownerid: $ownerid, companytokenid: $companytokenid, tokentype: $tokentype) {
              _id
              projectid
              name
              purpose
              address
              county
              latitude
              longitude
              companytokenid
              company
              startDate
              endDate
              status
              projectOwner
              ownerid
              tokentype
            }
          }
        `;
    project.mutate({
      mutation: mutation,
      variables: {
        name: Encrypt(request.name),
        purpose: Encrypt(request.purpose),
        address: Encrypt(request.address),
        county: Encrypt(request.county),
        latitude: Encrypt(request.latitude),
        longitude: Encrypt(request.longitude),
        companytokenid: Encrypt(request.companytokenid),
        company: Encrypt(request.company),
        startDate: Encrypt(request.startDate),
        endDate: Encrypt(request.endDate),
        projectmembers: Encrypt(request.projectmembers),
        teammembers: Encrypt(request.teammembers),
        projectOwner: Encrypt(request.projectOwner),
        outsidemembers: Encrypt(request.outsidemembers),
        ownerid: Encrypt(request.ownerid),
        tokentype: Encrypt(request.tokentype),
      }
    })
      .then(response => {

        if (request.files.length > 0) {
          var data = new FormData();
          request.files.map((val) => {
            data.append('files', val, val.name);
          })
          data.append('companyid', response.data.create_project.projectid);

          uploadFile(data, 'insert_attechment/').then((response) => {

          }).catch((err) => {
            dispatch(notificationFail(err))
          })
        }
        let requestNew = {
          companyname: request.company
        }
        dispatch(actions.getUserProjectList(project, requestNew))
        if (request.checkRequest === "yes") {
          let requestParams = {
            projectid: response.data.create_project.projectid,
            email: request.email,
            isRefresh: true,
          }
          dispatch(actions.getAccess(client, requestParams))
        }
        dispatch(notificationSuccess(projectcreate))
        dispatch(getListSuccess(response.data.create_staff, types.Create))
      }).catch((error) => {
        // if (error.graphQLErrors && error.graphQLErrors.length > 0 ){
        //   let msg= notificationMsg(error.graphQLErrors[0].statusCode)         
        //   dispatch(notificationFail({ message: msg}))
        // } else {
          dispatch(notificationFail(error))
        // }
      });
  }
}

// get Search Success
const getSearchSuccess = (data) => {
  return {
    type: actionTypes.GET_SEARCH_PROJECT_LIST,
    state: data
  }
}

// remove All Msgs
export const removeAllMsgs = () => {
  return {
    type: actionTypes.REMOVE_ALL_MESSAGES,
    state: []
  }
}

// search Company
export const searchCompany = (client, request) => {
  return dispatch => {
    const query = gql`
        query company_list($search: String!) {
            company_list(search: $search){
              _id
              name
              email
              company_id
              company_name
              display_name
              company_address
              registration_country
              contact_number
              status
              approve_reject_by
              approve_reject_datetime
              company_strength
              confirm_account
              is_editable
              created_at
              updated_at
              attachments
              account_status
          }
        }
        `;
    client.query({
      query: query,
      variables: {
        search: Encrypt(request.search)
      }
    }).then(response => {
      dispatch(getSearchSuccess(response.data.company_list))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// get Licence List Success
const getLicenceListSuccess = (data) => {
  return {
    type: actionTypes.GET_COMPANY_LICENCE_LIST,
    state: data
  }
}

// get company licence list in profile
export const getCompanylicence = (client, request) => {
  return dispatch => {
    const query = gql`{
          company_licence_list {
            _id
            licenceid
            licencename
            price
            used
            companylicenceid
            purchasedate
            expirydate
          }
        }
        `;
    client.query({
      query: query,
      fetchPolicy: 'no-cache',
    }).then(response => {
      dispatch(getLicenceListSuccess(response.data.company_licence_list))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// get Project Licence Success
const getProjectLicenceSuccess = (data) => {
  return {
    type: actionTypes.GET_PROJECT_LICENCE_LIST,
    state: data
  }
}

// get Poject Licence
export const getPojectLicence = (client, request) => {
  return dispatch => {
    const query = gql`{
          project_licence_list {
            licenceid
            companylicenceid
            licencename
            expirydate
            count
          }
        }
        `;
    client.query({
      query: query,
    }).then(response => {
      dispatch(getProjectLicenceSuccess(response.data.project_licence_list))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// get project Details Success
const getprojectDetailsSuccess = (data) => {
  return {
    type: actionTypes.GET_PROJECT_DETAILS,
    state: data
  }
}

// get project Details
export const getprojectDetails = (client, request) => {
  return dispatch => {
    const query = gql`
          query project_detail($id: String!, $company:String) {
            project_detail(id: $id, company: $company) {
              _id
              projectid
              name
              purpose
              address
              county
              latitude
              longitude
              companytokenid
              company
              startDate
              endDate
              status
              attachments
              projectOwner
              isclosed
              otherSettings
              tokentype
              project_member{
                name
                userid
                rolename
                roleid
                firebaseid
                companyid
                licenceid
                licencename
                status
                inviteid
                created_at
                displayname
                companyname
                companylicenceid
                email
                membercompanydisplayname
              }
              invite_member {
                name
                email
                userid
                rolename
                roleid
                firebaseid
                companyid
                licenceid
                licencename
                status
                inviteid
                created_at
                displayname
                companyname
                companylicenceid
                membercompanydisplayname
              }
              active_member{
                name
                email
                userid
                rolename
                roleid
                firebaseid
                companyid
                companyname
                licenceid
                licencename
                status
                inviteid
                created_at
                companylicenceid
                displayname
                membercompanydisplayname
              }
              delete_member{
                name
                userid
                rolename
                roleid
                firebaseid
                companyid
                licenceid
                licencename
                status
                inviteid
                created_at
                companylicenceid
              }
            }
          }
        `;
    client.query({
      query: query,
      variables: {
        id: Encrypt(request.id),
        company: request.company ? Encrypt(request.company) : null,
      }
    }).then(response => {
      dispatch(getprojectDetailsSuccess(response.data.project_detail))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// edit Project
export const editProject = (client, request) => {
  return dispatch => {
    const mutation = gql`
    mutation edit_project ($projectid: String!, $name: String!, $purpose:String!, $address:String, $county:String, $latitude: String!, $longitude:String!, $company:String!, $startDate:String!, $endDate:String, $projectOwner: String!, $projectmembers: String!, $teammembers: String!,$outsidemembers: String!,$otherSettings: String,$tokentype: String, $companytokenid: String) {
      edit_project(projectid: $projectid, name: $name, purpose: $purpose, address:$address, county:$county, latitude:$latitude, longitude:$longitude, company:$company, startDate:$startDate, endDate:$endDate, projectOwner:$projectOwner, projectmembers: $projectmembers, teammembers: $teammembers, outsidemembers: $outsidemembers, otherSettings: $otherSettings, tokentype: $tokentype, companytokenid: $companytokenid) {
        _id
        projectid
        name
        purpose
        address
        county
        latitude
        longitude
        company
        startDate
        endDate
        status
        projectOwner
        otherSettings
        project_member {
          name
          rolename
        }
        invite_member{
          companyname
          rolename
        }
      }
    }
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        projectid: Encrypt(request.projectid),
        name: Encrypt(request.name),
        purpose: Encrypt(request.purpose),
        address: Encrypt(request.address),
        county: Encrypt(request.county),
        latitude: Encrypt(request.latitude),
        longitude: Encrypt(request.longitude),
        company: Encrypt(request.company),
        startDate: Encrypt(request.startDate),
        endDate: Encrypt(request.endDate),
        projectOwner: Encrypt(request.projectOwner),
        projectmembers: Encrypt(request.projectmembers),
        teammembers: Encrypt(request.teammembers),
        outsidemembers: Encrypt(request.outsidemembers),
        otherSettings: request.otherSettings ? Encrypt(request.otherSettings) : null,
        tokentype: Encrypt(request.tokentype),
        companytokenid: Encrypt(request.companytokenid),
      }
    })
      .then(response => {
        if (request.files.length > 0) {
          var data = new FormData();
          request.files.map((val) => {
            data.append('files', val, val.name);
          })
          data.append('companyid', response.data.edit_project.projectid);

          uploadFile(data, 'insert_attechment/').then((response) => {

          }).catch((err) => {
            dispatch(notificationFail(err))
          })
        }

        dispatch(actions.getProjectList(client, request))

        let requestParams = {
          id: request.projectid
        }
        dispatch(actions.getprojectDetails(client, requestParams))
        
        dispatch(notificationSuccess(projectupdate))
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}

// remove Project
export const removeProject = (client, request) => {
  return dispatch => {
    const mutation = gql`
          mutation delete_project ($projectid: String!) {
            delete_project(projectid: $projectid) {
              _id
              projectid
              name
              purpose
              address
              latitude
              longitude
              company
              startDate
              endDate
              status
              projectOwner
              isclosed
              created_at
              }
            }
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        projectid: Encrypt(request.projectid),
      }
    })
      .then(response => {
        let requestNew = {
          companyname: request.company
        }
        dispatch(actions.getUserProjectList(client, requestNew))
        dispatch(getListSuccess(response.data.delete_project))
        dispatch(notificationSuccess(projectremove))
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}

// get Project Invite Success
const getProjectInviteSuccess = (data) => {
  return {
    type: actionTypes.GET_INVITE_USER_LIST,
    state: data
  }
}

export const getProjectInvite = (client, request) => {
  return dispatch => {
    const query = gql`
        {
          invite_list {
            _id
            inviteid
            projectid
            senderid
            receiverid
            roleid
            rolename
            status
            created_at
          }
        }
        `;
    client.query({
      query: query,
    }).then(response => {
      dispatch(getProjectInviteSuccess(response.data.invite_list))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// accept Project Invite
export const acceptProjectInvite = (client, request, client2) => {
  return dispatch => {
    const mutation = gql`
      mutation accept_request ($name: String!, $company: String!, $licenceid: String!, $userid: String!, $inviteid: String!, $firebaseid: String!, $licencename: String!) {
        accept_request(name: $name, company: $company, licenceid: $licenceid, userid: $userid, inviteid: $inviteid, firebaseid: $firebaseid, licencename: $licencename) {
          _id
          inviteid
          projectid
          senderid
          receiverid
          roleid
          rolename
          status
          created_at
        }
      }
  `;
    client.mutate({
      mutation: mutation,
      variables: {
        name: Encrypt(request.name),
        company: Encrypt(request.company),
        licenceid: Encrypt(request.licenceid),
        userid: Encrypt(request.userid),
        inviteid: Encrypt(request.inviteid),
        firebaseid: Encrypt(request.firebaseid),
        licencename: Encrypt(request.licencename),
      }
    })
      .then(response => {
        let newRequest = {
          companyname: request.companyname
        }
        dispatch(actions.getUserRequestData(client2, newRequest))

        dispatch(actions.getCompanylicence(client2, request))
        dispatch(getProjectInviteSuccess(response.data.accept_request))
        dispatch(notificationSuccess(projectUserAssigned))
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}

// reject Project Request
export const rejectProjectRequest = (client, request, client2) => {
  return dispatch => {
    const mutation = gql`
          mutation decline_request($inviteid: String!) {
            decline_request(inviteid: $inviteid) {
                message
                success
                error
            }
          }
          `;
    client.mutate({
      mutation: mutation,
      variables: {
        inviteid: Encrypt(request.inviteid),
      }
    })
      .then(response => {
        let newRequest = {
          companyname: request.companyname
        }
        dispatch(actions.getUserRequestData(client2, newRequest))
        dispatch(actions.getCompanylicence(client2, request))
        //  dispatch(getProjectInviteSuccess(response.data.accept_request))       
        dispatch(notificationSuccess(projectUserReqRejected))
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}

// reject Campaign Invite Request
export const rejectCampaignInviteRequest = (campaign, request, client) => {
  return dispatch => {
    const mutation = gql`
            mutation decline_campaign_invite_request($campaignid: String! , $inviteid: String!) {
              decline_campaign_invite_request(campaignid: $campaignid, inviteid: $inviteid) {
                message
                error
                success
              }
            }
          `;
    campaign.mutate({
      mutation: mutation,
      variables: {
        campaignid: Encrypt(request.campaignid),
        inviteid: Encrypt(request.inviteid),
      }
    })
      .then(response => {

        let newRequest = {
          companyname: request.companyname
        }
        dispatch(actions.getUserRequestData(client, newRequest))
        dispatch(notificationSuccess(projectInviteReqRejected))
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}

// save All Message
const saveAllMessage = (data) => {
  return ({
    type: actionTypes.ALL_MESSAGES,
    state: data
  })
}

/**
 * All messages of project
 */
export const getProjectMessages = (projectid, userdata, state) => {
  return dispatch => {
    firebase.database().ref('projects/' + projectid).orderByValue().on("value", (snapshot) => {
      let allMessages = []
      snapshot.forEach((value) => {
        if (value.key === "messages") {
          value.forEach((msg) => {
            allMessages.push(msg.val())
          })
        }
        if (value.key === "unreadcount") {
          value.forEach((unreadcount) => {
            if (unreadcount.key === userdata.userid) {
              firebase.database().ref('projects/' + projectid + '/unreadcount/' + userdata.userid).set(0);
            }
          })
        }
      })
      /**
       * Save all messages in reducer
       */
      dispatch(saveAllMessage(allMessages))
    })
  }
}

// sendMessage
export const sendMessage = (userdata, state, client) => {
  let pics = JSON.parse(userdata.userattachments)

  let user_image = ""
  if (pics && pics[0] && pics[0].user_pic) {
    user_image = pics[0].user_pic
  } else {
    user_image = ""
  }

  return dispatch => {
    if (state.message.trim() !== "" || state.url !== "") {
      let currentTimestamp = moment().unix();
      let msgdata = {
        senderid: userdata.userid,
        sendername: userdata.name,
        message: state.message,
        sender_image: user_image,
        filename: state.filename ? state.filename : "",
        filenamestorage: state.filenamestorage ? state.filenamestorage : "",
        timestamp: currentTimestamp,
        url: state.url != "" ? state.url : null
      }
      let sendNotificationRequest = {
        projectid: state.projectDetails.projectid,
        companyname: state.company_name,
        userid: userdata.userid,
        message: state.message.trim(),
        docurl: state.url ? state.url : "",
        filename: state.filename ? state.filename : ""
      }
      firebase.database().ref('projects/' + state.projectDetails.projectid + "/messages/" + currentTimestamp).set(msgdata).then(() => {
        firebase.database().ref('projects/' + state.projectDetails.projectid + '/unreadcount').once("value", (snap) => {
          let data = snap.val();
          let firebasedata = [];
          firebasedata[userdata.userid] = 0;
          if (data) {
            Object.keys(data).map((val, index) => {
              if (val !== userdata.userid) {
                firebasedata[val] = data[val] + 1;
              }
            });
          }
          firebase.database().ref('projects/' + state.projectDetails.projectid + '/unreadcount').set(firebasedata);
        });
      })
      dispatch(actions.sendNotificaionToOtherUser(client, sendNotificationRequest))
    }
  }
}

// send notification to other user
export const sendNotificaionToOtherUser = (client, request) => {
  return dispatch => {
    const mutation = gql`
            mutation save_chat_notification(
              $projectid: String!,
              $companyname: String!,
              $userid: String!,
              $message: String!,
              $docurl: String!,
              $filename: String!
              ) {
              save_chat_notification(
                projectid: $projectid,
                companyname: $companyname,
                userid: $userid,
                message: $message,
                docurl: $docurl,
                filename: $filename
              ) {
                message
                error
                success
              }
            }
          `;
    client.mutate({
      mutation: mutation,
      variables: {
        projectid: Encrypt(request.projectid),
        companyname: Encrypt(request.companyname),
        userid: Encrypt(request.userid),
        message: Encrypt(request.message),
        docurl: Encrypt(request.docurl),
        filename: Encrypt(request.filename)
      }
    }).then(response => {
      // dispatch(notificationSuccess('Notification send to ohter user successfully'))
    }).catch((error) => {
      // dispatch(notificationFail(error))
    });
  }
}

// get user documents
export const getUserDocument = (client, request) => {
  return dispatch => {
    const query = gql`
        query get_user_document($userid: String!) {
            get_user_document(userid: $userid) {
              file_name
              length,
              type,
              url
          }
        }
        `;
    client.query({
      query: query,
      variables: {
        userid: Encrypt(request.userid)
      }
    }).then(response => {
      dispatch(getDocumentList(response.data.get_user_document))
    }).catch((error) => {
      //(notificationFail(error))
    });
  }
}

// get project documents
export const getProjectDocument = (client, request) => {
  return dispatch => {
    const query = gql`
        query get_attchments($filterid: String!) {
          get_attchments(filterid: $filterid) {
              file_name
              length,
              type,
              url
          }
        }
        `;
    client.query({
      query: query,
      variables: {
        filterid: Encrypt(request.projectid)
      }
    }).then(response => {
      dispatch(getDocumentList(response.data.get_attchments))
    }).catch((error) => {
      //(notificationFail(error))
    });
  }
}

// close Project
export const closeProject = (client, request) => {

  return dispatch => {
    const mutation = gql`
        mutation close_project($projectid: String!, $close_description: String!, $isclosed: Boolean!) {
          close_project(projectid: $projectid, close_description: $close_description, isclosed: $isclosed) {
            projectid
          }
        }
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        projectid: Encrypt(request.projectid),
        close_description: Encrypt(request.close_description),
        isclosed: request.isclosed
      }
    }).then(response => {
      dispatch(actions.getProjectList(client, request))
      dispatch(notificationSuccess(projectUpdate))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// get Status Wise Project List Success
const getStatusWiseProjectListSuccess = (data) => {
  return {
    type: actionTypes.GET_STATUS_WISE_PROJECT_LIST,
    state: data
  }
}
// get Status Wise Project List
export const getStatusWiseProjectList = (client, request) => {
  return dispatch => {
    const query = gql`
        query get_statuswise_project_list($isclosed: Boolean!, $isadmin: Boolean!) {
          get_statuswise_project_list(isclosed: $isclosed, isadmin: $isadmin ) {
              _id
              projectid
              companytokenid 
              name 
              projectname 
              purpose 
              address 
              latitude 
              longitude 
              company 
              companyname 
              displayname 
              startDate 
              endDate 
              status 
              isclosed 
              close_description 
              projectOwner 
              ownerid 
              attachments
              project_member {
                name 
                projectid 
                userid 
                rolename 
                roleid 
                firebaseid 
                companyid 
                companyname 
                displayname 
                licenceid 
                licencename 
                companylicenceid 
                status 
                inviteid 
                created_at 
              }
              invite_member {
                name 
                projectid 
                userid 
                rolename 
                roleid 
                firebaseid 
                companyid 
                companyname 
                displayname 
                licenceid 
                licencename 
                companylicenceid 
                status 
                inviteid 
                created_at 
              }
              active_member {
                name 
                projectid 
                userid 
                rolename 
                roleid 
                firebaseid 
                companyid 
                companyname 
                displayname 
                licenceid 
                licencename 
                companylicenceid 
                status 
                inviteid 
                created_at 
              }              
          }
        }
        `;
    client.query({
      query: query,
      variables: {
        isclosed: request.isclosed,
        isadmin: request.isadmin
      }
    }).then(response => {
      dispatch(getStatusWiseProjectListSuccess(response.data.get_statuswise_project_list))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// sending email to project member
export const sendEmailProjectMembers = (client, request) => {
  var emailArray = []
  emailArray.push(request.to_email)
  return dispatch => {
    const mutation = gql`
        mutation send_email_project_members($from_email: String!, $to_email: String!,$subject_email: String!,$html_email: String!,$project_name: String!) {
          send_email_project_members(from_email: $from_email,to_email: $to_email,subject_email: $subject_email,html_email: $html_email,project_name: $project_name) {
            message
            error
            success
          }
        }
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        from_email: Encrypt(request.from_email),
        to_email: Encrypt(JSON.stringify(emailArray)),
        subject_email: Encrypt(request.subject_email),
        html_email: request.html_email,
        project_name: Encrypt(request.project_name)
      }
    }).then(response => {
      if (response.data.send_email_project_members.error == false) {
          //dispatch(notificationSuccess(response.data.send_email_project_members.message))
          dispatch(notificationSuccess(email_sent_successfully))
      } else {
          dispatch(notificationFail(response.data.send_email_project_members.error))
      }
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// get all members projects list success
const getAllMembersProjectListSuccess = (data) => {
  return {
      type: actionTypes.GET_ALL_MEMBERS_PROJECT_LIST,
      state: data
  }
}

// get all members projects list 
export const getAllMembersProjectList = (client, request) => {
  return dispatch => {
      const query = gql`
      {
          get_all_members_project_list {
            _id
            projectid
            companytokenid
            name
            projectname
            purpose
            address
            latitude
            longitude
            company
            companyname
            displayname
            startDate
            endDate
            status
            isclosed
            close_description
            projectOwner
            ownerid
            current_user
            current_user_companylicenceid
            current_user_licencename
            current_userid
        }
      }`;
      client.query({
          query: query,
          fetchPolicy: 'no-cache',
      })
      .then(response => {
          dispatch(getAllMembersProjectListSuccess(response.data.get_all_members_project_list))
      }).catch((error) => {
          dispatch(notificationFail(error))
      });
  }
}

// get Campaign Test Analysis Data List Success
const getCampaignTestAnalysisDataListSuccess = (data) => {
  return {
      type: actionTypes.GET_CAMPAIGN_TEST_ANALYSIS_DATA,
      state: data
  }
}
// get Campaign Test Analysis data list
export const getCampaignTestAnalysisDataList = (client, request) => {
  return dispatch => {
    const query = gql`
        query get_campaign_test_analysis_data_list(
          $campaignid: String!,
          $projectid: String!
          ) {
          get_campaign_test_analysis_data_list(
            campaignid: $campaignid,
            projectid: $projectid  
            ) {
             _id
          }
        }
        `;
    client.query({
      query: query,
      variables: {
        campaignid: Encrypt(request.campaignid),
        projectid: Encrypt(request.projectid)
      }
    }).then(response => {
      dispatch(getCampaignTestAnalysisDataListSuccess(response.data.get_campaign_test_analysis_data_list))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}


// get Campaign Test Analysis data list
export const getCampaignTestAnalysisDataListForAnalysisReport = (client, request) => {
  return dispatch => {
    const query = gql`
        query get_campaign_test_analysis_data_list(
          $campaignid: String!,
          $projectid: String!
          ) {
          get_campaign_test_analysis_data_list(
            campaignid: $campaignid,
            projectid: $projectid  
            ) {
              sheet_resp_data
          }
        }
        `;
    client.query({
      query: query,
      variables: {
        campaignid: Encrypt(request.campaignid),
        projectid: Encrypt(request.projectid)
      }
    }).then(response => {
      dispatch(getCampaignTestAnalysisDataListSuccess(response.data.get_campaign_test_analysis_data_list))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// get Campaign Test Analysis Data List Success
const getMultipleCampaignTestAnalysisDataListSuccess = (data) => {
  return {
      type: actionTypes.GET_MULTIPLE_CAMPAIGN_TEST_ANALYSIS_DATA,
      state: data
  }
}

// get Campaign Test Analysis data list
export const getMultipleCampaignTestAnalysisDataListForAnalysisReport = (client, request) => {
  return dispatch => {
    const query = gql`
        query get_test_analysis_data_list(
          $campaign_ids: String,
          $projectid: String!
          ) {
            get_test_analysis_data_list(
            campaign_ids: $campaign_ids,
            projectid: $projectid  
            ) {
              sheet_resp_data
              campaignid
          }
        }
        `;
    client.query({
      query: query,
      variables: {
        campaign_ids: Encrypt(request.campaign_ids),
        projectid: Encrypt(request.projectid)
      }
    }).then(response => {
      let obj = {
        campaign_names : request.campaign_names,
        data: response.data.get_test_analysis_data_list
      }
      dispatch(getMultipleCampaignTestAnalysisDataListSuccess(obj))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}



// deleteTestAnalysisData
export const deleteTestAnalysisData = (client, request) => {
  return dispatch => {
    const mutation = gql`
        mutation delete_test_analysis_data (
          $id: String!
          ) {
            delete_test_analysis_data (
              id: $id
              ) {
            message
            error
            success
          }
        }
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        id: Encrypt(request.id)
      }
    }).then(response => {
      if(response.data.delete_test_analysis_data.error === false){
        dispatch(actions.getCampaignTestAnalysisDataList(client, request))
        //dispatch(notificationSuccess(response.data.delete_test_analysis_data.message))
        dispatch(notificationSuccess(excelsheet_deleted_successfully))
      } else {
        dispatch(notificationFail(response.data.delete_test_analysis_data.message))  
      }
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}


// free User Licence
export const freeUserActiveLicence = (client, request, client2) => {
  return dispatch => {
    const mutation = gql`
        mutation user_licence_remove (
          $companylicenceid: String!,
          $userid: String!,
          $companyname: String!,
          ) {
            user_licence_remove (
              companylicenceid: $companylicenceid,
              userid: $userid,
              companyname: $companyname,
            ) {
              message
              error
              success
          }
        }
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        companylicenceid: Encrypt(request.companylicenceid),
        companyname: Encrypt(request.companyname),
        userid: Encrypt(request.userid),        
      }
    }).then(response => {

       //My code to refresh the userdata after licence assigned for sidebar menu access changes
       if(request.userselectedprojectid && request.useremail){
        let requestParams = {
            projectid: request.userselectedprojectid,
            email: request.useremail,
        }
        dispatch(actions.getAccess(client2, requestParams))
      }

      if(response.data.user_licence_remove.error === false){
        dispatch(notificationSuccess(licence_removed_successfully))
      } else {
        dispatch(notificationFail(response.data.user_licence_remove.message))  
      }

      //if(request.selected_page && request.selected_page == 'profile_page_members_tab_free_licence'){
          
          let requestParams = {
              userid: request.userid
          };
          dispatch(actions.getMemberDetails(client, requestParams))
      //}

      //For refreshing used/unused licence list
      dispatch(actions.getCompanylicence(client2, []))

      //For refreshing members tab licence details
      dispatch(actions.getMemberList(client2, []));
    
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}


// free User Licence
export const freeUserLicence = (client, request, client2) => {
  return dispatch => {
    const mutation = gql`
        mutation remove_user_project_license (
          $project_name: String!,
          $member_name: String!,
          $companylicenceid: String!,
          $companyname: String!,
          $projectid: String!,
          $userid: String!,
          ) {
            remove_user_project_license (
              project_name: $project_name,
              member_name: $member_name,
              companylicenceid: $companylicenceid,
              companyname: $companyname,
              projectid: $projectid,
              userid: $userid
            ) {
              message
              error
              success
          }
        }
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        project_name: Encrypt(request.project_name),
        member_name: Encrypt(request.member_name),
        companylicenceid: Encrypt(request.companylicenceid),
        companyname: Encrypt(request.companyname),
        projectid: Encrypt(request.projectid),
        userid: Encrypt(request.userid),
      }
    }).then(response => {

       //My code to refresh the userdata after licence assigned for sidebar menu access changes
       if(request.userselectedprojectid && request.useremail){
        let requestParams = {
            projectid: request.userselectedprojectid,
            email: request.useremail,
        }
        dispatch(actions.getAccess(client2, requestParams))
      }

      if(response.data.remove_user_project_license.error === false){
        dispatch(notificationSuccess(licence_removed_successfully))
      } else {
        dispatch(notificationFail(response.data.remove_user_project_license.message))  
      }

      if(request.selected_page && request.selected_page == 'profile_page_members_tab_free_licence'){
          let requestParams = {
              userid: request.userid
          };
          dispatch(actions.getMemberDetails(client, requestParams))
      }
      if(request.selected_page && request.selected_page == 'profile_page_user_projects_tab_free_licence'){
          dispatch(actions.getAllMembersProjectList(client, []))
        }
    
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// get available licence list success
const getAvailableLicenceListSuccess = (data) => {
  return {
      type: actionTypes.GET_AVAILABLE_LICENCE_LIST,
      state: data
  }
}

// get available licence list
export const getAvailableLicenceList = (client, request) => {
  return dispatch => {
      const query = gql`
      query get_available_licence_list {
        get_available_licence_list {
          _id
          licenceid
          licencename
          companylicenceid
          purchasedate
          expirydate
          price
          count
          used
          created_at
        }
      }`;
      client.query({
          query: query,
          variables: {},
          fetchPolicy: 'no-cache',
      })
      .then(response => {
          dispatch(getAvailableLicenceListSuccess(response.data.get_available_licence_list))
      }).catch((error) => {
          dispatch(notificationFail(error))
      });
  }
}


// get member project detail success
const getMemberProjectDetailSuccess = (data) => {
  return {
      type: actionTypes.GET_MEMBER_PROJECT_DETAILS,
      state: data
  }
}

// get member project detail
export const getMemberProjectDetail = (client, request) => {
  return dispatch => {
      const query = gql`
      query get_member_project_detail(
            $companyname: String!,
            $userid: String!
            $projectid: String!
          ) {
            get_member_project_detail(
              companyname: $companyname,
              userid: $userid
              projectid: $projectid  
            ) {
              _id
              projectid
              name
              userid
              firebaseid
              roleid
              rolename
              companyid
              companyname
              displayname
              licenceid
              licencename
              companylicenceid
              status
              inviteid
          }
      }
      `;
      client.query({
          query: query,
          variables: {
            companyname: Encrypt(request.companyname),
            userid: Encrypt(request.userid),
            projectid: Encrypt(request.projectid),
          },
          fetchPolicy: 'no-cache',
      })
      .then(response => {
          dispatch(getMemberProjectDetailSuccess(response.data.get_member_project_detail))
      }).catch((error) => {
          dispatch(notificationFail(error))
      });
  }
}

