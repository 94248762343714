import { useCallback, useEffect, useState } from 'react';
import * as turf from '@turf/turf';

/**
 * custom hook to calculate the center of a list of polygons using turf
 */

export function calculCenter(features) {
	const colFeat = turf.featureCollection(features);
	const center = turf.center(colFeat);
	return [center.geometry.coordinates[0], center.geometry.coordinates[1]];
}

export function calculBBox(features) {
	const colFeat = turf.featureCollection(features);
	//[minLng, minLat, maxLng, maxLat]
	const bbox = turf.bbox(colFeat);
	return bbox;
}

const useCalculCentre = (feature, defaut) => {
	const [centreMap, setCentreMap] = useState([2.2107982, 49.1072647]);
	const calc = useCallback(() => {
		return calculCenter(feature);
	}, [feature]);
	useEffect(() => {
		if (feature) {
			const centre = calc();
			setCentreMap(centre);
		}
	}, [calc, feature]);

	return centreMap || defaut;
};

export default useCalculCentre;
