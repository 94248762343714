import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_DOCUMENT_LIST:
            return updateObject(state, { documentList: action.state })
        case actionTypes.GET_SHARE_DOCUMENT_LIST:
            return updateObject(state, { shareDocumentList: action.state })
        case actionTypes.GET_USER_DOCUMENT_INFO:
            return updateObject(state, { documentInfo: action.state })
        case actionTypes.GET_COMP_DOCUMENT_LIST:
            return updateObject(state, { companyDocumentList: action.state })            
        default:
            return state;
    }
}

export default reducer
