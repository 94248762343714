import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../shared/utility';

const initialState = {
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_NEW_NOTIFICATION_SUCCESS:
            return updateObject(state, { message: action.state, success: true})
        case actionTypes.ADD_NEW_NOTIFICATION_FAIL:
            return updateObject(state, { message: action.state, success: false })
        case actionTypes.CLEAR_NOTIFICATION:
            return updateObject(state, { message: null, success: null, type: null })
        default:
            return state;
    }
}

export default reducer