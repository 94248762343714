import React, { useState } from 'react';
import { Button, ButtonToolbar, Input, Modal } from 'reactstrap';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
const tlang = ChangeAppLanguageFront.translateLanguage;
const close_project = tlang('close_project') || 'Close Project';
const client_areyousureclose_project =
	tlang('client_areyousureclose_project') || 'Are you sure you want to close this project';
const cancel_btn = tlang('cancel_btn') || 'Cancel';

export default function ModalCloseProject({ isOpen, onClose, projectName, onConfirm }) {
	const [reason, setReason] = useState('');
	return (
		<Modal
			isOpen={isOpen}
			toggle={() => onClose()}
			className={`modal-dialog--success modal-dialog--header`}
		>
			<div className="modal__header">
				<button
					className="lnr lnr-cross modal__close-btn"
					type="button"
					onClick={() => onClose()}
				/>
				<h4 className="bold-text  modal__title">{close_project}</h4>
			</div>
			<div className="modal__body">
				{client_areyousureclose_project + ' ( ' + projectName + ' ) ?'}
				<Input onChange={e => setReason(e.target.value)} type="text" value={reason} />
			</div>
			<ButtonToolbar className="modal__footer">
				<Button color={'success'} outline={true} onClick={() => onClose()}>
					{cancel_btn}
				</Button>
				<Button color={'success'} onClick={() => onConfirm(reason)}>
					{close_project}
				</Button>
			</ButtonToolbar>
		</Modal>
	);
}
