import React from 'react';
import * as actionTypes from "../actionTypes";
import Server from '../../../Common/Server';
import { gql } from "apollo-boost";
import { Encrypt } from "../../shared/encrpt";
import axios from "axios";
import * as actions from "../index";
import notificationMsg  from '../../../App/view/Layout/NotificationMessage';
import * as ChangeAppLanguageFront from '../../../App/view/Layout/ChangeAppLanguageFront';
let tlang  = ChangeAppLanguageFront.translateLanguage;

const fileupload = tlang('notificationSuccess_excelUpload_fileupload')?tlang('notificationSuccess_excelUpload_fileupload'):'File uploaded sucessfully';
const datasave = tlang('notificationSuccess_excelUpload_datasave')?tlang('notificationSuccess_excelUpload_datasave'):'Data saved Successfully';
const analysissave = tlang('notificationSuccess_excelUpload_analysissave')?tlang('notificationSuccess_excelUpload_analysissave'):'Analysis saved Successfully';
const settingsave = tlang('notificationSuccess_excelUpload_settingsave')?tlang('notificationSuccess_excelUpload_settingsave'):'Setting saved Successfully';
const exceptionsave = tlang('notificationSuccess_excelUpload_exceptionsave')?tlang('notificationSuccess_excelUpload_exceptionsave'):'Exception saved Successfully';
const settingdel = tlang('notificationSuccess_excelUpload_settingdel')?tlang('notificationSuccess_excelUpload_settingdel'):'Setting deleted Successfully';
const error_something_wrong = tlang('error_something_wrong') ? tlang('error_something_wrong') : 'Something Went Wrong!';

// notification Fail
const notificationFail = err => {
  let msg = error_something_wrong;
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    // msg = err.graphQLErrors[0].message;
    let translateMsg =notificationMsg(err.graphQLErrors[0].statusCode)
    msg = translateMsg;
  } else if (err.message) {
    msg = err.message;
  }
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_FAIL,
    state: msg
  };
};

// notification Sheet Fail
const notificationSheetFail = err => {
  let msg = error_something_wrong;
  if (err && err != "") {
   msg = err
  }
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_FAIL,
    state: msg
  };
};

// notification Success
const notificationSuccess = msg => {
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_SUCCESS,
    state: msg
  };
};

// upload Excel Success
const uploadExcelSuccess = data => {
  return {
    type: actionTypes.UPLOAD_EXCEL,
    state: data
  };
};

// save Excel Success
const saveExcelSuccess = data => {
  return {
    type: actionTypes.SAVE_EXCEL_DATA,
    state: data
  };
};

// get Excel Data Success
const getExcelDataSuccess = data => {
  return {
    type: actionTypes.GET_EXCEL_DATA,
    state: data
  };
};

// get Selected Excel Data Success
const getSelectedExcelDataSuccess = data => {
  return {
    type: actionTypes.GET_SELECTED_EXCEL_DATA,
    state: data
  };
};

// get Probings By Length Success
const getProbingsByLengthSuccess = data => {
  return {
    type: actionTypes.GET_PROBINGS_BY_LENGTH,
    state: data
  };
};

// get Excel Settings Success
const getExcelSettingsSuccess = data => {
  return {
    type: actionTypes.GET_EXCEL_SETTINGS,
    state: data
  };
};

// get Excel Settings Success
const getMatchedTestFamiliesSuccess = data => {
  return {
    type: actionTypes.GET_MATCHED_TESTFAMYLIES,
    state: data
  };
};

// get Analysis Success
const getAnalysisSuccess = data => {
  return {
    type: actionTypes.GET_ANALYSIS,
    state: data
  };
};

// save Analysis Success
const saveAnalysisSuccess = data => {
  return {
    type: actionTypes.SAVE_ANALYSIS,
    state: data
  };
};

// upload excel file
export const uploadExcelFile = (client, request) => {
  var formData = new FormData();
  formData.append("files", request.file);
  formData.append("sheet", request.data.sheet);
  formData.append("row", request.data.row);
  formData.append("rownumber", request.data.rownumber);
  formData.append("rowprobing", request.data.rowprobing);
  formData.append("rowprobingnumber", request.data.rowprobingnumber);
  formData.append("startinglength", request.data.startinglength);
  formData.append("endinglength", request.data.endinglength);
  formData.append("check", request.check);
  formData.append("companyid", request.companyid);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return (dispatch) => {
    axios
      .post(Server.ImageAPI + "upload_test_sheet", formData, config)
      .then((result) => {
        if (result.data.success === true) {
          dispatch(uploadExcelSuccess(result.data));
          dispatch(notificationSuccess(fileupload));
        } else {
          dispatch(notificationSheetFail(result.data.message));
        }
      })
      .catch((error) => {
        dispatch(notificationSheetFail(error_something_wrong));
      });
  };
};

// save excel data
export const saveExcelData = (probing, request) => {
  return dispatch => {
    const mutation = gql`
      mutation create_test_analysis_data(
        $campaignid: String!
        $start_length: String!
        $end_length: String!
        $probing_data: String!
        $file_name: String!
        $sheet_name: String!
        $rows_for_header: String!
        $row_for_header_no: String!
        $rows_for_head_probing: String!
        $row_for_head_probing_no: String!
        $sheet_resp_header: String!
        $sheet_resp_data: String!
        $projectid: String!
        $confirmStatus: Boolean!
        $extra_information: String!
        $id: String!
        $manual_test_tags: String!
      ) {
        create_test_analysis_data(
          campaignid: $campaignid
          start_length: $start_length
          end_length: $end_length
          probing_data: $probing_data
          file_name: $file_name
          sheet_name: $sheet_name
          rows_for_header: $rows_for_header
          row_for_header_no: $row_for_header_no
          rows_for_head_probing: $rows_for_head_probing
          row_for_head_probing_no: $row_for_head_probing_no
          sheet_resp_header: $sheet_resp_header
          sheet_resp_data: $sheet_resp_data
          projectid:$projectid
          confirmStatus:$confirmStatus
          extra_information:$extra_information
          id: $id
          manual_test_tags: $manual_test_tags
        ) {
          status
          resp_data
          error
          matched_test_arr
          cnt_matched_test
          cnt_notmatched_test
        }
      }
    `;
    probing.mutate({
      mutation: mutation,
      variables: {
        campaignid: Encrypt(request.campaignid),
        start_length: Encrypt(request.start_length),
        end_length: Encrypt(request.end_length),
        probing_data: Encrypt(request.probing_data),
        file_name: Encrypt(request.file_name),
        sheet_name: Encrypt(request.sheet_name),
        rows_for_header: Encrypt(request.rows_for_header),
        row_for_header_no: Encrypt(request.row_for_header_no),
        rows_for_head_probing: Encrypt(request.rows_for_head_probing),
        row_for_head_probing_no: Encrypt(request.row_for_head_probing_no),
        sheet_resp_header: Encrypt(request.sheet_resp_header),
        sheet_resp_data: Encrypt(request.sheet_resp_data),
        projectid: Encrypt(request.projectid),
        confirmStatus:request.confirmStatus,
        extra_information: Encrypt(request.extra_information),
        id: Encrypt(request.id),
        manual_test_tags: Encrypt(request.manual_test_tags),
      }
    })
      .then(response => {  
        if(response.data.create_test_analysis_data.status == "true"){
          dispatch(notificationSuccess(datasave));
        }
        dispatch(saveExcelSuccess(response.data.create_test_analysis_data));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// get Excel Data List
export const getExcelDataList = (probing, request) => {

  return dispatch => {
    const query = gql`
      query get_test_analysis_data_list($projectid: String!) {
        get_test_analysis_data_list(projectid: $projectid) { 
          _id
          campaignid
          start_length
          end_length
          probing_data
          file_name
          sheet_name
          rows_for_header
          row_for_header_no
          rows_for_head_probing
          row_for_head_probing_no
          sheet_resp_header
          sheet_resp_data
          projectid
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid)
        }
      })
      .then(response => {
        dispatch(getExcelDataSuccess(response.data.get_test_analysis_data_list));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  }; 
};


// get Excel Data List for Probing Analysis page
export const getExcelDataListForProbingAnalysis = (probing, request) => {

  return dispatch => {
    const query = gql`
      query get_test_analysis_data_list($projectid: String!) {
        get_test_analysis_data_list(projectid: $projectid) { 
          campaignid
          sheet_resp_data
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid)
        }
      })
      .then(response => {
        dispatch(getExcelDataSuccess(response.data.get_test_analysis_data_list));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  }; 
};



const getAllSampleDataofPollutantSuccess = data => {
  return {
    type: actionTypes.GET_SAMPLE_DATA_OF_POLLUTANT,
    state: data
  };
};


// get all sample data of particular pollutant
export const getAllSampleDataofPollutant = (probing, request) => {

  return dispatch => {
    const query = gql`
      query get_sample_pollutantwise_analysis_data($projectid: String!,$test_parent_id: String!,$test_child_id: String!) {
        get_sample_pollutantwise_analysis_data(projectid: $projectid,test_parent_id: $test_parent_id,test_child_id: $test_child_id) { 
          status
          resp_data
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid),
          test_parent_id: Encrypt(request.test_parent_id),
          test_child_id: Encrypt(request.test_child_id)
        }
      })
      .then(response => {
        dispatch(getAllSampleDataofPollutantSuccess(response.data.get_sample_pollutantwise_analysis_data));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  }; 
};


const getAllSampleDataofPollutantForLabelofDVSuccess = data => {
  return {
    type: actionTypes.GET_SAMPLE_DATA_OF_POLLUTANT_FOR_LABEL_OF_DV,
    state: data
  };
};

// get all sample data of particular pollutant for New Lable of DV
export const getAllSampleDataofPollutantForLabelofDV = (probing, request) => {

  return dispatch => {
    const query = gql`
      query get_sample_pollutantwise_analysis_data($projectid: String!,$test_parent_id: String!,$test_child_id: String!) {
        get_sample_pollutantwise_analysis_data(projectid: $projectid,test_parent_id: $test_parent_id,test_child_id: $test_child_id) { 
          status
          resp_data
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid),
          test_parent_id: Encrypt(request.test_parent_id),
          test_child_id: Encrypt(request.test_child_id)
        }
      })
      .then(response => {
        dispatch(getAllSampleDataofPollutantForLabelofDVSuccess(response.data.get_sample_pollutantwise_analysis_data));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  }; 
};




// get Excel Data List
export const getSelectedExcelData = (probing, request) => {

  return dispatch => {
    const query = gql`
      query get_test_analysis_data_detail($id: String!,$projectid: String!) {
        get_test_analysis_data_detail(id: $id, projectid: $projectid) { 
          _id
          campaignid
          campaign_name
          start_length
          end_length
          probing_data
          file_name
          sheet_name
          rows_for_header
          row_for_header_no
          rows_for_head_probing
          row_for_head_probing_no
          sheet_resp_header
          sheet_resp_data
          extra_information
          matched_count
          unmatched_count
          projectid
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          id: Encrypt(request.id),
          projectid: Encrypt(request.projectid)
        }
      })
      .then(response => {
        dispatch(getSelectedExcelDataSuccess(response.data.get_test_analysis_data_detail));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  }; 
};

// get Campaigns for map
export const getProbingsByLength = (campaign, request) => {
  return dispatch => {
    const query = gql`
      query get_probingby_length($campaignid: String!, $start_length: String!, $end_length: String!) {
        get_probingby_length(campaignid: $campaignid, start_length: $start_length, end_length: $end_length) { 
          data
          status
        }
      }
    `;
    campaign
      .query({
        query: query,
        variables: {
          campaignid: Encrypt(request.campaignid),
          start_length: Encrypt(request.start_length),
          end_length: Encrypt(request.end_length)
        }
      })
      .then(response => {
        dispatch(getProbingsByLengthSuccess(response.data.get_probingby_length.data));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// save Analysis
export const saveAnalysis = (probing, request) => {
  return dispatch => {
    const mutation = gql`
      mutation create_test_analysis_result_data(
        $campaignid: String!
        $test_data_id: String!
        $desposal_list: String!
        $result_name: String!
        $projectid: String!
        $confirmStatus: Boolean!
      ) {
        create_test_analysis_result_data(
          campaignid: $campaignid
          test_data_id: $test_data_id
          desposal_list: $desposal_list
          result_name: $result_name
          projectid: $projectid
          confirmStatus: $confirmStatus
        ) {
          status
          resp_data
          error
        }
      }
    `;
    probing.mutate({
      mutation: mutation,
      variables: {
        campaignid: Encrypt(request.campaignid),
        test_data_id: Encrypt(request.test_data_id),
        desposal_list: Encrypt(request.desposal_list),
        result_name: Encrypt(request.result_name),
        projectid: Encrypt(request.projectid),
        confirmStatus:request.confirmStatus
      }
    })
      .then(response => {
        // if(response.data.create_test_analysis_result_data.status == "true"){
          dispatch(notificationSuccess(analysissave));
        // }
        // dispatch(saveAnalysisSuccess(response.data.create_test_analysis_data));        
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// get Analysis
export const getAnalysis = (probing, request) => {
  return dispatch => {
    const query = gql`
      query get_test_analysis_result_data_list($projectid: String!, $test_data_id: String!) {
        get_test_analysis_result_data_list(projectid: $projectid, test_data_id: $test_data_id) { 
          _id
          projectid
          campaignid
          test_data_id
          desposal_list
          result_name
          file_name
          result_data
          created_at
          updated_at
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid),
          test_data_id: Encrypt(request.test_data_id)
        }
      })
      .then(response => {
        dispatch(getAnalysisSuccess(response.data.get_test_analysis_result_data_list));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// list Excel Setting
export const getExcelSettingsList = (master, request) => {
  return dispatch => {
    const query = gql`
      query get_test_sheet_settings_list($company_name: String!) {
        get_test_sheet_settings_list(company_name: $company_name) { 
          _id
          sheet_name
          rows_for_header
          row_for_header_no
          rows_for_head_probing
          row_for_head_probing_no
          created_at
          updated_at
        }
      }
    `;
    master
      .query({
        query: query,
        variables: {
          company_name: Encrypt(request.company_name)
        }
      })
      .then(response => {
        dispatch(getExcelSettingsSuccess(response.data.get_test_sheet_settings_list));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};


// get Exception Settings Success
const getExceptionSettingsSuccess = data => {
  return {
    type: actionTypes.GET_EXCEPTION_SETTINGS,
    state: data
  };
};

// list Excel Setting
export const getExceptionSetting = (master, request) => {
  return dispatch => {
    const query = gql`
      query get_test_sheet_exception($company_id: String!) {
        get_test_sheet_exception(company_id: $company_id) { 
          _id
          company_id
          exception_strings
          created_at
          updated_at
        }
      }
    `;
    master
      .query({
        query: query,
        variables: {
          company_id: Encrypt(request.company_id)
        }
      })
      .then(response => {
        dispatch(getExceptionSettingsSuccess(response.data.get_test_sheet_exception));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};



// list Excel Setting
export const getMatchedTestFamilies = (master, request) => {
  return dispatch => {
    const query = gql`
      query match_test_families($test_arr: String!) {
        match_test_families(test_arr: $test_arr) { 
          success
          matched_test
          unmatched_test
          duplicate_test
        }
      }
    `;
    master
      .query({
        query: query,
        variables: {
          test_arr: Encrypt(request.test_arr)
        }
      })
      .then(response => {
        dispatch(getMatchedTestFamiliesSuccess(response.data.match_test_families));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};


// save Excel Setting
export const saveExceptionSetting = (master, request) => {
  return dispatch => {
    const mutation = gql`
      mutation set_test_sheet_exception(        
        $company_id: String!
        $exception_strings:String!
      ) {
        set_test_sheet_exception(          
          company_id:$company_id
          exception_strings:$exception_strings
        ) {
          _id
          company_id
          exception_strings
          created_at
          updated_at
        }
      }
    `;
    master.mutate({
      mutation: mutation,
      variables: {
        company_id: Encrypt(request.company_id),
        exception_strings: Encrypt(request.exception_strings),
      }
    })
      .then(response => {
        dispatch(notificationSuccess(exceptionsave));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};




// save Excel Setting
export const saveExcelSetting = (master, request) => {
  return dispatch => {
    const mutation = gql`
      mutation add_test_sheet_settings(        
        $company_name: String!
        $id:String!
        $sheet_name: String!
        $rows_for_header: String!
        $row_for_header_no: String!
        $rows_for_head_probing: String!
        $row_for_head_probing_no: String!
      ) {
        add_test_sheet_settings(          
          company_name:$company_name
          id:$id
          sheet_name: $sheet_name
          rows_for_header: $rows_for_header
          row_for_header_no: $row_for_header_no
          rows_for_head_probing: $rows_for_head_probing
          row_for_head_probing_no:$row_for_head_probing_no
        ) {
          _id
          sheet_name
          rows_for_header
          row_for_header_no
          rows_for_head_probing
          row_for_head_probing_no
          created_at
          updated_at
        }
      }
    `;
    master.mutate({
      mutation: mutation,
      variables: {
        company_name: Encrypt(request.company_name),
        id: Encrypt(request.id),
        sheet_name: Encrypt(request.sheet_name),
        rows_for_header: Encrypt(request.rows_for_header),
        row_for_header_no: Encrypt(request.row_for_header_no),
        rows_for_head_probing: Encrypt(request.rows_for_head_probing),
        row_for_head_probing_no: Encrypt(request.row_for_head_probing_no)
      }
    })
      .then(response => {
        let settingRequest = {
          company_name: request.company_name
        }
        dispatch(actions.getExcelSettingsList(master, settingRequest))
        dispatch(notificationSuccess(settingsave));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// delete Excel setting
export const deleteExcelSetting = (master, request) => {
  return dispatch => {
    const mutation = gql`
      mutation delete_test_sheet_setting($id: String!, $company_name: String!) {
        delete_test_sheet_setting(id: $id, company_name:$company_name) {
          message  
          error
          success
        }
      }
      `;
      master.mutate({
        mutation: mutation,
        variables: {
          id: Encrypt(request.id),
          company_name: Encrypt(request.company_name)
        }
      })
      .then(response => {
        let settingRequest = {
          company_name: request.company_name
        }
        dispatch(actions.getExcelSettingsList(master, settingRequest))
        dispatch(notificationSuccess(settingdel)); 
      }).catch((error) => {
          dispatch(notificationFail(error))
      });
  }
}

// get Probing Report Success
const getProbingReportSuccess = data => {
  return {
    type: actionTypes.GET_PROBING_REPORT,
    state: data
  };
};

// get Filtred Analysis Report
export const getProbingReport = (probing, request) => {
  return dispatch => {
    const query = gql`
      query get_test_probing_report(
          $id: String!
          $lang: String!
          $test_family_id: String!
          $start_length: String!
          $end_length: String!
        ) {
        get_test_probing_report(
          id: $id 
          lang: $lang
          test_family_id: $test_family_id
          start_length: $start_length
          end_length: $end_length
          ) { 
          status
          resp_data
          probing_data
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          id: Encrypt(request.id),
          lang: Encrypt(request.lang),
          test_family_id: Encrypt(request.test_family_id),
          start_length: Encrypt(request.start_length),
          end_length: Encrypt(request.end_length)
        }
      })
      .then(response => {
        dispatch(getProbingReportSuccess(response.data.get_test_probing_report));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// generate alaysis Report Success
const generateAnlysisReportSuccess = data => {
  return {
    type: actionTypes.GENERATE_DIRECT_ANALYSIS_REPORT,
    state: data
  };
};

// generate Probing Report Success
const generateProbingReportSuccess = data => {
  return {
    type: actionTypes.GENERATE_DIRECT_PROBING_REPORT,
    state: data
  };
};

// get derect probing report
export const getDirectProbingReport = (probing, request) => {
  return dispatch => {
    const query = gql`
      query direct_generate_probing_report(
          $campaignid: String!, 
          $test_child_name: String!
        ) {
          direct_generate_probing_report(
            campaignid: $campaignid, 
            test_child_name: $test_child_name
          ) { 
            status
            error
            resp_data
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          campaignid: Encrypt(request.campaignid),
          test_child_name: Encrypt(request.test_child_name),
        }
      })
      .then(response => {
        dispatch(generateProbingReportSuccess(response.data.direct_generate_probing_report))
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

export const getDirectAnalysisReport  = (probing, request) => {
  return dispatch => {
    const query = gql`
      query direct_generate_test_analysis_result(
          $projectid: String!, 
          $campaignid: String, 
          $desposal_list: String!,
          $start_len: Int,
          $end_len: Int
        ) {
          direct_generate_test_analysis_result(
            projectid: $projectid, 
            campaignid: $campaignid, 
            desposal_list: $desposal_list,
            start_len: $start_len,
            end_len: $end_len
          ) { 
            status
            error
            resp_data
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid),
          campaignid: Encrypt(request.campaignid),
          desposal_list: Encrypt(request.desposal_list),
          start_len: request.start_len,
          end_len: request.end_len
        }
      })
      .then(response => {
        dispatch(generateAnlysisReportSuccess(response.data.direct_generate_test_analysis_result))
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};