import Cryptr from 'cryptr';

export const Encrypt = (value) => {
    const cryptr = new Cryptr('AexdoEnc');
    let encrypted = cryptr.encrypt(value);
    return encrypted;
}

export const Decrypt = (value) => {
    const cryptr = new Cryptr('AexdoEnc');
    let decrypted = cryptr.decrypt(value);
    return decrypted;
}

