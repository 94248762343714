import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {

};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPLOAD_EXCEL:
            return updateObject(state, { datalist: action.state })
        case actionTypes.SAVE_EXCEL_DATA:
            return updateObject(state, { excelDataSaved: action.state })
        case actionTypes.GET_EXCEL_DATA:
            return updateObject(state, { excelDataList: action.state })
        case actionTypes.GET_SAMPLE_DATA_OF_POLLUTANT:
            return updateObject(state, { sampledatabypollutant: action.state })
        case actionTypes.GET_SAMPLE_DATA_OF_POLLUTANT_FOR_LABEL_OF_DV:
            return updateObject(state, { sampledatabypollutantforlabel: action.state })    
        case actionTypes.GET_SELECTED_EXCEL_DATA:
            return updateObject(state, { selectedExcelDataList: action.state })
        case actionTypes.GET_PROBINGS_BY_LENGTH:
            return updateObject(state, { probing_data: action.state })
        case actionTypes.GET_EXCEL_SETTINGS:
            return updateObject(state, { settingsList: action.state })
        case actionTypes.GET_EXCEPTION_SETTINGS:
            return updateObject(state, { exceptionSettingsList: action.state })
        case actionTypes.GET_MATCHED_TESTFAMYLIES:
            return updateObject(state, { matchUnmatchDuplicateFamylies: action.state })
        case actionTypes.GET_ANALYSIS:
            return updateObject(state, { analysisList: action.state })
        case actionTypes.GET_PROBING_REPORT:
            return updateObject(state, { probingReport: action.state })
        case actionTypes.GENERATE_DIRECT_ANALYSIS_REPORT:
            return updateObject(state, { analysisReport: action.state })
        case actionTypes.GENERATE_DIRECT_PROBING_REPORT:
            return updateObject(state, { directProbingReport: action.state })
        // case actionTypes.SAVE_ANALYSIS:
        //     return updateObject(state, { analysisResponse: action.state })
        default:
            return state;
    }
}

export default reducer