import React from 'react';
import * as actionTypes from "../actionTypes";
import { gql } from "apollo-boost";
import { Encrypt } from "../../shared/encrpt";
import * as types from "../types";
import fingerprint from "fingerprintjs";
import jwt from "jsonwebtoken";
import axios from "axios";
import Server from "../../../Common/Server";
import * as actions from "../index";
import moment from "moment";
import notificationMsg  from '../../../App/view/Layout/NotificationMessage';
import * as ChangeAppLanguageFront from "../../../App/view/Layout/ChangeAppLanguageFront";
let tlang = ChangeAppLanguageFront.translateLanguage;

const templateupdate = tlang('notificationSuccess_contentmgmt_templateupdate') ? tlang('notificationSuccess_contentmgmt_templateupdate') : 'Template Updated Successfully';
const emailsetting = tlang('notificationSuccess_contentmgmt_emailsetting') ? tlang('notificationSuccess_contentmgmt_emailsetting') : 'Email Settings Updated Successfully';
const notisettingupdate = tlang('notificationSuccess_contentmgmt_notisettingupdate') ? tlang('notificationSuccess_contentmgmt_notisettingupdate') : 'Notification Settings Updated Successfully';
const noticlear = tlang('notificationSuccess_contentmgmt_noticlear') ? tlang('notificationSuccess_contentmgmt_noticlear') : 'Notification cleared successfully';
const notisent = tlang('notificationSuccess_contentmgmt_notisent') ? tlang('notificationSuccess_contentmgmt_notisent') : 'Notification sent successfully';
const notiupdate = tlang('notificationSuccess_contentmgmt_notiupdate') ? tlang('notificationSuccess_contentmgmt_notiupdate') : 'Notification Updated Successfully';
const error_something_wrong = tlang('error_something_wrong') ? tlang('error_something_wrong') : 'Something Went Wrong!';
// notification Success
const notificationSuccess = (msg) => {
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_SUCCESS,
    state: msg,
  };
};

// notification Fail
const notificationFail = (err) => {
  let msg = error_something_wrong;
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    // msg = err.graphQLErrors[0].message;
    let translateMsg =notificationMsg(err.graphQLErrors[0].statusCode)
    msg = translateMsg;
  } else if (err.message) {
    msg = err.message;
  }
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_FAIL,
    state: msg,
  };
};

// get Email Template List Success
const getEmailTemplateListSuccess = (data) => {
  return {
    type: actionTypes.GET_EMAIL_TEMPLATE_LIST,
    state: data,
  };
};

// get email template list
export const getEmailTemplateList = (client, request) => {
  return (dispatch) => {
    const query = gql`
      {
        get_email_template_list {
          _id
          name
          key
          subject
          description
        }
      }
    `;
    client
      .query({
        query: query,
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        dispatch(
          getEmailTemplateListSuccess(response.data.get_email_template_list)
        );
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// get Email Template Detail Success
const getEmailTemplateDetailSuccess = (data) => {
  return {
    type: actionTypes.GET_EMAIL_TEMPLATE_DETAILS,
    state: data,
  };
};

// get email template details
export const getEmailTemplateDetail = (client, request) => {
  return (dispatch) => {
    const query = gql`
      query get_email_template_detail($key: String!) {
        get_email_template_detail(key: $key) {
          _id
          name
          key
          subject
          description
          lang_code
        }
      }
    `;
    client
      .query({
        query: query,
        variables: {
          key: Encrypt(request.key),
        },
      })
      .then((response) => {
        // dispatch(actions.getEmailTemplateList(client,[]));
        dispatch(
          getEmailTemplateDetailSuccess(response.data.get_email_template_detail)
        );
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// save email template detail
export const saveTemplateDetail = (client, request) => {
  return (dispatch) => {
    const mutation = gql`
      mutation save_template_detail(
        $templateid: String!
        $key: String!
        $name: String!
        $subject: String!
        $description: String!
        $lang_code: String!
      ) {
        save_template_detail(
          templateid: $templateid
          key: $key
          name: $name
          subject: $subject
          description: $description
          lang_code: $lang_code
        ) {
          message
          error
          success
        }
      }
    `;
    client
      .mutate({
        mutation: mutation,
        variables: {
          templateid: Encrypt(request.templateid),
          key: Encrypt(request.key),
          name: Encrypt(request.name),
          subject: Encrypt(request.subject),
          description: Encrypt(request.description),
          lang_code: Encrypt(request.lang_code),
        },
      })
      .then((response) => {
        dispatch(notificationSuccess(templateupdate));
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// Save Email Settings
export const SaveEmailSettings = (client, request) => {
  return (dispatch) => {
    const mutation = gql`
      mutation save_email_settings(
        $userid: String!
         $key_data: String!
         ) {
        save_email_settings(
           userid: $userid
          key_data: $key_data
        ) {
          message
          error
          success
        }
      }
    `;
    client
      .mutate({
        mutation: mutation,
        variables: {
          userid: Encrypt(request.userid),
          key_data: Encrypt(request.key_data),
        },
      })
      .then((response) => {
        dispatch(
          notificationSuccess(emailsetting)
        );
        dispatch(actions.getEmailSettingsList(client, request))
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// Save Notification Settings 
export const SaveNotificationSettings = (client, request) => {
  return (dispatch) => {
    const mutation = gql`
    mutation save_notification_settings(
      $userid: String!
      $key_data: String!
    ) {
      save_notification_settings(
        userid: $userid
        key_data: $key_data
      ) {
        message
        error
        success
      }
    }
  `;
    client
      .mutate({
        mutation: mutation,
        variables: {
          userid: Encrypt(request.userid),
          key_data: Encrypt(request.key_data),
        },
      })
      .then((response) => {
        if (response.data.save_notification_settings.success == "true") {
          dispatch(
            notificationSuccess(notisettingupdate)
          );
        } else {
          dispatch(notificationFail(response.data.save_notification_settings.error));
        }
        dispatch(actions.getNotificationSettingsList(client, request))
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// get User Notification List Success
const getUserNotificationListSuccess = (data) => {
  return {
    type: actionTypes.GET_USER_NOTIFICATION_LIST,
    state: data,
  };
};

// get Notification Settings List Success
const getNotificationSettingsListSuccess = (data) => {
  return {
    type: actionTypes.GET_NOTIFICATION_SETTINGS_LIST,
    state: data,
  };
};

// get email template details
export const getNotificationSettingsList = (client, request) => {
  return (dispatch) => {
    const query = gql`
      query get_notification_settings_list($userid: String!) {
        get_notification_settings_list(userid: $userid) {
          _id
          userid
          key
          name
          isdisable
          created_at
          updated_at
          }
      }
    `;
    client
      .query({
        query: query,
        variables: {
          userid: Encrypt(request.userid),
        },
      })
      .then((response) => {
        dispatch(
          getNotificationSettingsListSuccess(
            response.data.get_notification_settings_list
          )
        );
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// get User Notification List
export const getUserNotificationList = (client, request) => {
  return (dispatch) => {
    const query = gql`
      query get_user_notification_list($userid: String!,$skip: Int) {
        get_user_notification_list(userid: $userid,skip: $skip) {
          _id
          userid
          notifications
          created_at
          notification_template{
            key
            name
            description
            lang_desc
          }
        }
      }
    `;
    client
      .query({
        query: query,
        variables: {
          userid: Encrypt(request.userid),
          skip: request.skip,
        },
      })
      .then((response) => {
        // dispatch(actions.getEmailTemplateList(client,[]));
        dispatch(
          getUserNotificationListSuccess(
            response.data.get_user_notification_list
          )
        );
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// get User Notification Unread Count Success
const getUserNotificationUnreadCountSuccess = (data) => {
  return {
    type: actionTypes.GET_USER_NOTIFICATION_UNREAD_COUNT,
    state: data,
  };
};

// get User Notification Unread Count
export const getUserNotificationUnreadCount = (client, request) => {
  return (dispatch) => {
    const query = gql`
      query get_user_notification_unread_count($userid: String!) {
        get_user_notification_unread_count(userid: $userid) {
          userid
          unread_count
        }
      }
    `;
    client
      .query({
        query: query,
        variables: {
          userid: Encrypt(request.userid)
        },
      })
      .then((response) => {
        dispatch(
          getUserNotificationUnreadCountSuccess(
            response.data.get_user_notification_unread_count
          )
        );
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// getEmailSettingsListSuccess
const getEmailSettingsListSuccess = (data) => {
  return {
    type: actionTypes.GET_EMAIL_SETTINGS_LIST,
    state: data,
  };
};

// get email template details
export const getEmailSettingsList = (client, request) => {
  return (dispatch) => {
    const query = gql`
      query get_email_settings_list($userid: String!) {
        get_email_settings_list(userid: $userid) {
          _id
          userid
          key
          name
          isdisable
          created_at
          updated_at  
        }
      }
    `;
    client
      .query({
        query: query,
        variables: {
          userid: Encrypt(request.userid),
        },
      })
      .then((response) => {
        dispatch(
          getEmailSettingsListSuccess(
            response.data.get_email_settings_list
          )
        );
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// mark All As Read Notification
export const notificationMarkAllAsRead = (master, client, request) => {
  return (dispatch) => {
    const mutation = gql`
      mutation notification_mark_all_as_read(
        $userid: String! 
        $chatid: String!
        ){
        notification_mark_all_as_read(
          userid: $userid
          chatid: $chatid
        ){
          message
          error
          success
        }
      }
    `;
    master
      .mutate({
        mutation: mutation,
        variables: {
          userid: Encrypt(request.userid),
          chatid: Encrypt(request.chatid)
        },
      })
      .then((response) => {
        // dispatch(actions.getUserNotificationList(client, request));
        dispatch(actions.getUserNotificationUnreadCount(client, request));
        // dispatch(notificationSuccess(noticlear));
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// get User List Success
const getUserListSuccess = (data) => {
  return {
    type: actionTypes.GET_USERS_LIST_TO_SEND_NOTIFICATION,
    state: data,
  };
};

// get Users List To Send Notification
export const getUsersListToSendNotification = (client, request) => {
  return (dispatch) => {
    const query = gql`
      {
        get_all_users {
          _id
          name
          email
          userid
          password
          company_name
          display_name
          account_status
          confirm_account
          created_at
        }
      }
    `;
    client
      .query({
        query: query,
        fetchPolicy: "no-cache",
        variables: {},
      })
      .then((response) => {
        dispatch(getUserListSuccess(response.data.get_all_users));
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// send Notification To Selected Users
export const sendNotificationToSelectedUsers = (master, request) => {
  return (dispatch) => {
    const mutation = gql`
      mutation send_notification_users(
        $notification_msg: String!
        $user_list: String!
      ) {
        send_notification_users(
          notification_msg: $notification_msg
          user_list: $user_list
        ) {
          message
          error
          success
        }
      }
    `;
    master
      .mutate({
        mutation: mutation,
        variables: {
          notification_msg: Encrypt(request.notification_msg),
          user_list: Encrypt(request.user_list),
        },
      })
      .then((response) => {
        dispatch(notificationSuccess(notisent));
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// get Notification Template List Success
const getNotificationTemplateListSuccess = (data) => {
  return {
    type: actionTypes.GET_NOTIFICATION_TEMPLATE_LIST,
    state: data,
  };
};

// get notification template list
export const getNotificationTemplateList = (client, request) => {
  return (dispatch) => {
    const query = gql`
      {
        get_notification_template_list {
          _id
          key
          name          
          description
          created_at
          updated_at
        }
      }
    `;
    client
      .query({
        query: query,
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        dispatch(
          getNotificationTemplateListSuccess(response.data.get_notification_template_list)
        );
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// get Notification Template Detail Success
const getNotificationTemplateDetailSuccess = (data) => {
  return {
    type: actionTypes.GET_NOTIFICATION_TEMPLATE_DETAILS,
    state: data,
  };
};

// get notification template details
export const getNotificationTemplateDetail = (client, request) => {
  return (dispatch) => {
    const query = gql`
      query get_notification_template_detail($key: String!) {
        get_notification_template_detail(key: $key) {
          _id
          key
          name
          description
          created_at
          updated_at
          lang_desc
        }
      }
    `;
    client
      .query({
        query: query,
        variables: {
          key: Encrypt(request.key),
        },
      })
      .then((response) => {
        dispatch(
          getNotificationTemplateDetailSuccess(
            response.data.get_notification_template_detail
          )
        );
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// save notification template detail
export const saveNotificationTemplateDetail = (client, request) => {
  return (dispatch) => {
    const mutation = gql`
      mutation save_notification_template(
        $templateid: String!
        $name: String!
        $key: String!
        $description: String!
        $lang_desc: String!
      ) {
        save_notification_template(
          templateid: $templateid
          name: $name
          key: $key
          description: $description
          lang_desc: $lang_desc
        ) {
          message
          error
          success
        }
      }
    `;
    client
      .mutate({
        mutation: mutation,
        variables: {
          templateid: Encrypt(request.templateid),
          name: Encrypt(request.name),
          key: Encrypt(request.key),
          description: Encrypt(request.description),
          lang_desc: Encrypt(request.lang_desc),
        },
      })
      .then((response) => {
        dispatch(notificationSuccess(notiupdate));
      })
      .catch((error) => {
        dispatch(notificationFail(error));
      });
  };
};

// get Send Notification Filter Success
const getSendNotificationFilterSuccess = (data) => {
  return {
    type: actionTypes.GET_SEND_NOTIFICATION_FILTERS,
    state: data,
  };
};

// get SendNotification Filters
export const getSendNotificationFilters = (client, request) => {
  return dispatch => {
    const query = gql`
        query send_notification_filters($filter_type: String!) {
          send_notification_filters(filter_type: $filter_type) {
            resp_data
            success
            filter_type
            }
        }            
        `;
    client.query({
      query: query,
      variables: {
        filter_type: Encrypt(request.filter_type)
      }
    })
      .then(response => {
        dispatch(getSendNotificationFilterSuccess(response.data.send_notification_filters))
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
};

// get Send Notification Filter Wise User Success
const getSendNotificationFilterWiseUserSuccess = (data) => {
  return {
    type: actionTypes.GET_NOTIFICATION_FILTERS_WISE_USER,
    state: data,
  };
};

// get Notification Filters Wise Users
export const getNotificationFiltersWiseUsers = (client, request) => {
  return dispatch => {
    const query = gql`
        query get_notification_filters_wise_users(
          $filter_type: String!
          $company_name: String
          $projectid: String
          $roleid: String
          ) {
          get_notification_filters_wise_users(
            filter_type: $filter_type
            company_name: $company_name
            projectid: $projectid
            roleid: $roleid
            ) {
            resp_data
            success
            error
            filter_type
            }
        }            
        `;
    client.query({
      query: query,
      variables: {
        filter_type: Encrypt(request.filter_type),
        company_name: Encrypt(request.company_name),
        projectid: Encrypt(request.projectid),
        roleid: Encrypt(request.roleid)
      }
    })
      .then(response => {
        dispatch(getSendNotificationFilterWiseUserSuccess(response.data.get_notification_filters_wise_users))
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
};
