import { Icon, Tooltip } from '@material-ui/core';
import orderBy from 'lodash/orderBy';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'reactstrap';
import __ from 'underscore';
import * as actions from '../../../../Store/actions/index';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import {supremeRole} from '../../Common/commonFunctions';
import randomString from '../../../lib/randomString';
import ModalDeleteConfirmation from '../components/dataVisualization/modals/deleteConfirmation';
import ModalProjectDetails from '../Project/projectDetails';
import ModalCloseProject from './closeProject';
import ModalEditProject from './editProject';

let tlang = ChangeAppLanguageFront.translateLanguage;

const TERMINATE = 'Terminate';
const PENDING = 'Pending';

const project_sr_no = tlang('project_sr_no') || 'Sr.No';
const project_project_name = tlang('project_project_name') || 'Project Name';
const project_purpose = tlang('project_purpose') || 'Project Purpose';
const project_project_promoter = tlang('project_project_promoter') || 'Promoter';
const client_viewprobing_status = tlang('client_viewprobing_status') || 'Status';
const project_actions = tlang('project_actions') || 'Actions';
const common_project_list = tlang('common_project_list') || 'Project List';
const tooltip_view = tlang('tooltip_view') || 'View';
const tooltip_edit = tlang('tooltip_edit') || 'Edit';
const tooltip_delete = tlang('tooltip_delete') || 'Delete';
const open_project = tlang('open_project') || 'Open Project';
const sort_text = tlang('sort_text') || 'Sort';
const rows_per_page_text = tlang('rows_per_page_text') || 'Rows per page:';
const no_match_record_found = tlang('no_match_record_found') || 'Sorry, no matching records found';
const search_text = tlang('search_text') ? tlang('search_text') : 'Search';
const client_token_type = tlang('client_token_type') || 'Token type';
const client_project_delete_project = tlang('client_project_delete_project') || 'Delete Project';
const client_delete_project_message =
	tlang('client_delete_project_message') || 'Are you sure you want to delete this Project';
const need_help_of_support_team =
	tlang('need_help_of_support_team') || 'Need help ? invite support team';
const client_terminate_project_support =
	tlang('client_terminate_project_support') || 'Terminate Project Support';
const client_terminate_project_support_message =
	tlang('client_terminate_project_support_message') ||
	'Are you sure you want to terminate support of this Project';
const client_project_get_project_support =
	tlang('client_project_get_project_support') || 'Get Project Support';
const client_get_project_support_message =
	tlang('client_get_project_support_message') ||
	'Are you sure you want to get support of this Project';

const options = {
	filterType: 'dropdown',
	selectableRows: 'none',
	print: false,
	download: false,
	viewColumns: false,
	filter: true,
	responsive: 'stacked',
	textLabels: {
		body: {
			noMatch: no_match_record_found,
			toolTip: sort_text,
		},
		pagination: {
			rowsPerPage: rows_per_page_text,
		},
		toolbar: {
			search: search_text,
		},
	},
};

export default function ProjectsList() {
	const dispatch = useDispatch();
	const apolloClient = useSelector(state => state.apolloClient);
	const authReducer = useSelector(state => state.authReducer);
	const projectReducer = useSelector(state => state.projectReducer);
	const [userAccess, setUserAccess] = useState({});
	const [loading, setLoading] = useState(false);
	const [viewProject, setViewProject] = useState('');
	const [editProject, setEditProject] = useState('');
	const [projectsList, setProjectsList] = useState([]);
	const [showRemoveProject, setShowRemoveProject] = useState(false);
	const [showCloseProject, setShowCloseProject] = useState(false);
	const [selectedProject, setSelectedProject] = useState({});
	const [refreshProjectList, setrefreshProjectList] = useState(false);
	const [showProjectSupport, setShowProjectSupport] = useState(false);
	const [supportProjectParam, setSupportProjectParam] = useState({});

	const isAdmin = useMemo(() => authReducer.userData.email === authReducer.userData.owner, [
		authReducer.userData?.email,
		authReducer.userData?.owner,
	]);

	const columns = useMemo(
		() => [
			{
				name: project_sr_no,
				options: { filter: false },
			},
			{
				name: project_project_name,
				options: { filter: false },
			},
			{
				name: project_purpose,
				options: {
					filter: false,
					customBodyRender: value => {
						let purpose = value.substring(0, 50);
						if (value.length > 50) {
							purpose = purpose + '...';
						}
						return <>{purpose}</>;
					},
				},
			},
			{
				name: project_project_promoter,
				options: { filter: false },
			},
			{
				name: client_token_type,
				options: { filter: false },
			},
			{
				name: client_viewprobing_status,
				options: { filter: false },
			},
			{
				name: client_viewprobing_status,
				options: {
					filter: true,
					display: false,
				},
			},
			{
				name: project_actions,
				options: {
					filter: false,
					customBodyRender: value => {
						if (value) {
							let supportRequest = value?.support_request_status;
							return (
								<>
									{userAccess.view_project && (
										<Button
											className={'system-btn clear-border'}
											outline={true}
											color={'success'}
											onClick={() => setViewProject(value.projectid)}
										>
											<Tooltip title={tooltip_view}>
												<Icon>remove_red_eye</Icon>
											</Tooltip>
										</Button>
									)}
									{userAccess.modify_project &&
										!value.isclosed &&
										!authReducer.userData.other_company &&
										(isAdmin || supremeRole.includes(authReducer.userData.role_id)) && (
											<>
												<Button
													className={'system-btn clear-border'}
													outline={true}
													color={'success'}
													onClick={() => handleRemoveProjectConfirmation(value)}
												>
													<Tooltip title={tooltip_delete}>
														<Icon>delete</Icon>
													</Tooltip>
												</Button>
												<Button
													className={'system-btn clear-border'}
													outline={true}
													color={'success'}
													onClick={() => handleEditProject(value.projectid)}
												>
													<Tooltip title={tooltip_edit}>
														<Icon>edit</Icon>
													</Tooltip>
												</Button>
												<Button
													className={'system-btn clear-border'}
													outline={true}
													color={'success'}
													onClick={() => handleCloseProjectConfirmation(value)}
												>
													<Tooltip title={open_project}>
														<Icon>lock_open</Icon>
													</Tooltip>
												</Button>
												<Button
													className={'system-btn clear-border'}
													outline={true}
													color={'success'}
													onClick={() =>
														handleTerminateProjectSupportConfirmation(value, supportRequest)
													}
												>
													<Tooltip
														title={
															!supportRequest || supportRequest === PENDING
																? need_help_of_support_team
																: client_terminate_project_support
														}
													>
														<Icon>
															{!supportRequest || supportRequest === PENDING
																? 'support'
																: 'exit_to_app'}
														</Icon>
													</Tooltip>
												</Button>
											</>
										)}
								</>
							);
						}
						return '-';
					},
				},
			},
		],
		[
			userAccess?.view_project,
			userAccess?.modify_project,
			isAdmin,
			authReducer?.userData?.role_id,
			projectReducer.projectList,
		]
	);

	useEffect(() => {
		setUserAccess(authReducer.userData.useraccesspage || {});
	}, [authReducer.userData?.useraccesspage]);

	useEffect(() => {
		const request = {
			company: authReducer.userData.company_name,
			projectid: authReducer.userData.projectid,
			isadmin: isAdmin,
		};
		/* get projects list */
		dispatch(actions.getProjectList(apolloClient.project, request));
		dispatch(actions.getAvailableLicenceList(apolloClient.client, []));

		setLoading(true);
	}, [
		authReducer.userData?.company_name,
		authReducer.userData?.projectid,
		isAdmin,
		refreshProjectList,
	]);

	useEffect(() => {
		setLoading(false);
		setrefreshProjectList(false);
		if (!projectReducer.projectList?.length) return;
		const projects = orderBy(projectReducer.projectList, ['created_at'], ['desc']);
		const projectlist = projects.map((val, index) => [
			index + 1,
			val.name,
			val.purpose,
			val.projectOwner,
			typeof val.tokentype === 'string'
				? val.tokentype.charAt(0).toUpperCase() + val.tokentype.slice(1)
				: '',
			!val.isclosed ? (
				<span className={'statusOpen'}>Open</span>
			) : (
				<span className={'statusClose'}>Closed</span>
			),
			!val.isclosed ? 'Open' : 'Closed',
			val,
		]);

		setProjectsList(projectlist);
	}, [projectReducer.projectList]);

	function handleRemoveProjectConfirmation(project) {
		setShowRemoveProject(true);
		setSelectedProject(project);
	}
	function handleRemoveProject(projectId) {
		const requestParams = {
			projectid: projectId,
			company: authReducer.userData.company_name,
		};
		dispatch(actions.removeProject(apolloClient.project, requestParams));
		setShowRemoveProject(false);
		const PL = projectsList.filter(val => val[7].projectid !== projectId);
		setProjectsList(PL);
	}

	function handleEditProject(projectId) {
		console.log('edit projectId :>> ', projectId);
		setEditProject(projectId);
	}

	function handleCloseProjectConfirmation(project) {
		setShowCloseProject(true);
		setSelectedProject(project);
	}
	function handleCloseProject(projectId, reason) {
		console.log('close project :>> ', projectId);
		const requestParams = {
			projectid: projectId,
			close_description: reason || '',
			isclosed: true,
			company: authReducer.userData.company_name,
			isadmin: isAdmin,
		};
		dispatch(actions.closeProject(apolloClient.project, requestParams));
		setShowCloseProject(false);
		const p = projectsList.find(val => val[7].projectid === projectId);
		if (p) {
			p[5] = <span className={'statusClose'}>Closed</span>;
			p[7].isclosed = true;
		}
		setProjectsList(PL => [...PL]);
	}
	function handleTerminateProjectSupportConfirmation(project, supportRequest) {
		const requestParams = {
			projectId: project.projectid,
			projectName: project.name,
			projectOwner: project.projectOwner,
			projectOwnerUserId: project.ownerid,
			companyOwnerEmail: authReducer.userData?.email,
			companyOwnerUserId: authReducer.userData?.userid,
			companyId: authReducer.userData.company_id,
			companyName: authReducer.userData.company_name,
			displayName: authReducer.userData.display_name,
			inviteId: randomString(30),
			status: __.isEmpty(supportRequest) || supportRequest === PENDING ? PENDING : TERMINATE,
		};
		setShowProjectSupport(true);
		setSupportProjectParam(requestParams);
	}
	function handleProjetSupport(requestParams) {
		dispatch(actions.createSupportInvitationRequest(apolloClient.masters, requestParams));
		setShowProjectSupport(false);
		setrefreshProjectList(true);
	}

	return (
		<>
			<div>
				<h3 className="page-title" style={{ marginBottom: '20px' }}>
					{common_project_list}
				</h3>
				{loading ? (
					<div className="div-spinner">
						<Spinner size="500" className="success" />
					</div>
				) : (
					<MUIDataTable data={projectsList} columns={columns} options={options} />
				)}
				{viewProject !== '' && (
					<ModalProjectDetails
						isOpen={viewProject !== ''}
						onClose={() => setViewProject('')}
						projectId={viewProject}
					/>
				)}
				{editProject !== '' && (
					<ModalEditProject
						isOpen={editProject !== ''}
						onClose={() => setEditProject('')}
						projectId={editProject}
						onSave={data => {
							console.log('data :>> ', data);
							setEditProject('');
						}}
					/>
				)}
				{showRemoveProject && (
					<ModalDeleteConfirmation
						isOpen={showRemoveProject}
						onClose={() => setShowRemoveProject(false)}
						deleteData={{
							title: client_project_delete_project,
							deleteMessage: client_delete_project_message,
							actionName: '',
							objectName: selectedProject?.name,
						}}
						onConfirm={() => handleRemoveProject(selectedProject.projectid)}
					/>
				)}
				{showProjectSupport && (
					<ModalDeleteConfirmation
						isOpen={showProjectSupport}
						onClose={() => setShowProjectSupport(false)}
						deleteData={{
							title:
								!supportProjectParam.status || supportProjectParam.status === 'Pending'
									? client_project_get_project_support
									: client_terminate_project_support,
							deleteMessage:
								!supportProjectParam.status || supportProjectParam.status === 'Pending'
									? client_get_project_support_message
									: client_terminate_project_support_message,
							actionName: '',
							objectName: supportProjectParam?.projectName,
						}}
						onConfirm={() => handleProjetSupport(supportProjectParam)}
					/>
				)}
				{showCloseProject && (
					<ModalCloseProject
						isOpen={showCloseProject}
						onClose={() => setShowCloseProject(false)}
						projectName={selectedProject?.name}
						onConfirm={reason => handleCloseProject(selectedProject.projectid, reason)}
					/>
				)}
			</div>
		</>
	);
}
