import { Icon } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import * as actions from '../../../../Store/actions/index';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import ModalDeleteConfirmation from '../components/dataVisualization/modals/deleteConfirmation';
import ModalRoleList from './roleList';

const tlang = ChangeAppLanguageFront.translateLanguage;
const client_project_email = tlang('client_project_email') || 'E-mail';
const project_sr_no = tlang('project_sr_no') || 'Sr.No';
const client_project_role = tlang('client_project_role') || 'Role';
const client_project_company = tlang('client_project_company') || 'Company';
const client_project_status = tlang('client_project_status') || 'Status';
const project_actions = tlang('project_actions') || 'Actions';
const sort_text = tlang('sort_text') || 'Sort';
const rows_per_page_text = tlang('rows_per_page_text') || 'Rows per page:';
const no_match_record_found = tlang('no_match_record_found') || 'Sorry, no matching records found';
const search_text = tlang('search_text') || 'Search';
const client_project_user_name = tlang('client_project_user_name') || 'User Name';
const client_project_member = tlang('client_project_member') || 'Project Member';

export default function useProjectMembers(campaign = false) {
	const authReducer = useSelector(state => state.authReducer);
	const [members, setMembers] = useState([]);
	const [actionButton, setActionButton] = useState({ action: '', member: {}, option: '' });

	function addMembers(actualMembers, membersData, isInvited = false) {
		const id = actualMembers.length + 1;
		const newMembers = membersData
			.filter(m => !m.delete && (!isInvited || m.status !== 'approve'))
			.map((member, index) => [
				id + index,
				member.name || '-',
				member.email || member.emailid || '-',
				member.rolename || '-',
				member.inviteid && member.receiverdisplayname ? (
					member.receiverdisplayname
				) : member.membercompanydisplayname ? (
					member.membercompanydisplayname
				) : member.inviteid && !member.receiverdisplayname ? (
					<span style={{ color: 'var(--clr-aexdo)' }}>external</span>
				) : (
					member.displayname
				),
				member.status !== 'approve' ? member.status : 'Active',

				// isInvited ? (
				// 	member.displayname || '-'
				// ) : member.isnew ? (
				// 	<span style={{ color: 'var(--clr-aexdo)' }}>external</span>
				// ) : (
				// 	member.membercompanydisplayname || member.displayname || '-'
				// ),
				// isInvited || member.isnew ? 'Request Pending' : 'Active',
				campaign && member.type === 'project' ? (
					<span style={{ color: 'var(--clr-aexdo)' }}>{client_project_member}</span>
				) : (
					<>
						{member.email !== authReducer.userData.owner ? (
							<>
								<Button
									className={'system-btn clear-border'}
									outline={true}
									color={'success'}
									onClick={
										() =>
											member.userid
												? setActionButton({ action: 'deleteActiveUser', member })
												: setActionButton({ action: 'deleteCompanyRequest', member })
										// isInvited ? deleteCompanyRequest(member) : deleteActiveUser(member)
									}
								>
									<Icon>delete</Icon>
								</Button>
								<Button
									className={'system-btn clear-border'}
									outline={true}
									color={'success'}
									onClick={
										() =>
											setActionButton({
												action: 'changeCompanyRole',
												member,
												option: isInvited ? 'invite_member' : 'active_member',
											})
										// changeCompanyRole(member, isInvited ? 'invite_member' : 'active_member')
									}
								>
									<Icon>edit</Icon>
								</Button>
							</>
						) : (
							''
						)}
					</>
				),
				member,
			]);
		return actualMembers.concat(newMembers);
	}

	function getMemberById(id) {
		return members.find(m => m[7].userid === id);
	}
	function getMemberByEmail(email) {
		return members.find(m => m[2] === email);
	}
	const memberIds = useMemo(() => {
		return members.map(m => m[7].userid);
	}, [members]);

	const allMembers = useMemo(() => {
		return members.map(m => m[7]);
	}, [members]);

	return {
		addMembers,
		getMemberById,
		getMemberByEmail,
		memberIds,
		allMembers,
		members,
		setMembers,
		actionButton,
	};
}

export function ProjectMembers({ members, setMembers, projectId, projectName, actionButton }) {
	const dispatch = useDispatch();
	const apolloClient = useSelector(state => state.apolloClient);
	const [showDeleteUser, setShowDeleteUser] = useState(false);
	const [showChangeRole, setShowChangeRole] = useState(false);
	const [selectedMember, setSelectedMember] = useState({});

	useEffect(() => {
		if (!actionButton) return;
		switch (actionButton.action) {
			case 'deleteCompanyRequest':
				deleteCompanyRequest(actionButton.member);
				break;
			case 'deleteActiveUser':
				deleteActiveUser(actionButton.member);
				break;
			case 'changeCompanyRole':
				changeCompanyRole(actionButton.member, actionButton.option);
				break;
			default:
				break;
		}
	}, [actionButton]);

	function deleteActiveUser(member) {
		setShowDeleteUser(true);
		setSelectedMember(member);
	}

	function handleDeleteUser(member) {
		let requestParams = {
			companyname: member.companyname,
			userid: member.userid,
			projectid: projectId,
			projectname: projectName,
		};
		dispatch(actions.deleteInvitedMember(apolloClient.project, requestParams));
		const m = members.find(m => m[7].userid === member.userid);
		if (m) m[7].delete = true;
		setMembers([...members]);
		setShowDeleteUser(false);
	}

	function changeCompanyRole(member, type) {
		setShowChangeRole(true);
		setSelectedMember({ ...member, type });
	}

	function handleChangeRole(newRole) {
		const member = members.find(m => m[7].userid === selectedMember.userid);
		if (member) {
			member[7].roleid = newRole.value;
			member[7].rolename = newRole.label;
			member[3] = newRole.label;
		}
		setMembers([...members]);
		setShowChangeRole(false);
	}

	function deleteCompanyRequest(member) {
		if (!member.inviteid) return;
		const m = members.find(m => m[7].inviteid === member.inviteid);
		if (m) m[7].delete = true;
		setMembers([...members]);
	}

	return (
		<>
			<MUIDataTable data={members.filter(m => !m[7].delete)} columns={columns} options={options} />
			{showDeleteUser && (
				<ModalDeleteConfirmation
					isOpen={showDeleteUser}
					onClose={() => setShowDeleteUser(false)}
					deleteData={{ actionName: client_project_user_name, objectName: selectedMember?.name }}
					onConfirm={() => handleDeleteUser(selectedMember)}
				/>
			)}
			{showChangeRole && (
				<ModalRoleList
					isOpen={showChangeRole}
					onClose={() => setShowChangeRole(false)}
					initialRole={selectedMember?.roleid}
					onNewRole={role => handleChangeRole(role)}
				/>
			)}
		</>
	);
}

const columns = [
	{ name: project_sr_no },
	{ name: client_project_user_name },
	{ name: client_project_email },
	{ name: client_project_role },
	{ name: client_project_company },
	{ name: client_project_status },
	{ name: project_actions },
];

const options = {
	filterType: 'checkbox',
	selectableRows: 'none',
	print: false,
	download: false,
	viewColumns: false,
	filter: false,
	responsive: 'stacked',
	pagination: false,
	search: false,
	textLabels: {
		body: {
			noMatch: no_match_record_found,
			toolTip: sort_text,
		},
		pagination: {
			rowsPerPage: rows_per_page_text,
		},
		toolbar: {
			search: search_text,
		},
	},
};
