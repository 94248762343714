import { useEffect, useState } from 'react';
import * as ChangeAppLanguageFront from '../view/Layout/ChangeAppLanguageFront';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import highchartsMap from 'highcharts/modules/map';
import { useDispatch, useSelector } from 'react-redux';
import { getMaterialListfilter } from '../view/Common/materialList';
// import PALETTES from '../../Common/colors';
import useSampleInfo from './useSampleInfo';
import formatDepth, { formatDepthNumber } from './formatDepth';
import * as actions from '../../Store/actions/index';

require('highcharts/modules/pattern-fill')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

highchartsMap(Highcharts);

HighchartsMore(Highcharts);
Highcharts.setOptions({
	lang: {
		thousandsSep: '',
	},
});

const tlang = ChangeAppLanguageFront.translateLanguage;
const mapbox_sample_has_no_disposal =
	tlang('mapbox_sample_has_no_disposal') || 'Sample has no disposal';
const mapbox_layer_has_no_sample = tlang('mapbox_layer_has_no_sample') || 'Layer has no sample';
const client_export_option_viewFullscreen =
	tlang('client_export_option_viewFullscreen') || 'View in full screen';
const client_export_option_printChart = tlang('client_export_option_printChart') || 'Print chart';
const client_export_option_downloadPNG =
	tlang('client_export_option_downloadPNG') || 'Download PNG image';
const client_export_option_downloadJPEG =
	tlang('client_export_option_downloadJPEG') || 'Download JPEG image';
const client_export_option_downloadPDF =
	tlang('client_export_option_downloadPDF') || 'Download PDF document';
const client_export_option_downloadSVG =
	tlang('client_export_option_downloadSVG') || 'Download SVG vactor image';
const client_export_option_downloadCSV =
	tlang('client_export_option_downloadCSV') || 'Download CSV';
const client_export_option_downloadXLS =
	tlang('client_export_option_downloadXLS') || 'Download XLS';
const client_probing_layer = tlang('client_probing_layer') || 'Layer';
const mapbox_chart_axislabel = tlang('mapbox_chart_axislabel') || 'Total probing consumption';
const material_masters_soil_family = tlang('materail_master_soil_family') || 'Soil Family';
const client_probing_lithology = tlang('client_probing_lithology') || 'Lithology';
const client_disposal = tlang('client_disposal') || 'Disposal';
const client_probing_Layer_Length = tlang('client_probing_Layer_Length') || 'Layer Length';
const client_sample_length = tlang('client_sample_length') || 'Sample Length';

const CLR_NO_DISPOSAL = '#A4A09F';
const CLR_NO_SAMPLE = '#e3e3e3';

// sample color generator
// function* sampleColorMaker() {
// 	const SAMPLE_COLORS = PALETTES.exotic;
// 	let colorIndex = 0;
// 	while (true) {
// 		if (colorIndex >= SAMPLE_COLORS.length) colorIndex = 0;
// 		yield SAMPLE_COLORS[colorIndex++];
// 	}
// }

// const sampleColor = sampleColorMaker();

const masterData = ['color', 'lithology', 'geology'];

export default function useCreateGraphProbings(
	probingList,
	title,
	mode = 'layer',
	withSample = false,
	withLabelInside = false,
	disposalOptions
) {
	const [loading, setLoading] = useState(true);
	const [chartData, setChartData] = useState({});
	const [probings, setProbings] = useState();
	const {
		sampleInfo,
		loading: loadingSampleInfo,
		samples,
		disposalList: disposals,
	} = useSampleInfo(probings, null, {
		disposalOptions,
	});
	const dispatch = useDispatch();
	const apolloClient = useSelector(state => state.apolloClient);
	const [lithologyList, setLithologyList] = useState([]);
	const [geologyList, setGeologyList] = useState([]);
	const [colorList, setColorList] = useState([]);
	const [hasData, setHasData] = useState(false);
	const masterReducer = useSelector(state => state.masterReducer);
	const authReducer = useSelector(state => state.authReducer);
	const unit = authReducer.userData.default_unit || 'M';

	useEffect(() => {
		const params = {
			type_list: JSON.stringify(masterData),
			lang_code: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
		};
		dispatch(actions.getTypeWiseOtherMatreials(apolloClient.masters, params));
	}, []);

	useEffect(() => {
		if (!probingList) return;
		if (Array.isArray(probingList)) setProbings(probingList);
		else setProbings([probingList]);
	}, [probingList]);

	useEffect(() => {
		const lithology = masterReducer.lithologyList;
		if (lithology) setLithologyList(getMaterialListfilter(lithology));
	}, [masterReducer.lithologyList.length]);

	useEffect(() => {
		const geology = masterReducer.geologyList;
		if (geology) setGeologyList(getMaterialListfilter(geology));
	}, [masterReducer.geologyList.length]);

	useEffect(() => {
		const color = masterReducer.colorList;
		if (color) setColorList(getMaterialListfilter(color));
	}, [masterReducer.colorList.length]);

	useEffect(() => {
		if (lithologyList.length && geologyList.length && colorList.length) setHasData(true);
	}, [
		lithologyList,
		lithologyList.length,
		geologyList,
		geologyList.length,
		colorList,
		colorList.length,
	]);

	// ====================== LAYER GRAPH ======================
	useEffect(() => {
		if (!hasData) return;
		if (mode !== 'layer') return;
		setLoading(false);
		if (!Array.isArray(probings)) return;

		const layerArrayLength = probings.map(v => {
			const layers = v.layer || v.layers || [];
			return layers.length;
		});
		const arrayCreat = Math.max(...layerArrayLength) || layerArrayLength.length;
		// stack column array
		const serieLayer = [];
		const serieSample = [];
		for (let i = 0; i < arrayCreat; i++) {
			const newLayer = {
				name: `${client_probing_layer}-${i + 1}`,
				type: 'columnrange',
				stacking: 'normal',
				data: [],
				colors: [],
				dataLabels: [
					{
						enabled: withLabelInside,
						inside: true,
						style: {
							fontSize: '16px',
						},
					},
				],
			};
			serieLayer.push(newLayer);
		}

		let chartValuesArray = [];
		let idProb = 0;
		for (const prob of probings) {
			let layerLength = 0;
			// let altLength = parseFloat(prob.altitudeOrg || prob.altitude);
			let altLength = parseFloat(prob.altitude);
			let idLayer = 1;
			for (let j = 0; j < arrayCreat; j++) {
				const layers = prob?.layer || prob?.layers || [];
				let layer = layers[j];
				if (layer) {
					layerLength = layer?.generalDetails?.layerLength
						? parseFloat(layer.generalDetails.layerLength)
						: 0;
					const start = Number(altLength);
					const end = Number(Number(altLength - layerLength).toFixed(2));
					altLength = end;
					let patternPath = 'M';
					let patternColor = 'white';
					let patternWidth = 5;
					let patternHeight = 5;
					let patternname = '';
					let isLithology = true;
					let lithoSoilName = '';
					let lithoFromName = '';
					if (layer.lithology.length > 0) {
						if (layer.lithology[0].isDropDoun) {
							const litho = layer.lithology[0].selectedlithologyfrom?.value;
							let patternObj = getLithologyData(litho);
							patternname = getLithologyName(litho);
							lithoSoilName = getLithologyName(litho);
							if (patternObj) {
								patternPath = patternObj.pattern_code;
								patternWidth = patternObj.pattern_width;
								patternHeight = patternObj.pattern_height;
							}
						} else {
							isLithology = false;
							lithoFromName = layer.lithology[0].from;
							let patternObj = getGeologyData(layer.lithology[0].soil_family);
							patternname = getGeologyName(layer.lithology[0].soil_family);
							if (patternObj) {
								lithoSoilName = patternObj.name;
								patternPath = patternObj.pattern_code;
								patternWidth = patternObj.pattern_width;
								patternHeight = patternObj.pattern_height;
							}
						}
					}
					if (layer.lithoColor) {
						patternColor = layer.lithoColor;
					} else if (layer?.color?.length > 0) {
						let colorStart = layer.color[0].from;
						let colorEnd = layer.color[0].to;
						if (layer.color[0].isDropDoun) {
							colorStart =
								layer.color[0].selectedcolorfrom?.color ||
								getColorData(layer.color[0].selectedcolorfrom?.value) ||
								'#fff';
							colorEnd =
								layer.color[0]?.selectedcolorto?.color ||
								getColorData(layer.color[0]?.selectedcolorto?.value);
						}
						if (colorEnd) {
							patternColor = {
								linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
								stops: [
									[0, colorStart],
									[1, colorEnd],
								],
							};
						} else {
							patternColor = colorStart;
						}
					}

					const patternData = {
						pattern: {
							path: {
								d: patternPath || 'M',
							},
							width: patternWidth,
							height: patternHeight,
							backgroundColor: patternColor,
							opacity: 1,
							patternname,
							isLithology,
							lithoSoilName,
							lithoFromName,
							layerLength,
						},
					};

					chartValuesArray.push(Number(formatDepth(start, unit)));
					chartValuesArray.push(Number(formatDepth(end, unit)));
					serieLayer[j].data.push([
						Number(formatDepth(start, unit)),
						Number(formatDepth(end, unit)),
					]);
					serieLayer[j].colors.push(patternData);
					if (withSample) {
						const devidedBy = layer.sample.length + 1;
						const diff = (start - end) / devidedBy;
						let preVal = start - diff;
						let idSample = 1;
						for (const sample of layer.sample) {
							// console.log('sample.name :>> ', sample.sampleNo);
							const sampleId = `${client_probing_layer}-${idLayer}-Sample-${idSample}`;
							let sampleObj = serieSample.find(s => s.id === sample.sampleNo);
							if (!sampleObj) {
								serieSample.push({
									name: sampleId,
									id: sample.sampleNo,
									type: 'scatter',
									color: CLR_NO_SAMPLE, // sampleColor.next().value,
									data: new Array(probings.length).fill(null),
								});
								sampleObj = serieSample[serieSample.length - 1];
							}
							const si = sampleInfo.find(s => s.sample_name === sample.sampleNo);
							const despColor = si?.desposal_color || CLR_NO_SAMPLE;
							const despName = si?.desposal_name;

							if (sample.depthLayer) {
								sampleObj.data[idProb] = {
									y: formatDepthNumber(sample.depthLayer, unit),
									despName,
									color: despColor, //sampleObj.color,
								};
							} else {
								const upper_depth = isNaN(sample.upper_depth) ? 0 : Number(sample.upper_depth);
								const lower_depth = isNaN(sample.lower_depth) ? 0 : Number(sample.lower_depth);

								const isCompleteLayer = sample.isCompleteLayer;
								if (
									(upper_depth !== '' || upper_depth === '0') &&
									lower_depth !== '' &&
									!isCompleteLayer
								) {
									const sampleDepth = (upper_depth + lower_depth) / 2;
									const middel_depth = start - sampleDepth;
									preVal = middel_depth;
								} else {
									if (idSample > 1) preVal = preVal - diff;
								}
								// sampleObj.data.push(Number(preVal.toFixed(2)));
								sampleObj.data[idProb] = {
									y: formatDepthNumber(preVal, unit),
									despName,
									color: despColor, // sampleObj.color,
								};
							}
							idSample += 1;
						}
					}
				} else {
					serieLayer[j].data.push([]);
					serieLayer[j].colors.push([]);
				}
				idLayer += 1;
			}
			idProb += 1;
		}
		const series = serieLayer.concat(serieSample);
		console.log('series :>> ', series);
		chartValuesArray = [...new Set(chartValuesArray)];
		const min = Math.min(...chartValuesArray);
		const max = Math.max(...chartValuesArray);
		const radius = Math.min(Math.max(100 / layerArrayLength.length, 3), 10);
		// chart categories show on x-axis
		const chartCategories = probings.map(prob => prob.probingcode || prob.probingName);
		const options = {
			chart: {
				type: 'columnrange',
			},
			exporting: {
				buttons: {
					contextButton: {
						menuItems: exportMenu,
					},
				},
			},
			title: {
				text: title,
			},
			xAxis: {
				categories: chartCategories,
			},
			yAxis: {
				max,
				min,
				stackLabels: true,
				title: {
					text: mapbox_chart_axislabel,
				},
			},
			tooltip: {
				formatter: function() {
					let tooltip = '';
					const point = this.point.color.pattern;
					if (point) {
						const lithoSoilName = point.lithoSoilName || '';
						const lithoFromName = point.lithoFromName || '';
						const layerLength = formatDepth(point.layerLength, unit) || '-';
						const lowerAltitude = Number(this.point.y - layerLength).toFixed(2);
						if (point.isLithology) {
							tooltip += `${this.series.name} : ${this.point.y} - ${lowerAltitude}<br/>${client_probing_Layer_Length}
							:${layerLength} ${unit}<br/>${client_probing_lithology} : ${lithoSoilName}`;
						} else {
							tooltip += `${this.series.name} : ${this.point.y} - ${lowerAltitude}<br/>${client_probing_Layer_Length}
							:${layerLength} ${unit}<br/>${client_probing_lithology} : ${lithoFromName}`;
						}
					} else {
						tooltip += `${this.series.name} : ${this.point.y}`;
						if (this.point.despName) {
							tooltip += `<br/>${client_disposal} : <span style="color:${checkTextColor(
								this.point.color || 'black'
							)};font-size:16px;font-weight:700;">${this.point.despName}</span>`;
						}
					}
					return tooltip;
				},
			},
			legend: {
				enabled: false,
			},
			credits: {
				enabled: false,
			},
			mapNavigation: {
				enabled: true,
				buttonOptions: {
					verticalAlign: 'bottom',
				},
			},
			plotOptions: {
				columnrange: {
					stacking: 'normal',
					grouping: false,
					colorByPoint: true,
					dataLabels: {
						enabled: true,
					},
					borderWidth: 1,
					borderColor: 'black',
				},
				series: {
					states: {
						hover: {
							enabled: false,
							brightness: 1,
						},
					},
				},
				scatter: {
					marker: {
						radius,
						lineWidth: 1,
					},
				},
			},
			series,
		};
		setChartData(options);
	}, [probings, disposals, mode, hasData, withSample]);

	// ====================== SAMPLE GRAPH ======================
	useEffect(() => {
		if (mode !== 'sample') return;
		if (loadingSampleInfo) return;
		setLoading(false);
		const layerArrayLength = probings.map(v => {
			const layers = v.layer || v.layers;
			return layers.length;
		});
		const arrayCreat = Math.max(...layerArrayLength) || layerArrayLength.length;
		// stack column array
		const serieLayer = [];
		for (let i = 0; i < arrayCreat; i++) {
			const newLayer = {
				name: `${client_probing_layer}-${i + 1}`,
				data: [],
				colors: [],
				dataLabels: [
					{
						enabled: false,
						inside: true,
						style: {
							fontSize: '10px',
						},
					},
				],
			};
			serieLayer.push(newLayer);
		}
		let chartValuesArray = [];
		for (const info of sampleInfo) {
			const smpl = samples.find(s => s.sampleNo === info.sample_name);
			if (smpl) {
				smpl.color = info.desposal_color || CLR_NO_DISPOSAL;
				smpl.disposal = info.desposal_name || mapbox_sample_has_no_disposal;
			}
		}

		for (const prob of probings) {
			let layerLength = 0;
			// let altLength = parseFloat(prob.altitudeOrg || prob.altitude);
			let altLength = Number(prob.altitude);
			for (let j = 0; j < arrayCreat; j++) {
				const layers = prob.layer || prob.layers;
				let layer = layers[j];
				if (layer) {
					if (layer.sample.length) {
						for (const smpl of layer.sample) {
							const sample = samples.find(s => s.sampleNo === smpl.sampleNo);
							if (sample) {
								layerLength = Number(sample.length || layer.generalDetails.layerLength);

								const start = Number(altLength);
								const end = Number(Number(altLength - layerLength).toFixed(2));
								altLength = end;

								const patternData = {
									pattern: {
										path: { d: 'M' },
										backgroundColor: sample.color || CLR_NO_DISPOSAL,
										opacity: 1,
										sampleNo: sample.sampleNo,
										disposalName: sample.disposal || mapbox_sample_has_no_disposal,
										noSampleInLayer: false,
										layerLength,
									},
								};

								chartValuesArray.push(Number(formatDepth(start, unit)));
								chartValuesArray.push(Number(formatDepth(end, unit)));
								serieLayer[j].data.push([
									Number(formatDepth(start, unit)),
									Number(formatDepth(end, unit)),
								]);
								serieLayer[j].colors.push(patternData);
							}
						}
					} else {
						layerLength = layer?.generalDetails?.layerLength
							? Number(layer.generalDetails.layerLength)
							: 0;
						const start = Number(altLength);
						const end = Number(Number(altLength - layerLength).toFixed(2));
						altLength = end;

						const patternData = {
							pattern: {
								path: { d: 'M' },
								backgroundColor: CLR_NO_SAMPLE,
								opacity: 1,
								sampleNo: mapbox_layer_has_no_sample,
								disposalName: '',
								noSampleInLayer: true,
								layerLength,
							},
						};

						chartValuesArray.push(Number(formatDepth(start, unit)));
						chartValuesArray.push(Number(formatDepth(end, unit)));
						serieLayer[j].data.push([
							Number(formatDepth(start, unit)),
							Number(formatDepth(end, unit)),
						]);
						serieLayer[j].colors.push(patternData);
					}
				} else {
					serieLayer[j].data.push([]);
					serieLayer[j].colors.push([]);
				}
			}
		}
		chartValuesArray = [...new Set(chartValuesArray)];
		const min = Math.min(...chartValuesArray);
		const max = Math.max(...chartValuesArray);

		// chart categories show on x-axis
		const chartCategories = probings.map(prob => prob.probingcode || prob.probingName);

		const options = {
			chart: {
				type: 'columnrange',
			},
			exporting: {
				buttons: {
					contextButton: {
						menuItems: exportMenu,
					},
				},
			},
			title: {
				text: title,
			},
			xAxis: {
				categories: chartCategories,
			},
			yAxis: {
				max: max,
				min: min,
				stackLabels: true,
				title: {
					text: mapbox_chart_axislabel,
				},
			},
			tooltip: {
				formatter: function() {
					let tooltip = '';
					const point = this.point.color.pattern;
					if (point) {
						const layerLength = formatDepth(this.point.color.pattern.layerLength, unit) || '-';
						const lowerAltitude = Number(this.point.y - layerLength).toFixed(2);
						tooltip +=
							this.point.color.pattern.sampleNo + ' : ' + this.point.y + ' - ' + lowerAltitude;
						tooltip += `<br/>${client_sample_length}:${layerLength} ${unit}`;
						if (!this.point.color.pattern.noSampleInLayer) {
							tooltip += `<br/>${client_disposal} : <span style="color:${checkTextColor(
								this.point.color.pattern.backgroundColor || 'black'
							)};font-size:16px;font-weight:700;">${this.point.color.pattern.disposalName}</span>`;
						}
					} else {
						tooltip += this.series.name + ' : ' + this.point.y;
					}

					return tooltip;
				},
			},
			legend: {
				enabled: false,
			},
			credits: {
				enabled: false,
			},
			mapNavigation: {
				enabled: true,
				buttonOptions: {
					verticalAlign: 'bottom',
				},
			},
			plotOptions: {
				columnrange: {
					stacking: 'normal',
					grouping: false,
					colorByPoint: true,
					dataLabels: {
						enabled: false,
					},
					borderWidth: 1,
					borderColor: 'black',
				},
				series: {
					states: {
						hover: {
							enabled: false,
						},
					},
				},
			},
			series: serieLayer,
		};

		setChartData(options);
	}, [mode, probings, loadingSampleInfo]);

	const getLithologyName = id => {
		return lithologyList.find(l => l._id === id)?.name || '';
	};

	const getGeologyName = id => {
		return geologyList.find(l => l._id === id)?.name || '';
	};

	const getLithologyData = id => {
		const val = lithologyList.find(l => l._id === id);
		if (val) return getGeologyData(val.value);
		return '';
	};

	const getGeologyData = id => {
		return geologyList.find(l => l._id === id) || {};
	};

	const getColorData = id => {
		return colorList.find(l => l._id === id)?.value;
	};

	const checkTextColor = hexcolor => {
		let color = hexcolor.substring(1);
		let r = parseInt(color.substr(0, 2), 16);
		let g = parseInt(color.substr(2, 2), 16);
		let b = parseInt(color.substr(4, 2), 16);
		let yiq = (r * 299 + g * 587 + b * 114) / 1000;
		return yiq > 200 ? '' : hexcolor;
	};

	return {
		loading,
		loadingSampleInfo,
		chartData,
		Highcharts,
		disposals,
		sampleInfo,
		samples,
	};
}

const exportMenu = [
	{
		text: client_export_option_viewFullscreen,
		textKey: 'viewFullscreen',
		onclick: function() {
			Highcharts.FullScreen.prototype.init(this.renderTo);
		},
	},
	{
		text: client_export_option_printChart,
		textKey: 'printChart',
		onclick: function() {
			this.print();
		},
	},
	'separator',
	{
		text: client_export_option_downloadPNG,
		textKey: 'downloadPNG',
		onclick: function() {
			this.exportChart();
		},
	},
	{
		text: client_export_option_downloadJPEG,
		textKey: 'downloadJPEG',
		onclick: function() {
			this.exportChart({
				type: 'image/jpeg',
			});
		},
	},
	{
		text: client_export_option_downloadPDF,
		textKey: 'downloadPDF',
		onclick: function() {
			this.exportChart({
				type: 'application/pdf',
			});
		},
	},
	{
		text: client_export_option_downloadSVG,
		textKey: 'downloadSVG',
		onclick: function() {
			this.exportChart({
				type: 'image/svg+xml',
			});
		},
	},
	'separator',
	{
		text: client_export_option_downloadCSV,
		textKey: 'downloadCSV',
		onclick: function() {
			this.downloadCSV();
		},
	},
	{
		text: client_export_option_downloadXLS,
		textKey: 'downloadXLS',
		onclick: function() {
			this.downloadXLS();
		},
	},
];
