

export const translateLanguage = (lable_key) => {
    let languagedata = JSON.parse(localStorage.getItem('language_data'));

    if(languagedata){
        let currentLanguage = window.localStorage.getItem('language');
        let languageArray = languagedata.filter((lan) => {
            return (lan.key === lable_key);
        });
        if(languageArray[0]){
            let labelLan = languageArray[0]['language']['en'];
            if(languageArray[0]['language'][currentLanguage] && languageArray[0]['language'][currentLanguage] != ""){
                    labelLan = languageArray[0]['language'][currentLanguage];
            }
            return labelLan;
        }else{
            return null;
        }
    }else{
        return null;
    }
}
