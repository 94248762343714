import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import * as actions from '../../../../Store/actions/index';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';

const tlang = ChangeAppLanguageFront.translateLanguage;

const client_token_type = tlang('client_token_type') || 'Token type';
const client_project_pls_enter_project_token =
	tlang('client_project_pls_enter_project_token') || 'Please Add Project Token';
const Lang_Loadingtxt = tlang('Lang_Loadingtxt') || 'Loading...';

export default function TokenList({ initialTokenType, onChange, origincompanytokenid }) {
	const [tokenList, setTokenList] = useState([]);
	const dispatch = useDispatch();
	const apolloClient = useSelector(state => state.apolloClient);
	const [isLoading, setIsLoading] = useState(false);
	const masterReducer = useSelector(state => state.masterReducer);
	const history = useHistory();
	const [tokenType, setTokenType] = useState('');
	const [companyTokenId, setCompanyTokenId] = useState();
	const [error, setError] = useState(false);

	useEffect(() => {
		dispatch(actions.getTokenList(apolloClient.client, []));
		dispatch(actions.getProjectTokenList(apolloClient.project, []));
		setIsLoading(true);
	}, []);

	useEffect(() => {
		if (!masterReducer.tokenList?.length) return;
		const tl = masterReducer.tokenList.map(t => ({
			value: t.tokenId,
			label: t.tokenName,
		}));
		setTokenList(tl);
		setIsLoading(false);
	}, [masterReducer.tokenList, masterReducer.tokenList?.length]);

	useEffect(() => {
		if (!tokenList?.length) return;
		setTokenType(initialTokenType || tokenList[0].value);
	}, [tokenList, initialTokenType, initialTokenType?.length]);

	function addToken() {
		localStorage.setItem('client_request_tab', 8);
		history.push({
			pathname: '/client/profile',
			state: { client_request: true },
		});
	}

	useEffect(() => {
		console.log('tokenType, companyTokenId', {
			tokenType,
			companyTokenId,
		});
		onChange(tokenType, companyTokenId);
	}, [tokenType, companyTokenId]);

	function validateToken(token) {
		const newTokenIdx = tokenList.findIndex(l => l.value === token.value);
		const prevTokenIdx = tokenList.findIndex(l => l.value === tokenType);
		setError(false);
		setTokenType(token.value);
		if (newTokenIdx >= prevTokenIdx) {
			if (token.value === 'internal' || token.value === 'open') {
				const getTokens = masterReducer.projectTokenList.filter(
					l => l.tokenid === token.value && !l.used
				);
				if (getTokens.length > 0) {
					// use first of greater token type
					const companytokenid = getTokens[0].companytokenid;
					setCompanyTokenId(companytokenid);
				} else {
					setCompanyTokenId('');
					setError(true);
				}
			}
		} else if (newTokenIdx === prevTokenIdx) {
			setCompanyTokenId(origincompanytokenid);
		} else {
			setCompanyTokenId('');
			setError(true);
		}
	}

	if (isLoading) return <div>{Lang_Loadingtxt}</div>;

	return (
		<FormGroup>
			<Label>{client_token_type}</Label>
			<Select
				placeholder="Select"
				value={tokenList.find(t => t.value === tokenType)}
				onChange={value => validateToken(value)}
				options={tokenList}
				className="react-select"
			/>
			{error && (
				<span
					className="error-message margin__top"
					style={{ textDecoration: 'underline', cursor: 'pointer' }}
					onClick={addToken}
				>
					{client_project_pls_enter_project_token}
				</span>
			)}
		</FormGroup>
	);
}
