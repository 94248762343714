import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import 'bootstrap/dist/css/bootstrap.css';
import '../src/App/assets/scss/app.scss';
import store from "./Store/store.js";
import MainContainer from '../src/mainContainer';
import { config as i18nextConfig } from '../src/Store/translations';
import firebase from 'firebase/app';
// import ErrorBoundry from "./errorBoundary";

if(firebase.apps.length === 0) {
	firebase.initializeApp({
		apiKey: "AIzaSyCxxX5RCJDz_KGsFfu2J0VIICHpJH3sVJo",
		authDomain: "aexdo-40ece.firebaseapp.com",
		databaseURL: "https://aexdo-40ece.firebaseio.com",
		projectId: "aexdo-40ece",
		storageBucket: "aexdo-40ece.appspot.com",
		messagingSenderId: "525460657962",
		appId: "1:525460657962:web:6303ee92933a8ce047f053",
		measurementId: "G-G5X26PHQ93"
	});
}
// Init language configuration
i18next.init(i18nextConfig);

// Init class for react app
class App extends Component {
	render() {
		return (
			<Provider store={store} >
				{/* <ErrorBoundry> */}
				<BrowserRouter>
					<I18nextProvider i18n={i18next}>
						<MainContainer /> {/* this will load the main component which load the components depending on the session */}
					</I18nextProvider>
				</BrowserRouter>
				{/* </ErrorBoundry> */}
			</Provider>
		);
	}
}

export default (App); //export component