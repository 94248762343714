import React from 'react';
import * as actionTypes from '../actionTypes';
import { gql } from "apollo-boost";
import { Encrypt } from "../../shared/encrpt";
import * as actions from '../index'
import * as ChangeAppLanguageFront from '../../../App/view/Layout/ChangeAppLanguageFront';
import notificationMsg  from '../../../App/view/Layout/NotificationMessage';
let tlang = ChangeAppLanguageFront.translateLanguage;

const reqisapproved = tlang('notificationSuccess_user_reqisapproved') ? tlang('notificationSuccess_user_reqisapproved') : 'User request is approved';
const reqisrejected = tlang('notificationSuccess_user_reqisrejected') ? tlang('notificationSuccess_user_reqisrejected') : 'User request is rejected';
const requestsubmitted = tlang('notificationSuccess_user_requestsubmitted') ? tlang('notificationSuccess_user_requestsubmitted') : 'Your request has been submitted.';
const requestisapproved = tlang('notificationSuccess_user_requestisapproved') ? tlang('notificationSuccess_user_requestisapproved') : 'Request is Approved';
const CmpisUnblocked = tlang('notificationSuccess_user_CmpisUnblocked') ? tlang('notificationSuccess_user_CmpisUnblocked') : 'Company is Unblocked';
const Cmpisblocked = tlang('notificationSuccess_user_Cmpisblocked') ? tlang('notificationSuccess_user_Cmpisblocked') : 'Company is Blocked';
const linkhassentuseremail = tlang('notificationSuccess_user_linkhassentuseremail') ? tlang('notificationSuccess_user_linkhassentuseremail') : 'Link has been sent to user email.';
const error_something_wrong = tlang('error_something_wrong') ? tlang('error_something_wrong') : 'Something Went Wrong!';

// notification Success
const notificationSuccess = (msg) => {
    return {
        type: actionTypes.ADD_NEW_NOTIFICATION_SUCCESS,
        state: msg
    }
}

// notification Fail
export const notificationFail = (err) => {
    let msg = error_something_wrong;
    if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        // msg = err.graphQLErrors[0].message;
        let translateMsg =notificationMsg(err.graphQLErrors[0].statusCode)
        msg = translateMsg;
    } else if (err.message) {
        msg = err.message;
    }
    return {
        type: actionTypes.ADD_NEW_NOTIFICATION_FAIL,
        state: msg
    }
}

// getList Success
export const getListSuccess = (data) => {
    return {
        type: actionTypes.GET_USER_REQUEST_LIST,
        state: data
    }
}

// get user signup request list
export const getUserRequest = (client, request) => {
    if (!request || request.length == 0) {
        request.status = "";
        request.confirm = false;
    }
    return dispatch => {
        const query = gql`
        query get_company_request_list($status: String, $confirm: Boolean!) {
            get_company_request_list(status: $status, confirm: $confirm) {
          _id
          email
          name
          company_id
          company_name
          display_name
          company_address
          registration_country
          contact_number
          company_strength
          confirm_account
          created_at
          updated_at
          attachments
          status
          account_status
        }
      }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                status: request.status,
                confirm: request.confirm
            }
        }).then(response => {
            dispatch(getListSuccess(response.data.get_company_request_list))
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}

// approove or reject the user request
export const requestResult = (client, request) => {
    return dispatch => {
        const mutation = gql`
            mutation company_approve_reject($email: String!, $status: String!, $reason: String) {
                company_approve_reject(email: $email, status: $status, reason: $reason) {
                _id
                name
                email
                company_id
                company_name
                company_address
                registration_country
                contact_number
                status
                company_strength
                confirm_account
                created_at
                updated_at
                attachments
                }
            }
        `;
        client.mutate({
            mutation: mutation,
            variables: {
                email: Encrypt(request.email),
                status: Encrypt(request.status),
                reason: Encrypt(request.msg),
            }
        })
        .then(response => {
            dispatch(actions.getSuperAdminDashboardData(client, []))
            dispatch(actions.getUserRequest(client, []))
            dispatch(notificationSuccess(request.status == "approve" ? reqisapproved : reqisrejected))
            dispatch(getListSuccess(response.data.company_approve_reject))
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}


export const submitUserPlans = (client, request) => {
    return dispatch => {
        const mutation = gql`
        mutation plan_selection($email: String!, $company_name: String!, $plan: String!, $licence: String!, $inviteuser: String!) {
            plan_selection(email: $email, company_name: $company_name, plan: $plan, licence: $licence, inviteuser: $inviteuser) {
              error
              success
              message
            }
          }
        `;
        client.mutate({
            mutation: mutation,
            variables: {
                email: Encrypt(request.email),
                company_name: Encrypt(request.company_name),
                plan: Encrypt(JSON.stringify(request.plan)),
                licence: Encrypt(JSON.stringify(request.licence)),
                inviteuser: Encrypt(JSON.stringify(request.inviteuser)),
            }
        })
        .then(response => {

            let detailArray = []
            detailArray.push(request.plan)
            let invoiceParam = {
                company_id: request.company_id,
                category_name: "SIGNUP",
                title: "Signup Plan Request",
                invoice_detail: JSON.stringify(detailArray),
                invoice_html: "",
                invoice_name: "",
            }

            dispatch(actions.saveInvoice(client, invoiceParam))

            let invoiceParam2 = {
                company_id: request.company_id,
                category_name: "LICENCE",
                title: "Signup Licence Request",
                invoice_detail: JSON.stringify(request.licence),
                invoice_html: "",
                invoice_name: "",
            }

            
            let checkLicence = false;
            request.licence && request.licence.map((val,index)=>{
                if(val.count > 0){
                    checkLicence = true;
                } 
            })

            if(checkLicence){
                dispatch(actions.saveInvoice(client, invoiceParam2))
            }

            dispatch(notificationSuccess(requestsubmitted))
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}

// get User Details Success
const getUserDetailsSuccess = (data) => {
    return {
        type: actionTypes.GET_USER_REQUEST_DETAILS,
        state: data
    }
}

// get user signup request list
export const getUserRequestData = (client, request) => {
    return dispatch => {
        const query = gql`
            query get_company_detail($companyname: String!) {
                get_company_detail (companyname: $companyname) {
                  licence {
                    _id
                    licenceid
                    licencename
                    cost
                    count
                  }
                  inviteusers {
                    _id
                    email
                    roleid
                    rolename
                    name
                    created_at
                    updated_at
                  }
                  projectinviteusers {
                      _id
                      inviteid
                      projectid
                      projectname
                      projectowner
                      senderid
                      receiverid
                      roleid
                      rolename
                      displayname
                      companyname
                      status
                      created_at
                  }
                  campaignInviteUsers{
                    _id
                    campaignid
                    campaignname
                    projectid
                    projectname
                    probingid
                    probingname
                    senderid
                    receiverid
                    senderdisplayname
                    receiverdisplayname
                    roleid
                    rolename
                    inviteid
                    status
                    created_at
                  }
                  plan {
                    _id
                    planid
                    planname
                    uploadsize
                    usersize
                    price
                    limit
                  }
                }
              }
        `;
        client.query({
            query: query,
            variables: {
                companyname: request.companyname ? Encrypt(request.companyname) : "",
            },
            fetchPolicy: 'cache-first',
        }).then(response => {
            dispatch(getUserDetailsSuccess(response.data.get_company_detail))
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}

// get user signup request list
export const getUserRequestDetailAdmin = (client, request) => {
    return dispatch => {
        const query = gql`
        query super_get_company_details($email: String!, $companyname: String!) {
            super_get_company_details (email: $email, companyname: $companyname) {
                _id
                name
                email
                company_id
                company_name
                display_name
                company_address
                registration_country
                contact_number
                status
                attachments
                approve_reject_by
                approve_reject_datetime
                company_strength
                confirm_account
                created_at
                updated_at
                attachments
                licence {
                  _id
                  licenceid
                  licencename
                  cost
                  count
                  month
                }
                inviteusers {
                  _id
                  email
                  roleid
                  rolename
                  name
                  created_at
                  updated_at
                }
                memberusers {
                  _id
                  email
                  role_id
                  role_name
                  other_company
                  name
                  created_at
                  updated_at
                }
                plan {
                  _id
                  planid
                  planname
                  uploadsize
                  usersize
                  price
                  limit
                  month
                  purchasedate
                  expirydate
                }
                allLicenceList
                tokenList
              }          
          }
        `;
        client.query({
            variables: {
                email: Encrypt(request.email),
                companyname: Encrypt(request.companyname),
            },
            query: query,
            fetchPolicy: 'no-cache'
        }).then(response => {
            dispatch(getUserDetailsSuccess(response.data.super_get_company_details))
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}

// confirm Company
export const confirmCompany = (client, request) => {
    return dispatch => {
        const mutation = gql`
        mutation confirm_company($email: String!, $companyname: String!) {
            confirm_company(email: $email, companyname: $companyname) {
                     error
              success
              message
            }
          }          
        `;
        client.mutate({
            mutation: mutation,
            variables: {
                email: Encrypt(request.email),
                companyname: Encrypt(request.companyname),
            }
        }).then(response => {
            dispatch(actions.getSuperAdminDashboardData(client, []))
            dispatch(actions.getUserRequest(client, []))
            dispatch(notificationSuccess(requestisapproved))
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}

// bloc kUser Account
export const blockUserAccount = (client, request) => {
    return dispatch => {
        const mutation = gql`
        mutation block_company($account_status: String!, $companyname: String!) {
            block_company(account_status: $account_status, companyname: $companyname) {
              error
              success
              message
            }
          }
        `;
        client.mutate({
            mutation: mutation,
            variables: {
                account_status: Encrypt(request.account_status),
                companyname: Encrypt(request.companyname)
            }
        }).then(response => {
            let req = {
                status: "",
                confirm: true,
            }
            dispatch(actions.getUserRequest(client, req))
            dispatch(notificationSuccess(request.account_status ? CmpisUnblocked : Cmpisblocked));
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}

// resend Url
export const resendUrl = (client, request) => {
    return dispatch => {
        const mutation = gql`
        mutation resend_email($email: String!, $content: String!) {
            resend_email(email: $email, content: $content) {
              error
              success
              message
            }
          }
        `;
        client.mutate({
            mutation: mutation,
            variables: {
                email: Encrypt(request.email),
                content: Encrypt(request.content)
            }
        }).then(response => {
            dispatch(actions.getSuperAdminDashboardData(client, []))
            dispatch(notificationSuccess(linkhassentuseremail));
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}

// get Company Request Success
const getCompanyRequestSuccess = (data) => {
    return {
        type: actionTypes.GET_USER_RESEND_REQUEST,
        state: data
    }
}

// get Company Request On Id
export const getCompanyRequestOnId = (client, request) => {
    return dispatch => {
        const query = gql`       
          query get_company_details_update($companyid: String!) {
            get_company_details_update (companyid: $companyid) {
              _id
              name
              email
              company_id
              company_name
              company_address
              registration_country
              contact_number
              status
              approve_reject_by
              approve_reject_datetime
              company_strength
              confirm_account
              created_at
              updated_at
              attachments
              account_status
              display_name
            }
          }
        `;
        client.query({
            variables: {
                companyid: Encrypt(request.companyid),
            },
            query: query,
            fetchPolicy: 'no-cache'
        }).then(response => {
            dispatch(getCompanyRequestSuccess(response.data.get_company_details_update))
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}

// get Users With Plan List Success
export const getUsersWithPlanListSuccess = (data) => {
    return {
        type: actionTypes.GET_USERS_WITH_PLAN_LIST,
        state: data
    }
}

// get Users With Plan List
export const getUsersWithPlanList = (client, request) => {
    return (dispatch) => {
        const query = gql`
            {
                get_userwise_plan_list {
                    _id
                    name
                    email
                    userid 
                    company_id 
                    company_name 
                    display_name  
                    company_address
                    registration_country
                    contact_number
                    status
                    approve_reject_by
                    role_id
                    role_name
                    company_strength
                    system_flag
                    plan {
                        _id
                        planid
                        planname
                        uploadsize
                        usersize
                        price
                        purchasedate
                        expirydate
                        limit
                    }
                }
            }
        `;
        client
            .query({
                query: query,
                fetchPolicy: "no-cache",
            })
            .then((response) => {
                dispatch(getUsersWithPlanListSuccess(response.data.get_userwise_plan_list));
            })
            .catch((error) => {
                dispatch(notificationFail(error));
            });
    };
}

// get Company Wise Plan History Success
export const getCompanyWisePlanHistorySuccess = (data) => {
    return {
        type: actionTypes.GET_COMPANY_WISE_PLAN_HISTORY,
        state: data
    }
}

// get user signup request list
export const getCompanyWisePlanHistory = (client, request) => {
    return dispatch => {
        const query = gql`
            query companywise_plan_history_list($companyname: String!) {
                companywise_plan_history_list (
                    companyname: $companyname
                ) { 
                    _id
                    planid
                    planname
                    companyname
                    displayname
                    requesteddate
                    status
                }
              }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                companyname: Encrypt(request.companyname)
            }
        }).then(response => {
            dispatch(getCompanyWisePlanHistorySuccess(response.data.companywise_plan_history_list))
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}

// get Company Wise Licence History Success
export const getCompanyWiseLicenceHistorySuccess = (data) => {
    return {
        type: actionTypes.GET_COMPANY_WISE_LICENCE_HISTORY,
        state: data
    }
}

// get user signup request list
export const getCompanyWiseLicenceHistory = (client, request) => {
    return dispatch => {
        const query = gql`
            query companywise_license_history_list($companyname: String!) {
                companywise_license_history_list (
                    companyname: $companyname
                ) { 
                    _id
                    licenceid
                    licencename
                    price
                    companyname
                    displayname
                    requestid
                    requesteddate
                    count
                    approve
                    renew
                    created_at
                    updated_at
                }
              }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                companyname: Encrypt(request.companyname)
            }
        }).then(response => {
            dispatch(getCompanyWiseLicenceHistorySuccess(response.data.companywise_license_history_list))
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}
