import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane, Button, ButtonToolbar, Row, ModalHeader, ModalBody, ModalFooter, Spinner, Modal,
} from 'reactstrap';
import classnames from 'classnames';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import MUIDataTable from "mui-datatables";
import * as actions from "../../../../Store/actions/index";
import { withTranslation } from 'react-i18next';
import Modals from './modals';
import { Icon, Tooltip, Radio } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import __ from 'underscore';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from '@material-ui/core/InputAdornment';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import Select from 'react-select';
import ModalStandardDisposalOrder from '../components/disposal/modals/standardDisposalOrder';
import ActiveDisposalCategory from '../components/disposal/activeDisposalCategory';
import ModalDisposalThreshold from '../components/disposal/modals/disposalThreshold';

const ALL_DISPOSALLIST = 'All';
const DEFAULT_DISPOSALLIST = 'Default';

let tlang  = ChangeAppLanguageFront.translateLanguage;

const reorder = (list, startIndex, endIndex) => {

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const sort_text = tlang('sort_text')?tlang('sort_text'):'Sort';
const rows_per_page_text = tlang('rows_per_page_text')?tlang('rows_per_page_text'):'Rows per page:';
const no_match_record_found = tlang('no_match_record_found')?tlang('no_match_record_found'):'Sorry, no matching records found';
const search_text = tlang('search_text')?tlang('search_text'):'Search';
const material_list_sr_no = tlang('material_list_sr_no')?tlang('material_list_sr_no'):'Sr.No';
const test_masters_name = tlang('test_masters_name')?tlang('test_masters_name'):'Name';
const user_request_actions = tlang('user_request_actions')?tlang('user_request_actions'):'Actions';
const add_threshold_value = tlang('add_threshold_value')?tlang('add_threshold_value'):'Add Threshold Value';
const add_new_test = tlang('add_new_test')?tlang('add_new_test'):'Add New Test';
const tooltip_add = tlang('tooltip_add')?tlang('tooltip_add'):'Add';   
const client_disposal = tlang('client_disposal')?tlang('client_disposal'):'Disposal';   
const client_disposal_standard = tlang('client_disposal_standard')?tlang('client_disposal_standard'):'Standard';   
const client_disposal_copy = tlang('client_disposal_copy')?tlang('client_disposal_copy'):'Copy Disposal';   
const threshold_value_btn = tlang('threshold_value_btn')?tlang('threshold_value_btn'):'Threshold Value';
const tooltip_view = tlang('tooltip_view')?tlang('tooltip_view'):'View';
const tooltip_delete = tlang('tooltip_delete')?tlang('tooltip_delete'):'Delete';
const tooltip_edit = tlang('tooltip_edit')?tlang('tooltip_edit'):'Edit';
const test_family_txt = tlang('test_family_txt')?tlang('test_family_txt'):'Test Family';
const desposal_list = tlang('desposal_list')?tlang('desposal_list'):'Disposal List';
const client_master_disposal = tlang('client_master_disposal') ? tlang('client_master_disposal') : 'Master Disposal';
const client_company_disposal = tlang('client_company_disposal') ? tlang('client_company_disposal') : 'Company Disposal';
const client_project_disposal = tlang('client_project_disposal') ? tlang('client_project_disposal') : 'Project Disposal';
const client_account_disposal = tlang('client_account_disposal') ? tlang('client_account_disposal') : 'Account Disposal';
const client_probing_please_fill_all_required_fileds = tlang('client_probing_please_fill_all_required_fileds') ? tlang('client_probing_please_fill_all_required_fileds') : 'Please fill all required fields';
const set_standard_order = tlang('set_standard_order')?tlang('set_standard_order'):'Set Standard Disposal Order';
const set_standard_list = tlang('set_standard_list') || 'Set Standard Disposal List';
const common_btn_save = tlang('common_btn_save') ? tlang('common_btn_save') : 'Save';
const client_active_disposal_cat = tlang('client_active_disposal_cat') ? tlang('client_active_disposal_cat') : 'Active Disposal Category';

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontSize: 13,
  },
palette: {
  primary: {
    light: '#E64823',
    main: '#E64823',
    dark: '#E64823',
    contrastText: '#fff',
  },
},
overrides: {
  MuiPaper: {
      elevation4: {
        boxShadow: "none",
      },
      root: {
        padding: "0px 20px 20px 20px"
      }
  },
  MuiTableHead: {
       
  },
  MUIDataTableFilterList:{
    root: { 
       marginBottom:"10px"
      }
  },
  MuiTableCell: {
       
      head: {
        color: "#646777",
        fontWeight: "700",
        textAlign: 'left',
        backgroundColor: "#fafbfe !important",
        borderBottom: "1px solid #c6c6c6",
        borderTop: "1px solid #c6c6c6",
      }
  },
}
});

class manageDisposal extends PureComponent {
  static propTypes = {
    i18n: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired,
    t: PropTypes.func.isRequired
  };
  constructor() {
    super();
    this.state = {
      error: [],      
      desposalDeleteModal: false,
      thresholdValueModal: false,
      testRuleModal: false,
      importJson: [], 
      desposalAddEditModal: false,
      toggleDesposalViewModal: false,
      testType: {
        name: "",
        _id: "",
        error: [],
        children: [
          {
            name: "",
            id: this.randomNumber(),
            description: [],
            short_description: [],
            long_description: [],
            tags: []
          }
        ]
      },
      testTypeList: [],
      desposal: {
        _id: "",
        name: "",
        value: "",
        start: "",
        end: "",
        percentage: "",
        error: [],
        standard: false,
        country: "",
        state: "",
        address: "",
        color: "",
        test_list: [],
        type: "",
        userId: "",
        projectId: "",
      },
      desposalList: [],
      test: {
        _id: "",
        name: "",
        threshold: "50",
        desposal: "5e6f1d59483b284014e3d39c",
        type: "",
        error: []
      },
      thresholdData: [],
      thresholdValue: [],
      radioValue: "radio1",
      radio1: {
        lg: "less"
      },
      radio2: {
        lg: "less",
        per: "",
        val: "",
        finalVal: ""
      },
      radio3: {
        lg: "less",
        sum: "",
        multiTest: []
      },
      childArray: [],
      testRule: {
        desposalArray: "",
        id: "",
        desposal_id: "",
        desposal_name: "",
        test_id: "",
        test_name: "",
        child_id: "",
        child_name: "",
        children: "",
        value: "",
        option_id: "",
        nolimit_finalvalue: false,
        formula:
        {
          condition: "less",
          test: [],
          percentage: 0,
          nolimit: false
        }
      },
      testRuleList: [],
      searchTestFamilyTexts: "",
      testFamilyList: [],      
      copyDisposal:false,
      getListLoading:true,
      getDetailsLoading:false,
      activeTab: '1',
      thresholdValuesToSave:[],
      warning: false,
      notification: {
        success: true,
        message: null
      },
      deleteDesposalName: "",
      selFamilyBfrEdit: [],
      isCopyDispo: false,
      copyDisposalId: "",
      disposalCateogy : {
        "1" : "master",
        "2" : "company",
        "3" : "project",
        "4" : "account",
      },
      standardDisposalArr: [],
      standardDisposalModal: false,
      activeDisposalCategory: "",
      saveBtnLoader: false,
      editedDisposalName: '',
      standardDisposalList: {}, //For Setting selected disposal categorywise disposal list
      activeDisposalList: '', 
      deleteRulesArr: [],
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    /* load init actions */
    this.props.getTestType(this.props.apolloClient.masters, [])

    let reqParam = {
      company_name: this.props.authReducer.userData.company_name,
    }
    this.props.getDesposal(this.props.apolloClient.masters, reqParam)
  }

  componentWillReceiveProps(props) {
     /* receive response data */
    if (props.masterReducer && props.masterReducer.testTypeList && props.masterReducer.desposalList) {
      let thresholdValue = []
      props.masterReducer.testTypeList.map((value1, index) => {
        let desposal = [];
        if (value1.desposal && value1.desposal.length > 0) {
          value1.children.map(val2 => {
            let tempobj = [];
            let tempChild = value1.desposal.filter(val => val.testId == val2.id)[0];
            if (tempChild) {
              props.masterReducer.desposalList.map((row, inx) => {
                let tempDes = tempChild.desposal.filter(val => val.desposal == row._id)[0]
                if (tempDes) {
                  tempobj.push(tempDes);
                } else {
                  tempobj.push({ desposal: row._id, value: "" });
                }
              })
              desposal.push({ testId: val2.id, desposal: tempobj })
            } else {
              let tempobj = [];
              props.masterReducer.desposalList.map((row, inx) => {
                tempobj.push({ desposal: row._id, value: "" });
              })
              desposal.push({
                testId: val2.id, desposal: tempobj
              })
            }
          })
        } else {
          value1.children.map(val2 => {
            let tempobj = [];
            props.masterReducer.desposalList.map((row, inx) => {
              tempobj.push({ desposal: row._id, value: "" });
            })
            desposal.push({ testId: val2.id, desposal: tempobj })
          })
        }
        thresholdValue.push({ typeId: value1._id, desposal: desposal })
      })

      this.setState({
        testFamilyList: props.masterReducer.testTypeList,
        thresholdData: props.masterReducer.testTypeList,
        thresholdValue
      })
    }

    if (props.masterReducer && props.masterReducer.standarddesposalList) {

      if(props.masterReducer.standarddesposalList.length > 0){

        let activeCat = this.state.disposalCateogy[this.state.activeTab];

        let standarddesposalList = props.masterReducer.standarddesposalList.filter((l) => l.standard == true);
        let allDisposalList = props.masterReducer.standarddesposalList;

        if(activeCat == "company"){
          let company_name = this.props.authReducer.userData.company_name
          standarddesposalList =  standarddesposalList.filter(d=>d.company_name != "" && d.company_name == company_name && d.type == "company")
          allDisposalList =  allDisposalList.filter(d=>d.company_name != "" && d.company_name == company_name && d.type == "company")
        }

        if(activeCat == "project"){
          let projectId = this.props.authReducer.userData.projectid;
          standarddesposalList = standarddesposalList.filter(d=>typeof d.type != "undefined" && d.type != "" && d.type == "project" && d.projectId == projectId)
          allDisposalList = allDisposalList.filter(d=>typeof d.type != "undefined" && d.type != "" && d.type == "project" && d.projectId == projectId)
        }

        if(activeCat == "account"){
          let userid = this.props.authReducer.userData.userid;
          standarddesposalList = standarddesposalList.filter(d=>typeof d.type != "undefined" && d.type != "" && d.type == "account" && d.userId == userid)
          allDisposalList = allDisposalList.filter(d=>typeof d.type != "undefined" && d.type != "" && d.type == "account" && d.userId == userid)
        }

          //standarddesposalList = sortBy(props.masterReducer.standarddesposalList, ['index_no']);
          standarddesposalList = sortBy(standarddesposalList, ['index_no']);

          allDisposalList = sortBy(allDisposalList, ['index_no']);

          let arrayList = []
          standarddesposalList.map((row, inx) => {
                  arrayList.push(
                      row
                  )
          });

          //My code for getting disposal_data
          let standardDisposalList = {...this.state.standardDisposalList};
          let disposal_data = {};
          let activeCatDisposalData = this.props.authReducer.userData.disposal_data.filter((l) => l.disposalType == activeCat);

          if(activeCatDisposalData.length > 0){
            //disposal_data = JSON.parse(activeCatDisposalData[0].disposalData);
            Object.assign(disposal_data, {[ALL_DISPOSALLIST]: allDisposalList}, JSON.parse(activeCatDisposalData[0].disposalData));
            standardDisposalList = disposal_data;

            //Push Any Newly created Disposal to the Default Category of standardDisposalList
            let presentInArrayList = arrayList.filter(f => !standardDisposalList[DEFAULT_DISPOSALLIST].some(item => item._id == f._id));
            if(presentInArrayList.length > 0){
              standardDisposalList[DEFAULT_DISPOSALLIST].push(...presentInArrayList);
            }
          }else{
            standardDisposalList[ALL_DISPOSALLIST] = allDisposalList;
            standardDisposalList[DEFAULT_DISPOSALLIST] = arrayList
          }

          
          
          this.setState({
              standardDisposalArr: arrayList,
              standardDisposalList: standardDisposalList,
          }, () => this.forceUpdate())

      }
      
    }

    // will recieve notifications data
    if (props.notification) {
      this.setState({
        loading: false,
        disableButtons: [],
        saveBtnLoader: false,
        //thresholdValuesToSave:[]
      })
      if (props.notification.success) {
        this.setState({
          testTypeDeleteModal: false,
          desposalDeleteModal: false,
          testTypeAddEditModal: false,
          desposalAddEditModal: false,
          testAddEditModal: false,
          testDeleteModal: false,
          thresholdModal: false,
          testRuleModal: false,
          standardDisposalModal: false,
          editedDisposalName: false,
          //thresholdValueModal: false
        })
      }
    }

    /* receive test Type Details */
    if (props.masterReducer.testTypeDetails) {
      let testType = props.masterReducer.testTypeDetails;
      this.setState({
        testType: testType
      }, () => this.forceUpdate())
    }

    /* receive desposal Details */
    if (props.masterReducer.desposalDetails) {
      if (props.masterReducer.desposalDetails._id) {
        if(this.state.copyDisposal){
          props.masterReducer.desposalDetails._id = ""
        }

        let desposal = props.masterReducer.desposalDetails;

        //My code to make disposal type blank while copy disposal
        if(this.state.isCopyDispo == true){
          desposal.type = "";
        }

        let testRule = this.state.testRule;
        testRule['desposalArray'] = desposal

        //store all selected Testfamily id at the time of Edit
        let allSelTestfamilyId = [];
        desposal.test_list.map((test, i) => {
            allSelTestfamilyId.push(test._id);
        })

        this.setState({
          copyDisposal:false,
          getDetailsLoading:false,
          testRule,
          desposal,
          selFamilyBfrEdit: allSelTestfamilyId
        }, () => this.forceUpdate())
      }
    }

    /* receive test Details */
    if (props.masterReducer.testDetails) {
      let test = props.masterReducer.testDetails;
      this.setState({
        test: test
      }, () => this.forceUpdate())
    }

    /* receive test Rule List */
    if (props.masterReducer && props.masterReducer.testRuleList) {
      this.setState({
        testRuleList: props.masterReducer.testRuleList
      }, () => this.forceUpdate())
    }
    
    /* receive search Test Family list */
    if (props.masterReducer.searchTestFamily) {
      this.setState({        
        testFamilyList: props.masterReducer.searchTestFamily
      }, () => this.forceUpdate())
    }
    if(props.masterReducer.desposalList){
      
      if(this.state.activeDisposalCategory == ""){
        let default_disposal_type = typeof this.props.authReducer.userData.default_disposal_type != "undefined" ? this.props.authReducer.userData.default_disposal_type : 'master';
        let default_disposal_list = typeof this.props.authReducer.userData.default_disposal_list != "undefined" ? this.props.authReducer.userData.default_disposal_list : '';
        this.setState({activeDisposalCategory: default_disposal_type, activeDisposalList: default_disposal_list})
      }
      
      this.setState({getListLoading:false})
    } 
  }

  saveActiveDisposalCategory = (data) => {

    let activeDisposalCategory = data.activeDisposalCategory;
    let activeDisposalList = data.activeDisposalList;

    if(activeDisposalCategory != ""){
      
      let request = {
        default_disposal_type: activeDisposalCategory,
        userid: this.props.authReducer.userData.userid,
        projectid: this.props.authReducer.userData.projectid,
        default_disposal_list: activeDisposalCategory == 'master' ? '' : activeDisposalList,
      }

      this.props.setUserDefaultDisposalCategory(this.props.apolloClient.client, request)
      this.setState({
        saveBtnLoader: true,
      })

    }
  
  }

  changeActiveDisposalCategory = (e) => {

    // this.state.errorLineSelection.map((data,index) => {
    //     if(data == 'lineType' && e.target.value != ""){
    //         this.state.errorLineSelection.splice(index, 1);
    //         this.forceUpdate();
    //     }
    // })

    this.setState({
      activeDisposalCategory:e.target.value,
      activeDisposalList: ''
    });        
  }

  saveStandardDesposalOrder = () => {

    let arrayList = [];

    this.state.standardDisposalArr.map((l,i) => {
        let object = {
            desposal_id: l._id,
            index_no: i+1
        }
        arrayList.push(object)
    })

    let requestParams = {
        desposal_data: JSON.stringify(arrayList),
        company_name: this.props.authReducer.userData.company_name,
    }
    this.props.configureDesposalOrder(this.props.apolloClient.masters, requestParams);
 
    this.setState({
        loading: true,
    });

  }

  toggleStandardDesposalOrder = () => {

    let request = {
      //standard_all: true
      standard_all: false
    }
    this.props.getStandardDesposalFront(this.props.apolloClient.masters, request)

    this.setState({
      standardDisposalList: {},
      loading: true
    },() => {
      setTimeout(function(){
        this.setState({
          standardDisposalModal: !this.state.standardDisposalModal
        })
      }.bind(this), 1000);
    })
   
  }

  saveStandardDesposalOrderNew = (data, updatedDefaultDisposalListName, activeCatName) => {
    
    for(let catName in data){
      let allItems = data[catName];
      allItems && allItems.map((l,i) => {
        l.index_no = i + 1;
      })
    }

    let request = {
      userId : this.props.authReducer.userData.userid,
      projectId : this.props.authReducer.userData.projectid,
      companyName : this.props.authReducer.userData.company_name,
      email: this.props.authReducer.userData.email, //I pass this for get_user_data api
      disposalType : this.state.disposalCateogy[this.state.activeTab],
      disposalData : JSON.stringify(data),
      updatedDefaultDisposalListName: updatedDefaultDisposalListName,
    }

    this.setState({
      loading: true,
      standardDisposalList: {}
    });

    this.props.setCategoryWiseStandardDisposal(this.props.apolloClient.masters, request);

    //For update disposal order at the master 

    let arrayList = [];

    data[DEFAULT_DISPOSALLIST].map((l,i) => {
        let object = {
            desposal_id: l._id,
            index_no: i+1
        }
        arrayList.push(object)
    })

    let requestParams = {
        desposal_data: JSON.stringify(arrayList),
        company_name: this.props.authReducer.userData.company_name,
    }
    this.props.configureDesposalOrder(this.props.apolloClient.masters, requestParams);

    //For update active disposal catgory
    let default_disposal_type = this.props.authReducer.userData?.default_disposal_type || 'master';
		let default_disposal_list = this.props.authReducer.userData?.default_disposal_list || '';

    if((activeCatName && (activeCatName !== default_disposal_list)) || (activeCatName && (default_disposal_type !== this.state.disposalCateogy[this.state.activeTab]))){
      const categoryInfo = {
          activeDisposalCategory: this.state.disposalCateogy[this.state.activeTab],
          activeDisposalList: activeCatName
      }
      this.saveActiveDisposalCategory(categoryInfo);
    }
  }

  onDragStart(result) {
  }

  onDragEnd(result) {
      
      //console.log("result.destination = ", result.destination);

      if (!result.destination) {
          return;
      }

      const arrayList = reorder(
          this.state.standardDisposalArr,
          result.source.index,
          result.destination.index
      );

      this.setState({
          standardDisposalArr: arrayList,
      }, () => this.forceUpdate())

  }


  /* generate random Number */
  randomNumber = () => {
    return Math.floor(1000 + Math.random() * 9000);
  }

  /* handle Change Radio */
  handleChangeRadio = (name) => event => {
    event.persist();
    let val = event.target.value
    let radio1 = this.state.radio1
    let radio2 = this.state.radio2
    let radio3 = this.state.radio3

    if (val === "radio1") {
        radio2 = {
            lg: "less",
            per: "",
            val: "",
            finalVal: ""
        }
        radio3 = {
            lg: "less",
            sum: "",
            multiTest: ""
        }
    } else if (val === "radio2") {
        radio1 = {
            lg: "less"
        }
        radio3 = {
            lg: "less",
            sum: "",
            multiTest: ""
        }
    } else if (val === "radio3") {
        radio1 = {
            lg: "less"
        }
        radio2 = {
            lg: "less",
            per: "",
            val: "",
            finalVal: ""
        }
    }
    let testRule = this.state.testRule
    testRule.formula = {
        condition: "less",
        test: [],
        percentage: 0,
        nolimit: false
    }

    //My code to put the condition for No limit rule
    if(val === "radio3"){
        testRule.formula = {
            condition: "",
            test: [],
            percentage: 0,
            nolimit: true
        }
    }


    let radioValue = this.state.radioValue
    radioValue = val
    this.setState({
        radioValue,
        radio1,
        radio2,
        radio3,
        testRule
    }, () => this.forceUpdate())        
  }

  /* handle Change Radio Value1 */
  handleChangeRadioValue1 = (key, name) => event => {
    event.persist();

    let val = event.target.value
    let radio1 = this.state.radio1
    radio1[name] = val
    this.setState({
      radio1
    }, () => this.forceUpdate())
  }

  /* handle Change Radio Value2 */
  handleChangeRadioValue2 = (key, name) => event => {
    event.persist();
    let radio2 = this.state.radio2
    let val = event.target.value
    if (name === "val") {
      let threshold = this.state.thresholdValue.filter(test => test.typeId == this.state.typeId)[0];
      if (threshold) {
        threshold = threshold.desposal.filter(desp => desp.testId == val)[0];
        threshold = threshold.desposal.filter(desp => desp.desposal == this.state.des_id)[0];
        let newValue = threshold.value
        let setValue = 0
        if (newValue) {
          setValue = isNaN(newValue) ? 0 : parseFloat(newValue);
        }
        radio2[name] = val
        radio2['thresholdValue'] = setValue
        radio2['finalVal'] = ""
        radio2['per'] = ""
      }
    } else {
      radio2[name] = val
    }
    if (name === "per") {
      let testVal = radio2.thresholdValue ? radio2.thresholdValue : 0
      let muliplay = testVal * val
      let finalVal = muliplay / 100
      radio2['finalVal'] = finalVal
    }
    this.setState({
      radio2
    }, () => this.forceUpdate())
  }

  /* handle Change Radio Value3 */
  handleChangeRadioValue3 = (key, name) => event => {
    let radio3 = this.state.radio3
    if (name === "lg") {
      let val = event.target.value
      radio3[name] = val
    } else {
      radio3[name] = event
      let threshold = this.state.thresholdValue.filter(test => test.typeId == this.state.typeId)[0];
      if (threshold) {
        let finalArray = []
        event.map((val1, index1) => {
          threshold.desposal.map((val2, index2) => {
            if (val1.value == val2.testId) {
              val2.desposal.map((val3, index3) => {
                if (val3.desposal === this.state.des_id) {
                  let newValue = 0
                  if (val3.value) {
                    newValue = isNaN(val3.value) ? 0 : parseFloat(val3.value);
                  }
                  finalArray.push(newValue)
                }
              })
            }
          })
        })
        let arraySum = finalArray.reduce(function (a, b) {
          return a + b;
        }, 0);
        radio3['sum'] = arraySum
      }
    }
    this.setState({
      radio3
    }, () => this.forceUpdate())
  }

  /* handle Change address */
  handleChangeaddress = (name) => value => {
    let desposal = this.state.desposal
    desposal[name] = value

    if(typeof desposal.error != "undefined" &&  desposal.error.length > 0){
      desposal.error.map((data,index) => {
        if(name == 'address' && data == 'address'){
          desposal.error.splice(index, 1);
        }
        if(name == 'address' && data == 'country'){
          desposal.error.splice(index, 1);
        }
        if(name == 'address' && data == 'state'){
          desposal.error.splice(index, 1);
        }
      })
    }


    this.setState({
      desposal
    }, () => this.forceUpdate())
  }

  /* handle Select */
  handleSelect = address => {
    let desposal = this.state.desposal
    geocodeByAddress(address)
      .then(results => {
        var place = results[0];
        desposal['address'] = place.formatted_address
        this.setState({
          desposal
        }, () => this.forceUpdate())
        for (var i = 0; i < place.address_components.length; i++) {
          var addressType = place.address_components[i].types[0];
          if (addressType) {
            if (addressType == "country") {
              var country = place.address_components[i].long_name;
              desposal['country'] = country
              this.setState({
                desposal
              }, () => this.forceUpdate())
            }
            if (addressType == "administrative_area_level_1") {
              var state = place.address_components[i].long_name;
              desposal['state'] = state
              this.setState({
                desposal
              }, () => this.forceUpdate())
            }
          }
        }
      })
      .catch(error => console.error('Error', error));
  };
 
  /* toggle Desposal Add-Edit Modal */
  toggleDesposalAddEditModal = (value) => {
    let desposal = {
      name: "",
      value: "",
      start: "",
      end: "",
      percentage: "",
      error: [],
      _id: "",
      standard: false,
      country: "",
      state: "",
      address: "",
      color: "",
      test_list: [],
      type: "",
      userId: "",
      projectId: "",
    }
    this.props.masterReducer.desposalDetails = []
    if (value) {
      this.setState({
        searchTestFamilyTexts: "",
        testFamilyList: this.state.thresholdData,
        desposalAddEditModal: !this.state.desposalAddEditModal,
        copyDisposal:false,
        getDetailsLoading:true,
        desposal:value,
        editedDisposalName: value.name
      }, () => this.forceUpdate())
      let requestParams = {
        id: value._id
      }
      /* get disaposal details */
      this.props.getDesposalDetails(this.props.apolloClient.masters, requestParams);
    } else {
      this.setState({
        isCopyDispo: false,
        copyDisposalId: "",
        searchTestFamilyTexts: "",
        testFamilyList: this.state.thresholdData,
        desposalAddEditModal: !this.state.desposalAddEditModal,
        copyDisposal:false,
        getDetailsLoading:false,
        desposal
      }, () => this.forceUpdate())
    }
  }

  /* toggle Desposal View Modal */
  toggleDesposalViewModal = (value) => {
    let desposal = {
      name: "",
      value: "",
      start: "",
      end: "",
      percentage: "",
      error: [],
      _id: "",
      standard: false,
      country: "",
      state: "",
      address: "",
      color: "",
      test_list: [],
      type: "",
      userId: "",
      projectId: "",
    }
    this.props.masterReducer.desposalDetails = []
    if (value) {
      this.setState({
        searchTestFamilyTexts: "",
        testFamilyList: this.state.thresholdData,
        desposalViewModal: !this.state.desposalViewModal,
        getDetailsLoading:true,
        desposal,
      }, () => this.forceUpdate())
      let requestParams = {
        id: value._id
      }
      /* get Test Rule List */
      this.props.getTestRuleList(this.props.apolloClient.masters, requestParams);
      /* get Desposal Details */
      this.props.getDesposalDetails(this.props.apolloClient.masters, requestParams);
    } else {
      this.setState({
        searchTestFamilyTexts: "",
        testFamilyList: this.state.thresholdData,
        desposalViewModal: !this.state.desposalViewModal,
        getDetailsLoading:false,
        desposal
      }, () => this.forceUpdate())
    }
  }
 
  /* toggle Threshold Value Modal */
  toggleThresholdValueModal = (value) => {
    let desposal = {
      name: "",
      value: "",
      start: "",
      end: "",
      percentage: "",
      error: [],
      _id: "",
      standard: false,
      country: "",
      state: "",
      address: "",
      color: "",
      test_list: [],
      type: "",
      userId: "",
      projectId: "",
    }
    if (value) {

      //For removing the cached threshold values stored in this.state
      let header = Object.keys(this.state);
      let constantStateKeys = header.filter(f => f.toLowerCase().startsWith('name_'));
      if(constantStateKeys.length > 0){
          constantStateKeys.map((l,i) => {
              this.state[l] = "";
          })
      }


      let testRule = this.state.testRule
      testRule['desposalArray'] = value
      this.setState({
        thresholdValueModal: !this.state.thresholdValueModal,
        desposal,
        testRule,
        thresholdValuesToSave:[],
        deleteRulesArr: [],
      }, () => this.forceUpdate())
      let requestParams = {
        id: value._id
      }
      /* get Disposal Details */
      this.props.getDesposalDetails(this.props.apolloClient.masters, requestParams);
      /* get Test Rule List */
      this.props.getTestRuleList(this.props.apolloClient.masters, requestParams);
    } else {

      //For removing the cached threshold values stored in this.state
      let header = Object.keys(this.state);
      let constantStateKeys = header.filter(f => f.toLowerCase().startsWith('name_'));
      if(constantStateKeys.length > 0){
          constantStateKeys.map((l,i) => {
              this.state[l] = "";
          })
      }

      this.setState({
        thresholdValueModal: !this.state.thresholdValueModal,
        desposal,
        thresholdValuesToSave:[],
        deleteRulesArr: [],
      }, () => this.forceUpdate())
    }
  }

  /* render Threshold Header */
  renderThresholdHeader() {
    let desposal = this.props.masterReducer.desposalList
    let test = this.props.masterReducer.testList;
    let jsx = [];
    jsx.push(<th key={0}></th>)
    desposal.map((val, index) => {
      jsx.push(<th key={index + 1}>{val.name}</th>)
    })
    return jsx;
  }

  /* render Threshold Value Header */
  renderThresholdValueHeader() {
    let desposal = [{
      _id: this.state.desposal._id,
      name: this.state.desposal.name
    }]
    let jsx = [];
    jsx.push(<th key={0}></th>)
    desposal.map((val, index) => {
      jsx.push(<th key={index + 1}>{val.name}</th>)
    })
    return jsx;
  }

  renderNote() {
    let desposal = [{
        _id: this.state.desposal._id,
        name: this.state.desposal.name
    }]
    let jsx = [];
    desposal.map((val, index) => {
        jsx.push(<th colSpan="2" key={index + 1}>Note : When rule has not been set for a pollutant yet and we typed value in the blank pollutant input then system is by default consider Rule -1.</th>)
    })
    return jsx;
  }

  /* handle Change Threshold */
  handleChangeThreshold = (typeId, testId, des_id) => (event) => {
    let thresholdValue = this.state.thresholdValue.map(type => {
      if (type.typeId == typeId) {
        type.desposal.map(test => {
          if (test.testId == testId) {
            test.desposal.map(desp => {
              if (desp.desposal == des_id) {
                desp.value = event.target.value;
              }
              return desp;
            });
          }
          return test;
        });
      }
      return type;
    });
    this.setState({
      thresholdValue
    })
  }

  /* render Threshold Input */
  renderThresholdInput = (child, value = undefined, typeId, des_id, testId) => {
    let threshold = this.state.thresholdValue.filter(test => test.typeId == typeId)[0];
    if (threshold) {
      threshold = threshold.desposal.filter(desp => desp.testId == testId)[0];
      threshold = threshold.desposal.filter(desp => desp.desposal == des_id)[0];
      return (<>
        <TextField
          variant="outlined"
          name="name"
          onChange={this.handleChangeThreshold(typeId, testId, des_id)}
          value={threshold.value}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  labelPlacement="start"
                  onChange={this.toggleThresholdFormulaModal(child, typeId, testId, des_id, threshold.value)}
                />
              </InputAdornment>
            ),
          }}
        />
      </>)
    }
  }

  keyPress = (evt) => {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    //Not allowed the dash to enter
    if(charCode == "45"){
        evt.preventDefault();
    }
  }

  deleteRule = (child, typeId, testId, des_id) => {

    let testRuleList = this.state.testRuleList.filter(t=>t.child_id != testId);
    let inputName = "name_" + des_id + "_" + testId;
    
    this.state[inputName] = "";
    
    this.setState({
        [inputName]: "",
        testRuleList
    }, () => this.forceUpdate());
    
    
    let findrule = this.state.testRuleList.filter(t=>t.child_id == testId);
    if(findrule.length > 0){

        let deleteRulesArr = [...this.state.deleteRulesArr];
        deleteRulesArr.push(findrule[0]._id);

        this.setState({deleteRulesArr: deleteRulesArr});
    }

  }

  


  /* render Threshold Data */
  renderThresholdData = (child, value, id, testId) => {
    let desposal = this.props.masterReducer.desposalList
    let data = []
    data.push(<td style={{ minWidth: "80px", textAlign: "left" }} key={id}>{value}</td>)
    desposal.map((val, index) => {
      data.push(<td key={val._id}>{this.renderThresholdInput(child, value = "", id, val._id, testId)}</td>)
    })
    return data;
  }

  

  /* toggle Threshold Formula Modal */
  toggleThresholdFormulaModal = (child, typeId, testId, des_id) => (event) => {
    let childArray = []
    if (child.length > 0) {
      let newChildArray = child.filter((val, index) => val.id != testId)
      childArray = newChildArray
    }
    this.setState({
      childArray,
      des_id,
      testId,
      typeId,
      thresholdFormulaModal: !this.state.thresholdFormulaModal
    }, () => this.forceUpdate())
  }

  /* toggle Test Rule Modal */
  toggleTestRuleModal = (child, typeId, testId, des_id, event) => {

    let childArray = []
    // if (child.length > 0) {
    //     let newChildArray = child.filter((val, index) => val.id != testId)
    //     childArray = newChildArray
    // }

    //My code to store all child pollutant of all particular disposal parent pollutant in childArray
    if(this.state.desposal.test_list.length > 0){
        this.state.desposal.test_list.map((pollutant, pindex) => {
            if(pollutant.children.length > 0){
                    pollutant.children.map((children, cindex) => {
                        if(children.id != testId){
                            children.Test_Parent_Id = pollutant._id;
                            let mytempObj = {
                              id : children.id,
                              name : children.name,
                              Test_Parent_Id : children.Test_Parent_Id
                          }
                            childArray.push(mytempObj);
                        }
                })

            }
        })
    }


    let testRule = this.state.testRule
    let test_list = testRule.desposalArray ? testRule.desposalArray.test_list : []
    let testListArray = test_list.filter((val, index) => val._id != typeId)
    let formula = {
        condition: "less",
        test: [],
        percentage: 0,
        nolimit: false
    }
    let radioValue = "radio1"
    let ruleFound = false;
    if (this.state.testRuleList.length > 0) {
        this.state.testRuleList.map((rule, index) => {
            if (rule.desposal_id == des_id && rule.test_id == typeId && rule.child_id == testId) {
                ruleFound = rule;
            }
        })
    }


    if (ruleFound) {
        if (ruleFound.formula.condition == "") {
            ruleFound.formula.condition = "less";
        }
        let selectedChild = child.filter((val, index) => val.id == ruleFound.child_id);
        testRule.id = ruleFound._id
        testRule.child_id = ruleFound.child_id
        testRule.child_name = ruleFound.child_name
        testRule.children = selectedChild.length > 0 ? selectedChild[0] : ""
        testRule.test_id = ruleFound.test_id
        testRule.test_name = ruleFound.test_name
        testRule.desposal_id = ruleFound.desposal_id
        testRule.desposal_name = ruleFound.desposal_name
        testRule.option_id = ruleFound.option_id
        testRule.nolimit_finalvalue = ruleFound.nolimit_finalvalue
        testRule.formula = ruleFound.formula;
        let tempFormulaTest = []
        if (ruleFound.formula.test && ruleFound.formula.test.length) {
            childArray.map(val => {
                ruleFound.formula.test.filter(row => val.id == row.id).map(formula => {
                    tempFormulaTest.push(val)
                })
            })
        }
        testRule.formula.test = tempFormulaTest;
        testRule.value = ruleFound.value
        radioValue = "radio" + (ruleFound.option_id != "" ? ruleFound.option_id : 1);
    } else {
        let selectedChild = child.filter((val, index) => val.id == testId);
        let selectedTest = test_list.filter((val, index) => val._id == typeId);
        testRule.id = ""
        testRule.child_id = testId
        testRule.child_name = selectedChild.length > 0 ? selectedChild[0].name : ""
        testRule.children = selectedChild.length > 0 ? selectedChild[0] : ""
        testRule.test_id = typeId
        testRule.test_name = selectedTest.length > 0 ? selectedTest[0].name : ""
        testRule.desposal_id = des_id
        testRule.desposal_name = testRule.desposalArray ? testRule.desposalArray.name : ""
        testRule.option_id = 1
        testRule.formula = formula
        testRule.value = ""
        testRule.nolimit_finalvalue = false
    }
    this.setState({
        radioValue,
        childArray,
        testRule,
        des_id,
        testId,
        typeId,
        testRuleModal: !this.state.testRuleModal
    }, () => this.forceUpdate())
  }

  /* handle Change Test Rule Radio */
  handleChangeTestRuleRadio = (name) => event => {
    event.persist();
    let val = event.target.value
    let testRule = this.state.testRule
    let formula = {
      condition: "less",
      test: [],
      percentage: 0,
      nolimit: false
    }
    testRule.formula = formula
    let radioValue = this.state.radioValue
    radioValue = val
    this.setState({
      radioValue,
      testRule
    }, () => this.forceUpdate())
  }

  /* selected Child List */
  selectedChildList = (event) => {
    let selected = this.state.testRule.formula.test ? this.state.testRule.formula.test : [];
    let newArray = []
    if (selected.length > 0) {
      selected.map((val, inx) => {
        newArray.push(val.name)
      })
    }
    return newArray.length > 0 ? newArray.join(', ') : ""
  }

  handleChangeFinalValue = (key) => (event) => {
    const isChecked = event.target.checked;
    const testRule = {...this.state.testRule};
    testRule[key] = isChecked;
    this.setState({
        testRule: testRule
    })
  }

  /* handle Change Test Formula */
  handleChangeTestFormula1 = (key, name) => (event) => {
    // event.persist();
    let val = event.target.value
    let testRule = this.state.testRule
    if (key == "radio4" && name == "test") {
        let test = [{
            id: val.id,
            name: val.name,
            error: []
        }]
        testRule.formula[name] = test
    }else if (key == "radio4" && name == "percentage") {
        let check = isNaN(val);
        if(check == false){
            testRule.formula[name] = val
        }
    }
    else {
        testRule.formula[name] = val
    }

    this.setState({
        testRule: testRule
    }, function () {
        this.forceUpdate();
    })
  }

  handleChangeTestFormula1SrcInp = selectedChild => {

    let testRule = this.state.testRule
    let tempChild = []
    //For Multiple selection
    if (selectedChild && selectedChild.length) {
        selectedChild.map((test, index) => {
        let selectChild = this.state.childArray.filter((l => l.id == test.value))
        if (selectChild.length != 0) {
            tempChild.push(selectChild[0])
        }
      })
    }else{
        //For Single selection
        let selectChild = this.state.childArray.filter((l => l.id == selectedChild.value))
        if (selectChild.length != 0) {
            tempChild.push(selectChild[0])
        }
    }
    testRule.formula.test = tempChild

    this.setState({
        testRule
      }, () => this.forceUpdate());
    
  }

  /* toggle Threshold Formula Close Modal */
  toggleThresholdFormulaCloseModal = () => {
    let radioValue = "radio1"
    let radio1 = {
      lg: "less"
    }
    let radio2 = {
      lg: "less",
      per: "",
      val: "",
      finalVal: ""
    }
    let radio3 = {
      lg: "less",
      sum: "",
      multiTest: []
    }
    this.setState({
      radioValue,
      radio1,
      radio2,
      radio3,
      thresholdFormulaModal: !this.state.thresholdFormulaModal
    }, () => this.forceUpdate())
  }

  /* toggle Test Rule Close Modal */
  toggleTestRuleCloseModal = () => {

    //My code to remove the threshold value if rule is 3 or 4. also blank the value key from thresholdValuesToSave state
    if(this.state.testRule != ""){
        if(this.state.testRule.option_id == "3" || this.state.testRule.option_id == "4"){
            let testRuleList = this.state.testRuleList;
            testRuleList.map((rule, index2) => {
                if (rule.desposal_id == this.state.testRule.desposal_id && rule.test_id == this.state.testRule.test_id && parseInt(rule.child_id) == this.state.testRule.child_id) {
                    rule.value = "";
                }
            })
            this.setState({
                testRuleList
            }, () => this.forceUpdate());

            if(this.state.thresholdValuesToSave.length > 0){
                let checkExist = this.state.thresholdValuesToSave.filter(t=>t.desposal_id == this.state.testRule.desposal_id && t.test_id == this.state.testRule.test_id && t.child_id == this.state.testRule.child_id);

                if(checkExist.length > 0){
                    this.state.thresholdValuesToSave.map((tvalue, tindex) => {
                        if(tvalue.child_id == checkExist[0].child_id){
                            tvalue.value = "";
                        }
                    })
                }
            }
        }
    }

    let testRule = {
        desposalArray: this.state.testRule.desposalArray,
        id: "",
        desposal_id: "",
        desposal_name: "",
        test_id: "",
        test_name: "",
        child_id: "",
        child_name: "",
        children: "",
        value: "",
        option_id: "1",
        nolimit_finalvalue: false,
        formula:
        {
            condition: "less",
            test: [],
            percentage: 0,
            nolimit: false
        }
    }
    this.setState({
        testRule,
        testRuleModal: !this.state.testRuleModal
    }, () => this.forceUpdate())
  }

  /* selected Test Child List */
  selectedTestChildList = () => {
    let newArray = []
    return newArray ? newArray.join(', ') : ""
  }

  /* render Threshold */
  renderThreshold() {
    let test = this.props.masterReducer.testTypeList;
    let len = this.props.masterReducer.desposalList.length;
    let jsx = []
    test.map((value2, index2) => {
      jsx.push(<tr key={-1}><td colSpan={len + 1} key={-1} style={{ textAlign: "left", paddingTop: "15px" }}><h4 style={{ fontWeight: "bold" }}>{value2.name}</h4></td></tr>)
      value2.children.map(val2 => {
        jsx.push(
          <tr key={index2}>
            {this.renderThresholdData(value2.children, val2.name, value2._id, val2.id)}
          </tr>)
      })
    })
    return jsx;
  }

  /* render Threshold Value Input */
  renderThresholdValueInput = (child, value = undefined, typeId, des_id, testId) => {

    // let rule_option_id = 1;
    // let rule_option_name = "Threshold Value";

    let rule_option_id = "";
    let rule_option_name = "";
    let rule_nolimit_finalvalue = false;


    if (this.state.testRuleList.length > 0) {
        let updateState = {};
        this.state.testRuleList.map((rule, index2) => {
            if (rule.desposal_id == des_id && rule.test_id == typeId && parseInt(rule.child_id) == testId) {
                rule_option_id = rule.option_id;

                let fieldName = "name_" + des_id + "_" + testId;
                Object.assign(updateState, { [fieldName]: rule.value });

                if(rule?.nolimit_finalvalue) rule_nolimit_finalvalue = true;

            }
            // else{
            //     let checkexist = this.state.thresholdValuesToSave.filter(l=>l.desposal_id == des_id && l.test_id == typeId && parseInt(l.child_id) == testId);
            //     if(checkexist.length > 0){
            //         rule_option_id = checkexist[0].option_id;

            //         let fieldName = "name_" + des_id + "_" + testId;
            //         Object.assign(updateState, { [fieldName]: checkexist[0].value });
            //     }
            // }
        });
        this.setState(
            updateState
        );
    }

    if(rule_option_id == "1"){
        rule_option_name = "Threshold Value"
    }else if(rule_option_id == "2"){
        rule_option_name = "Multiple Pollutant"
    }else if(rule_option_id == "3"){
        rule_option_name = "No Limit" + (rule_nolimit_finalvalue ? ' *' : '')
    }else if(rule_option_id == "4"){
        rule_option_name = "Percentage"
    }


    return (<>
        <TextField
            key={testId}
            variant="outlined"
            disabled={rule_option_id == "3" || rule_option_id == "4" ? true : false}
            name={"name_" + des_id + "_" + testId}
            type="number"
            onChange={(event) => this.handleChangeRule(child, typeId, testId, des_id, event)}
            onKeyPress={(e) => this.keyPress(e)}
            value={this.state["name_" + des_id + "_" + testId]}
            style={{ marginRight: "5px" }}
            onBlur={(event)=>this.changedTestFamily(child, typeId, testId, des_id, event)}
        />

        {rule_option_id != "" && rule_option_name != "" &&
        <>
            <Button className={"system-btn m-0"} outline={true} color="success" size="sm" onClick={(event) => this.toggleTestRuleModal(child, typeId, testId, des_id, event)}>{"Edit Rule"}</Button> 

            <Button style={{left:"10px"}} outline={true} color={'success'} className={"system-btn clear-border"} onClick={() => this.deleteRule(child, typeId, testId, des_id)} >
                <Tooltip title={"Remove Rule"}>
                    <Icon>delete</Icon>
                </Tooltip>
            </Button>

            <div style={{float: "right", marginLeft: "15px", textAlign: "left"}}>
                <h5>{"Applied Rule - "} {rule_option_id} {'( '+rule_option_name+' ) '}</h5>
            </div> 
        </>
        }

        {rule_option_id == "" && rule_option_name == "" &&
        <>
            <Button className={"system-btn m-0"} outline={true} color="success" size="sm" onClick={(event) => this.toggleTestRuleModal(child, typeId, testId, des_id, event)}>{"Set Rule"}</Button> 
        </>
        }

    </>)
  }

  /* render Threshold Value Data */
  renderThresholdValueData = (child, value, id, testId) => {
    let desposal = [{
      _id: this.state.desposal._id,
      name: this.state.desposal.name
    }]
    let data = []
    data.push(<td style={{ minWidth: "220px", textAlign: "left" }} key={`${Math.random()}`}>{value}</td>)
    desposal.map((val, index) => {
      data.push(<td key={`${Math.random()}`}>{this.renderThresholdValueInput(child, value = "", id, val._id, testId)}</td>)
    })
    return data;
  }

  /* render Threshold Value */
  renderThresholdValue() {
 console.log("this.state.desposal ", this.state.desposal);
 console.log("this.state.testRuleList ", this.state.testRuleList);
    let test = this.state.desposal.test_list ? this.state.desposal.test_list : []
    let len = 0;
    let jsx = []
    test.map((value2, index2) => {
      jsx.push(<tr key={`${Math.random()}`}><td colSpan={len + 1} key={`${Math.random()}`} style={{ textAlign: "left", paddingTop: "15px", paddingBottom: "15px" }}><h4 style={{ fontWeight: "bold" }}>{value2.name}</h4></td></tr>)
      value2.children.map(val2 => {
        jsx.push(
          <tr key={`${Math.random()}`}>
            {this.renderThresholdValueData(value2.children, val2.name, value2._id, val2.id)}
          </tr>)
      })
    })
    return jsx;
  }

  /* save Threshold */
  saveThreshold = () => {
    let thresholdValue = this.state.thresholdValue;
    this.props.createThreshold(this.props.apolloClient.masters, thresholdValue);
    this.setState({
      loading: true
    })
  }

  /* toggle Desposal Delete Modal */      
  toggleDesposalDeleteModal = (value, Type) => {
    let deleteDesposalName = "";

    if (value) {

      deleteDesposalName = value.name;

      this.setState({
        desposalDeleteModal: !this.state.desposalDeleteModal,
        deleteid: value._id,
        deleteDesposalName: deleteDesposalName
      }, () => this.forceUpdate())
    } else {
      this.setState({
        desposalDeleteModal: !this.state.desposalDeleteModal,
        deleteDesposalName: ""
      }, () => this.forceUpdate())
    }
  }

  // delete desposal
  deleteDesposal = () => {
    let state = this.state
    let requestParams = {
      id: state.deleteid,
      company_name: this.props.authReducer.userData.company_name,
      email: this.props.authReducer.userData.email, //I pass this for get_user_data api
      projectId : this.props.authReducer.userData.projectid, //I pass this for get_user_data api
    }
    /*delete disposal*/
    this.props.deleteDesposal(this.props.apolloClient.masters, requestParams);
    this.setState({
      loading: true
    })
  }
 
  /* handle Input Change */
  handleInputChange = (valType) => e => {
    let materialEdit = this.state.materialEdit;
    materialEdit[valType] = e.target.value;
    this.setState({
      materialEdit
    }, () => this.forceUpdate())
  }

  /* handle Input Master Change */
  handleInputMasterChange = (valType) => e => {
    let master = this.state.master;
    master[valType] = e.target.value;
    this.setState({
      master
    }, () => this.forceUpdate())
  }

  /* handle Input Test Type Change */
  handleInputTestTypeChange = (valType) => e => {
    let testType = this.state.testType;
    testType[valType] = e.target.value;
    this.setState({
      testType
    }, () => this.forceUpdate())
  }

  /* handle Input Desposal Change */
  handleInputDesposalChange = (valType, standard) => event => {
    let value = ""
    if (valType === "name" || valType === "test_list" || valType === "country" || valType === "state" || valType === "type") {
      value = event.target.value
    }
    if (valType === "color") {
      value = event
    }
    if (valType === 'standard' && standard != undefined) {
      value = !standard
    }
    let desposal = this.state.desposal;
    desposal[valType] = value;


    if(typeof desposal.error != "undefined" &&  desposal.error.length > 0){
      desposal.error.map((data,index) => {
        if(valType == 'name' && data == 'name'){
          desposal.error.splice(index, 1);
        }

        if(valType == 'country' && data == 'country'){
          desposal.error.splice(index, 1);
        }

        if(valType == 'state' && data == 'state'){
          desposal.error.splice(index, 1);
        }

        if(valType == 'color' && (data == 'color' || data === 'colorhex')){
          desposal.error.splice(index, 1);
        }

      })
    }
    this.setState({
      desposal
    }, () => this.forceUpdate())    
  }

  /* selected Test Family List */
  selectedTestFamilyList = () => {
    let desposal = this.state.desposal;
    let selected = desposal.test_list ? desposal.test_list : [];
    let newArray = []
    let tempTestList = [];
    if (selected.length > 0) {
      this.props.masterReducer.testTypeList &&
        this.props.masterReducer.testTypeList.map((val, inx) => {
          selected.filter(row => val._id == row._id).map(formula => {
            tempTestList.push(val)
            newArray.push(val.name)
          })
        })
    }
    desposal.test_list = tempTestList
    this.setState({ desposal })
    return newArray.length > 0 ? newArray.join(', ') : ""
  }

  /* handle Input Test Change */
  handleInputTestChange = (valType) => e => {
    let value = e.target.value
    let test = this.state.test;
    test[valType] = value;
    this.setState({
      test
    }, () => this.forceUpdate())
  }

  /* handle New Keys Tags */
  handleNewKeysTags = (val, valType) => {
    let materialEdit = this.state.materialEdit;
    materialEdit[valType] = val;
    this.setState({
      materialEdit
    }, () => this.forceUpdate())
  }

  /* handle New Value Color */
  handleNewValueColor = (val, valType) => {
    let colorData = this.state.master;
    colorData[valType] = val;
    this.setState({
      colorData
    }, () => this.forceUpdate())
  }
  
  /* desposal Validate */
  desposalValidate = () => {
    let error = false;
    let row1 = this.state.desposal;

    let company_name = this.props.authReducer.userData.company_name
    let companyDisposalList = this.props.masterReducer.desposalList.filter(d=>d.company_name!="" && d.company_name == company_name);

    let disposalType = this.state.isCopyDispo == true ? row1.type : this.state.disposalCateogy[this.state.activeTab]
    let projectId = this.props.authReducer.userData.projectid;

    let enteredName = row1.name.trim();
    let isExistName = companyDisposalList.filter((l) => l.name == enteredName); 

    row1.error = [];
    if (!row1.name) {
      row1.error.push("name")
      error = true;
    }
    if (this.state.isCopyDispo == true && !row1.type) {
      row1.error.push("type")
      error = true;
    }

    //Also add condition while Edit Disposal (row1._id !== '') and remove error in second if condition below, if project name is same
    if((this.state.isCopyDispo == true || row1._id == "" || row1._id !== '') && isExistName.length > 0){

      if(disposalType === 'project'){
        let isExistNameForProjectDispo = companyDisposalList.filter((l) => l.name === enteredName && l.projectId === projectId && l.type === 'project'); 
        //let isExistNameForProjectDispoInOthers = companyDisposalList.filter((l) => l.name == enteredName && l.type !== 'project'); 
        //if(isExistNameForProjectDispo.length > 0 || isExistNameForProjectDispoInOthers.length > 0){
        if(isExistNameForProjectDispo.length > 0){
          row1.error.push("nameexist")
          error = true;
        }        
      }else if(disposalType === 'company'){
        let isExistNameForCompanyDispo = companyDisposalList.filter((l) => l.name === enteredName && l.type === 'company'); 
        if(isExistNameForCompanyDispo.length){
          row1.error.push("nameexist")
          error = true;
        } 
      }else if(disposalType === 'account'){
        let isExistNameForAccountDispo = companyDisposalList.filter((l) => l.name === enteredName && l.type === 'account'); 
        if(isExistNameForAccountDispo.length){
          row1.error.push("nameexist")
          error = true;
        } 
      }else{
        row1.error.push("nameexist")
        error = true;
      }
      
    }

    //While Edit disposal check project name
    if( row1._id !== ''){
      if(row1.name.trim() === this.state.editedDisposalName){
        error = false;
        row1.error = row1.error.filter((l) => l !== 'nameexist');
      }
    }


    if (!row1.address) {
      row1.error.push("address")
      error = true;
    }
    if (!row1.country) {
      row1.error.push("country")
      error = true;
    }
    if (!row1.state) {
      row1.error.push("state")
      error = true;
    }
    if (!row1.color) {
      row1.error.push("color")
      error = true;
    }
    if(row1.color){
      var reg=/^#([0-9a-f]{3}){1,2}$/i;
      if(!reg.test(row1.color)){
        row1.error.push("colorhex")
        error = true;
      }
    }
    if (row1.test_list.length === 0) {
      row1.error.push("test_list")
      error = true;
    }
    this.setState({
      row1
    }, () => this.forceUpdate())    
    return error;
  }

  /* save Desposal */
  saveDesposal = () => {
    
    let error = this.desposalValidate()
    let desposal = this.state.desposal;
    
    let disposalType = this.state.isCopyDispo == true ? desposal.type : this.state.disposalCateogy[this.state.activeTab]
    let userid = this.props.authReducer.userData.userid;
    let projectId = this.props.authReducer.userData.projectid;
    let company_name = this.props.authReducer.userData.company_name


    let activeTab = this.state.activeTab;
    if(this.state.isCopyDispo == true){
      activeTab = Object.keys(this.state.disposalCateogy).find(key => this.state.disposalCateogy[key] === disposalType);
    }

    let indexCount = 0;
    if(desposal.standard == true && desposal && (desposal._id == "" || desposal.index_no == null || (desposal._id != "" && desposal.index_no == "0"))){  
      //While add/copy disposal    
      if(disposalType == 'account'){
          let allAccountDisposal = this.props.masterReducer.desposalList.filter(d=>typeof d.type != "undefined" && d.type != "" && d.type == "account" && d.standard == true && d.userId == userid);
          let totalAccountDisposal = allAccountDisposal.length;
          if(totalAccountDisposal == "0"){
            indexCount = totalAccountDisposal + 1;
          }else{
            var maxValue = Math.max.apply(null, 
              allAccountDisposal.map(function(o) { return o.index_no; }));
              indexCount = maxValue + 1;
          }
          
      }else if(disposalType == 'project'){      
        let allProjectDisposal = this.props.masterReducer.desposalList.filter(d=>typeof d.type != "undefined" && d.type != "" && d.type == "project" && d.standard == true && d.projectId == projectId);
        let totalProjectDisposal = allProjectDisposal.length;
        if(totalProjectDisposal == "0"){
          indexCount = totalProjectDisposal + 1;
        }else{
          var maxValue = Math.max.apply(null, 
            allProjectDisposal.map(function(o) { return o.index_no; }));
            indexCount = maxValue + 1;
        }
        
      }else if(disposalType == 'company'){
        let allCompanyDisposal = this.props.masterReducer.desposalList.filter(d=>d.company_name!="" && d.company_name == company_name && d.type == "company" && d.standard == true);
        let totalCompanyDisposal = allCompanyDisposal.length;
        if(totalCompanyDisposal == "0"){
          indexCount = totalCompanyDisposal + 1;
        }else{
          var maxValue = Math.max.apply(null, 
            allCompanyDisposal.map(function(o) { return o.index_no; }));
            indexCount = maxValue + 1;
        }
        
      }
    }else{
      //While Edit disposal
      if(desposal.standard == true){
        indexCount = desposal.index_no == "" || desposal.index_no == null ? indexCount : desposal.index_no ;
      }
    }


    if (!error) {

      let notPresentInData = [];

      if (desposal && desposal._id) {
        

        //To check that any already selected testfamily are removed or not
        let TestfamilyId = [];
        desposal.test_list.map((test, i) => {
            TestfamilyId.push(test._id);
        })
        let notPresentInData = this.state.selFamilyBfrEdit.filter(val => !TestfamilyId.includes(val));

        /* edit existing disposal */
        let request = {
          name: desposal.name,
          standard: desposal.standard,
          address: desposal.address,
          state: desposal.state,
          country: desposal.country,
          color: desposal.color,
          test_list: JSON.stringify(desposal.test_list),
          start: "",
          end: "",
          percentage: "",
          id: desposal._id,
          company_name:this.props.authReducer.userData.company_name,
          removed_test_ids: JSON.stringify(notPresentInData),
          clone_desposal_id: "",
          clone_desposal_status: false,
          type: disposalType,
          userId: userid,
          projectId: projectId,
          index_no: indexCount,
          email: this.props.authReducer.userData.email, //I pass this for get_user_data api
        }
        this.props.createDesposal(this.props.apolloClient.masters, request)
      } else {
        /* add new desposal or Copy Disposal */
        let request = {
          name: desposal.name,
          standard: desposal.standard,
          address: desposal.address,
          state: desposal.state,
          country: desposal.country,
          color: desposal.color,
          test_list: JSON.stringify(desposal.test_list),
          start: "",
          end: "",
          percentage: "",
          id: "",
          company_name:this.props.authReducer.userData.company_name,
          removed_test_ids: JSON.stringify(notPresentInData),
          clone_desposal_id: this.state.isCopyDispo == true ? this.state.copyDisposalId : "",
          clone_desposal_status: this.state.isCopyDispo == true ? true : false,
          type: disposalType,
          userId: userid,
          projectId: projectId,
          index_no: indexCount,
          email: this.props.authReducer.userData.email, //I pass this for get_user_data api
        }
        this.props.createDesposal(this.props.apolloClient.masters, request)
      }


      this.setState({
        loading: true,
        activeTab: activeTab,
        isCopyDispo: false,
      },() => {
        localStorage.setItem("client_disposal_tab", "2")
      })
    }else{
      this.setState({
        warning: true,
        notification: {
          success: false,
          message: client_probing_please_fill_all_required_fileds
        }
      })
    }
  }

  /* test Validate */
  testValidate = () => {
    let error = false;
    let row1 = this.state.test;
    row1.error = [];
    if (!row1.name) {
      row1.error.push("name")
      error = true;
    }
    if (!row1.type) {
      row1.error.push("type")
      error = true;
    }
    this.setState({
      row1
    }, () => this.forceUpdate())
    return error;
  }

  /* save Test */
  saveTest = () => {
    let error = this.testValidate()
    let test = this.state.test;
    if (!error) {
      if (test && test._id) {
        // edit
        let request = {
          name: test.name,
          threshold: test.threshold,
          desposal: test.desposal,
          type: test.type,
          id: test._id
        }
        this.props.createTest(this.props.apolloClient.masters, request)
      } else {
        // add
        let request = {
          name: test.name,
          threshold: test.threshold,
          desposal: test.desposal,
          type: test.type,
          id: ""
        }
        this.props.createTest(this.props.apolloClient.masters, request)
      }
      this.setState({
        loading: true
      })
    }
  }
          
  /* render master Desposal List */
  renderMasterDesposalList = () => {
    let arrayList = []
    let masterDisposalList = this.props.masterReducer.desposalList.filter(d=>d.company_name=="" && d.type == "master")
    masterDisposalList = sortBy(masterDisposalList, ['index_no']);

    let standardMst = masterDisposalList.filter((l) => l.standard == true);
    let nonStandardMst = masterDisposalList.filter((l) => l.standard == false);
    masterDisposalList = [...standardMst, ...nonStandardMst];

    masterDisposalList.map((row, inx) => {
      arrayList.push([
        inx + 1,
        row.name,
        <>
          {row.standard ? <span className={"statusOpen"}>Yes</span> : 
						<span className={"statusClose"}>No</span>
          }
        </>,
        row.standard?"Yes":"No",
        row
      ])
    })
    return arrayList;
  } 

  /* render company Desposal List */
  renderCompanyDesposalList = () => {
    let arrayList = []
    let company_name = this.props.authReducer.userData.company_name
    let companyDisposalList = this.props.masterReducer.desposalList.filter(d=>d.company_name!="" && d.company_name == company_name && d.type == "company")
    companyDisposalList = sortBy(companyDisposalList, ['index_no']);

    let standardCmp = companyDisposalList.filter((l) => l.standard == true);
    let nonStandardCmp = companyDisposalList.filter((l) => l.standard == false);
    nonStandardCmp = reverse(nonStandardCmp)

    companyDisposalList = [...standardCmp, ...nonStandardCmp];

    companyDisposalList.map((row, inx) => {
      arrayList.push([
        inx + 1,
        row.name,
        <>
          {row.standard ? <span className={"statusOpen"}>Yes</span> : 
						<span className={"statusClose"}>No</span>
          }
        </>,
        row.standard?"Yes":"No",
        row
      ])
    })
    return arrayList;
  } 

  renderProjectDesposalList = () => {
    let arrayList = []
    let company_name = this.props.authReducer.userData.company_name
    let projectId = this.props.authReducer.userData.projectid;

    let projectDisposalList = this.props.masterReducer.desposalList.filter(d=>typeof d.type != "undefined" && d.type != "" && d.type == "project" && d.projectId == projectId)
    projectDisposalList = sortBy(projectDisposalList, ['index_no']);

    let standardPro = projectDisposalList.filter((l) => l.standard == true);
    let nonStandardPro = projectDisposalList.filter((l) => l.standard == false);
    nonStandardPro = reverse(nonStandardPro)
    projectDisposalList = [...standardPro, ...nonStandardPro];

    projectDisposalList.map((row, inx) => {
      arrayList.push([
        inx + 1,
        row.name,
        <>
          {row.standard ? <span className={"statusOpen"}>Yes</span> : 
						<span className={"statusClose"}>No</span>
          }
        </>,
        row.standard?"Yes":"No",
        row
      ])
    })
    return arrayList;
  } 

  renderAccountDesposalList = () => {
    let arrayList = [];
    let company_name = this.props.authReducer.userData.company_name;
    let userid = this.props.authReducer.userData.userid;

    let accountDisposalList = this.props.masterReducer.desposalList.filter(d=>typeof d.type != "undefined" && d.type != "" && d.type == "account" && d.userId == userid)    
    accountDisposalList = sortBy(accountDisposalList, ['index_no']);

    let standardAcc = accountDisposalList.filter((l) => l.standard == true);
    let nonStandardAcc = accountDisposalList.filter((l) => l.standard == false);
    nonStandardAcc = reverse(nonStandardAcc)
    accountDisposalList = [...standardAcc, ...nonStandardAcc];

    accountDisposalList.map((row, inx) => {
      arrayList.push([
        inx + 1,
        row.name,
        <>
          {row.standard ? <span className={"statusOpen"}>Yes</span> : 
						<span className={"statusClose"}>No</span>
          }
        </>,
        row.standard?"Yes":"No",
        row
      ])
    })
    return arrayList;
  } 

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] == value) {
        return i;
      }
    }
    return -1;
  }

  /* handle Change Rule */
  handleChangeRule = (child, typeId, testId, des_id, event) => {

    if (event.target.value >= 0) {

        let temptestRule = {...this.state.testRule};
        temptestRule.value = event.target.value;
      
        this.setState({
            [event.target.name]: event.target.value,
            testRule: temptestRule
        }, () => this.forceUpdate());
        let testRuleList = this.state.testRuleList;
        testRuleList.map((rule, index2) => {
            if (rule.desposal_id == des_id && rule.test_id == typeId && parseInt(rule.child_id) == testId) {
                rule.value = event.target.value;
            }
        })
        this.setState({
            testRuleList
        }, () => this.forceUpdate());

    }
    
  }

  /* save Test Rule Value */
  saveTestRuleValue = (child, typeId, testId, des_id, event) => {
    if (typeof (this.state["name_" + des_id + "_" + testId]) == "undefined" || this.state["name_" + des_id + "_" + testId] == "") {
      this.setState({
        ["name_error_" + des_id + "_" + testId]: true
      })
    } else {
      this.setState({
        ["name_error_" + des_id + "_" + testId]: false,
        disableButtons: ["disable_" + des_id + "_" + testId]
      });
      let value = this.state["name_" + des_id + "_" + testId];
      let childArray = []
      if (child.length > 0) {
        let newChildArray = child.filter((val, index) => val.id != testId)
        childArray = newChildArray
      }
      let testRule = this.state.testRule
      let test_list = testRule.desposalArray ? testRule.desposalArray.test_list : []

      let testListArray = test_list.filter((val, index) => val._id != typeId)

      let formula = {
        condition: "less",
        test: [],
        percentage: 0,
        nolimit: false
      }
      let ruleFound = false;
      if (this.state.testRuleList.length > 0) {
        this.state.testRuleList.map((rule, index) => {
          if (rule.desposal_id == des_id && rule.test_id == typeId && rule.child_id == testId) {
            ruleFound = rule;
          }
        })
      }
      let request = false;
      if (ruleFound) {
        let selectedChild = child.filter((val, index) => val.id == ruleFound.child_id);
        let children = []
        if (selectedChild.length) {
          children = selectedChild[0]
        }
        let request = {
          id: ruleFound._id,
          desposal_id: ruleFound.desposal_id,
          desposal_name: ruleFound.desposal_name,
          test_id: ruleFound.test_id,
          test_name: ruleFound.test_name,
          child_id: ruleFound.child_id,
          child_name: ruleFound.child_name,
          children: JSON.stringify(children),
          value: value,
          option_id: ruleFound.option_id,
          formula: JSON.stringify(ruleFound.formula),
        }
        this.props.saveTestRule(this.props.apolloClient.masters, request);
        this.setState({
          loading: true
        })
      } else {
        let selectedChild = child.filter((val, index) => val.id == testId);
        let children = []
        if (selectedChild.length) {
          children = selectedChild[0]
        }
        let selectedTest = test_list.filter((val, index) => val._id == typeId);
        let request = {
          id: "",
          desposal_id: des_id,
          desposal_name: testRule.desposalArray ? testRule.desposalArray.name : "",
          test_id: typeId,
          test_name: selectedTest.length > 0 ? selectedTest[0].name : "",
          child_id: testId,
          child_name: selectedChild.length > 0 ? selectedChild[0].name : "",
          children: JSON.stringify(children),
          value: value,
          option_id: 1,
          formula: JSON.stringify(formula),
        }
        this.props.saveTestRule(this.props.apolloClient.masters, request);
        this.setState({
          loading: true
        })
      }
    }
  }

  /* save Test Rule */
  saveTestRule = () => {
    let testRule = this.state.testRule;
    testRule.error = [];
    let errors = false;
    if (this.state.radioValue == "radio1") {
        if (testRule.formula.condition == "") {
            testRule.error.push("radio1_condition");
            errors = true;
        }
        if (testRule.value == "") {

          let checkRec =  this.state.thresholdValuesToSave.filter(t=>t.desposal_id == testRule.desposal_id && t.test_id == testRule.test_id && t.child_id == testRule.child_id)
          if(checkRec.length > 0 && checkRec[0].value != ""){
              testRule.value = checkRec[0].value;
          }

          if (testRule.value == "") {
              testRule.error.push("radio1_thresholdvalue");
              errors = true;
          }
      }
    } else if (this.state.radioValue == "radio2") {
        if (testRule.formula.condition == "") {
            testRule.error.push("radio2_condition");
            errors = true;
        }
        if (testRule.formula.test.length <= 0) {
            testRule.error.push("radio2_test");
            errors = true;
        }
        if (testRule.value == "") {

          let checkRec =  this.state.thresholdValuesToSave.filter(t=>t.desposal_id == testRule.desposal_id && t.test_id == testRule.test_id && t.child_id == testRule.child_id)
          if(checkRec.length > 0 && checkRec[0].value != ""){
              testRule.value = checkRec[0].value;
          }

          if (testRule.value == "") {
              testRule.error.push("radio2_thresholdvalue");
              errors = true;
          }
      }
    } else if (this.state.radioValue == "radio4") {
        if (testRule.formula.condition == "") {
            testRule.error.push("radio4_condition");
            errors = true;
        }
        if (testRule.formula.percentage == "" || testRule.formula.percentage == "0") {
            testRule.error.push("radio4_percentage");
            errors = true;
        }
        if (testRule.formula.test.length <= 0) {
            testRule.error.push("radio4_test");
            errors = true;
        }
    }
    this.setState({
        testRule: testRule
    }, this.forceUpdate());
    if (!errors) {
        let testRule = this.state.testRule;
        let option = 1
        if (this.state.radioValue == "radio2") {
            option = 2
        } else if (this.state.radioValue == "radio3") {
            option = 3

            this.state["name_" + testRule.desposal_id + "_" + testRule.child_id] = "";
            testRule.value = "";
        } else if (this.state.radioValue == "radio4") {
            option = 4

            this.state["name_" + testRule.desposal_id + "_" + testRule.child_id] = "";
            testRule.value = "";
        }

        testRule.option_id = option;

        let children = []
        if (testRule.children) {
            children = testRule.children
        }

        //This is for removing the "desposalArray" key for solving the duplicate saved issue
        let temptestRule = {...testRule};
        if(temptestRule != ""){
            if(temptestRule.hasOwnProperty('desposalArray')){
                delete temptestRule.desposalArray;
            }
        }

        let thresholdValuesToSave = this.state.thresholdValuesToSave
        let checkValue =  thresholdValuesToSave.filter(t=>t.desposal_id == testRule.desposal_id && t.test_id == testRule.test_id && t.child_id == testRule.child_id)
        if(checkValue.length == 0){
            let temparr = [];
            temparr.push(temptestRule);
            thresholdValuesToSave = [...thresholdValuesToSave, temparr[0]];
        }else{
            thresholdValuesToSave.map((val, index) => {
                if(val.desposal_id == testRule.desposal_id && val.test_id == testRule.test_id && val.child_id == testRule.child_id){
                    thresholdValuesToSave[index] = temptestRule;
                }
            });
        }

        let request = {
            id: testRule.id,
            desposal_id: testRule.desposal_id != "" ? testRule.desposal_id : this.state.desposal._id,
            desposal_name: testRule.desposal_name != "" ? testRule.desposal_name : this.state.desposal.name,
            test_id: testRule.test_id,
            test_name: testRule.test_name,
            child_id: testRule.child_id,
            child_name: testRule.child_name,
            children: JSON.stringify(children),
            value: this.state["name_" + testRule.desposal_id + "_" + testRule.child_id] ? this.state["name_" + testRule.desposal_id + "_" + testRule.child_id] : testRule.value,
            option_id: option,
            formula: JSON.stringify(testRule.formula),
            nolimit_finalvalue: testRule.nolimit_finalvalue,
        }
        this.props.saveTestRule(this.props.apolloClient.masters, request);
        this.setState({
            loading: true,
            thresholdValuesToSave: thresholdValuesToSave
        })
    }
  }
     
  /* render Selected Test Family Details */
  renderSelectedTestFamilyDetails = () => {
    let desposal = this.state.desposal;
    let selected = desposal.test_list ? desposal.test_list : [];
    let selectedTestFamilyDetails = [];
    if (selected) {
      selected.map((val, index) => {
        let campaignName = "";
        let keys = []
        let description = []
        let short_description = []
        let long_description = []
        let tags = ""
        val.children.map((chil, cl_i) => {
          if(this.props.masterReducer.testRuleList){
            let getvalue = this.props.masterReducer.testRuleList.filter(rule=>rule.desposal_id == desposal._id).filter(test=>(test.test_name == val.name && test.child_name == chil.name))             
          }
          keys.push(<li>{chil.name}</li>)          
          if (chil.description) {
            chil.description.map((des, d_i) => {
              description.push(<li>{des.lang[0].toUpperCase() + des.lang.slice(1) + ": " + des.texts}</li>)
            })
          }
          if (chil.short_description) {
            chil.short_description.map((sdes, sd_i) => {
              short_description.push(<li>{sdes.lang[0].toUpperCase() + sdes.lang.slice(1) + ": " + sdes.texts}</li>)
            })
          }
          if (chil.long_description) {
            chil.long_description.map((ldes, ld_i) => {
              long_description.push(<li>{ldes.lang[0].toUpperCase() + ldes.lang.slice(1) + ": " + ldes.texts}</li>)
            })
          }
          if (chil.tags) {
            tags = chil.tags.join(",")
          }

        })
        selectedTestFamilyDetails.push([
          index + 1,
          val.name,
          keys,
          description,
          short_description,
          long_description,
          tags 
        ]);
      });
    }
    return selectedTestFamilyDetails;
  }

  /* render Selected Test Family values */
  renderSelectedTestFamilyValues = () => {
    let desposal = this.state.desposal;
    let selected = desposal.test_list ? desposal.test_list : [];
    let selectedTestFamilyDetails = [];
    if (selected) {
      selected.map((val, index) => {
        let campaignName = "";
        let keys = []
        let allValues = []
        val.children.map((chil, cl_i) => {
          let rules_val = "-"
          if(this.props.masterReducer.testRuleList){
            //let getvalue = this.props.masterReducer.testRuleList.filter(rule=>rule.desposal_id == desposal._id).filter(test=>(test.test_name == val.name && test.child_name == chil.name))
            let rules = this.props.masterReducer.testRuleList.filter(rule=>rule.desposal_id == desposal._id).filter(test=>(test.child_id == chil.id))

            if(rules.length){

              rules = rules[0];
               
                if (rules.option_id == "1") {
                  rules_val = rules.value;
                }
                else if (rules.option_id == "2") {
                    rules_val = rules.value + "*";
                }
                else if (rules.option_id == "3") {
                    rules_val = "No Limit" + (rules?.nolimit_finalvalue ? ' *' : '');
                }
                else if (rules.option_id == "4") {
                    rules_val = (rules.formula.condition == "greter" ? ">" : "<") + "" + rules.formula.percentage + "% of " + rules.formula.test[0].name;
                }
            }
            
          }
          keys.push(<li>{chil.name}</li>)
          allValues.push(<li style={{listStyle:"none"}}>{rules_val}</li>)
        })
        selectedTestFamilyDetails.push([
          index + 1,
          val.name,
          keys,
          allValues
        ]);
      });
    }
    return selectedTestFamilyDetails;
  }

  /* handle Test Family Search */
  handleTestFamilySearch = (event) => {
    this.setState({
      searchTestFamilyTexts: event.target.value
    }, () => this.forceUpdate())
    if (event.target.value == "") {
      this.setState({
        testFamilyList: this.state.thresholdData
      }, () => this.forceUpdate())
    }
  }

  /* search Test Family */
  searchTestFamily = (event) => {
    let testFamilyList = []
    if (this.state.searchTestFamilyTexts != "") {
      let request = {
        search: this.state.searchTestFamilyTexts
      }
      this.props.getSearchTestFamily(this.props.apolloClient.masters, request)
      let list = this.state.thresholdData
      this.setState({
        loading: true
      }, () => this.forceUpdate())
    } else {
      this.setState({
        testFamilyList: this.state.thresholdData
      }, () => this.forceUpdate())
    }
  }

  /* handle Test Family Change */
  handleTestFamilyChange = selectedTestFamily => {
    let desposal = this.state.desposal
    let tempTestFamily = []
    if (selectedTestFamily && selectedTestFamily.length) {
      selectedTestFamily.map((test, index) => {
        let selectTest = this.state.thresholdData.filter((testFamily => testFamily._id == test.value))
        if (selectTest.length != 0) {
          tempTestFamily.push(selectTest[0])
        }
      })
    }
    desposal.test_list = tempTestFamily

    if(typeof desposal.error != "undefined" &&  desposal.error.length > 0){
      desposal.error.map((data,index) => {
        if(data == 'test_list'){
          desposal.error.splice(index, 1);
        }
      })
    }

    this.setState({
      desposal
    }, () => this.forceUpdate());
  };

  /* copy Disposal Function */
  copyDisposalFunction = (value) => {
    let desposal = {
      name: "",
      value: "",
      start: "",
      end: "",
      percentage: "",
      error: [],
      _id: "",
      standard: false,
      country: "",
      state: "",
      address: "",
      color: "",
      test_list: [],
      type: "",
      userId: "",
      projectId: "",
    }
    this.props.masterReducer.desposalDetails = []
    if (value) {
      this.setState({
        isCopyDispo: true,
        copyDisposalId: value._id,
        searchTestFamilyTexts: "",
        testFamilyList: this.state.thresholdData,
        desposalAddEditModal: !this.state.desposalAddEditModal,
        copyDisposal:true,
        getDetailsLoading:true,
        desposal
      }, () => this.forceUpdate())
      let requestParams = {
        id: value._id
      }
      this.props.getDesposalDetails(this.props.apolloClient.masters, requestParams);
    }
  }
    /* toggle tab */
    toggle = (tab) => {
      const { activeTab } = this.state
        if (activeTab !== tab) {
            this.setState({
                activeTab: tab,
            }, () => {
                localStorage.setItem("client_disposal_tab", tab)
            }
            );
        }
    };

  /* change test family */  
  changedTestFamily = (child, typeId, testId, des_id, event) => {
    let value = this.state["name_" + des_id + "_" + testId];
    let childArray = []
    if (child.length > 0) {
      let newChildArray = child.filter((val, index) => val.id != testId)
      childArray = newChildArray
    }
    let testRule = this.state.testRule
    let test_list = testRule.desposalArray ? testRule.desposalArray.test_list : []

    let testListArray = test_list.filter((val, index) => val._id != typeId)

    let formula = {
      condition: "less",
      test: [],
      percentage: 0,
      nolimit: false
    }
    let ruleFound = false;
    if (this.state.testRuleList.length > 0) {
      this.state.testRuleList.map((rule, index) => {
        if (rule.desposal_id == des_id && rule.test_id == typeId && rule.child_id == testId) {
          ruleFound = rule;
        }
      })
    }
    let request = false;
    if (ruleFound) {
      let selectedChild = child.filter((val, index) => val.id == ruleFound.child_id);
      let children = []
      if (selectedChild.length) {
        children = selectedChild[0]
      }
      request = {
        id: ruleFound._id,
        desposal_id: ruleFound.desposal_id,
        desposal_name: ruleFound.desposal_name,
        test_id: ruleFound.test_id,
        test_name: ruleFound.test_name,
        child_id: ruleFound.child_id,
        child_name: ruleFound.child_name,
        children: children,
        value: value?value:0,
        option_id: ruleFound.option_id,
        formula: ruleFound.formula,
      } 
    } else {
      let selectedChild = child.filter((val, index) => val.id == testId);
      let children = []
      if (selectedChild.length) {
        children = selectedChild[0]
      }
      let selectedTest = test_list.filter((val, index) => val._id == typeId);
      request = {
        id: "",
        desposal_id: des_id,
        desposal_name: testRule.desposalArray ? testRule.desposalArray.name : "",
        test_id: typeId,
        test_name: selectedTest.length > 0 ? selectedTest[0].name : "",
        child_id: testId,
        child_name: selectedChild.length > 0 ? selectedChild[0].name : "",
        children: children,
        value: value?value:0,
        option_id: 1,
        formula: formula,
      } 
    }
    if(request){
      let thresholdValuesToSave = this.state.thresholdValuesToSave
      let checkValue =  thresholdValuesToSave.filter(t=>t.desposal_id == request.desposal_id && t.test_id == request.test_id && t.child_id == request.child_id)
      if(checkValue.length == 0){
        thresholdValuesToSave.push(request)
      } else {
        thresholdValuesToSave.map((t)=>{
          if(t.desposal_id == request.desposal_id && t.test_id == request.test_id && t.child_id == request.child_id){
            t.value = request.value?request.value:0
          }
        })
      }
    }
  }

  /* toggle Warning*/
  toggleWarning = () => {
    this.setState({
      warning: !this.state.warning
    })
  }

  saveThresholdValue = () => {
    if(!this.state.thresholdValuesToSave.length && !this.state.deleteRulesArr.length){
      this.setState({
        warning: true,
        notification: {
          success: false,
          message: "No any changes to save"
        }
      })
    } else {

      if(this.state.thresholdValuesToSave.length){
        //This is for converting the child_id from type number to string to solve the duplicate entry issue.
        this.state.thresholdValuesToSave.map((l,i) => {
          if(typeof l.child_id != "string"){
              l.child_id =  l.child_id.toString();
          }
        })

        this.setState({loading:true})
        let request = {
          desposal_id:this.state.thresholdValuesToSave[0].desposal_id,
          rules_data: JSON.stringify(this.state.thresholdValuesToSave)
        }
        
        this.props.saveTestfamilyValue(this.props.apolloClient.masters, request)
      }

      //Delete multiple test rule lists
      if(this.state.deleteRulesArr.length){
        let deleteRulesArr = this.state.deleteRulesArr;
        let desposal_id = this.state.desposal._id;

        let requestParams = {
            ruleIds: JSON.stringify(deleteRulesArr),
            desposal_id: desposal_id
        }

        /* delete multiple test rules of disposal */
        this.props.deleteMultipleTestRule(this.props.apolloClient.masters, requestParams);
      }


    setTimeout(function(){
        this.setState({      
            thresholdValuesToSave:[],
            thresholdValueModal: false
        })
    }.bind(this), 2000);

    }
  }

  saveAllPollutantThresholdValue = (pollutantDetails, deleteRulesArr) => {
    if(!pollutantDetails.length && !deleteRulesArr.length){
      this.setState({
        warning: true,
        notification: {
          success: false,
          message: "No any changes to save"
        }
      })
    } else {
      //Delete multiple test rule lists
      if(deleteRulesArr.length){
        let desposal_id = this.state.desposal._id;
        let request = {
            ruleIds: JSON.stringify(deleteRulesArr),
            desposal_id: desposal_id
        }
        /* delete multiple test rules of disposal */
        this.props.deleteMultipleTestRule(this.props.apolloClient.masters, request);
      }
      if(pollutantDetails.length){
        this.setState({loading:true})
        let request = {
          desposal_id: pollutantDetails[0].desposal_id,
          rules_data: JSON.stringify(pollutantDetails)
        }
        this.props.saveTestfamilyValue(this.props.apolloClient.masters, request)
      }
    }
  }

  handleChangeDisposalList = (e) => {
    this.setState({activeDisposalList: e != null ? e.value : ''});
  }


  render() { 
    const desposalOptionsMaster = {
      filterType: 'dropdown',
      selectableRows: "none",
      print: false,
      download: false,
      viewColumns: false,
      filter: true,
      responsive: "stacked",
      textLabels: {
        body: {
          noMatch: no_match_record_found,
          toolTip: sort_text
        },
        pagination: {
          rowsPerPage: rows_per_page_text,
        },
        toolbar: {
          search: search_text
        },
      },
      customToolbar: () => {
        return (
          <React.Fragment>
          </React.Fragment>
        );
      }
    };
    const desposalOptionsCompany = {
      filterType: 'dropdown',
      selectableRows: "none",
      print: false,
      download: false,
      viewColumns: false,
      filter: true,
      responsive: "stacked",
      textLabels: {
        body: {
          noMatch: no_match_record_found,
          toolTip: sort_text
        },
        pagination: {
          rowsPerPage: rows_per_page_text,
        },
        toolbar: {
          search: search_text
        },
      },
      customToolbar: () => {
        return (
          <React.Fragment>
            {this.props.authReducer.userData.email == this.props.authReducer.userData.owner && (
            <>
              <Button outline={true} color={'success'} className={"add-new-btn"} onClick={() => this.toggleDesposalAddEditModal('')}><Tooltip title={tooltip_add}><Icon>playlist_add</Icon></Tooltip></Button>
              <Button outline={true} color={'success'} className={"add-new-btn"} onClick={() => this.toggleStandardDesposalOrder('')}><Tooltip title={set_standard_list}>{this.state.loading ? <Spinner size="sm" className="btn-spinner" /> : <Icon>reorder</Icon> }</Tooltip></Button>
            </>
            )}
          </React.Fragment>
        );
      }
    };

    const desposalOptionsOther = {
      filterType: 'dropdown',
      selectableRows: "none",
      print: false,
      download: false,
      viewColumns: false,
      filter: true,
      responsive: "stacked",
      textLabels: {
        body: {
          noMatch: no_match_record_found,
          toolTip: sort_text
        },
        pagination: {
          rowsPerPage: rows_per_page_text,
        },
        toolbar: {
          search: search_text
        },
      },
      customToolbar: () => {
        return (
          <React.Fragment>
            <Button outline={true} color={'success'} className={"add-new-btn"} onClick={() => this.toggleDesposalAddEditModal('')}><Tooltip title={tooltip_add}><Icon>playlist_add</Icon></Tooltip></Button>
            <Button outline={true} color={'success'} className={"add-new-btn"} onClick={() => this.toggleStandardDesposalOrder('')}><Tooltip title={set_standard_list}>{this.state.loading ? <Spinner size="sm" className="btn-spinner" /> : <Icon>reorder</Icon> }</Tooltip></Button>
          </React.Fragment>
        );
      }
    };
    
    const desposalColumns = [
      { name: material_list_sr_no ,
        options: {
          filter: false,
        }
      },
      { name: test_masters_name,
        options: {
          filter: false,
        }
      },
      { name: client_disposal_standard,
        options: {
          filter: false,
          filterList: [],
          customFilterListOptions: {
             render: v => `Standard: ${v}` 
          },
        }
      },
      { name: client_disposal_standard,
        options: {
          filter: true,
          display: false,
          filterList: [],
          customFilterListOptions: {
             render: v => `Standard: ${v}` 
          },
        }
      },
      {
        name: user_request_actions,
        options: {
          filter: false,
          customBodyRender: (value, index) => {
            let isadmin = this.props.authReducer.userData.email == this.props.authReducer.userData.owner ? true : false;
            let displayStatus = (value.company_name && value.type == "company" && isadmin == true) ? true : (value.type != "company" && value.type != "master") ? true : false;
            return (<>
              <Button className={"system-btn clear-border"} outline={true} color={'success'} onClick={() => this.toggleDesposalViewModal(value)} ><Tooltip title={tooltip_view}><Icon>remove_red_eye</Icon></Tooltip></Button>              
              <Button className={"add-new-btn"} outline={true} color={'success'} onClick={() => this.copyDisposalFunction(value)}>{client_disposal_copy}</Button>
              {/* {value.company_name && */}
              {displayStatus &&
              <>
              <Button className={"add-new-btn"} outline={true} color={'success'} onClick={() => this.toggleThresholdValueModal(value)}>{threshold_value_btn}</Button>
              <Button className={"system-btn clear-border"} outline={true} color={'success'} onClick={() => this.toggleDesposalAddEditModal(value)} ><Tooltip title={tooltip_edit}><Icon>edit</Icon></Tooltip></Button>
              <Button title={tooltip_delete} outline={true} color={'success'} className={"system-btn clear-border"} onClick={() => this.toggleDesposalDeleteModal(value)} ><Icon>delete</Icon></Button>
              </>
              }
            </>);
          }
        }
      }
    ]
    
    const { activeTab } = this.state;
    return (
      <Col xs={12} md={12} lg={12} xl={12}>
        <h3 className="page-title" style={{ marginBottom: "20px" }}>
          {client_disposal}
        </h3>
        <Card>
          <CardBody>
          {this.state.getListLoading && (
            <div className="div-spinner"><Spinner size="sm" className="btn-spinner spinner_color spinner_size" /></div>
          )}
          {!this.state.getListLoading && (
            <>
            <div className="tabs tabs--bordered-top">
                <div className="tabs__wrap">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => {
                                    this.toggle('1');
                                }}
                            >
                              {client_master_disposal}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => {
                                    this.toggle('2');
                                }}
                            >
                              {client_company_disposal}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '3' })}
                                onClick={() => {
                                    this.toggle('3');
                                }}
                            >
                              {client_project_disposal}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '4' })}
                                onClick={() => {
                                    this.toggle('4');
                                }}
                            >
                              {client_account_disposal}
                            </NavLink>
                        </NavItem>
                        <NavItem style={{marginLeft : "auto"}}>
                            <NavLink
                                className={classnames({ active: activeTab === '5' })}
                                onClick={() => {
                                    this.toggle('5');
                                }}
                            >
                              {client_active_disposal_cat}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <MuiThemeProvider theme={theme}>
                              <MUIDataTable
                                data={this.renderMasterDesposalList()}
                                columns={desposalColumns}
                                options={desposalOptionsMaster}
                              />
                            </MuiThemeProvider>
                        </TabPane>
                        <TabPane tabId="2"> 
                          <MuiThemeProvider theme={theme}>                                               
                            <MUIDataTable
                              data={this.renderCompanyDesposalList()}
                              columns={desposalColumns}
                              options={desposalOptionsCompany}
                            />
                          </MuiThemeProvider>  
                        </TabPane>
                        <TabPane tabId="3"> 
                          <MuiThemeProvider theme={theme}>                                               
                            <MUIDataTable
                              data={this.renderProjectDesposalList()}
                              columns={desposalColumns}
                              options={desposalOptionsOther}
                            />
                          </MuiThemeProvider>  
                        </TabPane>
                        <TabPane tabId="4"> 
                          <MuiThemeProvider theme={theme}>                                               
                            <MUIDataTable
                              data={this.renderAccountDesposalList()}
                              columns={desposalColumns}
                              options={desposalOptionsOther}
                            />
                          </MuiThemeProvider>  
                        </TabPane>
                        <TabPane tabId="5"> 
                          <ActiveDisposalCategory
                            saveBtnLoader={this.state.saveBtnLoader}
                            onSave={data => this.saveActiveDisposalCategory(data)}        
                          />
                        </TabPane>
                    </TabContent>
                    {this.state.warning &&
                      <Snackbar
                        autoHideDuration={3000}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        onClose={this.toggleWarning}
                        open={this.state.warning}
                        TransitionComponent={Fade}
                        ContentProps={{
                          style: this.state.notification.success ? { background: "green" } : { background: "red" }
                        }}
                        message={this.state.notification.message}
                        variant="success"
                      />
                    }
                </div>
            </div>
          </>
          )}
          </CardBody>
        </Card>

        {this.state.desposalAddEditModal && 
            Modals.desposalAddEditModal(this)
        }

        {/* {this.state.standardDisposalModal &&
            Modals.standardDisposalModal(this)
        } */}

        <ModalStandardDisposalOrder
          isOpen={this.state.standardDisposalModal}
          onClose={() => this.setState({ standardDisposalModal: false, standardDisposalList: {} })}
          initialData={this.state.standardDisposalList}
          isloading={this.state.loading}
          selDisposalType={this.state.disposalCateogy[this.state.activeTab]}
          onSave={(data, updatedDefaultDisposalListName, activeCatName) => this.saveStandardDesposalOrderNew(data, updatedDefaultDisposalListName, activeCatName)}          
        />
        
        {this.state.desposalDeleteModal && 
            Modals.desposalDeleteModal(this)
        }

        {/* {this.state.thresholdValueModal && 
            Modals.thresholdValueModal(this)
        } */}

        {this.state.thresholdValueModal && (
					<ModalDisposalThreshold
						isOpen={this.state.thresholdValueModal}
						onClose={() => this.toggleThresholdValueModal()}
						disposal={this.state.desposal}
            testRuleList={this.state.testRuleList}
						onSave={(pollutantDetails, deleteRulesArr) => this.saveAllPollutantThresholdValue(pollutantDetails, deleteRulesArr)	}
					/>
				)}

        {this.state.testRuleModal && 
            Modals.testRuleModal(this)
        }

        {this.state.desposalViewModal && 
            Modals.desposalViewModal(this)
        }

      </Col>
    );
  }
}

manageDisposal.propTypes = {
  t: PropTypes.func.isRequired,
};

//load reducers
const mapStateToProps = state => {
  return {
    authReducer: state.authReducer,
    apolloClient: state.apolloClient,
    masterReducer: state.masterReducer,
    notification: state.notification,
    languagecodeReducer: state.languagecodeReducer,
  }
}

//Load actions
const mapDispatchToProps = dispatch => {
  return {
    getTestType: (client, request) => { dispatch(actions.getTestType(client, request)) },
    createTestType: (client, request) => { dispatch(actions.createTestType(client, request)) },
    getTestTypeDetails: (master, request) => { dispatch(actions.getTestTypeDetails(master, request)) },
    deleteTestType: (master, request) => { dispatch(actions.deleteTestType(master, request)) },
    getDesposal: (client, request) => { dispatch(actions.getDesposal(client, request)) },
    createDesposal: (client, request) => { dispatch(actions.createDesposal(client, request)) },
    getDesposalDetails: (master, request) => { dispatch(actions.getDesposalDetails(master, request)) },
    getTestRuleList: (master, request) => { dispatch(actions.getTestRuleList(master, request)) },
    deleteDesposal: (master, request) => { dispatch(actions.deleteDesposal(master, request)) },
    getTest: (client, request) => { dispatch(actions.getTest(client, request)) },
    createTest: (client, request) => { dispatch(actions.createTest(client, request)) },
    getTestDetails: (master, request) => { dispatch(actions.getTestDetails(master, request)) },
    createThreshold: (master, request) => { dispatch(actions.createThreshold(master, request)) },
    saveTestRule: (master, request) => { dispatch(actions.saveTestRule(master, request)) },
    getSearchTestFamily: (client, request) => { dispatch(actions.getSearchTestFamily(client, request)) },
    saveTestfamilyValue: (master, request) => { dispatch(actions.saveTestfamilyValue(master, request)) },
    deleteTestRule: (master, request) => { dispatch(actions.deleteTestRule(master, request)) },
    deleteMultipleTestRule: (master, request) => { dispatch(actions.deleteMultipleTestRule(master, request)) },
    getStandardDesposalFront: (client, request) => { dispatch(actions.getStandardDesposalFront(client, request)) },
    configureDesposalOrder: (master, request) => { dispatch(actions.configureDesposalOrder(master, request)) },
    setCategoryWiseStandardDisposal: (master, request) => { dispatch(actions.setCategoryWiseStandardDisposal(master, request)) },
    setUserDefaultDisposalCategory: (client, request) => { dispatch(actions.setUserDefaultDisposalCategory(client, request)) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(manageDisposal)); // export component
