import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    // memberDetails : []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_MEMBER_LIST:
            return updateObject(state, { memberList: action.state })
        case actionTypes.GET_REQUEST_MEMBER_LIST:
            return updateObject(state, { requestMemberList: action.state })
        case actionTypes.GET_MEMBER_DETAILS:
            return updateObject(state, { memberDetails: action.state })
        default:
            return state;
    }
}

export default reducer