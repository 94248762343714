import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import * as actions from '../../../../Store/actions/index';

const en_us = `${process.env.PUBLIC_URL}/img/language/en_us.png`;
const fr = `${process.env.PUBLIC_URL}/img/language/fr.png`;
const de = `${process.env.PUBLIC_URL}/img/language/de.png`;
const it = `${process.env.PUBLIC_URL}/img/language/it.png`;
const es = `${process.env.PUBLIC_URL}/img/language/es.png`;
const af = `${process.env.PUBLIC_URL}/img/language/af.png`;
const at = `${process.env.PUBLIC_URL}/img/language/at.png`;
const be = `${process.env.PUBLIC_URL}/img/language/be.png`;
const br = `${process.env.PUBLIC_URL}/img/language/br.png`;
const bg = `${process.env.PUBLIC_URL}/img/language/bg.png`;
const hr = `${process.env.PUBLIC_URL}/img/language/hr.png`;
const cz = `${process.env.PUBLIC_URL}/img/language/cz.png`;
const dk = `${process.env.PUBLIC_URL}/img/language/dk.png`;
const et = `${process.env.PUBLIC_URL}/img/language/et.png`;
const fi = `${process.env.PUBLIC_URL}/img/language/fi.png`;
const ge = `${process.env.PUBLIC_URL}/img/language/ge.png`;
const gr = `${process.env.PUBLIC_URL}/img/language/gr.png`;
const hu = `${process.env.PUBLIC_URL}/img/language/hu.png`;
const ind = `${process.env.PUBLIC_URL}/img/language/ind.png`;
const lv = `${process.env.PUBLIC_URL}/img/language/lv.png`;
const no = `${process.env.PUBLIC_URL}/img/language/no.png`;
const nl = `${process.env.PUBLIC_URL}/img/language/nl.png`;
const pl = `${process.env.PUBLIC_URL}/img/language/pl.png`;
const pt = `${process.env.PUBLIC_URL}/img/language/pt.png`;
const ro = `${process.env.PUBLIC_URL}/img/language/ro.png`;
const ru = `${process.env.PUBLIC_URL}/img/language/ru.png`;
const rs = `${process.env.PUBLIC_URL}/img/language/rs.png`;
const sk = `${process.env.PUBLIC_URL}/img/language/sk.png`;
const si = `${process.env.PUBLIC_URL}/img/language/si.png`;
const se = `${process.env.PUBLIC_URL}/img/language/se.png`;
const th = `${process.env.PUBLIC_URL}/img/language/th.png`;
const tr = `${process.env.PUBLIC_URL}/img/language/tr.png`;
const gb = `${process.env.PUBLIC_URL}/img/language/gb.png`;
const lt = `${process.env.PUBLIC_URL}/img/language/lt.png`;


const EnLng = () => (
  <span className="topbar__language-btn-title">
    <img src={en_us} alt="en" />
    <span>EN-US</span>
  </span>
);

const FrLng = () => (
  <span className="topbar__language-btn-title">
    <img src={fr} alt="fr" />
    <span>FR</span>
  </span>
);

const DeLng = () => (
  <span className="topbar__language-btn-title">
    <img src={de} alt="de" />
    <span>DE</span>
  </span>
);

const ItLng = () => (
  <span className="topbar__language-btn-title">
    <img src={it} alt="it" />
    <span>IT</span>
  </span>
);

const GbukLng = () => (
  <span className="topbar__language-btn-title">
    <img src={gb} alt="gb" />
    <span>EN-UK</span>
  </span>
);

const EsLng = () => (
  <span className="topbar__language-btn-title">
    <img src={es} alt="es" />
    <span>ES</span>
  </span>
);

const AfLng = () => (
  <span className="topbar__language-btn-title">
    <img src={af} alt="af" />
    <span>AF</span>
  </span>
);

const AtLng = () => (
  <span className="topbar__language-btn-title">
    <img src={at} alt="at" />
    <span>DE-AT</span>
  </span>
);

const BeLng = () => (
  <span className="topbar__language-btn-title">
    <img src={be} alt="be" />
    <span>FR-BE</span>
  </span>
);

const BrLng = () => (
  <span className="topbar__language-btn-title">
    <img src={br} alt="br" />
    <span>PT-BR</span>
  </span>
);

const BgLng = () => (
  <span className="topbar__language-btn-title">
    <img src={bg} alt="bg" />
    <span>BG</span>
  </span>
);

const HrLng = () => (
  <span className="topbar__language-btn-title">
    <img src={hr} alt="hr" />
    <span>HR</span>
  </span>
);

const CzLng = () => (
  <span className="topbar__language-btn-title">
    <img src={cz} alt="cz" />
    <span>CS-CZ</span>
  </span>
);

const DkLng = () => (
  <span className="topbar__language-btn-title">
    <img src={dk} alt="dk" />
    <span>DA-DK</span>
  </span>
);

const EtLng = () => (
  <span className="topbar__language-btn-title">
    <img src={et} alt="et" />
    <span>ET</span>
  </span>
);

const FiLng = () => (
  <span className="topbar__language-btn-title">
    <img src={fi} alt="fi" />
    <span>FI</span>
  </span>
);

const GeLng = () => (
  <span className="topbar__language-btn-title">
    <img src={ge} alt="ge" />
    <span>KA-GE</span>
  </span>
);

const GrLng = () => (
  <span className="topbar__language-btn-title">
    <img src={gr} alt="gr" />
    <span>EL-GR</span>
  </span>
);

const HuLng = () => (
  <span className="topbar__language-btn-title">
    <img src={hu} alt="hu" />
    <span>HU</span>
  </span>
);

const InLng = () => (
  <span className="topbar__language-btn-title">
    <img src={ind} alt="ind" />
    <span>HI-IN</span>
  </span>
);

const LvLng = () => (
  <span className="topbar__language-btn-title">
    <img src={lv} alt="lv" />
    <span>LV</span>
  </span>
);

const LtLng = () => (
  <span className="topbar__language-btn-title">
    <img src={lt} alt="lt" />
    <span>LT</span>
  </span>
);

const NlLng = () => (
  <span className="topbar__language-btn-title">
    <img src={nl} alt="nl" />
    <span>NL-NL</span>
  </span>
);

const NoLng = () => (
  <span className="topbar__language-btn-title">
    <img src={no} alt="no" />
    <span>NB-NO</span>
  </span>
);

const PlLng = () => (
  <span className="topbar__language-btn-title">
    <img src={pl} alt="pl" />
    <span>PL</span>
  </span>
);

const PtLng = () => (
  <span className="topbar__language-btn-title">
    <img src={pt} alt="pt" />
    <span>PT-PT</span>
  </span>
);

const RoLng = () => (
  <span className="topbar__language-btn-title">
    <img src={ro} alt="ro" />
    <span>RO</span>
  </span>
);

const RuLng = () => (
  <span className="topbar__language-btn-title">
    <img src={ru} alt="ru" />
    <span>RU</span>
  </span>
);

const RsLng = () => (
  <span className="topbar__language-btn-title">
    <img src={rs} alt="rs" />
    <span>SR-SP</span>
  </span>
);

const SkLng = () => (
  <span className="topbar__language-btn-title">
    <img src={sk} alt="sk" />
    <span>sk-SK</span>
  </span>
);

const SiLng = () => (
  <span className="topbar__language-btn-title">
    <img src={si} alt="si" />
    <span>sl-SI</span>
  </span>
);

const SeLng = () => (
  <span className="topbar__language-btn-title">
    <img src={se} alt="se" />
    <span>sv-SE</span>
  </span>
);

const ThLng = () => (
  <span className="topbar__language-btn-title">
    <img src={th} alt="th" />
    <span>th-TH</span>
  </span>
);

const TrLng = () => (
  <span className="topbar__language-btn-title">
    <img src={tr} alt="tr" />
    <span>tr-TR</span>
  </span>
);

class TopbarLanguage extends PureComponent {
  static propTypes = {
    i18n: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
      mainButtonContent: <EnLng />,
      languageCodeArray:[]
    };
  }

  componentWillMount() {
    let languagaeData = localStorage.getItem('language');
    this.changeLanguageComponent(languagaeData);
  }

  componentDidMount() {
    if(!(localStorage.getItem("language_codes"))){ 
    this.props.getLanguageCodeMasterList(this.props.apolloClient.language, []);
    }
  }
  componentWillReceiveProps(props) {
    if(props.languagecodeReducer.languagecodeLabelList){
      this.setState({
          languageCodeArray: props.languagecodeReducer.languagecodeLabelList,
        },() => this.forceUpdate());
    }
  }
  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  changeLanguageComponent = (lng) => {
    const { i18n } = this.props;
    localStorage.setItem('language', lng ? lng : 'en');
    i18n.changeLanguage(lng);
    switch (lng) {
      case 'en':
        this.setState({ mainButtonContent: <EnLng /> });
        break;
      case 'fr':
        this.setState({ mainButtonContent: <FrLng /> });
        break;
      case 'de':
        this.setState({ mainButtonContent: <DeLng /> });
        break;
      case 'it':
        this.setState({ mainButtonContent: <ItLng /> });
        break;
      case 'gb':
        this.setState({ mainButtonContent: <GbukLng /> });
        break;
      case 'es':
        this.setState({ mainButtonContent: <EsLng /> });
        break;
      case 'af':
        this.setState({ mainButtonContent: <AfLng /> });
        break;
      case 'at':
        this.setState({ mainButtonContent: <AtLng /> });
        break;
      case 'be':
        this.setState({ mainButtonContent: <BeLng /> });
        break;
      case 'br':
        this.setState({ mainButtonContent: <BrLng /> });
        break;
      case 'bg':
        this.setState({ mainButtonContent: <BgLng /> });
        break;
      case 'hr':
        this.setState({ mainButtonContent: <HrLng /> });
        break;
      case 'cz':
        this.setState({ mainButtonContent: <CzLng /> });
        break;
      case 'dk':
        this.setState({ mainButtonContent: <DkLng /> });
        break;
      case 'et':
        this.setState({ mainButtonContent: <EtLng /> });
        break;
      case 'fi':
        this.setState({ mainButtonContent: <FiLng /> });
        break;
      case 'ge':
        this.setState({ mainButtonContent: <GeLng /> });
        break;
      case 'gr':
        this.setState({ mainButtonContent: <GrLng /> });
        break;
      case 'hu':
        this.setState({ mainButtonContent: <HuLng /> });
        break;
      case 'in':
        this.setState({ mainButtonContent: <InLng /> });
        break;
      case 'lv':
        this.setState({ mainButtonContent: <LvLng /> });
        break;
      case 'lt':
        this.setState({ mainButtonContent: <LtLng /> });
        break;
      case 'nl':
        this.setState({ mainButtonContent: <NlLng /> });
        break;
      case 'no':
        this.setState({ mainButtonContent: <NoLng /> });
        break;
      case 'pl':
        this.setState({ mainButtonContent: <PlLng /> });
        break;
      case 'pt':
        this.setState({ mainButtonContent: <PtLng /> });
        break;
      case 'ro':
        this.setState({ mainButtonContent: <RoLng /> });
        break;
      case 'ru':
        this.setState({ mainButtonContent: <RuLng /> });
        break;
      case 'rs':
        this.setState({ mainButtonContent: <RsLng /> });
        break;
      case 'sk':
        this.setState({ mainButtonContent: <SkLng /> });
        break;
      case 'si':
        this.setState({ mainButtonContent: <SiLng /> });
        break;
      case 'se':
        this.setState({ mainButtonContent: <SeLng /> });
        break;
      case 'th':
        this.setState({ mainButtonContent: <ThLng /> });
        break;
      case 'tr':
        this.setState({ mainButtonContent: <TrLng /> });
        break;
      default:
        this.setState({ mainButtonContent: <EnLng /> });
        break;
    }
  };

  changeLanguage = (lng) => {
    const { i18n } = this.props;
    localStorage.setItem('language', lng? lng : 'en');
    window.location.reload()
    i18n.changeLanguage(lng);
    switch (lng) {
      case 'en':
        this.setState({ mainButtonContent: <EnLng />, collapse: !this.state.collapse });
        break;
      case 'fr':
        this.setState({ mainButtonContent: <FrLng />, collapse: !this.state.collapse });
        break;
      case 'de':
        this.setState({ mainButtonContent: <DeLng />, collapse: !this.state.collapse });
        break;
      case 'it':
        this.setState({ mainButtonContent: <ItLng />, collapse: !this.state.collapse });
        break;
      case 'gb':
      this.setState({ mainButtonContent: <GbukLng />, collapse: !this.state.collapse });
        break;
      case 'es':
        this.setState({ mainButtonContent: <EsLng />, collapse: !this.state.collapse });
        break;
      case 'af':
        this.setState({ mainButtonContent: <AfLng />, collapse: !this.state.collapse });
        break;
      case 'at':
        this.setState({ mainButtonContent: <AtLng />, collapse: !this.state.collapse });
        break;
      case 'be':
        this.setState({ mainButtonContent: <BeLng />, collapse: !this.state.collapse });
        break;
      case 'br':
        this.setState({ mainButtonContent: <BrLng />, collapse: !this.state.collapse });
        break;
      case 'bg':
        this.setState({ mainButtonContent: <BgLng />, collapse: !this.state.collapse });
        break;
      case 'hr':
        this.setState({ mainButtonContent: <HrLng />, collapse: !this.state.collapse });
        break;
      case 'cz':
        this.setState({ mainButtonContent: <CzLng />, collapse: !this.state.collapse });
        break;
      case 'dk':
        this.setState({ mainButtonContent: <DkLng />, collapse: !this.state.collapse });
        break;
      case 'et':
        this.setState({ mainButtonContent: <EtLng />, collapse: !this.state.collapse });
        break;
      case 'fi':
        this.setState({ mainButtonContent: <FiLng />, collapse: !this.state.collapse });
        break;
      case 'ge':
        this.setState({ mainButtonContent: <GeLng />, collapse: !this.state.collapse });
        break;
      case 'gr':
        this.setState({ mainButtonContent: <GrLng />, collapse: !this.state.collapse });
        break;
      case 'hu':
        this.setState({ mainButtonContent: <HuLng />, collapse: !this.state.collapse });
        break;
      case 'in':
        this.setState({ mainButtonContent: <InLng />, collapse: !this.state.collapse });
        break;
      case 'lv':
        this.setState({ mainButtonContent: <LvLng />, collapse: !this.state.collapse });
        break;
      case 'lt':
        this.setState({ mainButtonContent: <LtLng />, collapse: !this.state.collapse });
        break;
      case 'nl':
        this.setState({ mainButtonContent: <NlLng />, collapse: !this.state.collapse });
        break;
      case 'no':
        this.setState({ mainButtonContent: <NoLng />, collapse: !this.state.collapse });
        break;
      case 'pl':
        this.setState({ mainButtonContent: <PlLng />, collapse: !this.state.collapse });
        break;
      case 'pt':
        this.setState({ mainButtonContent: <PtLng />, collapse: !this.state.collapse });
        break;
      case 'ro':
        this.setState({ mainButtonContent: <RoLng />, collapse: !this.state.collapse });
        break;
      case 'ru':
        this.setState({ mainButtonContent: <RuLng />, collapse: !this.state.collapse });
        break;
      case 'rs':
        this.setState({ mainButtonContent: <RsLng />, collapse: !this.state.collapse });
        break;
      case 'sk':
        this.setState({ mainButtonContent: <SkLng />, collapse: !this.state.collapse });
        break;
      case 'si':
        this.setState({ mainButtonContent: <SiLng />, collapse: !this.state.collapse });
        break;
      case 'se':
        this.setState({ mainButtonContent: <SeLng />, collapse: !this.state.collapse });
        break;
      case 'th':
        this.setState({ mainButtonContent: <ThLng />, collapse: !this.state.collapse });
        break;
      case 'tr':
        this.setState({ mainButtonContent: <TrLng />, collapse: !this.state.collapse });
        break;
      default:
        this.setState({ mainButtonContent: <EnLng />, collapse: !this.state.collapse });
        break;
    }
    
  };
 
  render() {
    const { mainButtonContent, collapse } = this.state;
    let languageCodes=this.props.languagecodeReducer.languagecodeLabelList   
    return (
      <div className="topbar__collapse topbar__collapse--language" style={{minWidth: 0 + 'px', display: 'inline-block'}}>
        <button className="topbar__btn" type="button" onClick={this.toggle}>
          {mainButtonContent}
          <DownIcon className="topbar__icon" style={{fill: '#000000', marginTop: -29 + 'px'}} />
        </button>
        {collapse && <span className="topbar__back"  type="button" onClick={this.toggle} />}
        <Collapse
          isOpen={collapse}
          className="topbar__collapse-content topbar__collapse-content--language scroller"
          style={{backgroundColor: '#ffffff',bottom: "0",left: "0"}}
        >
          {languageCodes.map((codevalue, index) => {
                    let content;
                    if(codevalue.code=='fr'){
                      content = <FrLng />;
                    }else if(codevalue.code=='en'){
                      content = <EnLng />;
                    }else if(codevalue.code=='de'){
                      content = <DeLng />;
                    }else if(codevalue.code=='it'){
                      content = <ItLng />;
                    }else if(codevalue.code=='gb'){
                      content = <GbukLng />;
                    }else if(codevalue.code=='es'){
                      content = <EsLng />;
                    }else if(codevalue.code=='af'){
                      content = <AfLng />;
                    }else if(codevalue.code=='at'){
                      content = <AtLng />;
                    }else if(codevalue.code=='be'){
                      content = <BeLng />;
                    }else if(codevalue.code=='br'){
                      content = <BrLng />;
                    }else if(codevalue.code=='bg'){
                      content = <BgLng />;
                    }else if(codevalue.code=='hr'){
                      content = <HrLng />;
                    }else if(codevalue.code=='cz'){
                      content = <CzLng />;
                    }else if(codevalue.code=='dk'){
                      content = <DkLng />;
                    }else if(codevalue.code=='et'){
                      content = <EtLng />;
                    }else if(codevalue.code=='fi'){
                      content = <FiLng />;
                    }else if(codevalue.code=='ge'){
                      content = <GeLng />;
                    }else if(codevalue.code=='gr'){
                      content = <GrLng />;
                    }else if(codevalue.code=='hu'){
                      content = <HuLng />;
                    }else if(codevalue.code=='in'){
                      content = <InLng />;
                    }else if(codevalue.code=='lv'){
                      content = <LvLng />;
                    }else if(codevalue.code=='lt'){
                      content = <LtLng />;
                    }else if(codevalue.code=='nl'){
                      content = <NlLng />;
                    }else if(codevalue.code=='no'){
                      content = <NoLng />;
                    }else if(codevalue.code=='pl'){
                      content = <PlLng />;
                    }else if(codevalue.code=='pt'){
                      content = <PtLng />;
                    }else if(codevalue.code=='ro'){
                      content = <RoLng />;
                    }else if(codevalue.code=='ru'){
                      content = <RuLng />;
                    }else if(codevalue.code=='rs'){
                      content = <RsLng />;
                    }else if(codevalue.code=='sk'){
                      content = <SkLng />;
                    }else if(codevalue.code=='si'){
                      content = <SiLng />;
                    }else if(codevalue.code=='se'){
                      content = <SeLng />;
                    }else if(codevalue.code=='th'){
                      content = <ThLng />;
                    }else if(codevalue.code=='tr'){
                      content = <TrLng />;
                    }
                    
                    if(codevalue.code!='us'){
                      if(codevalue.isvisible==true){
                        return <button
                        className="topbar__language-btn"
                        type="button"
                        onClick={() => this.changeLanguage(codevalue.code)}
                        key={codevalue.code}
                        >{content}</button>;
                      }
                    }  
                  })}
        </Collapse>
      </div>
    );
  }
}

//load reducers
const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
    apolloClient: state.apolloClient,
    languagecodeReducer: state.languagecodeReducer,
  };
};

//Load actions
const mapDispatchToProps = (dispatch) => {
  return {
    getLanguageCodeMasterList: (master, request) => {
      dispatch(actions.getLanguageCodeMasterList(master, request));
    },
  };
};

//export default withTranslation('common')(TopbarLanguage);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(TopbarLanguage)); //export component
