
import * as actionTypes from '../actionTypes';

//this is common actions
export const clearNotif = () => {
    return dispatch => (
        dispatch({
            type: actionTypes.CLEAR_NOTIFICATION,
            data: {}
        })
    )
}


export const setCurrentUrl = (data) => {
    return {
        type: actionTypes.SET_CURRENT_URL,
        state: data
    }
}

export const currentNotificationRedirectPageUrl = (data) => {
    return {
        type: actionTypes.CURRENT_NOTIFICATION_REDIRECT_PAGE_URL,
        state: data
    }
}