import { MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Input, Label } from 'reactstrap';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import FormGroupRequired from '../components/formGroupRequired';

const tlang = ChangeAppLanguageFront.translateLanguage;

const client_sign_in_up_form_project_address =
	tlang('client_sign_in_up_form_project_address') || 'Address of Project';
const client_project_pls_enter_address =
	tlang('client_project_pls_enter_address') || 'Please Add Project Address';
const Lang_Loadingtxt = tlang('Lang_Loadingtxt') || 'Loading...';

export default function ProjectAddress({ initialAdress, onChange, error }) {
	const [address, setAddress] = useState('');

	useEffect(() => {
		setAddress(initialAdress);
		return () => setAddress('');
	}, [initialAdress]);

	async function handleSelect(address) {
		try {
			const geocodedAddress = await geocodeByAddress(address);
			const latLng = await getLatLng(geocodedAddress[0]);
			// const { long_name: county = '' } =
			// 	geocodedAddress[0].address_components.find(c => c.types.includes('street_number')) || '';

			onChange({
				lat: Number(latLng.lat),
				lng: Number(latLng.lng),
				address: address,
				company_address: address,
				//county: county,
			});
		} catch (error) {
			console.error('Error', error);
		}
	}
	return (
		<PlacesAutocomplete
			value={address || ''}
			onChange={newAdd => setAddress(newAdd)}
			onSelect={address => handleSelect(address)}
			id="address"
			name="address"
			multiline
			required
		>
			{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
				<>
					<FormGroupRequired>
						<Label>{client_sign_in_up_form_project_address}</Label>
						<Input
							type="text"
							{...getInputProps({
								className: 'location-search-input',
							})}
						/>
						{error && <span className="error-message">{client_project_pls_enter_address}</span>}
					</FormGroupRequired>
					<div className="autocomplete-dropdown-container">
						{loading && <div>{Lang_Loadingtxt}</div>}
						{suggestions.map((suggestion, idx) => {
							const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
							// inline style for demonstration purpose
							const style = suggestion.active
								? { backgroundColor: '#fafafa', cursor: 'pointer' }
								: { backgroundColor: '#ffffff', cursor: 'pointer' };
							return (
								<div
									{...getSuggestionItemProps(suggestion, {
										className,
										style,
									})}
									key={`PLACE-${idx}`}
								>
									<MenuItem value={''}>{suggestion.description}</MenuItem>
								</div>
							);
						})}
					</div>
				</>
			)}
		</PlacesAutocomplete>
	);
}
