export default {   
   API: process.env.REACT_APP_API,
   PROJECT_API: process.env.REACT_APP_PROJECT_API,
   MASTER_API: process.env.REACT_APP_MASTER_API,
   ImageAPI: process.env.REACT_APP_IMAGEAPI,
   CAMPAIGN_API: process.env.REACT_APP_CAMPAIGN_API,
   PROBING_API: process.env.REACT_APP_PROBING_API,
   IMAGE_URL: process.env.REACT_APP_IMAGE_URL,
   APP_API_IP: process.env.REACT_APP_API_IP,
   IMAGE_PORT: process.env.REACT_APP_IMAGE_PORT,
   COUCH_DATABASE: process.env.REACT_APP_COUCH_DATABASE,
   MAPBOX_ACCESS_TOKEN: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
}

