import React from 'react';
import * as actionTypes from '../actionTypes';
import fingerprint from 'fingerprintjs'
import jwt from 'jsonwebtoken';
import { gql } from "apollo-boost";
import { Encrypt } from "../../shared/encrpt";
import axios from 'axios';
import * as actions from '../index';
import Server from '../../../Common/Server';
import firebase from 'firebase/app';
import 'firebase/auth'; 
import ChangeAppLanguage from "../../../App/view/Layout/ChangeAppLanguage";
import notificationMsg  from '../../../App/view/Layout/NotificationMessage';
import * as ChangeAppLanguageFront from "../../../App/view/Layout/ChangeAppLanguageFront";
let tlang = ChangeAppLanguageFront.translateLanguage;

const loginsuccess = tlang('notificationSuccess_auth_loginsuccess') ? tlang('notificationSuccess_auth_loginsuccess') : 'Login Successfully';
const cmpcreate = tlang('notificationSuccess_auth_cmpcreate') ? tlang('notificationSuccess_auth_cmpcreate') : 'Company Created Successfully';
const newpwdsendemail = tlang('notificationSuccess_auth_newpwdsendemail') ? tlang('notificationSuccess_auth_newpwdsendemail') : 'New Password Send To Your Email';
const passwordchanged = tlang('notificationSuccess_auth_passwordchanged') ? tlang('notificationSuccess_auth_passwordchanged') : 'Password is changed successfully';
const UserSettingChanged = tlang('notificationSuccess_auth_UserSettingChanged') ? tlang('notificationSuccess_auth_UserSettingChanged') : 'User Setting is changed successfully';
const error_something_wrong = tlang('error_something_wrong') ? tlang('error_something_wrong') : 'Something Went Wrong!';
const creating_account_reviewed_msg_one = tlang('notification_success_creating_account_with_reviewed_msg_one') ? tlang('notification_success_creating_account_with_reviewed_msg_one') : 'Your request for creating account has been received. You will get an email on ';
const creating_account_reviewed_msg_two = tlang('notification_success_creating_account_with_reviewed_msg_two') ? tlang('notification_success_creating_account_with_reviewed_msg_two') : 'from registration@aexdo.com once the request has been reviewed';
const creating_account_reviewed_msg = tlang('other_notification_sucess_your_request_for_creating_account_has_been_reviewed') ? tlang('other_notification_sucess_your_request_for_creating_account_has_been_reviewed') : 'Your request for creating account has been received. You will get an email on your email from registration@aexdo.com once the request has been reviewed';

// on Login Succeed
const onLoginSucceed = (data) => {
  return {
    type: actionTypes.LOGIN_SUCCEED,
    state: data
  }
}

// store User Data
const storeUserData = (resp) => {
  let finderprint = new fingerprint().get();
  return jwt.sign(resp, finderprint.toString())
}

// notification Success
const notificationSuccess = (msg) => {
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_SUCCESS,
    state: msg
  }
}

// notification Fail
const notificationFail = (err) => {
  let msg = error_something_wrong;
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    let translateMsg =notificationMsg(err.graphQLErrors[0].statusCode)
    msg = translateMsg;
  } else if (err.message) {
    msg = err.message;
  }
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_FAIL,
    state: msg
  }
}

// login action
export const logIn = (client, request) => {
  return dispatch => {
    const mutation = gql`
        mutation login_user($email: String!, $password: String!) {
            login_user(email: $email, password: $password) {
              _id
              name
              email
              companydetail
              company_name
              display_name
              password
              created_at
              userid
              token              
              accesspages {
                user_list {
                  status
                }
                user_request {
                  status
                  sub {
                    view_user
                    user_accept
                    add_user
                  }
                }
                plan {
                  status
                }
                language {
                  status
                }
                staff {
                  status
                  sub {
                    add_staff
                    view_staff
                    update_staff
                    block_staff
                    delete_staff
                  }
                }
                masters {
                  status
                }
                project {
                  status
                }
                licence_request{
                  status
                }
                plan_request{
                    status
                }
                project_token{
                    status
                }
                send_notification{
                    status
                }
                email_management{
                    status
                }
                notification_management{
                    status
                }
              }
            }
          }
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        email: Encrypt(request.username),
        password: Encrypt(request.password),
      }
    }).then(response => {
      if (response.data.login_user.company_name) {
        let list = JSON.parse(response.data.login_user.company_name);

        if (request.remember_me === true) {
          let remember_me = JSON.stringify(request)
          localStorage.setItem('_rememberme_', storeUserData(remember_me));
        } else {
          localStorage.removeItem("_rememberme_");
        }

        if (response.data.login_user.companydetail && !response.data.login_user.companydetail.confirm_account) {
          let req = {
            email: response.data.login_user.email,
            company: list[0]
          }
          dispatch(actions.companyLogIn(client, req))
          dispatch(notificationSuccess(loginsuccess))
        } else {
          localStorage.setItem("_user_", storeUserData(response.data.login_user))
          if (response.data.login_user.companydetail) {
            localStorage.setItem("_userDetail_", storeUserData(response.data.login_user.companydetail))
          }
          dispatch(onLoginSucceed(response.data))
          dispatch(notificationSuccess(loginsuccess))
          setTimeout(function () { window.location = '/' }, 1000);
        }
      }
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

//login step 2
export const companyLogIn = (client, request) => {
  return dispatch => {
    const mutation = gql`
        mutation check_user_company($email: String!, $companyname: String!, $projectid: String) {
            check_user_company(email: $email, companyname: $companyname, projectid: $projectid) {
              _id
              staff_id
              userid
              name
              email
              userattachments
              newuseraccesspage
              company_id
              company_name
              display_name
              company_address
              registration_country
              contact_number
              phone_number
              status
              approve_reject_by
              company_strength
              system_flag
              confirm_account
              staffuser
              firstname
              lastname
              role_id
              role_name
              useraccesspage {
                basic {
                  access_to_the_dashboard
                  project_list
                  project_details_consultation
                  campaign_list
                  campaign_details_consultation
                  probing_list
                  probing_details_consultation
                  analysis_consultation
                  project_documents_consultation
                  project_documents_download
                  personal_document_upload
                  personal_document_sharing
                }
                user_management {
                  consult_user_list
                  choose_plan
                  add_user_to_the_corporate_account
                  add_modules
                  manage_licenses_modules
                }
                project_management {
                  create_project
                  modify_project
                  archive_project
                  add_document_to_project
                  download_document
                  share_document
                  delete_document
                  invite_user
                }
                campaign_management {
                  create_campaign
                  modify_campaign
                  archive_campaign
                  add_document_to_campaign
                  download_document
                  share_document
                  delete_document
                }
                probing_management {
                  import_probing
                  consult_probing_list
                  create_probing
                  modify_probing
                  delete_probing
                }
                analysis_management {
                  import_analysis
                  consul_analysis
                }
                }
              plan {
                planid
                planname
                uploadsize
                usersize
                price
                limit
              }
              created_at
              updated_at
              token
              accesspages {
                user_list {
                  status
                }
                user_request {
                  status
                  sub {
                    view_user
                    user_accept
                    add_user
                  }
                }
                plan {
                  status
                }
                language {
                  status
                }
                staff {
                  status
                  sub {
                    add_staff
                    view_staff
                    update_staff
                    block_staff
                    delete_staff
                  }
                }
                masters {
                  status
                }
                project {
                  status
                }
                licence_request{
                  status
                }
                plan_request{
                    status
                }
                project_token{
                    status
                }
                send_notification{
                    status
                }
                email_management{
                    status
                }
                notification_management{
                    status
                }
              },
              attachments,
              owner
            }
          }          
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        email: Encrypt(request.email),
        companyname: Encrypt(request.company.replace(/ /g, "_").toLowerCase()),
        projectid: request.projectid ? Encrypt(request.projectid) : null,
      }
    })
      .then(response => {
        firebase.auth().signInWithEmailAndPassword(request.email, "firebase@123")
          .then(() => {
            if (request.projectid) {
              response.data.check_user_company.projectid = request.projectid;
            } else {
              response.data.check_user_company.projectid = null;
            }
            localStorage.setItem("_user_", storeUserData(response.data.check_user_company))

            //My code to set the Company Profile Image
            let allAttachments = JSON.parse(response.data.check_user_company.attachments);
            if(allAttachments.length > 0){
              let img = allAttachments.filter(function (element) {
                let key = Object.keys(element);
                if (key[0].includes("profile_pic")) {
                  return element
                }
              });
  
              let companyProfilePic = "";
              if (img.length > 0) {
                companyProfilePic = Object.values(img[0])[0];
              }else{
                companyProfilePic =  `/img/company_ava5.jpeg`
              }

              let credential = localStorage.getItem("_user_");
              if (credential) {
                let finderprint = new fingerprint().get();
                credential = jwt.verify(credential, finderprint.toString());
        
                  let storageData = {
                    ...credential,
                    company_profile_image: companyProfilePic
                  }
                localStorage.setItem("_user_", storeUserData(storageData))
              }
            }else{
              let companyProfilePic =  `/img/company_ava5.jpeg`;
              let credential = localStorage.getItem("_user_");
              if (credential) {
                let finderprint = new fingerprint().get();
                credential = jwt.verify(credential, finderprint.toString());
        
                  let storageData = {
                    ...credential,
                    company_profile_image: companyProfilePic
                  }
                localStorage.setItem("_user_", storeUserData(storageData))
              }
            }
            


            
            //My code to set the User Profile Image
            if(typeof response.data.check_user_company.userattachments != "undefined" && response.data.check_user_company.userattachments != "" && response.data.check_user_company.userattachments != "[]"){
              let userattachments = JSON.parse(response.data.check_user_company.userattachments)
              
              if(userattachments.length > 0){
                  let firstIndexProfileImage = Object.values(userattachments[0])[0];

                  var credential = localStorage.getItem("_user_");
                  if (credential) {
                    let finderprint = new fingerprint().get();
                    credential = jwt.verify(credential, finderprint.toString());
            
                    let pic = JSON.stringify([{ user_pic: firstIndexProfileImage }]);
                      let storageData = {
                        ...credential,
                        userattachments: pic
                      }
                    localStorage.setItem("_user_", storeUserData(storageData))
                  }
              }
            }

            dispatch(onLoginSucceed(response.data))
            // dispatch(notificationSuccess(loginsuccess))
            setTimeout(function () { window.location = '/' }, 1000);
          }).catch(function (error) {
            setTimeout(function () { window.location = '/' }, 1000); //I add this to solve admin redirect issue
            console.log("firebase error: ", error);
          });
      }).catch((error) => {                
        dispatch(notificationFail(error))
      });
  }
}

//signup action
export const signUpRequest = (client, request) => {
  return dispatch => {

    const mutation = gql`
                    mutation signup_company($name: String!, $email: String!, $company_name: String!, $company_address: String!, $registration_country: String!, $contact_number: String!, $company_strength: Int!, $lang_code: String) {
                        signup_company(name: $name, email: $email, company_name: $company_name, company_address: $company_address, registration_country: $registration_country, contact_number: $contact_number, company_strength: $company_strength, lang_code: $lang_code) {
                        _id
                        name
                        email
                        company_name
                        company_address
                        registration_country
                        contact_number
                        company_strength
                        company_id
                        }
                    }
                    `;
    client.mutate({
      mutation: mutation,
      variables: {
        name: request.name,
        email: request.email,
        company_name: request.company_name,
        company_address: request.company_address,
        registration_country: request.registration_country,
        contact_number: request.contact_number,
        company_strength: parseInt(request.company_strength),
        lang_code: request.lang_code
      }
    }).then(response => {

      //My code for company profile image
      if (request.profile_pics && request.profile_pics.length > 0) {
        var data = new FormData();
        data.append('files', request.profile_pics[0], 'profile_pic.jpg');
        data.append('userid', request.company_id); //Store company id
        uploadProfileFile(data, 'user_profile_pic/').then((response) => {
       })
      }

      if(request.profile_pics.length){
        var data = new FormData();
        data.append('files', request.profile_pics[0], 'profile_pic.jpg');
        data.append('companyid', "logo-"+response.data.signup_company.company_id);
        uploadFile(data, 'insert_attechment/').then((response) => {
          setTimeout(function () { window.location = "/signin"; }, 5000);
          dispatch(notificationSuccess(creating_account_reviewed_msg_one + request.email +' '+ creating_account_reviewed_msg_two))
        }).catch((err) => {
          dispatch(notificationFail(err))
        })
       }

        


       if(request.files){
        var data = new FormData();
        data.append('files', request.profile_pics[0], 'profile_pic.jpg');
        data.append('companyid', "signup-"+response.data.signup_company.company_id);
      request.files.map((val) => {
        data.append('files', val, val.name);
      })
      uploadFile(data, 'insert_attechment/').then((response) => {
        setTimeout(function () { window.location = "/signin"; }, 5000);
        dispatch(notificationSuccess(creating_account_reviewed_msg_one + request.email +' '+ creating_account_reviewed_msg_two))
      }).catch((err) => {
        dispatch(notificationFail(err))
      })
        }


       



    }).catch((error) => {
      dispatch(notificationFail(error))
    });

  }
}

// sign Up Update Request
export const signUpUpdateRequest = (client, request) => {
  return dispatch => {
    const mutation = gql`
          mutation edit_company_request($name: String!, $email: String!, $company_id: String!, $company_name: String!, $company_address: String!, $registration_country: String!, $contact_number: String!, $company_strength: String!) {
            edit_company_request (
                name: $name, 
                email: $email, 
                company_id: $company_id,
                company_name: $company_name,
                company_address: $company_address,
                registration_country: $registration_country,
                contact_number: $contact_number,
                company_strength: $company_strength
              ) {
                error
                success
                message
              }
            }
          `;
    client.mutate({
      mutation: mutation,
      variables: {
        name: Encrypt(request.name),
        email: Encrypt(request.email),
        company_id: Encrypt(request.company_id),
        company_name: Encrypt(request.company_name),
        company_address: Encrypt(request.company_address),
        registration_country: Encrypt(request.registration_country),
        contact_number: Encrypt(request.contact_number),
        company_strength: Encrypt(parseInt(request.company_strength))
      }
    }).then(response => {
      if (request.files.length > 0) {
        var data = new FormData();
        request.files.map((val) => {
          data.append('files', val, val.name);
        })
        data.append('companyid', request.company_id);
        uploadFile(data, 'insert_attechment/').then((response) => {
          setTimeout(function () { window.location = "/signin"; }, 5000);
          dispatch(notificationSuccess(creating_account_reviewed_msg_one + request.email +' '+ creating_account_reviewed_msg_two))
        }).catch((err) => {
          dispatch(notificationFail(err))
        })
      } else {
        setTimeout(function () { window.location = "/signin"; }, 5000);
        dispatch(notificationSuccess(creating_account_reviewed_msg_one + request.email +' '+ creating_account_reviewed_msg_two))
      }
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}


//signup by super admin action
export const signUpRequestAdmin = (client, request) => {
  return dispatch => {
    const mutation = gql`
                    mutation signup_company($name: String!, $email: String!, $company_name: String!, $company_address: String!, $registration_country: String!, $contact_number: String!, $company_strength: Int!) {
                        signup_company(name: $name, email: $email, company_name: $company_name, company_address: $company_address, registration_country: $registration_country, contact_number: $contact_number, company_strength: $company_strength) {
                        _id
                        name
                        email
                        company_name
                        company_address
                        registration_country
                        contact_number
                        company_strength
                        company_id
                        }
                    }
                    `;
                    client.mutate({
                      mutation: mutation,
                      variables: {
                          name: request.name,
                          email: request.email,
                          company_name: request.company_name,
                          company_address: request.company_address,
                          registration_country: request.registration_country,
                          contact_number: request.contact_number,
                          company_strength: parseInt(request.company_strength)
                      }
                  }).then(response => {
                      if (request.profile_pics.length) {
                          var data = new FormData();
                          data.append('files', request.profile_pics[0], 'profile_pic.jpg');
                          data.append('companyid', "logo-" + response.data.signup_company.company_id);
                          request.files.map((val) => {
                              data.append('files', val, val.name);
                          })
                          uploadFile(data, 'insert_attechment/').then((response) => {
                              dispatch(actions.getUserRequest(client, []))
                              dispatch(notificationSuccess(cmpcreate))
                          }).catch((err) => {
                              dispatch(notificationFail(err))
                          })
                      }
                      if (request.files) {
                          var data = new FormData();
                          data.append('files', request.profile_pics[0], 'profile_pic.jpg');
                          data.append('companyid', response.data.signup_company.company_id);
                          request.files.map((val) => {
                              data.append('files', val, val.name);
                          })
                          uploadFile(data, 'insert_attechment/').then((response) => {
                              dispatch(actions.getUserRequest(client, []))
                              dispatch(notificationSuccess(cmpcreate))
                          }).catch((err) => {
                              dispatch(notificationFail(err))
                          })
                      }
                  }).catch((error) => {
                      dispatch(notificationFail(error))
                  });
                  }
}

//upload file
const uploadFile = async (data, method) => {
  return new Promise(async (resolve, reject) => {

    axios.post(Server.ImageAPI + method, data)
      .then(function (response) {
        resolve(creating_account_reviewed_msg);
      })
      .catch(function (error) {
        resolve(creating_account_reviewed_msg);
        // reject(error);
      })
  })
}

//upload file
const uploadProfileFile = async (data, method) => {
  return new Promise(async (resolve, reject) => {

    axios.post(Server.ImageAPI + method, data)
      .then(function (response) {
        resolve({ msg: creating_account_reviewed_msg, response: response });
      })
      .catch(function (error) {
        resolve({ msg: creating_account_reviewed_msg });
        // reject(error);
      })
  })
}

// logout User
const logoutUser = () => {
  setTimeout(() => {
    localStorage.clear();
    window.location = "/signin";
  }, 5000);
}

//this will update the stored user access 
export const getAccess = (client, request) => {
  return dispatch => {
    const query = gql`
            query get_user_data($email: String!, $projectid: String) {
            get_user_data(email: $email, projectid: $projectid) {
              _id
              name
              email
              staffuser
              newuseraccesspage
              company_name
              default_unit
              confirm_account
              created_at
              active
              other_company
              default_disposal_type
              default_disposal_list
              disposal_data
              camp_invite
              lic_exp_dtl
              isplanexp
              islicenseexp
              role_id
              role_name
              account_status
              lic_expirydate
              plan_expirydate 
              plan{
                _id
                planid
                planname
                uploadsize
                usersize
                price
                limit
                purchasedate
                expirydate
              }
              useraccesspage {
                basic {
                  access_to_the_dashboard
                  project_list
                  project_details_consultation
                  campaign_list
                  campaign_details_consultation
                  probing_list
                  probing_details_consultation
                  analysis_consultation
                  project_documents_consultation
                  project_documents_download
                  personal_document_upload
                  personal_document_sharing
                }
                user_management {
                  consult_user_list
                  choose_plan
                  add_user_to_the_corporate_account
                  add_modules
                  manage_licenses_modules
                }
                project_management {
                  create_project
                  modify_project
                  archive_project
                  add_document_to_project
                  download_document
                  share_document
                  delete_document
                  invite_user
                }
                campaign_management {
                  create_campaign
                  modify_campaign
                  archive_campaign
                  add_document_to_campaign
                  download_document
                  share_document
                  delete_document
                }
                probing_management {
                  import_probing
                  consult_probing_list
                  create_probing
                  modify_probing
                  delete_probing
                }
                analysis_management {
                  import_analysis
                  consul_analysis
                }
                }
              accesspages {
                user_list {
                  status
                }
                user_request {
                  status
                  sub {
                    view_user
                    user_accept
                    add_user
                  }
                }
                plan {
                  status
                }
                language {
                  status
                }
                staff {
                  status
                  sub {
                    add_staff
                    view_staff
                    update_staff
                    block_staff
                    delete_staff
                  }
                }
                masters {
                  status
                }
                project {
                  status
                }
                licence_request{
                  status
                }
                plan_request{
                    status
                }
                project_token{
                    status
                }
                send_notification{
                    status
                }
                email_management{
                    status
                }
                notification_management{
                    status
                }
              }
            }
        }          
        `;
    client.query({
      query: query,
      variables: {
        email: Encrypt(request.email),
        projectid: request.projectid ? Encrypt(request.projectid) : null,
      }
    }).then(response => {
      
      var credential = localStorage.getItem("_user_");

      if (credential) {
        let finderprint = new fingerprint().get();
        credential = jwt.verify(credential, finderprint.toString());
        credential.other_company = response.data.get_user_data.other_company


        let storageData = {
          ...credential,
          accesspages: response.data.get_user_data.accesspages,
          isplanexp: response.data.get_user_data.isplanexp,
          islicenseexp: response.data.get_user_data.islicenseexp,
          lic_exp_dtl: response.data.get_user_data.lic_exp_dtl,
          account_status: response.data.get_user_data.account_status,
          useraccesspage: response.data.get_user_data.newuseraccesspage,
          newuseraccesspage: response.data.get_user_data.newuseraccesspage,
          projectid: request.projectid ? request.projectid : null,
          plan: response.data.get_user_data.plan,
          default_unit: response.data.get_user_data.default_unit,
          camp_invite : response.data.get_user_data.camp_invite,
          lic_expirydate : response.data.get_user_data.lic_expirydate,
          plan_expirydate : response.data.get_user_data.plan_expirydate,
          default_disposal_type: response.data.get_user_data.default_disposal_type,
          default_disposal_list: response.data.get_user_data.default_disposal_list,
          disposal_data: response.data.get_user_data.disposal_data,
          role_id: response.data.get_user_data.role_id,
          role_name: response.data.get_user_data.role_name,
        }

        localStorage.setItem("_user_", storeUserData(storageData))
        dispatch(onLoginSucceed(storageData))
        if (request.isRefresh) {
        
          setTimeout(function () {
            if(!request.projectid){
              window.location = '/'
            } else {
              window.location.reload(); 
            }
          }, 500);
        }
      }
    }).catch((error) => {
      if (error.graphQLErrors && error.graphQLErrors.length ) {
        logoutUser();
        let msg= notificationMsg(error.graphQLErrors[0].statusCode) 
        dispatch(notificationFail({ message: msg}))
      } else {
        dispatch(notificationFail(error))
      }
    });
  }
}

// forgot password api
export const forgetPassword = (client, request) => {
  return dispatch => {
    const mutation = gql`
        mutation forget_password($email: String!) {
            forget_password(email: $email ) {
              success
              message
            }
          }
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        email: Encrypt(request.email),
      }
    }).then(response => {
      dispatch(notificationSuccess(newpwdsendemail))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

//change password api
export const changePassword = (client, request) => {
  return dispatch => {
    const mutation = gql`
        mutation change_password($email: String!, $company_name: String!, $old: String!, $new: String!) {
            change_password(email: $email, company_name: $company_name, old_password: $old, new_password: $new) {
              error
              success
              message
            }
          }          
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        email: Encrypt(request.email),
        company_name: Encrypt(request.company_name),
        old: Encrypt(request.old),
        new: Encrypt(request.new),
      }
    }).then(response => {
      dispatch(notificationSuccess(passwordchanged))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

//change user settings
export const changeUserSetting = (client, request) => {
  return dispatch => {
    const mutation = gql`
        mutation edit_user_data($name: String!, $contact_number: String!, $email: String!) {
          edit_user_data(name: $name, contact_number: $contact_number, email: $email) {
            error
            success
            message
          }
        }`;
    client.mutate({
      mutation: mutation,
      variables: {
        name: Encrypt(request.name),
        contact_number: Encrypt(request.contact_number),
        email: Encrypt(request.email)
      }
    }).then(response => {

      var credential = localStorage.getItem("_user_");
      if (credential) {
        let finderprint = new fingerprint().get();
        credential = jwt.verify(credential, finderprint.toString());

        let storageData = {
          ...credential,
          name: request.name,
          contact_number: request.contact_number
        }
        localStorage.setItem("_user_", storeUserData(storageData))
        dispatch(onLoginSucceed(storageData))
      }

      if (request.profile_pics && request.profile_pics.length > 0) {

        var data = new FormData();
        data.append('files', request.profile_pics[0], 'user_pic.jpg');
        data.append('userid', request.userid);
        uploadProfileFile(data, 'user_profile_pic/').then((response) => {

          var credential = localStorage.getItem("_user_");
          if (credential) {
            let finderprint = new fingerprint().get();
            credential = jwt.verify(credential, finderprint.toString());

            let img = JSON.parse(credential.userattachments);
            //if (img.length == 0) {
              let pic = JSON.stringify([{ user_pic: response.response.data.url }]);
              let storageData = {
                ...credential,
                userattachments: pic
              }
              localStorage.setItem("_user_", storeUserData(storageData))
              dispatch(onLoginSucceed(storageData))
            //}
            window.location.reload()
          }
        }).catch((err) => {
          dispatch(notificationFail(err))
        })
      }
      dispatch(notificationSuccess(UserSettingChanged))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

//change company settings
export const changeCompanySetting = (client, request) => {
  return dispatch => {
    const mutation = gql`
        mutation edit_company_data($company_address: String!, $registration_country: String!, $company_strength: String!, $company_id: String!) {
          edit_company_data(company_address: $company_address, registration_country: $registration_country, company_strength: $company_strength, company_id: $company_id) {
            error
            success
            message
          }
        }`;
    client.mutate({
      mutation: mutation,
      variables: {
        company_address: Encrypt(request.company_address),
        registration_country: Encrypt(request.registration_country),
        company_strength: Encrypt(request.company_strength),
        company_id: Encrypt(request.company_id)
      }
    }).then(response => {

      var credential = localStorage.getItem("_user_");
      if (credential) {
        let finderprint = new fingerprint().get();
        credential = jwt.verify(credential, finderprint.toString());

        let storageData = {
          ...credential,
          company_address: request.company_address,
          registration_country: request.registration_country,
          company_strength: request.company_strength
        }
        localStorage.setItem("_user_", storeUserData(storageData))
        dispatch(onLoginSucceed(storageData))
      }

      //My code to update company image
       if (request.company_profile_pics && request.company_profile_pics.length > 0) {

         var data = new FormData();
         data.append('files', request.company_profile_pics[0], 'profile_pic.jpg');
         data.append('userid', request.company_id); //Store company id
         uploadProfileFile(data, 'user_profile_pic/').then((response) => {

          var credential = localStorage.getItem("_user_");
          if (credential) {
            let finderprint = new fingerprint().get();
            credential = jwt.verify(credential, finderprint.toString());
              let companyProfilePic = response.response.data.url;
              let storageData = {
                ...credential,
                company_profile_image: companyProfilePic
              }
              localStorage.setItem("_user_", storeUserData(storageData))
              dispatch(onLoginSucceed(storageData))
          
            window.location.reload()
          }
        }).catch((err) => {
          dispatch(notificationFail(err))
        })
       }
      dispatch(notificationSuccess(UserSettingChanged))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

const companyProjects = (data) => {
  return {
    type: actionTypes.COMPANY_PROJECT_LIST_LOGIN,
    state: data
  }
}

// get Company Project List
export const getCompanyProjectList = (client, request) => {
  return dispatch => {
    const query = gql`
          query user_project($companyname: String!, $userid: String!) {
            user_project(companyname: $companyname, userid: $userid) {
              projectid
              projectname
              purpose
              address
              projectOwner
              startDate
              isclosed
            }
          }
        `;
    client.query({
      query: query,
      variables: {
        userid: Encrypt(request.userid),
        companyname: Encrypt(request.companyname)
      },
      fetchPolicy: 'no-cache',
    }).then(response => {
      dispatch(companyProjects(response.data.user_project))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// get User Project List
export const getUserProjectList = (client, request) => {
  return dispatch => {
    const query = gql`
          query get_user_project_list($company: String!) {
            get_user_project_list(company: $company) {
              projectid
              projectname
              purpose
              isclosed
              address
              projectOwner
              startDate
              created_at
            }
          }        
        `;
    client.query({
      query: query,
      variables: {
        company: Encrypt(request.companyname)
      },
      fetchPolicy: 'no-cache',
    }).then(response => {
      dispatch(companyProjects(response.data.get_user_project_list))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

const companyProjectsForNotFound = (data) => {
  return {
    type: actionTypes.COMPANY_PROJECT_LIST_FOR_NOTFOUND,
    state: data
  }
}

// get User Project List
export const getUserProjectListForNotFound = (client, request) => {
  return dispatch => {
    const query = gql`
          query get_user_project_list($company: String!) {
            get_user_project_list(company: $company) {
              projectid
              projectname
              purpose
              isclosed
              address
              projectOwner
              startDate
            }
          }        
        `;
    client.query({
      query: query,
      variables: {
        company: Encrypt(request.companyname)
      },
      fetchPolicy: 'no-cache',
    }).then(response => {
      dispatch(companyProjectsForNotFound(response.data.get_user_project_list))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}