import { Icon } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, PopoverBody, Row, UncontrolledPopover } from 'reactstrap';
import ModalSendEmail from '../../modals/sendEmail';

export default function StakeHolder({ member, idx, title }) {
	const key = `stackholderpopover${idx}`;
	const authReducer = useSelector(state => state.authReducer);
	const [showEmail, setShowEmail] = useState(false);

	return (
		<>
			{showEmail && (
				<ModalSendEmail
					isOpen={showEmail}
					onClose={() => setShowEmail(false)}
					from={authReducer.userData.email}
					to={member.email}
					title={title}
				/>
			)}
			<div
				className={`border-class-detail ${
					member.roleid === 'AXD_RL_02' && member.status === 'approve'
						? 'selectedColorCampaign'
						: 'bgHover1'
				}`}
				style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
				id={key}
			>
				{member.name || `Request Pending${member.emailid ? ` (${member.emailid})` : ''}`}
				{member.email && (
					<UncontrolledPopover trigger="legacy" placement="bottom" target={key}>
						<PopoverBody style={{ textAlign: 'center' }}>
							<h5>{member.name}</h5>
							<br />
							<div>
								<Row>
									<Col xs={10}>
										<h6>{member.email}</h6>
									</Col>
									<Col xs={2} className="email-icon">
										<Icon onClick={() => setShowEmail(true)} style={{ verticalAlign: 'bottom' }}>
											email
										</Icon>
									</Col>
								</Row>
							</div>
						</PopoverBody>
					</UncontrolledPopover>
				)}
			</div>
		</>
	);
}
