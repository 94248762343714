import React from 'react';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import * as ChangeAppLanguageFront from '../../../../Layout/ChangeAppLanguageFront';
import __ from 'underscore';

const tlang = ChangeAppLanguageFront.translateLanguage;
const realtime_exp_modal_header = tlang('realtime_exp_modal_header') || 'Aexdo warning';
const common_btn_okay = tlang('common_btn_okay') || 'Okay';
const user_block_msg = tlang('user_block_msg') || 'Your account has blocked by Admin please contact the administrator.';

export default function ModalPlanExpirePreWarning({ isOpen, onClose }) {

	return (
		<Modal
			isOpen={isOpen}
			toggle={onClose}
			className={`modal-dialog--success modal-dialog--header`}
		>
			<div className="modal__header">
				<button className="lnr lnr-cross modal__close-btn" type="button" onClick={onClose} />
				<h4 className="bold-text  modal__title">{realtime_exp_modal_header}</h4>
			</div>
			<div className="modal__body">
				{user_block_msg}
			</div>

			<ButtonToolbar className="modal__footer">
				<Button color={'success'} onClick={() => onClose()}>
					{common_btn_okay}
				</Button>
			</ButtonToolbar>
		</Modal>
	);
}
