import React from 'react';
import { useSelector } from 'react-redux';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import * as ChangeAppLanguageFront from '../../../../Layout/ChangeAppLanguageFront';
import __ from 'underscore';
import moment from 'moment';
import styled from 'styled-components';

const tlang = ChangeAppLanguageFront.translateLanguage;
const client_renew_btn = tlang('client_renew_btn') || 'Renew';
const client_close_btn = tlang('client_close_btn') || 'Close';
const client_plan_expired_pre_warning = tlang('client_plan_expired_pre_warning') || 'Plan expired pre-warning';
const client_plan_expired_pre_warning_msg = tlang('client_plan_expired_pre_warning_msg') || 'Your membership plan will expire soon. Please renew it soon.';
const client_plan_details = tlang('client_plan_details') || 'Your membership plan details are as below';
const client_plan_id = tlang('client_plan_id') || 'Plan id';
const client_plan_name = tlang('client_plan_name') || 'Plan name';
const client_plan_amount = tlang('client_plan_amount') || 'Plan amount';
const client_plan_expired_date = tlang('client_plan_expired_date') || 'Plan expired date';
const client_contact_to_admin_for_renew_plan_msg = tlang('client_contact_to_admin_for_renew_plan_msg') || 'Please contact to your Company Administrator to Renew your membership plan.';

export default function ModalPlanExpirePreWarning({ isOpen, onClose, plan, onSave }) {

	const authReducer = useSelector(state => state.authReducer);

	return (
		<Modal
			isOpen={isOpen}
			toggle={onClose}
			className={`modal-dialog--success modal-dialog--header`}
		>
			<div className="modal__header">
				<button className="lnr lnr-cross modal__close-btn" type="button" onClick={onClose} />
				<h4 className="bold-text  modal__title">{client_plan_expired_pre_warning}</h4>
			</div>
			<div className="modal__body">
				<DetailsContainer>
					<h5 className="bold-text orange_color">{client_plan_expired_pre_warning_msg}</h5>
					{authReducer.userData.owner == authReducer.userData.email && (
					<>
						<h5 className="bold-text orange_color">{client_plan_details} : </h5>
						<div className="detail">
							<p>{client_plan_id}</p>
							<p>{plan.planid} </p>
						</div>
						<div className="detail">
							<p>{client_plan_name}</p>
							<p>{plan.planname} </p>
						</div>
						<div className="detail">
							<p>{client_plan_amount}</p>
							<p>${plan.price} </p>
						</div>
						<div className="detail">
							<p>{client_plan_expired_date}</p>
							<p>{moment.unix(plan.expirydate).format('DD/MM/YYYY HH:mm')} </p>
						</div>
					</>
					)}
					{authReducer.userData.owner !== authReducer.userData.email && (
					<h5 className="bold-text orange_color">
						{client_contact_to_admin_for_renew_plan_msg}
					</h5>
					)}
				</DetailsContainer>
			</div>

			<ButtonToolbar className="modal__footer">
				<Button color={'success'} outline={true} onClick={onClose}>
					{client_close_btn}
				</Button>
				<Button color={'success'} onClick={() => onSave()}>
					{client_renew_btn}
				</Button>
			</ButtonToolbar>
		</Modal>
	);
}

const DetailsContainer = styled.div`
	text-align: center;
	border: 1px solid var(--clr-aexdo);
	border-radius: 5px;
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
	gap: 5px;
	.detail {
		display: flex;
		justify-content: space-between;
		p {
			margin: 0;
		}
		p:first-child {
			font-weight: 700;
			color: var(--clr-aexdo);
		}
		p:last-child {
			font-size: 1.2em;
		}
	}
`;
