import React, { Component } from 'react';
import { connect } from "react-redux";
import { compose } from 'redux';
import ClientMenuList from './Common/SuperAdminMenu';

//This component will execute in every page load this will check the session and wrong url
class Notfound extends Component {

    componentWillMount() {
        let pathname = this.props.location.pathname;
        let checkClientPath = false;
        let clientAccPath = "/";

        //url list for not login users
        let pathList = [
            '/',
            '/signin',
            '/company',
            '/register'
        ];

        pathList.map((val, index) => {
            if (val === pathname) {
                return checkClientPath = true;
            }
        })
        
        if(pathname == '/client/signup'){
            checkClientPath = true;
        }

        //check if url exist in cliet menu
        ClientMenuList.map((val, index) => {
            var toVal = val.to;
            if (toVal === pathname) {
                return checkClientPath = true;
            }

            if (val.child && !checkClientPath) {
                val.child.map((res) => {
                    var childToVal = res.to;
                    if (childToVal == pathname) {
                        return checkClientPath = true;
                    }
                })
            }
        })

        if (!checkClientPath) {
            this.props.history.push(clientAccPath);
        }
    }

    render() {
        return (<div></div>);
    }
}

const mapStateToProps = (state) => { return {}; };

const mapDispatchToProps = (dispatch) => { return {}; };

export default compose(connect(mapStateToProps, mapDispatchToProps))(Notfound);

