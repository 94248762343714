import { useState, useEffect } from 'react';
import * as actions from '../../Store/actions/index';
import { useDispatch, useSelector } from 'react-redux';
import randomString from './randomString';
import orderBy from 'lodash/orderBy';

const STATUS_DELETE = 'Delete';
const STATUS_PENDING = 'Pending';
const PENDING_PROBING_COLOR = '#ffc107';

const createGroup = (group_name = [], all_list = []) => {
	const checkPendingGroup = group_name.filter(val => val.is_pending);
	if (!checkPendingGroup.length) {
		const create_family_id = randomString(30);
		const newObj = {
			family_id: create_family_id,
			family_name: '-',
			inr_ids: [],
			is_pending: true,
			group_is_checked: true,
		};
		group_name.unshift(newObj); // Add element to front of array
	}

	let groupList = [];
	group_name.forEach(all => {
		let assignGroupList = groupList.concat(all.inr_ids);
		groupList = assignGroupList;
	});

	all_list.forEach(all => {
		const checkId = groupList.filter(val => val === all.id);
		if (!checkId.length) {
			group_name[0].inr_ids = group_name[0].inr_ids.concat(all.id);
		}
	});
	// pending data on top
	let sortArray = orderBy(group_name, ['is_pending'], ['desc']);
	return sortArray;
};

export default function useGetMapData(projectId, campaignId, allProbings = false, allPendingProbings = false, refresh) {
	const dispatch = useDispatch();
	const apolloClient = useSelector(state => state.apolloClient);
	const authReducer = useSelector(state => state.authReducer);
	const dataVisualizaionReducer = useSelector(state => state.dataVisualizaionReducer);
	const projectReducer = useSelector(state => state.projectReducer);
	const probingReducer = useSelector(state => state.probingReducer);

	const [campaigns, setCampaigns] = useState([]);
	const [probings, setProbings] = useState([]);
	const [boundaries, setBoundaries] = useState([]);
	const [origins, setOrigins] = useState([]);
	const [destinations, setDestinations] = useState([]);
	const [boundariesGroup, setBoundariesGroup] = useState([]);
	const [originsGroup, setOriginsGroup] = useState([]);
	const [destinationsGroup, setDestinationsGroup] = useState([]);
	const [images, setImages] = useState([]);
	const [lngLat, setLngLat] = useState(); // [2.2107982, 49.1072647]
	const [savedMapCampaigns, setSavedMapCampaigns] = useState([]);
 
	useEffect(() => {
		const isadmin = authReducer.userData.email === authReducer.userData.owner;
		const reqInfo = {
			companyname: authReducer.userData.company_name,
			other_company: authReducer.userData.other_company,
			projectid: projectId,
			isadmin,
		};

		const reqMap = {
			mapid: '',
			companyname: authReducer.userData.company_name,
			projectid: projectId,
			map_name: 'DEFAULT_DESIGN_MAP',
		};

		dispatch(actions.getselectedmap(apolloClient.campaign, reqMap));
		dispatch(actions.getCampaignsForMap(apolloClient.campaign, reqInfo));
		const reqProject = {
			id: projectId,
			company: authReducer.userData.company_name,
		};
		dispatch(actions.getprojectDetails(apolloClient.project, reqProject));
		if (allProbings || allPendingProbings) {
			const reqProb = {
				campaignid: campaignId || '',
				projectid: projectId,
			};
			dispatch(actions.getProbingList(apolloClient.probing, reqProb));
		}
	}, [projectId, refresh]);

	useEffect(() => {
		if (!Array.isArray(dataVisualizaionReducer?.campaigns)) return;
		const campaigns = dataVisualizaionReducer.campaigns;
		const selectedCampaigns = campaigns.filter(c =>
			campaignId ? c.campaignid === campaignId : true
		);
		setCampaigns(selectedCampaigns);
		if (allProbings || allPendingProbings) {
			if (!Array.isArray(probingReducer.getProbingList)) return;
			const probings = probingReducer.getProbingList.filter(p =>
				(allPendingProbings && !allProbings) ? p.status !== STATUS_DELETE : true
			).map(p => {
				const campaign = campaigns.find(c => c.campaignid === p.campaignid);
				return {
					...p,
					symbol: campaign.symbol,
					campaign: p.campaignid,
					id: p.probingid,
					lat: Number(p.latitude),
					lng: Number(p.longitude),
					color: p.status === STATUS_PENDING ? PENDING_PROBING_COLOR : campaign.color,
					name: p.probingcode,
				};
			});
			setProbings(probings);
		} else {
			const probings = selectedCampaigns
				.map(c =>
					c.probing_data.map(p => ({
						...p,
						symbol: c.symbol,
						campaign: p.campaignid,
						id: p.probingid,
						lat: Number(p.latitude),
						lng: Number(p.longitude),
						color: c.color,
						name: p.probingcode,
					}))
				)
				.flat();
			setProbings(probings);
		}
	}, [
		dataVisualizaionReducer.campaigns,
		campaignId,
		allProbings,
		probingReducer.getProbingList,
		probingReducer.getProbingList?.length,
	]);

	useEffect(() => {
		if (Array.isArray(projectReducer?.projectDetails)) {
			const pd = projectReducer.projectDetails[0];
			setLngLat([Number(pd.longitude), Number(pd.latitude)]);
		}
	}, [projectReducer?.projectDetails]);

	useEffect(() => {
		if (!Array.isArray(dataVisualizaionReducer?.selectedmapdata?.boundries)) return;
		setBoundaries(dataVisualizaionReducer.selectedmapdata.boundries || []);
		setBoundariesGroup(
			createGroup(dataVisualizaionReducer?.selectedmapdata?.boundries_group || [], boundaries)
		);
	}, [
		dataVisualizaionReducer.selectedmapdata?.boundries?.length,
		dataVisualizaionReducer?.selectedmapdata?.boundries_group?.length,
		JSON.stringify(dataVisualizaionReducer.selectedmapdata?.boundries),
	]);

	useEffect(() => {
		if (!Array.isArray(dataVisualizaionReducer?.selectedmapdata?.origins)) return;
		setOrigins(dataVisualizaionReducer.selectedmapdata.origins || []);
		setOriginsGroup(
			createGroup(
				dataVisualizaionReducer.selectedmapdata?.origins_group || [],
				dataVisualizaionReducer.selectedmapdata.origins
			)
		);
	}, [
		dataVisualizaionReducer.selectedmapdata?.origins?.length,
		dataVisualizaionReducer.selectedmapdata?.origins_group?.length,
		JSON.stringify(dataVisualizaionReducer.selectedmapdata?.origins),
	]);

	useEffect(() => {
		if (!Array.isArray(dataVisualizaionReducer?.selectedmapdata?.destinations)) return;
		setDestinations(dataVisualizaionReducer.selectedmapdata.destinations || []);
		setDestinationsGroup(
			createGroup(
				dataVisualizaionReducer.selectedmapdata?.destinations_group || [],
				dataVisualizaionReducer.selectedmapdata.destinations
			)
		);
	}, [
		dataVisualizaionReducer.selectedmapdata?.destinations?.length,
		dataVisualizaionReducer.selectedmapdata?.destinations_group?.length,
		JSON.stringify(dataVisualizaionReducer.selectedmapdata?.destinations),
	]);

	useEffect(() => {
		if (!Array.isArray(dataVisualizaionReducer?.selectedmapdata?.images)) return;
		setImages(dataVisualizaionReducer?.selectedmapdata?.images || []);
	}, [dataVisualizaionReducer.selectedmapdata?.images?.length]);

	useEffect(() => {
		if (!Array.isArray(dataVisualizaionReducer?.selectedmapdata?.campaigns)) return;
		setSavedMapCampaigns(dataVisualizaionReducer?.selectedmapdata?.campaigns || []);
	}, [dataVisualizaionReducer.selectedmapdata?.campaigns?.length, dataVisualizaionReducer.campaigns]);

	return {
		campaigns,
		savedMapCampaigns,
		probings,
		boundaries,
		origins,
		destinations,
		boundariesGroup,
		originsGroup,
		destinationsGroup,
		images,
		lngLat,
	};
}
