/* eslint-disable camelcase */
import common_fr from './fr/common.json';
import common_en from './en/common.json';
import common_de from './de/common.json';
import common_it from './it/common.json';
import common_gb from './gb/common.json';
import common_es from './es/common.json';
import common_af from './af/common.json';
import common_br from './br/common.json';
import common_ru from './ru/common.json';
import common_lv from './lv/common.json';

export default {
  en: {
    common: common_en,
  },
  fr: {
    common: common_fr,
  },
  de: {
    common: common_de,
  },
  it: {
    common: common_it,
  },
  gb: {
    common: common_gb,
  },
  es: {
    common: common_es,
  },
  af: {
    common: common_af,
  },
  br: {
    common: common_br,
  },
  ru: {
    common: common_ru,
  },
  lv: {
    common: common_lv,
  },
};
