//import React, { PureComponent } from 'react';
import { Col, Container, Row , CustomInput, Card, CardBody} from 'reactstrap';
import React, { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { connect } from "react-redux";
//import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as actions from '../../../Store/actions/index';
import MUIDataTable from "mui-datatables";
import moment from 'moment';
import LoadingIcon from 'mdi-react/LoadingIcon';
import {
    Spinner
} from 'reactstrap';

import * as ChangeAppLanguageFront from '../Layout/ChangeAppLanguageFront';

let tlang  = ChangeAppLanguageFront.translateLanguage;
      
const sort_text = tlang('sort_text') || 'Sort';
const rows_per_page_text = tlang('rows_per_page_text') || 'Rows per page:';
const no_match_record_found = tlang('no_match_record_found') || 'Sorry, no matching records found';
const search_text = tlang('search_text') || 'Search';
const dashboard_sr_no = tlang('dashboard_sr_no') || 'Sr No';         
const dashboard_log_action = tlang('dashboard_log_action') || 'Action';
const dashboard_log_details = tlang('dashboard_log_details') || 'Details';
const dashboard_log_date = tlang('dashboard_log_date') || 'Date';
const dashboard_system_log = tlang('dashboard_system_log') || 'System log';
const sign_in_up_username = tlang('sign_in_up_username') || 'Username';

const initialState = {
    refresh:false,
    loading:true,
    systemLog:[],
    pageno: 0,
    limit: 10,
    totalrecord: 0,
    status: "",
    confirm: true,
    searchtext: "",
    sortcolumn: "",
    sorttype: "",
    tempPageno:0,
    tempLimit:10,
    tempSearchText:"",
    sample_count:0,
    systemLogList : []
};

function reducer (state, action) {
    switch (action.type) { 
        default:

            const upd = {};
            for (const key of Object.keys(action)) {
              upd[key] = action[key];
            }
            // console.log(`upd`, { upd });
            return { ...state, ...upd };

    }
}

function Systemlog() {
    const [state, localDispatch] = useReducer(reducer, initialState);
    const dispatch = useDispatch();
    const apolloClient = useSelector((state) => state.apolloClient);
    const authReducer = useSelector((state) => state.authReducer);
    const masterReducer = useSelector((state) => state.masterReducer);

    useEffect(() => {
    
            let request_parameter = {
                pageno: state.pageno,
                limit: state.limit,
                companyname: "master",
                searchtext : ""
            }
            dispatch(actions.getSystemLog(apolloClient.client, request_parameter));
    
    }, []);

    useEffect(() => {
        if(masterReducer){
            if(masterReducer.systemLog){
                let renderData = []
                masterReducer.systemLog && masterReducer.systemLog.data.map((val, index) => {
                    const date = moment(new Date(val.created_at) / 1000).format(
                      "DD/MM/YYYY HH:mm"
                    );
                    var dateToFormat = moment(date, "DD/MM/YYYY HH:mm", true).isValid();
                    renderData.push([
                      state.pageno == 0 ? index + 1 : state.pageno *  state.tempLimit + index + 1,
                      val.username,
                      val.action,
                      val.detail,
                      dateToFormat
                        ? moment
                            .unix(new Date(val.created_at) / 1000)
                            .format("DD/MM/YYYY HH:mm")
                        : moment.unix(val.created_at / 1000).format("DD/MM/YYYY HH:mm"),
                    ]);
                  });

                //localDispatch({ pageno: state.tempPageno });
                //localDispatch({ limit: state.tempLimit });

                localDispatch({ systemLog: masterReducer.systemLog.data, 
                                totalrecord: masterReducer.systemLog.totalrecord,
                                loading: false,
                                systemLogList: renderData });

               
            }
        }

    }, [
        masterReducer.systemLog,
    ]);

    function changePage (page, limit, searchtext) {
        let companyname = authReducer.userData.company_name
        if(authReducer.userData.company_name == '["superadmin"]'){
            companyname = "master" 
        }

        let finalSearchText =  searchtext == null || searchtext == undefined ? '' : searchtext

        let request = {
          pageno: page,
          limit: limit,
          companyname:companyname,
          searchtext : finalSearchText
        };

        localDispatch({ pageno: page, 
                        tempLimit: limit,
                        tempSearchText: finalSearchText,
                        searchtext: finalSearchText });

        dispatch(actions.getSystemLog(apolloClient.client, request));
       
    }


    const log_columns = [
        { name: dashboard_sr_no,
            options: {
                sort: false,
            }, 
        },                
        { name: sign_in_up_username , 
            options: {
                sort: false,
            }, 
        },
        { name: dashboard_log_action,
            options: {
                sort: false,
            },  
        },
        { name: dashboard_log_details,
            options: {
                sort: false,
            },
        },
        { name: dashboard_log_date,
            options: {
                sort: false,
            },  
        },
    ];

    const log_options = {
        filterType: "checkbox",
        selectableRows: "none",
        print: false,
        download: false,
        viewColumns: false,
        filter: false,
        responsive: "stacked",
        textLabels: {
            body: {
            noMatch: no_match_record_found,
            toolTip: sort_text
            },
            pagination: {
            rowsPerPage: rows_per_page_text,
            },
            toolbar: {
            search: search_text
            },
        },
        serverSide: true,
        limit: state.limit,
        page: state.pageno,
        count: state.totalrecord,
        searchText: state.searchtext,
        rowsPerPage: 10,
        onTableChange: (action, tableState) => {
            let page = tableState.page;
            let rowsPerPage = tableState.rowsPerPage;
            let searchText = tableState.searchText;
            switch (action) {
            case "changeRowsPerPage":
                changePage(
                page,
                rowsPerPage,
                searchText
                );
                break;
            case "changePage":
                changePage(
                page,
                rowsPerPage,
                searchText
                );
                break;
            case "search":
                changePage(
                page,
                rowsPerPage,
                searchText
                );
            break;
            }
        },
    };

    return(
        <>
        <Container>
                <h3 className="page-title" style={{ marginBottom: "20px" }}>
                    {dashboard_system_log}
                  </h3>
                {state.loading ? (
                    <div className="div-spinner"> <Spinner size="500" className="success" /></div>
                    ) : (  
                        <MUIDataTable
                            data={state.systemLogList}
                            columns={log_columns}
                            options={log_options}
                        /> 
                    )
                }
            </Container>
        </>
    )

}

export default Systemlog;