export default function formatDepth(depth, unit = 'M') {
	if (isNaN(depth)) return '0';
	const divider = unit === 'CM' ? 1 : 100;
	const decimal = unit === 'CM' ? 0 : 2;
	return Number(Number(depth) / divider).toFixed(decimal);
}

export function formatDepthNumber(depth, unit = 'M') {
	return Number(formatDepth(depth, unit));
}

export function formatLngLat(lngLat) {
	if (isNaN(lngLat)) return '0';
	return Number(lngLat).toFixed(7);
}
