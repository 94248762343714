/**
 * Styling forms, labels and inputs
 * override some bootstrap css
 */

import styled from 'styled-components';

const Form = styled.div`
	font-size: 14px;
	.form-group {
		margin-bottom: 0.5rem;
	}
	label,
	.label {
		font-weight: 500;
		margin-bottom: 0;
		font-size: 0.8em;
	}
	.data-container {
		display: flex;
		align-items: center;

		flex-direction: row;
		&.column {
			flex-direction: column;
		}
		.label::after {
			content: ':';
			padding-inline: 1ch;
		}
		.data {
			font-size: 1.05em;
			display: inline-flex;
			align-items: center;
			gap: 10px;
		}
	}
	.flex-row {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 2px;
		.data-container {
			flex: 1;
			min-width: 200px;
			padding: 3px;
			border: 1px solid #ccc;
			border-radius: 3px;
		}
	}
	input,
	textarea,
	.datepicker {
		padding: 0.25rem 0.5rem;
		font-size: 1em;
	}
	.inline-flex {
		display: inline-flex;
		gap: 1em;
	}
`;

export default Form;
