const Menu = [
    {
      label: "adminmenu_dashboard",
      icon: "dashboard",
      to: "/",
      key: "all",
    },
    {
      label: "admin_companies",
      icon: "business",
      hasChild: true,
      child: [
        {
          label: "adminmenu_user_list",
          icon: "list",
          to: "/admin/companyList",
          key: "user_list",
        },
        {
          label: "adminmenu_user_request",
          icon: "person_add",
          to: "/admin/request",
          key: "user_request",
        },
        {
          label: "client_signup_wizard_users",
          icon: "group",
          to: "/admin/companyUsers",
          key: "user_request",
        },
      ],
    },
    // {
    //     label: 'menu.plan',
    //     icon: 'bar_chart',
    //     to: '/admin/plan',
    //     key: "plan"
    // },
    {
      label: "adminmenu_language",
      icon: "language",
      to: "/admin/language",
      key: "language",
    },
    {
      label: "adminmenu_staff",
      icon: "supervised_user_circle",
      to: "/admin/staff",
      key: "staff",
    },
    {
      label: "adminmenu_masters",
      icon: "ac_unit",
      hasChild: true,
      child: [
        {
          label: "adminmenu_plan_masters",
          icon: "pages",
          to: "/admin/planMasters",
          key: "masters",
        },
        {
          label: "adminmenu_material_masters",
          icon: "pages",
          to: "/admin/materialMasters",
          key: "masters",
        },
        {
          label: "adminmenu_test_masters",
          icon: "pages",
          to: "/admin/testMasters",
          key: "masters",
        },
      ],
    },
    {
      label: "adminmenu_projects",
      icon: "business_center",
      to: "/admin/projects",
      key: "project",
    },
    {
      label: "adminmenu_licenceRequest",
      icon: "credit_card",
      to: "/admin/licenceRequest",
      key: "licence_request",
    },
    {
      label: "adminmenu_planRequest",
      icon: "featured_play_list",
      to: "/admin/planRequest",
      key: "plan_request",
    },
   /* {
      label: "clientmenu_invoice",
      icon: "money",
      to: "/admin/invoices",
      key: "plan_request",
    }, */
    {
      label: "adminmenu_projectToken",
      icon: "assignment",
      to: "/admin/projectToken",
      key: "project_token",
    },
    {
      label: "adminmenu_send_notification",
      icon: "notifications_active",
      to: "/admin/sendNotification",
      key: "send_notification",
    },
    {
      label: "adminmenu_email_management",
      icon: "mail",
      to: "/admin/emailManagement",
      key: "all",
      isAdmin: true,
    },
    {
      label: "adminmenu_notification_management",
      icon: "notifications",
      to: "/admin/notificationManagement",
      key: "all",
      isAdmin: true,
    },
 /*   {
      label: 'dashboard_system_log',
      icon: 'assignment',
      to: '/admin/systemLog',
      key: "all",
      isAdmin: true
    }, */
    {
      // label: '',
      // icon: 'assignment',
      to: "/account/profile",
      key: "project",
    },
    
  ];
  
  export default Menu;
  