import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    testAnalysisDataByProbingList : []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CAMPAIGNS_FOR_MAP:
            return updateObject(state, { campaigns: action.state })
        case actionTypes.GET_MAPVISUALIZE_DATA_LIST:
            return updateObject(state, { allmaplist: action.state })
        case actionTypes.SAVE_MAPVISUALIZE_DATA:
            return updateObject(state, { lastsavedmapdata: action.state })
        case actionTypes.GET_SELECTED_MAPVISUALIZE_DATA:
            return updateObject(state, { selectedmapdata: action.state })
        case actionTypes.UPDATED_MAPVISUALIZE_DATA:
            return updateObject(state, { updatedmapdata: action.state })
        case actionTypes.GET_MAP_IMAGE_GALLERY:
            return updateObject(state, { imageGalleryList: action.state })
        case actionTypes.GET_TEST_ANALYSIS_PROBING_DATA:
            return updateObject(state, { testAnalysisDataByProbingList: action.state })
        case actionTypes.GET_MULTIPLE_POLLUTANT_WISE_TEST_ANALYSIS_DATA:
            return updateObject(state, { multiplePollutantWisetestAnalysisData: action.state })
        case actionTypes.GET_TEST_ANALYSIS_DATA_PROJECT_WISE:
            return updateObject(state, { testAnalysisDataByProjectWise: action.state })
        case actionTypes.GET_TEST_ANALYSIS_MULTIPLE_PROBING_DATA:
            return updateObject(state, { multipleProbingList: action.state })
        case actionTypes.GET_MAP_EXPORT_DATA_LIST:
            return updateObject(state, { mapExportDataList: action.state })
        default:
            return state;
    }
}

export default reducer