import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';

/* get type wise other materials */
export const getMaterialListfilter = otherMaterialList => {
	//My code
	const languageCode = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
	const matList = otherMaterialList.map(val => {
		const lang_data = filter(val.lang_data, ['lang_code', languageCode]);
		if (lang_data.length > 0) val.name = lang_data[0]['lang_text'];
		return val;
	});

	return sortBy(matList, ['name']);
};
