import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    refreshNotificationRedirectPage : ""
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CURRENT_URL:
            return updateObject(state, { refreshViewProbing: action.state })
        case actionTypes.CURRENT_NOTIFICATION_REDIRECT_PAGE_URL:
            return updateObject(state, { refreshNotificationRedirectPage: action.state })
        default:
            return state;
    }
}

export default reducer