import React, { Component } from 'react';
import * as ChangeAppLanguageFront from '../Layout/ChangeAppLanguageFront';
let tlang = ChangeAppLanguageFront.translateLanguage;

const renew_plan_request_pending = tlang('renew_plan_request_pending') ? tlang('renew_plan_request_pending') : 'Your renew plan request is still pending, please contact axedo';
const notification_err_license_renew_request_api_msg = tlang('notification_err_license_renew_request_api_msg') ? tlang('notification_err_license_renew_request_api_msg') : 'License renew request already sent';
const payment_request_pending = tlang('payment_request_pending') ? tlang('payment_request_pending') : 'Your payment confirmation request is still pending to approve';
const company_request_pending = tlang('company_request_pending') ? tlang('company_request_pending') : 'Your company request is still pending to approve, please contact axedo';
const dashboard_modal_your_plan_is_expired = tlang('dashboard_modal_your_plan_is_expired') ? tlang('dashboard_modal_your_plan_is_expired') : 'Your plan is expired, please select any one membership plan.';
const project_already_exist = tlang('project_already_exist') ? tlang('project_already_exist') : 'Project Name already Exist.';
const email_password_incorrect = tlang('email_password_incorrect') ? tlang('email_password_incorrect') : 'Email or password incorrect';
const company_exists = tlang('company_exists') ? tlang('company_exists') : 'Company already exists';
const email_exists = tlang('email_exists') ? tlang('email_exists') : 'User Email already exists';
const email_already_exists = tlang('email_already_exists') ? tlang('email_already_exists') : 'Email already exists.';
const staff_not_found = tlang('staff_not_found') ? tlang('staff_not_found') : 'Staff member not found.';
const staff_login_not_in_mobile = tlang('staff_login_not_in_mobile') ? tlang('staff_login_not_in_mobile') : 'Staff login not allowed in mobile';
const account_locked_msg = tlang('account_locked_msg') ? tlang('account_locked_msg') : "Sorry your account has been locked! Please contact support.";
const error_something_wrong = tlang('error_something_wrong') ? tlang('error_something_wrong') : 'Something Went Wrong!';
const error_staff_list = tlang('error_staff_list') ? tlang('error_staff_list') : "Error in get staff user's list.";
const error_company_request_list = tlang('error_company_request_list') ? tlang('error_company_request_list') : 'Error in get company request list';
const error_update_staff = tlang('error_update_staff') ? tlang('error_update_staff') : 'Error in update staff user';
const error_delete_staff = tlang('error_delete_staff') ? tlang('error_delete_staff') : 'Error in delete staff user.';
const error_block_staff = tlang('error_block_staff') ? tlang('error_block_staff') : 'Error in block staff user.';
const error_create_staff = tlang('error_create_staff') ? tlang('error_create_staff') : 'Error in create staff user';
const invalid_token = tlang('invalid_token') ? tlang('invalid_token') : "Invalid token or expired.";
const user_not_found = tlang('user_not_found') ? tlang('user_not_found') : 'User not found';
const error_forget_password = tlang('error_forget_password') ? tlang('error_forget_password ') : 'Error in forget password';
const mail_failed = tlang('mail_failed') ? tlang('mail_failed') : 'Mail sent failed';
const company_not_found = tlang('company_not_found') ? tlang('company_not_found') : 'Company not found';
const error_approve_company = tlang('error_approve_company') ? tlang('error_approve_company') : 'Error in approve company';
const password_not_match = tlang('password_not_match') ? tlang('password_not_match') : 'Old password is not match.';
const error_plan_update = tlang('error_plan_update') ? tlang('error_plan_update') : 'Error in plan update';
const error_get_licence = tlang('error_get_licence') ? tlang('error_get_licence') : 'Error in get licence';
const error_get_tokens = tlang('error_get_tokens') || 'Error in get tokens';
const error_update_token = tlang('error_update_token') || 'Error in token update';
const error_confirm_company = tlang('error_confirm_company') ? tlang('error_confirm_company') : 'Error in confirm company';
const error_block_company = tlang('error_block_company') ? tlang('error_block_company') : 'Error in block company';
const error_update_plan = tlang('error_update_plan') ? tlang('error_update_plan') : 'Error in update plan.';
const plan_not_found = tlang('plan_not_found') ? tlang('plan_not_found') : 'Plan not found.';
const error_plan_list = tlang('error_plan_list') ? tlang('error_plan_list') : 'Error in plan list.';
const licence_not_found = tlang('licence_not_found') ? tlang('licence_not_found') : 'Licence not found';
const error_licence_update = tlang('error_licence_update') ? tlang('error_licence_update') : 'Error in licence update';
const company_update_error = tlang('company_update_error') ? tlang('company_update_error') : 'Company update error';
const user_update_error = tlang('user_update_error') ? tlang('user_update_error') : 'User update error';
const maximum_limit_reached = tlang('maximum_limit_reached') ? tlang('maximum_limit_reached') : 'Maximum limit reached';
const error_add_member = tlang('error_add_member') ? tlang('error_add_member') : 'Error in add member';
const error_delete_member = tlang('error_delete_member') ? tlang('error_delete_member') : 'Error in delete member';
const member_exist_project = tlang('member_exist_project') ? tlang('member_exist_project') : 'Member Exist in Project or Campaign.';
const plan_name_exists = tlang('plan_name_exists') ? tlang('plan_name_exists') : 'Plan name already exists';
const licence_name_exists = tlang('licence_name_exists') ? tlang('licence_name_exists') : 'Licence name already exists.';
const select_one_project = tlang('select_one_project') ? tlang('select_one_project') : 'Please select any one project.';
const select_one_file = tlang('select_one_file') ? tlang('select_one_file') : 'Please select any one file or user';
const files_not_available = tlang('files_not_available') ? tlang('files_not_available') : 'Files not available';
const error_document_delete = tlang('error_document_delete') ? tlang('error_document_delete') : 'Document not delete.';
const error_template_detail = tlang('error_template_detail') ? tlang('error_template_detail') : 'Template detail not available';
const licence_exp_msg = tlang('licence_exp_msg') ? tlang('licence_exp_msg') : 'Your Licence is Expired please contact the administrator.';
const error_project_list = tlang('error_project_list') ? tlang('error_project_list') : 'Error in get project list';
const error_create_project = tlang('error_create_project') ? tlang('error_create_project') : 'Error in create project';
const error_update_project_status = tlang('error_update_project_status') ? tlang('error_update_project_status') : 'Error in update project status';
const error_update_project = tlang('error_update_project') ? tlang('error_update_project') : 'Error in update project';
const error_delete_project = tlang('error_delete_project') ? tlang('error_delete_project') : 'Error in delete project';
const licence_not_available = tlang('licence_not_available') ? tlang('licence_not_available') : 'Licence not available';
const project_not_available = tlang('project_not_available') ? tlang('project_not_available') : 'Project not available"';
const project_not_found = tlang('project_not_found') ? tlang('project_not_found') : 'Project not found';
const user_exist_with_same_licence = tlang('user_exist_with_same_licence') ? tlang('user_exist_with_same_licence') : 'User already exist in project with same licence';
const user_already_exists_project = tlang('user_already_exists_project') ? tlang('user_already_exists_project') : 'User already exists in project';
const user_exists_other_company = tlang('user_exists_other_company') ? tlang('user_exists_other_company') : 'User already exists in other company';
const client_add_promotor = tlang('client_add_promotor') ? tlang('client_add_promotor') : 'Please add promotor member';
const promoter_licence_error = tlang('promoter_licence_error') ? tlang('promoter_licence_error') : 'Promoter Licence not available';
const campaign_already_assign = tlang('campaign_already_assign') ? tlang('campaign_already_assign') : 'Campaign already assign in this project, so please first delete campaign';
const licence_for_promoter = tlang('licence_for_promoter') ? tlang('licence_for_promoter') : 'Please select licence for promoter';
const token_used = tlang('token_used') ? tlang('token_used') : 'Project token already used';
const material_type_exist = tlang('material_type_exist') ? tlang('material_type_exist') : 'Material type already exist';
const material_not_found = tlang('material_not_found') ? tlang('material_not_found') : 'Material not found';
const request_failed = tlang('request_failed') ? tlang('request_failed') : 'Request failed';
const another_req_pending = tlang('another_req_pending') ? tlang('another_req_pending') : 'Another request is already pending';
const member_already_exist = tlang('member_already_exist') ? tlang('member_already_exist') : 'Member already exist';
const request_status_update_failed = tlang('request_status_update_failed') ? tlang('request_status_update_failed') : 'Request status update failed';
const plan_request_not_found = tlang('plan_request_not_found') ? tlang('plan_request_not_found') : 'Plan request not found"';
const camp_detail_not_available = tlang('camp_detail_not_available') ? tlang('camp_detail_not_available') : 'Campaign detail not available';
const error_add_lang_data = tlang('error_add_lang_data') ? tlang('error_add_lang_data') : 'Unable to Add Language Data';
const error_update_language_data = tlang('error_update_language_data') ? tlang('error_update_language_data') : 'Unable to Update Language Data';
const lang_key_exist = tlang('lang_key_exist') ? tlang('lang_key_exist') : 'Language Data Key already Exist';
const error_add_test_setting = tlang('error_add_test_setting') ? tlang('error_add_test_setting') : 'Unable to Add Test Setting';
const error_update_test_setting = tlang('error_update_test_setting') ? tlang('error_update_test_setting') : 'Unable to Update Test Setting';
const test_setting_exist = tlang('test_setting_exist') ? tlang('test_setting_exist') : 'Test Setting already Exist';
const probing_already_assign_campaign = tlang('probing_already_assign_campaign') ? tlang('probing_already_assign_campaign') : 'Probing already assign in this campaign, please first delete probing';
const error_add_map_data = tlang('error_add_map_data') ? tlang('error_add_map_data') : 'Error while Add Map Data';
const min_one_probing = tlang('min_one_probing') ? tlang('min_one_probing') : 'Please create minimum one probing in this campaing';
const duplicate_probing_code = tlang('duplicate_proxbing_code') ? tlang('duplicate_probing_code') : 'Duplicate Probing Code Found';
const probing_already_exist = tlang('probing_already_exist') ? tlang('probing_already_exist') : 'This probing has already existed in the system';

const file_not_found = tlang('file_not_found') ? tlang('file_not_found') : 'Attchment Not Found';
const space_error = tlang('space_error') ? tlang('space_error') : 'Space not available';
const error_sheetname = tlang('error_sheetname') ? tlang('error_sheetname') : 'Sheet name not found';
const error_xls_read = tlang('error_xls_read') ? tlang('error_xls_read') : 'Error in Excel Read';
const error_doc_upload = tlang('error_doc_upload') ? tlang('error_doc_upload') : 'Error in document upload';

const error_copy_disposal = tlang('error_copy_disposal') ? tlang('error_copy_disposal') : 'Error while copying disposal';
const error_support_invitation_request = tlang('error_support_invitation_request') || 'Support Invitation Request is already pending to approve';

let NotificationMessage = [
    {
        message: company_exists,
        statusCode: 101
    }, {
        message: email_exists,
        statusCode: 102
    }, {
        message: email_already_exists,
        statusCode: 103
    }, {
        message: staff_not_found,
        statusCode: 104
    }, {
        message: staff_login_not_in_mobile,
        statusCode: 105
    }, {
        message: email_password_incorrect,
        statusCode: 106
    }, {
        message: account_locked_msg,
        statusCode: 107
    }, {
        message: error_something_wrong,
        statusCode: 108
    }, {
        message: error_staff_list,
        statusCode: 109
    }, {
        message: error_company_request_list,
        statusCode: 110
    }, {
        message: error_update_staff,
        statusCode: 111
    }, {
        message: error_delete_staff,
        statusCode: 112
    }, {
        message: error_block_staff,
        statusCode: 113
    }, {
        message: error_create_staff,
        statusCode: 114
    }, {
        message: invalid_token,
        statusCode: 115
    }, {
        message: user_not_found,
        statusCode: 116
    }, {
        message: error_forget_password,
        statusCode: 117
    }, {
        message: mail_failed,
        statusCode: 118
    }, {
        message: company_not_found,
        statusCode: 119
    }, {
        message: error_approve_company,
        statusCode: 120
    }, {
        message: password_not_match,
        statusCode: 121
    }, {
        message: error_plan_update,
        statusCode: 122
    }, {
        message: error_get_licence,
        statusCode: 123
    }, {
        message: error_confirm_company,
        statusCode: 124
    }, {
        message: error_block_company,
        statusCode: 125
    }, {
        message: error_update_plan,
        statusCode: 126
    }, {
        message: plan_not_found,
        statusCode: 127
    }, {
        message: error_plan_list,
        statusCode: 128
    }, {
        message: licence_not_found,
        statusCode: 129
    }, {
        message: error_licence_update,
        statusCode: 130
    }, {
        message: company_update_error,
        statusCode: 131
    }, {
        message: user_update_error,
        statusCode: 132
    }, {
        message: maximum_limit_reached,
        statusCode: 133
    }, {
        message: error_add_member,
        statusCode: 134
    }, {
        message: error_delete_member,
        statusCode: 135
    }, {
        message: member_exist_project,
        statusCode: 136
    }, {
        message: plan_name_exists,
        statusCode: 137
    }, {
        message: licence_name_exists,
        statusCode: 138
    }, {
        message: select_one_project,
        statusCode: 139
    }, {
        message: select_one_file,
        statusCode: 140
    }, {
        message: files_not_available,
        statusCode: 141
    }, {
        message: error_document_delete,
        statusCode: 142
    }, {
        message: error_template_detail,
        statusCode: 143
    }, {
        message: dashboard_modal_your_plan_is_expired,
        statusCode: 144
    }, {
        message: renew_plan_request_pending,
        statusCode: 145
    }, {
        message: licence_exp_msg,
        statusCode: 146
    }, {
        message: payment_request_pending,
        statusCode: 147
    }, {
        message: company_request_pending,
        statusCode: 148
    }, {
        message: error_project_list,
        statusCode: 201
    }, {
        message: error_create_project,
        statusCode: 202
    }, {
        message: project_already_exist,
        statusCode: 203
    }, {
        message: error_update_project_status,
        statusCode: 204
    }, {
        message: error_update_project,
        statusCode: 205
    }, {
        message: error_delete_project,
        statusCode: 206
    }, {
        message: licence_not_available,
        statusCode: 207
    }, {
        message: project_not_available,
        statusCode: 208
    }, {
        message: project_not_found,
        statusCode: 209
    }, {
        message: user_exist_with_same_licence,
        statusCode: 210
    }, {
        message: user_already_exists_project,
        statusCode: 211
    }, {
        message: user_exists_other_company,
        statusCode: 212
    }, {
        message: client_add_promotor,
        statusCode: 213
    }, {
        message: licence_not_available,
        statusCode: 214
    }, {
        message: promoter_licence_error,
        statusCode: 215
    }, {
        message: campaign_already_assign,
        statusCode: 216
    }, {
        message: licence_for_promoter,
        statusCode: 217
    }, {
        message: token_used,
        statusCode: 218
    }, {
        message: error_project_list,
        statusCode: 301
    }, {
        message: error_create_project,
        statusCode: 302
    }, {
        message: error_update_project_status,
        statusCode: 303
    }, {
        message: error_update_project,
        statusCode: 304
    }, {
        message: error_delete_project,
        statusCode: 305
    }, {
        message: licence_not_available,
        statusCode: 306
    }, {
        message: project_not_available,
        statusCode: 307
    }, {
        message: project_not_found,
        statusCode: 308
    }, {
        message: material_type_exist,
        statusCode: 309
    }, {
        message: material_not_found,
        statusCode: 310
    }, {
        message: request_failed,
        statusCode: 311
    }, {
        message: another_req_pending,
        statusCode: 312
    }, {
        message: request_status_update_failed,
        statusCode: 313
    }, {
        message: plan_request_not_found,
        statusCode: 314
    }, {
        message: error_add_lang_data,
        statusCode: 315
    }, {
        message: error_update_language_data,
        statusCode: 316
    }, {
        message: lang_key_exist,
        statusCode: 317
    }, {
        message: error_add_test_setting,
        statusCode: 318
    }, {
        message: error_update_test_setting,
        statusCode: 319
    }, {
        message: test_setting_exist,
        statusCode: 320
    }, {
        message: notification_err_license_renew_request_api_msg,
        statusCode: 321
    }, {
        message: error_copy_disposal,
        statusCode: 322
    }, {
        message: error_project_list,
        statusCode: 401,
    }, {
        message: error_create_project,
        statusCode: 402,
    }, {
        message: error_update_project_status,
        statusCode: 403,
    }, {
        message: error_update_project,
        statusCode: 404,
    }, {
        message: error_delete_project,
        statusCode: 405,
    }, {
        message: licence_not_available,
        statusCode: 406,
    }, {
        message: project_not_available,
        statusCode: 407,
    }, {
        message: project_not_found,
        statusCode: 408,
    }, {
        message: user_already_exists_project,
        statusCode: 409,
    }, {
        message: user_exists_other_company,
        statusCode: 410,
    }, {
        message: camp_detail_not_available,
        statusCode: 411,
    }, {
        message: member_already_exist,
        statusCode: 412,
    }, {
        message: licence_not_available,
        statusCode: 413,
    }, {
        message: probing_already_assign_campaign,
        statusCode: 414,
    }, {
        message: error_add_map_data,
        statusCode: 415,
    }, {
        message: min_one_probing,
        statusCode: 416,
    }, {
        message: duplicate_probing_code,
        statusCode: 417,
    }, {
        message: project_not_found,
        statusCode: 418,
    }, {
        message: error_project_list,
        statusCode: 501
    }, {
        message: error_create_project,
        statusCode: 502
    }, {
        message: error_update_project_status,
        statusCode: 503
    }, {
        message: error_update_project,
        statusCode: 504
    }, {
        message: error_delete_project,
        statusCode: 505
    }, {
        message: licence_not_available,
        statusCode: 506
    }, {
        message: project_not_available,
        statusCode: 507
    }, {
        message: project_not_found,
        statusCode: 508
    }, {
        message: user_already_exists_project,
        statusCode: 509
    }, {
        message: user_exists_other_company,
        statusCode: 510
    }, {
        message: camp_detail_not_available,
        statusCode: 511
    }, {
        message: member_already_exist,
        statusCode: 512
    }, {
        message: probing_already_exist,
        statusCode: 513
    }, {
        message: error_something_wrong,
        statusCode: 400,
    },    // rest API  message 
    {
        message: file_not_found,
        statusCode: 601
    }, {
        message: space_error,
        statusCode: 602
    }, {
        message: error_doc_upload,
        statusCode: 603
    }, {
        message: error_something_wrong,
        statusCode: 604
    }, {
        message: error_sheetname,
        statusCode: 605
    }, {
        message: error_xls_read,
        statusCode: 606
    }, {
        message: error_get_tokens,
        statusCode: 149
    }, {
        message: error_update_token,
        statusCode: 150
    }, {
        message: error_support_invitation_request,
        statusCode: 323
    },
]
const notificationMsg = (errorCode) => {
    let result = NotificationMessage.find(element => element.statusCode == errorCode)
    return result.message
}
export default notificationMsg