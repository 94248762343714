import React from 'react';
import * as actionTypes from '../actionTypes';
import { gql } from "apollo-boost";
import { Encrypt } from "../../shared/encrpt";
import * as actions from '../../../Store/actions/index';
import fingerprint from 'fingerprintjs'
import jwt from 'jsonwebtoken';
import store from '../../store';
import notificationMsg  from '../../../App/view/Layout/NotificationMessage';
import * as ChangeAppLanguageFront from '../../../App/view/Layout/ChangeAppLanguageFront';
let tlang = ChangeAppLanguageFront.translateLanguage;

const planupdate = tlang('notificationSuccess_masters_planupdate') || 'Plan Updated Successfully';
const Licenceupdate = tlang('notificationSuccess_masters_Licenceupdate') || 'Licence Updated Successfully';
const Tokenupdate = tlang('notificationSuccess_masters_Tokenupdate') || 'Token Updated Successfully';
const MaterialMasterupdate = tlang('notificationSuccess_masters_MaterialMasterupdate') || 'Material Master Updated Successfully';
const MaterialMastercreate = tlang('notificationSuccess_masters_MaterialMastercreate') || 'Material Master Created Successfully';
const Colorupdate = tlang('notificationSuccess_masters_Colorupdate') || 'Color Updated Successfully';
const Natureupdate = tlang('notificationSuccess_masters_Natureupdate') || 'Nature Updated Successfully';
const Geologyupdate = tlang('notificationSuccess_masters_Geologyupdate') || 'Geology Updated Successfully';
const Organolepticupdate = tlang('notificationSuccess_masters_Organolepticupdate') || 'Organoleptic Updated Successfully';
const Colorcreate = tlang('notificationSuccess_masters_Colorcreate') || 'Color Created Successfully';
const Naturecreate = tlang('notificationSuccess_masters_Naturecreate') || 'Nature Created Successfully';
const Geologycreate = tlang('notificationSuccess_masters_Geologycreate') || 'Geology Created Successfully';
const Organolepticcreate = tlang('notificationSuccess_masters_Organolepticcreate') || 'Organoleptic Created Successfully';
const Materialdelete = tlang('notificationSuccess_masters_Materialdelete') || 'Material Deleted Successfully';
const Colordelete = tlang('notificationSuccess_masters_Colordelete') || 'Color Deleted Successfully';
const Naturedelete = tlang('notificationSuccess_masters_Naturedelete') || 'Nature Deleted Successfully';
const Geologydelete = tlang('notificationSuccess_masters_Geologydelete') || 'Geology Deleted Successfully';
const Organolepticdelete = tlang('notificationSuccess_masters_Organolepticdelete') || 'Organoleptic Deleted Successfully';
const ProbingMethodCreated = tlang('notificationSuccess_masters_ProbingMethodCreated') || 'Probing Method Created Successfully';
const ProbingMethodUpdated = tlang('notificationSuccess_masters_ProbingMethodUpdated') || 'Probing Method Updated Successfully';
const ProbingMethodDel = tlang('notificationSuccess_masters_ProbingMethodDel') || 'Probing Method Deleted Successfully';
const TestTypeCreated = tlang('notificationSuccess_masters_TestTypeCreated') || 'Test Type Created Successfully';
const TestTypeUpdated = tlang('notificationSuccess_masters_TestTypeUpdated') || 'Test Type Updated Successfully';
const TestTypeDeleted = tlang('notificationSuccess_masters_TestTypeDeleted') || 'Test Type Deleted Successfully';
const DesposalCreated = tlang('notificationSuccess_masters_DesposalCreated') || 'Disposal Created Successfully';
const DesposalUpdated = tlang('notificationSuccess_masters_DesposalUpdated') || 'Disposal Updated Successfully';
const DesposalDeleted = tlang('notificationSuccess_masters_DesposalDeleted') || 'Disposal Deleted Successfully';
const TestDeleted = tlang('notificationSuccess_masters_TestDeleted') || 'Test Deleted Successfully';
const TestUpdated = tlang('notificationSuccess_masters_TestUpdated') || 'Test Updated Successfully';
const TestCreated = tlang('notificationSuccess_masters_TestCreated') || 'Test Created Successfully';
const Requestrejected = tlang('notificationSuccess_masters_Requestrejected') || 'Request rejected successfully';
const Requestapproved = tlang('notificationSuccess_masters_Requestapproved') || 'Request approved successfully';
const Requestsentsystemadmin = tlang('notificationSuccess_masters_Requestsentsystemadmin') || 'Your request has been sent to System Administrator';
const planreqapproved = tlang('notificationSuccess_masters_planreqapproved') || 'Plan request approved successfully';
const planreqrejected = tlang('notificationSuccess_masters_planreqrejected') || 'Plan request rejected successfully';
const reqsentsystemadmin = tlang('notificationSuccess_masters_reqsentsystemadmin') || 'Your request has been sent to System Administrator';
const Licenceassigned = tlang('notificationSuccess_masters_Licenceassigned') || 'Licence assigned successfully.';
const ThresholdUpdated = tlang('notificationSuccess_masters_ThresholdUpdated') || 'Threshold Updated Successfully';
const TokenPriceUpdated = tlang('notificationSuccess_masters_TokenPriceUpdated') || 'Token Price Updated Successfully';
const requestaccept = tlang('notificationSuccess_masters_requestaccept') || 'Request accepted successfully.';
const requestreject = tlang('notificationSuccess_masters_requestreject') || 'Request rejected successfully.';
const Extendplanreqapprove = tlang('notificationSuccess_masters_Extendplanreqapprove') || 'Extend plan request approved successfully';
const Renewplanreqapprove = tlang('notificationSuccess_masters_Renewplanreqapprove') || 'Renew plan request approved successfully';
const Renewlicencereqapprove = tlang('notificationSuccess_masters_Renewlicencereqapprove') || 'Renew licence request approved successfully';
const Extendlicencereqapprove = tlang('notificationSuccess_masters_Extendlicencereqapprove') || 'Extend licence request approved successfully';
const RuleCreated = tlang('notificationSuccess_masters_RuleCreated') || 'Rule Created Successfully';
const RuleUpdated = tlang('notificationSuccess_masters_RuleUpdated') || 'Rule Updated Successfully';
const error_something_wrong = tlang('error_something_wrong') || 'Something Went Wrong!';
const Categoryupdate = tlang('notificationSuccess_masters_Categoryupdate') || 'Category Updated Successfully';
const Categorycreate = tlang('notificationSuccess_masters_Categorycreate') || 'Category Created Successfully';
const Categorydelete = tlang('notificationSuccess_masters_Categorydelete') || 'Category Deleted Successfully';
const Lithologyupdate = tlang('notificationSuccess_masters_Lithologyupdate') || 'Lithology Updated Successfully';
const Lithologycreate = tlang('notificationSuccess_masters_Lithologycreate') || 'Lithology Created Successfully';
const Lithologydelete = tlang('notificationSuccess_masters_Lithologydelete') || 'Lithology Deleted Successfully';
const Moistureupdate = tlang('notificationSuccess_masters_Moistureupdate') || 'Moisture Updated Successfully';
const Moisturecreate = tlang('notificationSuccess_masters_Moisturecreate') || 'Moisture Created Successfully';
const Moisturedelete = tlang('notificationSuccess_masters_Moisturedelete') || 'Moisture Deleted Successfully';
const Anomalyupdate = tlang('notificationSuccess_masters_Anomalyupdate') || 'Anomaly Updated Successfully';
const Anomalycreate = tlang('notificationSuccess_masters_Anomalycreate') || 'Anomaly Created Successfully';
const Anomalydelete = tlang('notificationSuccess_masters_Anomalydelete') || 'Anomaly Deleted Successfully';
const Reasonupdate = tlang('notificationSuccess_masters_Reasonupdate') || 'Reason Updated Successfully';
const Reasoncreate = tlang('notificationSuccess_masters_Reasoncreate') || 'Reason Created Successfully';
const Reasondelete = tlang('notificationSuccess_masters_Reasondelete') || 'Reason Deleted Successfully';
const SoilFamilyupdate = tlang('notificationSuccess_masters_SoilFamilyupdate') || 'Soil Family Updated Successfully';
const SoilFamilycreate = tlang('notificationSuccess_masters_SoilFamilycreate') || 'Soil Family Created Successfully';
const SoilFamilydelete = tlang('notificationSuccess_masters_SoilFamilydelete') || 'Soil Family Deleted Successfully';
const ObservationUpdate = tlang('notificationSuccess_masters_observation_update') || 'Observation Updated Successfully';
const ObservationCreate = tlang('notificationSuccess_masters_observation_create') || 'Observation Created Successfully';
const ObservationDelete = tlang('notificationSuccess_masters_observation_delete') || 'Observation Deleted Successfully';
const PatternUpdate = tlang('notificationSuccess_masters_pattern_update') || 'Pattern Updated Successfully';
const PatternCreate = tlang('notificationSuccess_masters_pattern_create') || 'Pattern Created Successfully';
const PatternDelete = tlang('notificationSuccess_masters_pattern_delete') || 'Pattern Deleted Successfully';
const RuleDeleted = tlang('notificationSuccess_masters_RuleDeleted') || 'Rule Deleted Successfully';
const standardDisposalOrderUpdated = tlang('notificationSuccess_masters_standardDisposalOrder') || 'Standard disposal list updated successfully';
const notification_err_license_renew_request_api_msg = tlang('notification_err_license_renew_request_api_msg') || 'License renew request already sent';
const unit_update = tlang('unit_update') || 'Default Unit Updated successfully';
const invoice_mail = tlang('invoice_mail') || 'Invoice Email Sent successfully';
const default_disposal_category_update = tlang('default_disposal_category_update') || 'Default Disposal Category Updated successfully';
const createSupportInvitationRequestMessage = tlang('create_support_invitation_request') || 'Support Invitation Request Sent Successfully';
const terminateSupportInvitationRequestMessage = tlang('terminate_support_invitation_request') || 'Support Invitation Request Terminated Successfully';
const approveSupportInvitationRequestMessage = tlang('approve_support_invitation_request') || 'Support Invitation Request Approved Successfully';
const dataSavedSuccessfully = tlang('data_saved_successfully') || 'Data saved successfully';


/* on Login Succeed */
const onLoginSucceed = (data) => {
    return {
        type: actionTypes.LOGIN_SUCCEED,
        state: data
    }
}

/* store User Data */
const storeUserData = (resp) => {
    let finderprint = new fingerprint().get();
    return jwt.sign(resp, finderprint.toString())
}

// notification Success
const notificationSuccess = (msg) => {
    return {
        type: actionTypes.ADD_NEW_NOTIFICATION_SUCCESS,
        state: msg,
    }
}

// notification Fail
const notificationFail = (err) => {
    let msg = error_something_wrong;
    if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        // msg = err.graphQLErrors[0].message;
        let translateMsg =notificationMsg(err.graphQLErrors[0].statusCode)
        msg = translateMsg;
    } else if (err.message) {
        msg = err.message;
    }
    return {
        type: actionTypes.ADD_NEW_NOTIFICATION_FAIL,
        state: msg
    }
}

//get masters of plan
const getPlanListSuccess = (data) => {
    return {
        type: actionTypes.GET_PLAN_LIST,
        state: data
    }
}

// get Plan List
export const getPlanList = (client, request) => {
    return dispatch => {
        const query = gql`{
            plan_list {
              _id
              planid
              planname
              uploadsize
              usersize
              price
              limit
            }
          }
        `;
        client.query({
            query: query,
            fetchPolicy: 'cache-first',
        }).then(response => {
            dispatch(getPlanListSuccess(response.data.plan_list))
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}

//get masters of role
const getRoleListSuccess = (data) => {
    return {
        type: actionTypes.GET_ROLE_LIST,
        state: data
    }
}

// get Role List
export const getRoleList = (client, request) => {
    return dispatch => {
        const query = gql`
        query role_list($isSuperAdmin: Boolean){
            role_list(isSuperAdmin: $isSuperAdmin) {
              _id
              roleid
              rolename
              rolestatus
            }
          }
        `;
        client.query({
            query: query,
            variables: {
                isSuperAdmin: request?.isSuperAdmin,
            },
            fetchPolicy: 'cache-first',
        }).then(response => {
            dispatch(getRoleListSuccess(response.data.role_list))
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}

//get masters of Licenses
const getLicenceListSuccess = (data) => {
    return {
        type: actionTypes.GET_LICENCE_LIST,
        state: data
    }
}

// get Licence List
export const getLicenceList = (client, request) => {
    return dispatch => {
        const query = gql`
        query licence_list($isSuperAdmin: Boolean){
            licence_list(isSuperAdmin: $isSuperAdmin) {
              _id
              licenceid
              licencename
              cost
              status
              accesspage
            }
          }
        `;
        client.query({
            query: query,
            variables: {
                isSuperAdmin: request?.isSuperAdmin,
            },
            fetchPolicy: 'cache-first',

        }).then(response => {
            dispatch(getLicenceListSuccess(response.data.licence_list))
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}


//get masters of Token
const getTokenListSuccess = (data) => {
    return {
        type: actionTypes.GET_TOKEN_LIST,
        state: data
    }
}

// get Token List
export const getTokenList = (client, request) => {
    return dispatch => {
        const query = gql`{
            token_list {
              _id
              tokenId
              tokenName
              cost
            }
          }
        `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',

        }).then(response => {
            dispatch(getTokenListSuccess(response.data.token_list))
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}

// edit Plan Setting
export const editPlanSetting = (client, request) => {
    return dispatch => {
        const mutation = gql`
            mutation edit_plan($planid: String!, $planname: String, $uploadsize: String!, $usersize: String!, $price: String!) {
                edit_plan(planid: $planid, planname: $planname, uploadsize: $uploadsize, usersize: $usersize, price: $price) {
                        _id
                planid
                planname
                uploadsize
                usersize
                price
                limit
                }
            }
        `;
        client.mutate({
            mutation: mutation,
            variables: {
                planid: Encrypt(request.planid),
                planname: Encrypt(request.planname),
                uploadsize: Encrypt(request.uploadsize),
                usersize: Encrypt(request.usersize),
                price: Encrypt(request.price)
            }
        })
            .then(response => {
                dispatch(notificationSuccess(planupdate))
                dispatch(getPlanListSuccess(response.data.edit_plan))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// edit Licence Cost
export const editLicenceCost = (client, request) => {
    return dispatch => {
        const mutation = gql`
            mutation edit_licence($licenceid: String!, $cost: String!, $licencename: String!, $status: Boolean, $accesspage: String!) {
                edit_licence(licenceid: $licenceid, cost: $cost, licencename: $licencename, status: $status, accesspage: $accesspage) {
                _id
                licenceid
                licencename
                cost
                status
                accesspage
                }
            }
        `;
        client.mutate({
            mutation: mutation,
            variables: {
                licencename: Encrypt(request.licencename),
                licenceid: Encrypt(request.licenceid),
                cost: Encrypt(request.cost),
                status: request.status,
                accesspage: Encrypt(request.accesspage),
            }
        })
            .then(response => {
                dispatch(notificationSuccess(Licenceupdate))
                dispatch(getLicenceListSuccess(response.data.edit_licence))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// edit Licence Cost
export const editTokenCost = (client, request) => {
    return dispatch => {
        const mutation = gql`
            mutation edit_token($tokenId: String!, $cost: String!, $tokenName: String!) {
                edit_token(tokenId: $tokenId, cost: $cost, tokenName: $tokenName) {
                _id
                tokenId
                tokenName
                cost
                }
            }
        `;
        client.mutate({
            mutation: mutation,
            variables: {
                tokenName: Encrypt(request.tokenName),
                tokenId: Encrypt(request.tokenId),
                cost: Encrypt(request.cost),
            }
        })
            .then(response => {
                dispatch(notificationSuccess(Tokenupdate))
                dispatch(getTokenListSuccess(response.data.edit_token))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}


// create material
export const createMaterialMasters = (client, request) => {
    return dispatch => {
        const mutation = gql`
        mutation create_material($id: String!, $type: String!, $color: String!, $key: String , $tag: String) {
            create_material(id: $id, type: $type, color: $color, key: $key, tag: $tag) {
                success
                message
            }
          }
        `;
        client.mutate({
            mutation: mutation,
            variables: {
                id: Encrypt(request.id),
                type: Encrypt(request.type),
                color: Encrypt(request.color),
                key: Encrypt(request.key),
                tag: Encrypt(request.tag),
            }
        })
            .then(response => {
                if (request.id) {
                    dispatch(notificationSuccess(MaterialMasterupdate));
                } else {
                    dispatch(notificationSuccess(MaterialMastercreate));
                }
                dispatch(actions.getMaterialMasters(client, []))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// create other material
export const createOtherMaterialMasters = (master, request) => {
    let requestParams = {
        masterType: request.masterType
    }
    let newVariables;
    if (request.masterType === 'geology' || request.masterType === 'pattern') {
        newVariables = {
            id: Encrypt(request.id),
            name: Encrypt(request.name),
            value: Encrypt(request.value),
            type: Encrypt(request.masterType),
            key: Encrypt(request.key),
            tag: Encrypt(request.tag),
            pattern_id: Encrypt(request.pattern_id),
            pattern_name: Encrypt(request.pattern_name),
            pattern_code: Encrypt(request.pattern_code),
            pattern_width: Encrypt(request.pattern_width),
            pattern_height: Encrypt(request.pattern_height),
            lang_data: Encrypt(request.lang_data)
        }
    } else {
        newVariables = {
            id: Encrypt(request.id),
            name: Encrypt(request.name),
            value: Encrypt(request.value),
            type: Encrypt(request.masterType),
            key: Encrypt(request.key),
            tag: Encrypt(request.tag),
            lang_data: Encrypt(request.lang_data)
        }
    }

    return dispatch => {
        const mutation = gql`
        mutation create_other_material($type: String!, $name: String!, $value: String, $id: String,$key: String , $tag: String, $pattern_id: String, $pattern_name: String, $pattern_code: String, $pattern_width: String, $pattern_height: String, $lang_data: String) {
            create_other_material(type: $type, name: $name, value: $value, id: $id, key: $key, tag: $tag, pattern_id:$pattern_id, pattern_name: $pattern_name, pattern_code: $pattern_code, pattern_width: $pattern_width, pattern_height: $pattern_height, lang_data: $lang_data) {
                error
                message
                success
            }
          }
        `;
        master.mutate({
            mutation: mutation,
            variables: newVariables
        })
            .then(response => {
                if (request.id) {
                    if (request.masterType === "color") {
                        dispatch(notificationSuccess(Colorupdate))
                    } else if (request.masterType === "nature") {
                        dispatch(notificationSuccess(Natureupdate))
                    } else if (request.masterType === "geology") {
                        dispatch(notificationSuccess(SoilFamilyupdate))
                    } else if (request.masterType === "organoleptic") {
                        dispatch(notificationSuccess(Organolepticupdate))
                    } else if (request.masterType === "category") {
                        dispatch(notificationSuccess(Categoryupdate))
                    } else if (request.masterType === "lithology") {
                        dispatch(notificationSuccess(Lithologyupdate))
                    } else if (request.masterType === "moisture") {
                        dispatch(notificationSuccess(Moistureupdate))
                    } else if (request.masterType === "anomaly") {
                        dispatch(notificationSuccess(Anomalyupdate))
                    } else if (request.masterType === "reason") {
                        dispatch(notificationSuccess(Reasonupdate))
                    } else if (request.masterType === "observation") {
                        dispatch(notificationSuccess(ObservationUpdate))
                    } else if (request.masterType === "pattern") {
                        dispatch(notificationSuccess(PatternUpdate))
                    }

                } else {
                    if (request.masterType === "color") {
                        dispatch(notificationSuccess(Colorcreate))
                    } else if (request.masterType === "nature") {
                        dispatch(notificationSuccess(Naturecreate))
                    } else if (request.masterType === "geology") {
                        dispatch(notificationSuccess(SoilFamilycreate))
                    } else if (request.masterType === "organoleptic") {
                        dispatch(notificationSuccess(Organolepticcreate))
                    } else if (request.masterType === "category") {
                        dispatch(notificationSuccess(Categorycreate))
                    } else if (request.masterType === "lithology") {
                        dispatch(notificationSuccess(Lithologycreate))
                    } else if (request.masterType === "moisture") {
                        dispatch(notificationSuccess(Moisturecreate))
                    } else if (request.masterType === "anomaly") {
                        dispatch(notificationSuccess(Anomalycreate))
                    } else if (request.masterType === "reason") {
                        dispatch(notificationSuccess(Reasoncreate))
                    } else if (request.masterType === "observation") {
                        dispatch(notificationSuccess(ObservationCreate))
                    } else if (request.masterType === "pattern") {
                        dispatch(notificationSuccess(PatternCreate))
                    }
                }
                dispatch(actions.getOtherMaterialMasters(master, requestParams))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get Color Master Success
const getColorMasterSuccess = (data) => {
    return {
        type: actionTypes.GET_COLOR_LIST,
        state: data
    }
}

// get English Color Master Success
const getEnglishColorMasterSuccess = (data) => {
    return {
        type: actionTypes.GET_DEFAULT_COLOR_LIST,
        state: data
    }
}

// get Nature Master Success
const getNatureMasterSuccess = (data) => {
    return {
        type: actionTypes.GET_NATURE_LIST,
        state: data
    }
}

// get Geology Master Success
const getGeologyMasterSuccess = (data) => {
    return {
        type: actionTypes.GET_GEOLOGY_LIST,
        state: data
    }
}

// get Organoleptic Master Success
const getOrganolepticMasterSuccess = (data) => {
    return {
        type: actionTypes.GET_ORGANOLEPTIC_LIST,
        state: data
    }
}

// get Category Master Success
const getCategoryMasterSuccess = (data) => {
    return {
        type: actionTypes.GET_CATEGORY_LIST,
        state: data
    }
}

// get Lithology Master Success
const getLithologyMasterSuccess = (data) => {
    return {
        type: actionTypes.GET_LITHOLOGY_LIST,
        state: data
    }
}

// get English Lithology Master Success
const getEnglishLithologyMasterSuccess = (data) => {
    return {
        type: actionTypes.GET_DEFAULT_LITHOLOGY_LIST,
        state: data
    }
}

// get Moisture Master Success
const getMoistureMasterSuccess = (data) => {
    return {
        type: actionTypes.GET_MOISTURE_LIST,
        state: data
    }
}

// get Anomaly Master Success
const getAnomalyMasterSuccess = (data) => {
    return {
        type: actionTypes.GET_ANOMALY_LIST,
        state: data
    }
}

// get Anomaly Master Success
const getReasonMasterSuccess = (data) => {
    return {
        type: actionTypes.GET_REASON_LIST,
        state: data
    }
}

// get Observation Master Success
const getObservationMasterSuccess = (data) => {
    return {
        type: actionTypes.GET_OBSERVATION_LIST,
        state: data
    }
}

// get Observation Master Success
const getPatternMasterSuccess = (data) => {
    return {
        type: actionTypes.GET_PATTERN_LIST,
        state: data
    }
}

// get other material
export const getOtherMaterialMasters = (client, request) => {
    return dispatch => {
        const query = gql`
            query get_other_material($type: String!) {
                get_other_material(type: $type) {
                    _id
                    value
                    name        
                    key
                    tag 
                    pattern_id
                    pattern_name
                    pattern_code
                    pattern_height
                    pattern_width
                    lang_data
                    lang_code
                }
            }
            `;
        client.query({
            query: query,
            variables: {
                type: Encrypt(request.masterType)
            },
            fetchPolicy: 'no-cache',
        })
            .then(response => {
                switch (request.masterType) {
                    case "color":
                        dispatch(getColorMasterSuccess(response.data.get_other_material))
                        break;
                    case "nature":
                        dispatch(getNatureMasterSuccess(response.data.get_other_material))
                        break;
                    case "geology":
                        dispatch(getGeologyMasterSuccess(response.data.get_other_material))
                        break;
                    case "organoleptic":
                        dispatch(getOrganolepticMasterSuccess(response.data.get_other_material))
                        break;
                    case "category":
                        dispatch(getCategoryMasterSuccess(response.data.get_other_material))
                        break;
                    case "lithology":
                        dispatch(getLithologyMasterSuccess(response.data.get_other_material))
                        break;
                    case "moisture":
                        dispatch(getMoistureMasterSuccess(response.data.get_other_material))
                        break;
                    case "anomaly":
                        dispatch(getAnomalyMasterSuccess(response.data.get_other_material))
                        break;
                    case "reason":
                        dispatch(getReasonMasterSuccess(response.data.get_other_material))
                    case "observation":
                        dispatch(getObservationMasterSuccess(response.data.get_other_material))
                        break;
                    case "pattern":
                        dispatch(getPatternMasterSuccess(response.data.get_other_material))
                        break;
                    default:
                        break;
                }
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get Other Material Details Success
const getOtherMaterialDetailsSuccess = (data) => {
    return {
        type: actionTypes.GET_OTHER_MATERIAL_DETAILS,
        state: data
    }
}

// get other material detail
export const getOtherMaterialDetails = (client, request) => {
    return dispatch => {
        const query = gql`
            query get_other_material_detail($id: String! , $type: String!) {
                get_other_material_detail(id: $id , type: $type) {
                    _id
                    name
                    value   
                    key
                    tag
                    pattern_name
                    pattern_code
                    pattern_height
                    pattern_width
                    lang_data
                }
            }            
            `;
        client.query({
            query: query,
            variables: {
                id: Encrypt(request.id),
                type: Encrypt(request.masterType),
            }
        })
            .then(response => {
                dispatch(getOtherMaterialDetailsSuccess(response.data.get_other_material_detail))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// delete material
export const deleteMaterialMaster = (client, request) => {
    return dispatch => {
        const query = gql`
            query delete_material_detail($id: String!) {
                delete_material_detail(id: $id) {
                    error
                    message
                    success 
                }
            }            
            `;
        client.query({
            query: query,
            variables: {
                id: Encrypt(request.id)
            }
        })
            .then(response => {
                dispatch(notificationSuccess(Materialdelete))
                dispatch(actions.getMaterialMasters(client, []))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// delete other material
export const deleteOtherMaterialMaster = (client, request) => {
    let requestParams = {
        masterType: request.masterType,
        lang_code: request.lang_code,
    }
    return dispatch => {
        const query = gql`
            query delete_other_material_detail($id: String! , $type: String!) {
                delete_other_material_detail(id: $id , type: $type) {
                    success
                    message
                    error 
                }
            }            
            `;
        client.query({
            query: query,
            variables: {
                id: Encrypt(request.id),
                type: Encrypt(request.masterType),
            }
        })
            .then(response => {
                if (request.masterType === "color") {
                    dispatch(notificationSuccess(Colordelete))
                } else if (request.masterType === "nature") {
                    dispatch(notificationSuccess(Naturedelete))
                } else if (request.masterType === "geology") {
                    dispatch(notificationSuccess(SoilFamilydelete))
                } else if (request.masterType === "organoleptic") {
                    dispatch(notificationSuccess(Organolepticdelete))
                } else if (request.masterType === "category") {
                    dispatch(notificationSuccess(Categorydelete))
                } else if (request.masterType === "lithology") {
                    dispatch(notificationSuccess(Lithologydelete))
                } else if (request.masterType === "moisture") {
                    dispatch(notificationSuccess(Moisturedelete))
                } else if (request.masterType === "anomaly") {
                    dispatch(notificationSuccess(Anomalydelete))
                } else if (request.masterType === "reason") {
                    dispatch(notificationSuccess(Reasondelete))
                } else if (request.masterType === "observation") {
                    dispatch(notificationSuccess(ObservationDelete))
                } else if (request.masterType === "pattern") {
                    dispatch(notificationSuccess(PatternDelete))
                }
                dispatch(actions.getOtherMaterialMasters(client, requestParams))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// multiple delete in other material 
export const deleteMultipleOtherMaterialMaster = (client, request) => {
    let requestParams = {
        masterType: request.masterType,
        lang_code: request.lang_code,
    }

    return dispatch => {
        const mutation = gql`
            mutation delete_multiple_other_material($ids: String! , $type: String!) {
                delete_multiple_other_material(ids: $ids , type: $type) {
                    success
                    message
                    error 
                }
            }            
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                ids: Encrypt(request.id),
                type: Encrypt(request.masterType),
            }
        })
            .then(response => {
                if (request.masterType === "color") {
                    dispatch(notificationSuccess(Colordelete))
                } else if (request.masterType === "nature") {
                    dispatch(notificationSuccess(Naturedelete))
                } else if (request.masterType === "geology") {
                    dispatch(notificationSuccess(SoilFamilydelete))
                } else if (request.masterType === "organoleptic") {
                    dispatch(notificationSuccess(Organolepticdelete))
                } else if (request.masterType === "category") {
                    dispatch(notificationSuccess(Categorydelete))
                } else if (request.masterType === "lithology") {
                    dispatch(notificationSuccess(Lithologydelete))
                } else if (request.masterType === "moisture") {
                    dispatch(notificationSuccess(Moisturedelete))
                } else if (request.masterType === "anomaly") {
                    dispatch(notificationSuccess(Anomalydelete))
                } else if (request.masterType === "reason") {
                    dispatch(notificationSuccess(Reasondelete))
                } else if (request.masterType === "observation") {
                    dispatch(notificationSuccess(ObservationDelete))
                }
                dispatch(actions.getOtherMaterialMasters(client, requestParams))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get Material Success
const getMaterialSuccess = (data) => {
    return {
        type: actionTypes.GET_MATERIAL_MASTER_LIST,
        state: data
    }
}

// get material
export const getMaterialMasters = (client, request) => {
    return dispatch => {
        const query = gql`{
                get_material{
                    _id
                    key
                    tag
                    color
                    type
                }
            }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        })
            .then(response => {
                dispatch(getMaterialSuccess(response.data.get_material))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get Material Details Success
const getMaterialDetailsSuccess = (data) => {
    return {
        type: actionTypes.GET_MATERIAL_DETAILS,
        state: data
    }
}

// get material detail
export const getMaterialDetails = (client, request) => {
    return dispatch => {
        const query = gql`
            query get_material_detail($id: String!) {
                get_material_detail(id: $id) {
                    _id
                    key
                    tag
                    color
                    type 
                }
            }            
            `;
        client.query({
            query: query,
            variables: {
                id: Encrypt(request.id)
            }
        })
            .then(response => {
                dispatch(getMaterialDetailsSuccess(response.data.get_material_detail))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get Probing Method Success
const getProbingMethodSuccess = (data) => {
    return {
        type: actionTypes.GET_PROBING_METHOD_LIST,
        state: data
    }
}

// get probing method
export const getProbingMethod = (client, request) => {
    return dispatch => {
        const query = gql`{
            get_probing_method{
                    _id
                    name
                    lang_data
                }
            }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        })
            .then(response => {
                dispatch(getProbingMethodSuccess(response.data.get_probing_method))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// create probing method
export const createProbingMethod = (client, request) => {
    return dispatch => {
        const mutation = gql`
        mutation create_probing_method($name: String!,$id: String!, $lang_data: String ) {
            create_probing_method(name: $name,id: $id, lang_data: $lang_data) {
                error
                success
                message
            }
          }
        `;
        client.mutate({
            mutation: mutation,
            variables: {
                id: Encrypt(request.id),
                name: Encrypt(request.name),
                lang_data: Encrypt(request.lang_data)
            }
        })
            .then(response => {
                if (request.id) {
                    dispatch(notificationSuccess(ProbingMethodUpdated));
                } else {
                    dispatch(notificationSuccess(ProbingMethodCreated));
                }
                dispatch(actions.getProbingMethod(client, []))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get Probing Method Details Success
const getProbingMethodDetailsSuccess = (data) => {
    return {
        type: actionTypes.GET_PROBING_METHOD_DETAILS,
        state: data
    }
}

// get probing method detail
export const getProbingMethodDetails = (client, request) => {

    return dispatch => {
        const query = gql`
            query get_probing_method_detail($id: String!) {
                get_probing_method_detail(id: $id) {
                    _id
                    name
                    lang_data
                }
            }            
            `;
        client.query({
            query: query,
            variables: {
                id: Encrypt(request.id)
            }
        })
            .then(response => {
                dispatch(getProbingMethodDetailsSuccess(response.data.get_probing_method_detail))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}


// delete probing method
export const deleteProbingMethod = (client, request) => {
    return dispatch => {
        const query = gql`
            query delete_probing_method($id: String!) {
                delete_probing_method(id: $id) {
                    success
                    message
                    error 
                }
            }            
            `;
        client.query({
            query: query,
            variables: {
                id: Encrypt(request.id)
            }
        })
            .then(response => {
                dispatch(notificationSuccess(ProbingMethodDel));
                dispatch(actions.getProbingMethod(client, []))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}


// get Test Type Success
const getTestTypeSuccess = (data) => {
    return {
        type: actionTypes.GET_TEST_TYPE_LIST,
        state: data
    }
}

// get test type
export const getTestType = (client, request) => {
    return dispatch => {
        const query = gql`{
            get_test_type{
                    _id
                    name
                    children
                    desposal
                }
            }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        })
            .then(response => {
                dispatch(getTestTypeSuccess(response.data.get_test_type))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}


// create test type
export const configureDesposalOrder = (master, request) => {
    return dispatch => {
        const mutation = gql`
        mutation configure_desposal_order($desposal_data: String! ) {
            configure_desposal_order(desposal_data: $desposal_data) {
                success
                message
            }
          }
        `;
        master.mutate({
            mutation: mutation,
            variables: {
                desposal_data: Encrypt(request.desposal_data)
            }
        })
            .then(response => {
                dispatch(notificationSuccess(standardDisposalOrderUpdated));
                let reqParam = {
                    company_name: request.company_name,
                }
                dispatch(actions.getDesposal(master, reqParam))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// create test type
export const createSupportInvitationRequest = (master, request) => {
    return dispatch => {
        const mutation = gql`
        mutation create_support_invitation_request($projectId: String!, $projectName: String!, $projectOwner: String!, $projectOwnerUserId: String!, $companyOwnerEmail: String!, $companyOwnerUserId: String!, $companyId: String!, $companyName: String!, $displayName: String!, $inviteId: String!, $status: String!  ) {
            create_support_invitation_request(projectId: $projectId, projectName: $projectName, projectOwner: $projectOwner, projectOwnerUserId: $projectOwnerUserId, companyOwnerEmail: $companyOwnerEmail, companyOwnerUserId: $companyOwnerUserId, companyId: $companyId, companyName: $companyName, displayName: $displayName, inviteId: $inviteId, status: $status ) {
                success
                message
            }
          }
        `;
        master.mutate({
            mutation: mutation,
            variables: {
                projectId: Encrypt(request.projectId),
                projectName: Encrypt(request.projectName),
                projectOwner: Encrypt(request.projectOwner),
                projectOwnerUserId: Encrypt(request.projectOwnerUserId),
                companyOwnerEmail: Encrypt(request.companyOwnerEmail),
                companyOwnerUserId: Encrypt(request.companyOwnerUserId),
                companyId: Encrypt(request.companyId),
                companyName: Encrypt(request.companyName),
                displayName: Encrypt(request.displayName),
                inviteId: Encrypt(request.inviteId),
                status: Encrypt(request.status),
            }
        })
            .then(response => {
                if(request.status == 'Terminate'){
                    dispatch(notificationSuccess(terminateSupportInvitationRequestMessage));
                }else{
                    dispatch(notificationSuccess(createSupportInvitationRequestMessage));
                }
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get support invitation request success
const getSupportInvitationRequestDetailsSuccess = (data) => {
    return {
        type: actionTypes.GET_SUPPORT_INVITATION_LIST,
        state: data
    }
}

// get support invitation request
export const getSupportInvitationRequest = (master, request) => {
    return dispatch => {
        const query = gql`
            query get_support_invitation_request($isApprove : Boolean!) {
                get_support_invitation_request(isApprove : $isApprove ) {
                    _id
                    projectId
                    projectName
                    projectOwner
                    projectOwnerUserId
                    companyOwnerEmail
                    companyId
                    companyName
                    displayName
                    inviteId
                    status
                    companyOwnerUserId
                    supportStartTime
                    supportApproveTime
                    supportEndTime
                }
            }            
            `;
        master.query({
            query: query,
            fetchPolicy: 'no-cache',
            variables: {
                isApprove: request.isApprove
            }
        })
            .then(response => {
                dispatch(getSupportInvitationRequestDetailsSuccess(response.data.get_support_invitation_request))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// create categoriwise disposal order for selected disposal type
export const approveSupportInvitationRequest = (master, request) => {
    return dispatch => {
        const mutation = gql`
        mutation approve_support_invitation_request($projectId: String!) {
            approve_support_invitation_request(projectId: $projectId) {
                success
                message
                error
            }
          }
        `;
        master.mutate({
            mutation: mutation,
            variables: {
                projectId: Encrypt(request.projectId),
            }
        })
        .then(response => {

            //For getting the fresh value of disposal_data
            let requestParams = {
                isApprove: false,                   
            }
            dispatch(actions.getSupportInvitationRequest(master, requestParams))

            dispatch(notificationSuccess(approveSupportInvitationRequestMessage));
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}

// create categoriwise disposal order for selected disposal type
export const setCategoryWiseStandardDisposal = (master, request) => {
    return dispatch => {
        const mutation = gql`
        mutation set_category_wise_standard_disposal($userId: String, $projectId: String, $companyName: String, $disposalType: String, $disposalData: String, $updatedDefaultDisposalListName: String  ) {
            set_category_wise_standard_disposal(userId: $userId, projectId: $projectId, companyName: $companyName, disposalType: $disposalType, disposalData: $disposalData, updatedDefaultDisposalListName: $updatedDefaultDisposalListName) {
                success
                message
                error
            }
          }
        `;
        master.mutate({
            mutation: mutation,
            variables: {
                userId: Encrypt(request.userId),
                projectId: Encrypt(request.projectId),
                companyName: Encrypt(request.companyName),
                disposalType: Encrypt(request.disposalType),
                disposalData: Encrypt(request.disposalData),
                updatedDefaultDisposalListName: Encrypt(request.updatedDefaultDisposalListName),
            }
        })
            .then(response => {
                
                //For getting the fresh value of disposal_data
                let requestParams = {
                    projectid: request.projectId,
                    email: request.email,                    
                }
                dispatch(actions.getAccess(store.getState().apolloClient.client, requestParams))

                dispatch(notificationSuccess(standardDisposalOrderUpdated));
                let reqParam = {
                    company_name: request.companyName,
                }
                dispatch(actions.getDesposal(master, reqParam))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}



// create test type
export const createTestType = (client, request) => {
    return dispatch => {
        const mutation = gql`
        mutation create_test_type($name: String!,$id: String!,$children: String! ) {
            create_test_type(name: $name,id: $id,children: $children) {
                success
                message
            }
          }
        `;
        client.mutate({
            mutation: mutation,
            variables: {
                id: Encrypt(request.id),
                name: Encrypt(request.name),
                children: Encrypt(request.children)
            }
        })
            .then(response => {
                if (request.id) {
                    dispatch(notificationSuccess(TestTypeUpdated));
                } else {
                    dispatch(notificationSuccess(TestTypeCreated));
                }
                dispatch(actions.getTestType(client, []))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get Test Type Details Success
const getTestTypeDetailsSuccess = (data) => {
    return {
        type: actionTypes.GET_TEST_TYPE_DETAILS,
        state: data
    }
}

// get test type detail
export const getTestTypeDetails = (client, request) => {

    return dispatch => {
        const query = gql`
            query get_test_type_detail($id: String!) {
                get_test_type_detail(id: $id) {
                    _id
                    name
                    children
                }
            }            
            `;
        client.query({
            query: query,
            variables: {
                id: Encrypt(request.id)
            }
        })
            .then(response => {
                dispatch(getTestTypeDetailsSuccess(response.data.get_test_type_detail))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// delete test type
export const deleteTestType = (client, request) => {
    return dispatch => {
        const query = gql`
            query delete_test_type($id: String!) {
                delete_test_type(id: $id) {
                    success
                    message
                }
            }            
            `;
        client.query({
            query: query,
            variables: {
                id: Encrypt(request.id)
            }
        })
            .then(response => {
                dispatch(notificationSuccess(TestTypeDeleted));
                dispatch(actions.getTestType(client, []))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get Desposal Success
const getDesposalSuccess = (data) => {
    return {
        type: actionTypes.GET_DESPOSAL_LIST,
        state: data
    }
}

// get desposal
// export const getDesposal = (client, request) => {
//     return dispatch => {
//         const query = gql`{
//             get_desposal{
//                     _id
//                     name
//                     start
//                     end
//                     index_no
//                     percentage
//                     standard
//                     address
//                     state
//                     country
//                     color
//                     isused
//                     company_name
//                     test_list
//                 }
//             }
//             `;
//         client.query({
//             query: query,
//             fetchPolicy: 'no-cache',
//         })
//             .then(response => {
//                 dispatch(getDesposalSuccess(response.data.get_desposal))
//             }).catch((error) => {
//                 dispatch(notificationFail(error))
//             });
//     }
// }


// get rules of all desposal
export const getDesposal = (client, request) => {
    return dispatch => {
        const query = gql`
        query get_desposal($company_name: String) {
            get_desposal(company_name: $company_name){
                _id
                name
                start
                end
                index_no
                percentage
                standard
                address
                state
                country
                color
                isused
                company_name
                test_list
                type
                userId
                projectId
                }
            }
            `;
        client.query({
            query: query,
            variables: {
                company_name: Encrypt(request.company_name)
            },
            fetchPolicy: 'no-cache',
        })
            .then(response => {
                dispatch(getDesposalSuccess(response.data.get_desposal))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}





// get Desposal Success
const getStandardDesposalSuccess = (data) => {
    return {
        type: actionTypes.GET_STANDARD_DESPOSAL_LIST,
        state: data
    }
}

// get rules of all desposal
export const getStandardDesposal = (client, request) => {
    return dispatch => {
        const query = gql`
        query get_desposal($standard_only: Boolean) {
            get_desposal(standard_only: $standard_only){
                _id
                name
                index_no
                standard
                color
                company_name
                type
                userId
                projectId
                }
            }
            `;
        client.query({
            query: query,
            variables: {
                standard_only: request.standard_only
            },
            fetchPolicy: 'no-cache',
        })
            .then(response => {
                dispatch(getStandardDesposalSuccess(response.data.get_desposal))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

export const getStandardDesposalFront = (client, request) => {
    return dispatch => {
        const query = gql`
        query get_desposal($standard_all: Boolean) {
            get_desposal(standard_all: $standard_all){
                _id
                name
                index_no
                standard
                color
                company_name
                type
                userId
                projectId
                }
            }
            `;
        client.query({
            query: query,
            variables: {
                standard_all: request.standard_all
            },
            fetchPolicy: 'no-cache',
        })
            .then(response => {
                dispatch(getStandardDesposalSuccess(response.data.get_desposal))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}


// get Desposal Rule List Success
const getDesposalRuleListSuccess = (data) => {
    return {
        type: actionTypes.GET_ALL_DESPOSAL_RULELIST,
        state: data
    }
}

// get rules of all desposal
export const getRuleListOfAllDisposal = (client, request) => {
    return dispatch => {
        const query = gql`
        query get_multiple_test_rule_list($desposal_ids: String!) {
            get_multiple_test_rule_list(desposal_ids: $desposal_ids){
                _id
                desposal_id
                desposal_name
                test_id
                test_name
                child_id
                child_name
                value
                option_id
                formula
                nolimit_finalvalue
                }
            }
            `;
        client.query({
            query: query,
            variables: {
                desposal_ids: Encrypt(request.desposal_ids)
            },
            fetchPolicy: 'no-cache',
        })
            .then(response => {
                dispatch(getDesposalRuleListSuccess(response.data.get_multiple_test_rule_list))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}



// create desposal
export const createDesposal = (client, request) => {

    return dispatch => {
        const mutation = gql`
        mutation create_desposal(
            $name: String!, 
            $id: String!, 
            $start: String, 
            $end: String, 
            $percentage: String
            $standard: Boolean
            $address: String
            $state: String
            $country: String
            $color: String
            $test_list: String
            $company_name: String
            $removed_test_ids: String
            $clone_desposal_id: String
            $clone_desposal_status: Boolean
            $type: String
            $userId: String
            $projectId: String
            $index_no: Int
            ) {
            create_desposal(
                name: $name, 
                id: $id, 
                start:$start, 
                end:$end, 
                percentage:$percentage
                standard:$standard
                address:$address
                state:$state
                country:$country
                color:$color
                test_list:$test_list
                company_name: $company_name
                removed_test_ids: $removed_test_ids
                clone_desposal_id: $clone_desposal_id
                clone_desposal_status: $clone_desposal_status
                type: $type
                userId: $userId
                projectId: $projectId
                index_no: $index_no
            ) {
                success
                message
            }
          }
        `;
        client.mutate({
            mutation: mutation,
            variables: {
                id: Encrypt(request.id),
                name: Encrypt(request.name),
                start: Encrypt(request.start),
                end: Encrypt(request.end),
                percentage: Encrypt(request.percentage),
                standard: request.standard,
                address: Encrypt(request.address),
                state: Encrypt(request.state),
                country: Encrypt(request.country),
                color: Encrypt(request.color),
                test_list: Encrypt(request.test_list),
                company_name: Encrypt(request.company_name),
                removed_test_ids: Encrypt(request.removed_test_ids),
                clone_desposal_id: Encrypt(request.clone_desposal_id),
                clone_desposal_status: request.clone_desposal_status,
                type: Encrypt(request.type),
                userId: Encrypt(request.userId),
                projectId: Encrypt(request.projectId),
                index_no: Encrypt(request.index_no),
            }
        })
            .then(response => {
                if (request.id) {
                    dispatch(notificationSuccess(DesposalUpdated));
                } else {
                    dispatch(notificationSuccess(DesposalCreated));
                }
                let reqParam = {
                    company_name: request.company_name,
                }
                let requestParams = {
                    projectid: request.projectId,
                    email: request.email,                    
                }
                dispatch(actions.getDesposal(client, reqParam))
                dispatch(actions.getAccess(store.getState().apolloClient.client, requestParams))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get Desposal Details Success
const getDesposalDetailsSuccess = (data) => {
    return {
        type: actionTypes.GET_DESPOSAL_DETAILS,
        state: data
    }
}

// get Test Rule List Success
const getTestRuleListSuccess = (data) => {
    return {
        type: actionTypes.GET_TEST_RULE_LIST,
        state: data
    }
}

// get desposal detail
export const getDesposalDetails = (client, request) => {
    return dispatch => {
        const query = gql`
            query get_desposal_detail($id: String!) {
                get_desposal_detail(id: $id) {
                    _id
                    name
                    start
                    end
                    percentage
                    standard
                    address
                    index_no
                    state
                    country
                    color
                    test_list
                    isused
                    company_name
                    type
                    userId
                    projectId
                }
            }            
            `;
        client.query({
            query: query,
            variables: {
                id: Encrypt(request.id)
            }
        })
            .then(response => {
                dispatch(getDesposalDetailsSuccess(response.data.get_desposal_detail))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get desposal detail
export const getTestRuleList = (client, request) => {
    return dispatch => {
        const query = gql`
            query get_test_rule_list($desposal_id: String!) {
                get_test_rule_list(desposal_id: $desposal_id) {
                    _id
                    desposal_id
                    desposal_name
                    test_id
                    test_name
                    child_id
                    child_name
                    value
                    option_id
                    formula
                    children
                    nolimit_finalvalue
                    created_at
                    updated_at
                }
            }            
            `;
        client.query({
            query: query,
            variables: {
                desposal_id: Encrypt(request.id)
            }
        })
            .then(response => {
                dispatch(getTestRuleListSuccess(response.data.get_test_rule_list))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// delete desposal
export const deleteDesposal = (client, request) => {
    return dispatch => {
        const query = gql`
            query delete_desposal($id: String!) {
                delete_desposal(id: $id) {
                    success
                    message
                }
            }            
            `;
        client.query({
            query: query,
            variables: {
                id: Encrypt(request.id)
            }
        })
            .then(response => {
                dispatch(notificationSuccess(DesposalDeleted));
                let reqParam = {
                    company_name: request.company_name,
                }
                let requestParams = {
                    projectid: request.projectId,
                    email: request.email,      
                }
                dispatch(actions.getDesposal(client, reqParam))
                dispatch(actions.getAccess(store.getState().apolloClient.client, requestParams))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}


// delete test rule
export const deleteTestRule = (master, request) => {
    return dispatch => {
        const mutation = gql`
        mutation delete_test_rule($id: String!) {
                delete_test_rule(id: $id) {
                    success
                    message
                }
            }            
            `;
        master.mutate({
            mutation: mutation,
            variables: {
                id: Encrypt(request.id)
            }
        })
            .then(response => {
                let requestParam = {
                    id: request.desposal_id
                }
                dispatch(actions.getTestRuleList(master, requestParam))
                //dispatch(notificationSuccess(RuleDeleted));

            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// delete test rule
export const deleteMultipleTestRule = (master, request) => {
    return dispatch => {
        const mutation = gql`
        mutation delete_multiple_test_rule($ruleIds: String!) {
            delete_multiple_test_rule(ruleIds: $ruleIds) {
                    success
                    message
                }
            }            
            `;
        master.mutate({
            mutation: mutation,
            variables: {
                ruleIds: Encrypt(request.ruleIds)
            }
        })
            .then(response => {
                let requestParam = {
                    id: request.desposal_id
                }
                dispatch(actions.getTestRuleList(master, requestParam))
                //dispatch(notificationSuccess(RuleDeleted));
                dispatch(notificationSuccess(dataSavedSuccessfully))

            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get Test Success
const getTestSuccess = (data) => {
    return {
        type: actionTypes.GET_TEST_LIST,
        state: data
    }
}

// get test
export const getTest = (client, request) => {
    return dispatch => {
        const query = gql`{
            get_test{
                _id
                name
                type
                desposal
            }
        }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        })
            .then(response => {
                response.data.get_test.map((val, index) => {
                    val.desposal = JSON.parse(val.desposal)
                })
                dispatch(getTestSuccess(response.data.get_test))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// create test
export const createTest = (client, request) => {

    return dispatch => {
        const mutation = gql`
        mutation create_test($name: String!, $id: String!, $type: String! ) {
            create_test(name: $name, id: $id, type:$type) {
                success
                message
            }
          }
        `;
        client.mutate({
            mutation: mutation,
            variables: {
                id: Encrypt(request.id),
                name: Encrypt(request.name),
                // threshold: Encrypt(request.threshold),
                type: Encrypt(request.type),
                // desposal: Encrypt(request.desposal)
            }
        })
            .then(response => {
                if (request.id) {
                    dispatch(notificationSuccess(TestUpdated));
                } else {
                    dispatch(notificationSuccess(TestCreated));
                }
                dispatch(actions.getTest(client, []))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get Test Details Success
const getTestDetailsSuccess = (data) => {
    return {
        type: actionTypes.GET_TEST_DETAILS,
        state: data
    }
}

// get test detail
export const getTestDetails = (client, request) => {
    return dispatch => {
        const query = gql`
            query get_test_detail($id: String!) {
                get_test_detail(id: $id) {
                    _id
                    name
                    type
                }
            }            
            `;
        client.query({
            query: query,
            variables: {
                id: Encrypt(request.id)
            }
        })
            .then(response => {
                dispatch(getTestDetailsSuccess(response.data.get_test_detail))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// delete test
export const deleteTest = (client, request) => {
    return dispatch => {
        const query = gql`
            query delete_test($id: String!) {
                delete_test(id: $id) {
                    success
                    message
                }
            }            
            `;
        client.query({
            query: query,
            variables: {
                id: Encrypt(request.id)
            }
        })
            .then(response => {
                dispatch(notificationSuccess(TestDeleted));
                dispatch(actions.getTest(client, []))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get Licence Rq Success
const getLicenceRqSuccess = (data) => {
    return {
        type: actionTypes.GET_LICECNCE_REQUST_LIST,
        state: data
    }
}

// get Licence renew Rq Success
const getLicenceRenewRqSuccess = (data) => {
    return {
        type: actionTypes.GET_LICECNCE_RENEW_REQUST_LIST,
        state: data
    }
}

// get All Licence Req
export const getAllLicenceReq = (client, request) => {
    return dispatch => {
        const query = gql`
            query get_licence_request($renew: Boolean!) {
                get_licence_request(renew: $renew) {
                price
                displayname
                requesteddate
                count
                requestid
                month                
                }
            }
            `;
        client.query({
            query: query,
            variables: {
                renew: request.renew,
            },
            fetchPolicy: 'no-cache',
        })
            .then(response => {
                if (request.renew) {
                    dispatch(getLicenceRenewRqSuccess(response.data.get_licence_request))
                } else {
                    dispatch(getLicenceRqSuccess(response.data.get_licence_request))
                }
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get Licence Detail Success
const getTokenDetailSuccess = (data) => {
    return {
        type: actionTypes.GET_TOKEN_REQUST_DETAIL,
        state: data
    }
}

// get Token Req Details
export const getTokenReqDetails = (client, request) => {
    return dispatch => {
        const query = gql`
            query get_token_request_details($requestid: String!) {
                get_token_request_details(requestid: $requestid) {
                    _id
                    tokenid
                    tokenname
                    price
                    companyname
                    displayname
                    requestedDate
                    count
                    requestid
                    approve
                    created_at
                    updated_at           
                }
            }            
            `;
        client.query({
            query: query,
            variables: {
                requestid: Encrypt(request.requestid),
            }
        })
            .then(response => {
                dispatch(getTokenDetailSuccess(response.data.get_token_request_details))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}



// get Licence Detail Success
const getLicenceDetailSuccess = (data) => {
    return {
        type: actionTypes.GET_LICECNCE_REQUST_DETAIL,
        state: data
    }
}

// get Licence Req Details
export const getLicenceReqDetails = (client, request) => {
    return dispatch => {
        const query = gql`
            query get_licence_request_details($requestid: String!) {
                get_licence_request_details(requestid: $requestid) {
                    _id
                    licenceid
                    licencename
                    price
                    companyname
                    displayname
                    requesteddate
                    count
                    approve
                    renew
                    month
                    created_at
                    updated_at           
                }
            }            
            `;
        client.query({
            query: query,
            variables: {
                requestid: Encrypt(request.requestid),
            }
        })
            .then(response => {
                dispatch(getLicenceDetailSuccess(response.data.get_licence_request_details))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// accept Licence Request
export const acceptTokenRequest = (client, request) => {
    return dispatch => {
        const mutation = gql`
                mutation token_request_accept_reject($requestid: String!, $status: Boolean!) {
                    token_request_accept_reject (requestid: $requestid, status: $status) {
                    message
                    error
                    success
                    }
                }     
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                requestid: Encrypt(request.requestid),
                status: request.status
            }
        })
            .then(response => {
                let reqParam1 = {
                    renew: request.renew
                }
                dispatch(actions.getSuperAdminDashboardData(store.getState().apolloClient.client, []))
                dispatch(actions.getProjectTokenRequest(client, []))
                if (request.status == true) {
                    dispatch(notificationSuccess(Requestapproved));
                } else {
                    dispatch(notificationSuccess(Requestrejected));
                }
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}


// accept Licence Request
export const acceptLicenceRequest = (client, request) => {
    return dispatch => {
        const mutation = gql`
                mutation licence_request_accept_reject($requestid: String!, $status: Boolean!) {
                    licence_request_accept_reject (requestid: $requestid, status: $status) {
                    message
                    error
                    success
                    }
                }     
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                requestid: Encrypt(request.requestid),
                status: request.status
            }
        })
            .then(response => {
                let reqParam1 = {
                    renew: request.renew
                }
                dispatch(actions.getSuperAdminDashboardData(store.getState().apolloClient.client, []))
                dispatch(actions.getAllLicenceReq(client, reqParam1))
                if (request.status == true) {
                    dispatch(notificationSuccess(Requestapproved));
                } else {
                    dispatch(notificationSuccess(Requestrejected));
                }
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// request Token
export const requestTokenNew = (client, request, client2) => {
    return dispatch => {
        const mutation = gql`
                mutation token_request($token: String!) {
                    token_request(token: $token) {
                    message
                    error
                    success
                    }
                }
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                token: Encrypt(request.tokens)
            }
        })
            .then(response => {

                let invoiceParam = {
                    company_id: request.company_id,
                    category_name: "TOKEN",
                    title: "Token Request",
                    invoice_detail: request.tokens,
                    invoice_html: "",
                    invoice_name: "",
                }
                dispatch(actions.saveInvoice(client2, invoiceParam))
                dispatch(notificationSuccess(Requestsentsystemadmin));
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}



// request Licence
export const requestLicence = (client, request, client2) => {
    return dispatch => {
        const mutation = gql`
                mutation licence_request($licence: String!) {
                    licence_request(licence: $licence) {
                    message
                    error
                    success
                    }
                }
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                licence: Encrypt(request.licences),
                company_id: Encrypt(request.company_id)
            }
        })
            .then(response => {

                let invoiceParam = {
                    company_id: request.company_id,
                    category_name: "LICENCE",
                    title: "Licence Request",
                    invoice_detail: request.licences,
                    invoice_html: "",
                    invoice_name: "",
                }
                dispatch(actions.saveInvoice(client2, invoiceParam))
                dispatch(notificationSuccess(Requestsentsystemadmin));
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}


// request Licence
export const requestLicenceForRenew = (client, request, client2) => {
    return dispatch => {
        const mutation = gql`
                mutation licence_request($licence: String!, $licence_renew: Boolean) {
                    licence_request(licence: $licence, licence_renew: $licence_renew) {
                    message
                    error
                    success
                    }
                }
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                licence: Encrypt(request.licences),
                licence_renew: true
            }
        })
            .then(response => {

                let invoiceParam = {
                    company_id: request.company_id,
                    category_name: "LICENCE",
                    title: "Licence Renew",
                    invoice_detail: request.licences,
                    invoice_html: "",
                    invoice_name: "",
                }
                dispatch(actions.saveInvoice(client2, invoiceParam))
                dispatch(notificationSuccess(Requestsentsystemadmin));
            }).catch((error) => {
                // error.graphQLErrors[0].message = notification_err_license_renew_request_api_msg;
                //License renew request already sent!!!
                dispatch(notificationFail(error))
            });
    }
}



// get Plan Detail Success
const getPlanDetailSuccess = (data) => {
    return {
        type: actionTypes.GET_PLAN_REQUST_DETAIL,
        state: data
    }
}

// get All Plan Req
export const getAllPlanReq = (client, request) => {
    return dispatch => {
        const query = gql`
        {
            renew_plan_request_list{
            _id
            planid
            planname
            planprice
            month
            companyname
            displayname
            requesteddate
          }
        }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        })
            .then(response => {
                dispatch(getPlanDetailSuccess(response.data.renew_plan_request_list))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// accept Plan Request
export const acceptPlanRequest = (client, request) => {
    return dispatch => {
        const mutation = gql`
        mutation renew_plan_status_update($id: String!, $status: String!) {
            renew_plan_status_update(id: $id, status: $status) {
              message
              error
              success
            }
          }
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                id: Encrypt(request.id),
                status: Encrypt(request.status)
            }
        })
            .then(response => {
                if (request.status == "approve") {
                    dispatch(actions.getSuperAdminDashboardData(store.getState().apolloClient.client, []))
                    dispatch(notificationSuccess(planreqapproved));
                } else {
                    dispatch(actions.getSuperAdminDashboardData(store.getState().apolloClient.client, []))
                    dispatch(notificationSuccess(planreqrejected));
                }
                dispatch(actions.getAllPlanReq(client, []))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// request Plan
export const requestPlan = (client, request, client2) => {

    return dispatch => {
        const mutation = gql`
            mutation renew_plan_request($planid: String!, $planname: String!, $companyname: String!, $displayname: String!, $month: String!) {
                renew_plan_request(planid: $planid, planname: $planname, companyname: $companyname, displayname: $displayname, month: $month) {
                    message
                    error
                    success
                }
            } 
        `;
        client.mutate({
            mutation: mutation,
            variables: {
                planid: Encrypt(request.plan_id),
                planname: Encrypt(request.plan_name),
                companyname: Encrypt(request.company_name),
                displayname: Encrypt(request.display_name),
                month: Encrypt(request.month),
            }
        })
            .then(response => {

                let detailArray = []
                detailArray.push(request)
                let invoiceParam = {
                    company_id: request.company_id,
                    category_name: "SIGNUP",
                    title: "Plan Request",
                    invoice_detail: JSON.stringify(detailArray),
                    invoice_html: "",
                    invoice_name: "",
                }

                dispatch(actions.saveInvoice(client2, invoiceParam))

                dispatch(notificationSuccess(reqsentsystemadmin));
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get Active Project Success
const getActiveProjectSuccess = (data) => {
    return {
        type: actionTypes.GET_ACTIVE_PROJECT_LIST,
        state: data
    }
}

// get active project list for user  
export const getActiveProjectList = (client, request) => {
    return dispatch => {
        const query = gql`
        {
            get_active_project_list{
                _id
                projectid
                name
                purpose
                address
                latitude
                longitude
                company
                companyname
                displayname
                startDate
                endDate
                status
                projectOwner
                attachments
                isclosed
                tokentype
          }
        }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        })
            .then(response => {
                dispatch(getActiveProjectSuccess(response.data.get_active_project_list))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// accept Assign Licence
export const acceptAssignLicence = (project, request, client) => {
    return dispatch => {
        const mutation = gql`
        mutation user_licence_assign ($projectid: String!, $name: String!, $userid: String!, $roleid: String!, $rolename: String!, $companyid: String!, $companyname: String!, $displayname: String!, $licenceid: String!, $licencename: String!, $firebaseid: String!,  $companylicenceid: String!) {
          user_licence_assign(projectid: $projectid, name: $name, userid: $userid, roleid: $roleid, rolename: $rolename, companyid: $companyid, companyname: $companyname, displayname: $displayname, licenceid: $licenceid,licencename: $licencename ,firebaseid: $firebaseid , companylicenceid: $companylicenceid ) {
            message
            error
            success
          }
        }
    `;
        project.mutate({
            mutation: mutation,
            variables: {
                projectid: Encrypt(request.projectid),
                name: Encrypt(request.name),
                userid: Encrypt(request.userid),
                roleid: Encrypt(request.roleid),
                rolename: Encrypt(request.rolename),
                companyid: Encrypt(request.companyid),
                companyname: Encrypt(request.companyname),
                displayname: Encrypt(request.displayname),
                licenceid: Encrypt(request.licenceid),
                licencename: Encrypt(request.licencename),
                firebaseid: Encrypt(request.firebaseid),
                companylicenceid: Encrypt(request.companylicenceid),
            }
        })
            .then(response => {

                //My code to refresh the userdata after licence assigned for sidebar menu access changes
                if (request.useremail) {
                    let requestParams = {
                        projectid: request.userselectedprojectid ? request.userselectedprojectid : "",
                        email: request.useremail,
                    }
                    dispatch(actions.getAccess(client, requestParams))
                }
                dispatch(actions.getCompanylicence(client, []))
                dispatch(notificationSuccess(Licenceassigned))

                if(request.selected_page && request.selected_page == 'profile_page_user_projects_tab'){
                    dispatch(actions.getAllMembersProjectList(project, []))
                }
                if(request.selected_page && request.selected_page == 'profile_page_members_tab'){
                    let requestParams = {
                        userid: request.userid
                    };
                    dispatch(actions.getMemberDetails(project, requestParams))
                }

            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// create threshold
export const createThreshold = (client, request) => {
    return dispatch => {
        const mutation = gql`
        mutation update_test_family_desposal($data: String!) {
            update_test_family_desposal(data: $data) {
                success
                message
                error
            }
          }
        `;
        client.mutate({
            mutation: mutation,
            variables: {
                data: Encrypt(JSON.stringify(request)),
            }
        })
            .then(response => {
                setTimeout(function () {
                    dispatch(notificationSuccess(ThresholdUpdated));
                }, 2000);
                dispatch(actions.getTestType(client, []))
                dispatch(actions.getDesposal(client, []))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get Project Token Price Success
const getProjectTokenPriceSuccess = (data) => {
    return {
        type: actionTypes.GET_PROJECT_TOKEN_PRICE,
        state: data
    }
}

// get active project list for user  
export const getProjectTokenPrice = (client, request) => {
    return dispatch => {
        const query = gql`
        {
            get_project_token_price{
                _id
                tokenprice
          }
        }
            `;
        client.query({
            query: query,
            fetchPolicy: 'cache-first',
        })
            .then(response => {
                dispatch(getProjectTokenPriceSuccess(response.data.get_project_token_price))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// create project token price
export const saveProjectTokenPrice = (client, request) => {
    return dispatch => {
        const mutation = gql`
        mutation save_token_price($tokenprice: String!) {
            save_token_price(tokenprice: $tokenprice) {
                _id
                tokenprice
            }
          }
        `;
        client.mutate({
            mutation: mutation,
            variables: {
                tokenprice: Encrypt(request.tokenprice),
            }
        })
            .then(response => {
                dispatch(notificationSuccess(TokenPriceUpdated));
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// request Project Token
export const requestProjectToken = (client, request, client2) => {
    return dispatch => {
        const mutation = gql`
                mutation project_token_request($total_price: String!,$no_of_token: String!,$companyname: String!,$displayname: String!) {
                    project_token_request(total_price: $total_price,no_of_token: $no_of_token,companyname: $companyname,displayname: $displayname) {
                        success
                        message
                        error
                    }
                }
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                total_price: Encrypt(request.price),
                no_of_token: Encrypt(request.count),
                companyname: Encrypt(request.companyname),
                displayname: Encrypt(request.displayname),
            }
        })
            .then(response => {
                let detailArray = []
                detailArray.push(request)

                let invoiceParam = {
                    company_id: request.company_id,
                    category_name: "TOKEN",
                    title: "Project Token Request",
                    invoice_detail: JSON.stringify(detailArray),
                    invoice_html: "",
                    invoice_name: "",
                }
                dispatch(actions.saveInvoice(client2, invoiceParam))
                dispatch(notificationSuccess(reqsentsystemadmin));
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get Project Token Request Success
const getProjectTokenRequestSuccess = (data) => {
    return {
        type: actionTypes.GET_PROJECT_TOKEN_REQUEST,
        state: data
    }
}

// get active project list for user  
export const getProjectTokenRequest = (client, request) => {
    return dispatch => {
        const query = gql`
        {
            get_project_token_request{
                _id
                tokenid
                count
                companyname
                displayname
                price
                approve
                requestid
                requestedDate
          }
        }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        })
            .then(response => {
                dispatch(getProjectTokenRequestSuccess(response.data.get_project_token_request))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// accept project token request
export const acceptProjectTokenRequest = (client, request) => {
    return dispatch => {
        const mutation = gql`
                mutation project_token_request_accept($tokenid: String!,$status: Boolean!) {
                    project_token_request_accept(tokenid: $tokenid,status: $status) {
                        success
                        message
                        error
                    }
                }
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                tokenid: Encrypt(request.tokenid),
                status: request.status
            }
        })
            .then(response => {
                dispatch(actions.getSuperAdminDashboardData(store.getState().apolloClient.client, []))
                if (request.status) {
                    dispatch(notificationSuccess(requestaccept));
                } else {
                    dispatch(notificationSuccess(requestreject));
                }
                dispatch(actions.getProjectTokenRequest(client, []))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get Project Token List Success
const getProjectTokenListSuccess = (data) => {
    return {
        type: actionTypes.GET_PROJECT_TOKEN_LIST,
        state: data
    }
}

// get project token list   
export const getProjectTokenList = (client, request) => {
    return dispatch => {
        const query = gql`
        {
            get_project_token{
                _id
                tokenid
                companytokenid
                companyname
                displayname
                price
                used
                created_at
                updated_at
                project_data{
                    name
                }
          }
        }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        })
            .then(response => {
                dispatch(getProjectTokenListSuccess(response.data.get_project_token))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// update renew and extend plan request
export const renewExtendPlanRequest = (client, request) => {
    return dispatch => {
        const mutation = gql`
        mutation update_plan_renewal($renew_type: String!, $company_id: String!, $price: String, $expirydate: String, $comment: String) {
            update_plan_renewal(renew_type: $renew_type, company_id: $company_id, price: $price, expirydate: $expirydate, comment: $comment) {
              message
              error
              success
            }
          }
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                renew_type: Encrypt(request.type),
                company_id: Encrypt(request.companyid),
                price: Encrypt(request.price),
                expirydate: Encrypt(request.date),
                comment: Encrypt(request.comment)
            }
        })
            .then(response => {
                let detailArray = []
                detailArray.push(request)
                let invoiceParam = {
                    company_id: request.companyid,
                    category_name: "SIGNUP",
                    title: request.type == "renew" ? "Plan Renew Request" : "Plan Extend Request",
                    invoice_detail: JSON.stringify(detailArray),
                    invoice_html: "",
                    invoice_name: "",
                }

                dispatch(actions.getPlanRenewalList(client, []))
                dispatch(actions.saveInvoice(client, invoiceParam))

                if (request.type == "renew") {
                    dispatch(notificationSuccess(Renewplanreqapprove));
                } else {
                    dispatch(notificationSuccess(Extendplanreqapprove));
                }
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get Plan Renewal List Success
const getPlanRenewalListSuccess = (data) => {
    return {
        type: actionTypes.GET_PLAN_RENEWAL_LIST,
        state: data
    }
}

// get plan renew list   
export const getPlanRenewalList = (client, request) => {
    return dispatch => {
        const query = gql`
        {
            plan_renewal_list{
                _id
                planid
                comment
                company_id
                company_name
                plan_name
                userid
                expirydate
                price
          }
        }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        })
            .then(response => {
                dispatch(getPlanRenewalListSuccess(response.data.plan_renewal_list))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get Licence Renewal List Success
const getLicenceRenewalListSuccess = (data) => {
    return {
        type: actionTypes.GET_LICENCE_RENEWAL_LIST,
        state: data
    }
}

// get licence renew list   
export const getLicenceRenewalList = (client, request) => {
    return dispatch => {
        const query = gql`
        {
            license_renewal_list{
                _id
                company_id
                licenceid
                licencename
                userid
                expirydate
                comment
                cost
                company_name
                companylicenceid
          }
        }
            `;
        client.query({
            query: query,
            fetchPolicy: 'no-cache',
        })
            .then(response => {
                dispatch(getLicenceRenewalListSuccess(response.data.license_renewal_list))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}


// update renew and extend licence request
export const renewExtendLicenceRequest = (client, request) => {
    return dispatch => {
        const mutation = gql`
        mutation update_license_renewal($renew_type: String!, $company_id: String!, $cost: String, $expirydate: String, $comment: String, $licenceid: String!, $companylicenceid: String!) {
            update_license_renewal(renew_type: $renew_type, company_id: $company_id, cost: $cost, expirydate: $expirydate, comment: $comment, licenceid: $licenceid, companylicenceid: $companylicenceid) {
              message
              error
              success
            }
          }
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                renew_type: Encrypt(request.type),
                company_id: Encrypt(request.companyid),
                cost: Encrypt(request.price),
                expirydate: Encrypt(request.date),
                comment: Encrypt(request.comment),
                licenceid: Encrypt(request.licenceid),
                companylicenceid: Encrypt(request.companylicenceid),
            }
        })
            .then(response => {
                let detailArray = []
                detailArray.push(request)
                let invoiceParam = {
                    company_id: request.companyid,
                    category_name: "LICENCE",
                    title: request.type == "renew" ? "Licence Renew Request" : "Licence Extend Request",
                    invoice_detail: JSON.stringify(detailArray),
                    invoice_html: "",
                    invoice_name: "",
                    invoice_status: "success",
                }
                dispatch(actions.getLicenceRenewalList(client, []))
                dispatch(actions.saveInvoice(client, invoiceParam))
                if (request.type == "renew") {
                    dispatch(notificationSuccess(Renewlicencereqapprove));
                } else {
                    dispatch(notificationSuccess(Extendlicencereqapprove));
                }
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// save invoice 
export const saveInvoice = (client, request) => {
    return dispatch => {
        const mutation = gql`
        mutation invoice_data_add($company_id: String!, $category_name: String!, $title: String, $invoice_detail: String!, $invoice_html: String, $invoice_name: String, $invoice_status: String) {
            invoice_data_add(company_id: $company_id, category_name: $category_name, title: $title, invoice_detail: $invoice_detail, invoice_html: $invoice_html, invoice_name: $invoice_name, invoice_status: $invoice_status) {
              message
              error
              success
            }
          }
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                company_id: Encrypt(request.company_id),
                category_name: Encrypt(request.category_name),
                title: Encrypt(request.title),
                invoice_detail: Encrypt(request.invoice_detail),
                invoice_html: Encrypt(request.invoice_html),
                invoice_name: Encrypt(request.invoice_name),
                invoice_status: typeof request.invoice_status != "undefined" ? Encrypt(request.invoice_status) : ""
            }
        })
            .then(response => {
                // dispatch(notificationSuccess('invoice generate successfully'));
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// save Test Rule
export const saveTestRule = (client, request) => {
    return dispatch => {
        const mutation = gql`
        mutation add_test_rule(
            $id: String!, 
            $desposal_id: String!
            $desposal_name: String! 
            $test_id: String!
            $test_name: String!
            $child_id: String!
            $child_name: String!
            $value: String!
            $option_id: String!
            $formula: String!
            $children: String!
            $nolimit_finalvalue: Boolean
            ) {
            add_test_rule(
                id:$id, 
                desposal_id:$desposal_id, 
                desposal_name:$desposal_name
                test_id:$test_id
                test_name:$test_name
                child_id:$child_id
                child_name:$child_name
                value:$value
                option_id:$option_id
                formula: $formula
                children: $children
                nolimit_finalvalue: $nolimit_finalvalue
            ) {
                success
                error
                message
            }
          }
        `;
        client.mutate({
            mutation: mutation,
            variables: {
                id: Encrypt(request.id),
                desposal_id: Encrypt(request.desposal_id),
                desposal_name: Encrypt(request.desposal_name),
                test_id: Encrypt(request.test_id),
                test_name: Encrypt(request.test_name),
                child_id: Encrypt(request.child_id),
                child_name: Encrypt(request.child_name),
                value: Encrypt(request.value),
                option_id: Encrypt(request.option_id),
                formula: Encrypt(request.formula),
                children: Encrypt(request.children),
                nolimit_finalvalue: request.nolimit_finalvalue,
            }
        })
            .then(response => {
                if (response.data.add_test_rule.error == true) {
                    dispatch(notificationFail(response.data.add_test_rule.error))
                } else {
                    let desposalRequest = {
                        id: request.desposal_id
                    }
                    dispatch(actions.getTestRuleList(client, desposalRequest))
                    if (request.id) {
                        dispatch(notificationSuccess(RuleUpdated));
                    } else {
                        dispatch(notificationSuccess(RuleCreated));
                    }
                }
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// save Test family values
export const saveTestfamilyValue = (client, request) => {
    return dispatch => {
        const mutation = gql`
        mutation create_multiple_test_rule(
            $rules_data: String!
            ) {
            create_multiple_test_rule(
                rules_data:$rules_data,
            ) {
                success
                error
                message
            }
          }
        `;
        client.mutate({
            mutation: mutation,
            variables: {
                rules_data: request.rules_data,
            }
        })
            .then(response => {
                let desposalRequest = {
                    id: request.desposal_id
                }
                dispatch(actions.getTestRuleList(client, desposalRequest))
                dispatch(notificationSuccess(response.data.create_multiple_test_rule.message))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}


// get Search Testfamily Success
const getSearchTestfamilySuccess = (data) => {
    return {
        type: actionTypes.GET_SEARCH_TEST_FAMILY,
        state: data
    }
}
// search testfamily
export const getSearchTestFamily = (client, request) => {
    return dispatch => {
        const query = gql`
            query new_get_test_type($search: String!) {
                new_get_test_type(search: $search) {
                    _id
                    name
                    children
                    desposal
                }
            }            
            `;
        client.query({
            query: query,
            variables: {
                search: Encrypt(request.search)
            }
        })
            .then(response => {
                dispatch(getSearchTestfamilySuccess(response.data.new_get_test_type))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get Super Admin Dashboard Data Success
const getSuperAdminDashboardDataSuccess = (data) => {
    return {
        type: actionTypes.GET_SUPERADMIN_DASHBOARD_DATA,
        state: data
    }
}
// search testfamily
export const getSuperAdminDashboardData = (client, request) => {

    return dispatch => {
        const query = gql`
            query get_super_admin_dashboard_data {
                get_super_admin_dashboard_data {
                    success
                    users
                    plans
                    licence
                    users_count
                    company_count
                    plan_count
                    lic_count
                    get_company_request_list{
                        _id
                        email
                        name
                        company_id
                        company_name
                        display_name
                        company_address
                        registration_country
                        contact_number
                        company_strength
                        confirm_account
                        created_at
                        updated_at
                        attachments
                        status
                        account_status
                        plan {
                            _id
                            planid
                            planname
                            uploadsize
                            usersize
                            price
                            limit
                            month
                            purchasedate
                            expirydate
                          }
                    }
                    get_lic_request_list{
                        _id
                        licencename
                        price
                        displayname
                        requesteddate
                        count
                        renew
                        requestid
                        month
                        company_deatails{
                            company_id
                            company_name
                          }                    
                    }
                    get_plan_request_list{
                        _id
                        planid
                        planname
                        planprice
                        month
                        companyname
                        displayname
                        requesteddate
                        company_deatails{
                            company_id
                            company_name
                          } 
                    }
                    get_token_req_list{
                        _id
                        tokenid 
                        count
                        companyname
                        displayname
                        price
                        requestid
                        approve
                        created_at  
                        company_deatails{
                            company_id
                            company_name
                          } 
                    }
                }
            }
            `;
        client.query({
            query: query,
            fetchPolicy: "no-cache",
        }).then(response => {
            dispatch(getSuperAdminDashboardDataSuccess(response.data.get_super_admin_dashboard_data))
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}

// get Client Dashboard Data Success
const getClientDashboardDataSuccess = (data) => {
    return {
        type: actionTypes.GET_CLIENT_DASHBOARD_DATA,
        state: data
    }
}
// get Client Dashboard Data
export const getClientDashboardData = (client, request) => {
    return dispatch => {
        const query = gql`
            query get_client_dashboard_data($companyname: String!, $isadmin: Boolean!,$projectid: String!) {
                get_client_dashboard_data(companyname: $companyname, isadmin: $isadmin, projectid: $projectid) {
                    success
                    users
                    projects
                    campaign
                    probing
                    sample_count
                    layer_count
                }
            }            
            `;
        client.query({
            query: query,
            variables: {
                companyname: Encrypt(request.companyname),
                isadmin: request.isadmin,
                projectid: Encrypt(request.projectid),
            }
        })
            .then(response => {
                dispatch(getClientDashboardDataSuccess(response.data.get_client_dashboard_data))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get System Log Success
const getSystemLogSuccess = (data) => {
    return {
        type: actionTypes.GET_SYSTEM_LOG,
        state: data
    }
}
// get System Log
export const getSystemLog = (client, request) => {
    return dispatch => {
        const query = gql`
            query get_all_system_log(
                $pageno: Int!
                $limit: Int!
                $companyname: String!
                $searchtext: String
                 ) {
                get_all_system_log(
                    pageno: $pageno
                    limit: $limit
                    companyname: $companyname
                    searchtext: $searchtext
                    ) {
                    message
                    error
                    success
                    data
                    totalpage
                    totalrecord
                }
            }            
            `;
        client.query({
            query: query,
            variables: {
                pageno: request.pageno,
                limit: request.limit,
                searchtext: Encrypt(request.searchtext),
                companyname: Encrypt(request.companyname),
            }
        }).then(response => {
            if (response.data.get_all_system_log.error == false) {
                dispatch(getSystemLogSuccess(response.data.get_all_system_log))
            } else {
                dispatch(notificationFail(response.data.get_all_system_log))
            }
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}

// send Invoices
export const sendInvoices = (client, request) => {
    return dispatch => {
        const mutation = gql`
              mutation send_email_invoice(
                $company_id: String!,
                $invoice_name: String!,
                $invoice_html: String!
                ) {
                send_email_invoice(
                    company_id: $company_id,
                    invoice_name: $invoice_name,
                    invoice_html: $invoice_html
                ) {
                  message
                  error
                  success
                }
              }
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                company_id: Encrypt(request.company_id),
                invoice_name: Encrypt(request.invoice_name),
                invoice_html: Encrypt(request.invoice_html)
            }
        }).then(response => {
            if (response.data.send_email_invoice.error == false) {

                dispatch(notificationSuccess(invoice_mail))
            } else {
                dispatch(notificationFail(response.data.send_email_invoice.error))
            }
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}

/* get type wise other materials */
export const getTypeWiseOtherMatreials = (client, request) => {

    return dispatch => {
        const query = gql`
            query get_typewise_other_material($type_list: String!,$lang_code: String!) {
                get_typewise_other_material(type_list: $type_list,lang_code: $lang_code) {
                    color 
                    nature 
                    organoleptic 
                    geology 
                    category 
                    lithology 
                    moisture 
                    anomaly 
                    reason 
                    observation
                }
            }
            `;
        client.query({
            query: query,
            variables: {
                type_list: Encrypt(request.type_list),
                lang_code: Encrypt(request.lang_code)
            },
            fetchPolicy: 'no-cache',
        })
            .then(response => {
                let responseDate = response.data.get_typewise_other_material
                if (responseDate.color) {
                    dispatch(getColorMasterSuccess(responseDate.color))
                }
                if (responseDate.nature) {
                    dispatch(getNatureMasterSuccess(responseDate.nature))
                }
                if (responseDate.geology) {
                    dispatch(getGeologyMasterSuccess(responseDate.geology))
                }
                if (responseDate.organoleptic) {
                    dispatch(getOrganolepticMasterSuccess(responseDate.organoleptic))
                }
                if (responseDate.category) {
                    dispatch(getCategoryMasterSuccess(responseDate.category))
                }
                if (responseDate.lithology) {
                    dispatch(getLithologyMasterSuccess(responseDate.lithology))
                }
                if (responseDate.moisture) {
                    dispatch(getMoistureMasterSuccess(responseDate.moisture))
                }
                if (responseDate.anomaly) {
                    dispatch(getAnomalyMasterSuccess(responseDate.anomaly))
                }
                if (responseDate.reason) {
                    dispatch(getReasonMasterSuccess(responseDate.reason))
                }
                if (responseDate.observation) {
                    dispatch(getObservationMasterSuccess(responseDate.observation))
                }
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

/* get type wise other materials */
export const defaultGetTypeWiseOtherMatreials = (client, request) => {

    return dispatch => {
        const query = gql`
            query get_typewise_other_material($type_list: String!,$lang_code: String!) {
                get_typewise_other_material(type_list: $type_list,lang_code: $lang_code) {
                    color 
                    lithology 
                }
            }
            `;
        client.query({
            query: query,
            variables: {
                type_list: Encrypt(request.type_list),
                lang_code: Encrypt(request.lang_code)
            },
            fetchPolicy: 'no-cache',
        })
            .then(response => {
                let responseDate = response.data.get_typewise_other_material
                if (responseDate.color) {
                    dispatch(getEnglishColorMasterSuccess(responseDate.color))
                }
                if (responseDate.lithology) {
                    dispatch(getEnglishLithologyMasterSuccess(responseDate.lithology))
                }
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}


/* change user default unit */
export const changeUserDefaultUnitImportProbing = (client, request) => {
    return dispatch => {
        const mutation = gql`
              mutation change_user_default_unit(
                    $default_unit: String!,
                    $userid: String!
                ) {
                change_user_default_unit(
                    default_unit: $default_unit,
                    userid: $userid
                ) {
                  message
                  error
                  success
                }
              }
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                default_unit: Encrypt(request.default_unit),
                userid: Encrypt(request.userid)
            }
        }).then(response => {
            if (response.data.change_user_default_unit.error == false) {
                var credential = localStorage.getItem("_user_");
                if (credential) {
                    let finderprint = new fingerprint().get();
                    credential = jwt.verify(credential, finderprint.toString());
                    let storageData = {
                        ...credential,
                        default_unit: request.default_unit
                    }
                    localStorage.setItem("_user_", storeUserData(storageData))
                    dispatch(onLoginSucceed(storageData))
                }
                //dispatch(notificationSuccess(unit_update))
            } else {
                dispatch(notificationFail(response.data.change_user_default_unit.error))
            }
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}

/* change user default unit */
export const changeUserDefaultUnit = (client, request) => {
    return dispatch => {
        const mutation = gql`
              mutation change_user_default_unit(
                    $default_unit: String!,
                    $userid: String!
                ) {
                change_user_default_unit(
                    default_unit: $default_unit,
                    userid: $userid
                ) {
                  message
                  error
                  success
                }
              }
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                default_unit: Encrypt(request.default_unit),
                userid: Encrypt(request.userid)
            }
        }).then(response => {
            if (response.data.change_user_default_unit.error == false) {
                var credential = localStorage.getItem("_user_");
                if (credential) {
                    let finderprint = new fingerprint().get();
                    credential = jwt.verify(credential, finderprint.toString());
                    let storageData = {
                        ...credential,
                        default_unit: request.default_unit
                    }
                    localStorage.setItem("_user_", storeUserData(storageData))
                    dispatch(onLoginSucceed(storageData))
                }
                dispatch(notificationSuccess(unit_update))
            } else {
                dispatch(notificationFail(response.data.change_user_default_unit.error))
            }
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}

/* change user default unit */
export const setUserDefaultDisposalCategory = (client, request) => {
    return dispatch => {
        const mutation = gql`
              mutation set_user_default_disposal_category_type(
                    $default_disposal_type: String!,
                    $userid: String!,
                    $projectid: String,
                    $default_disposal_list: String
                ) {
                    set_user_default_disposal_category_type(
                        default_disposal_type: $default_disposal_type,
                        userid: $userid,
                        projectid: $projectid,
                        default_disposal_list: $default_disposal_list
                ) {
                  message
                  error
                  success
                }
              }
            `;
        client.mutate({
            mutation: mutation,
            variables: {
                default_disposal_type: Encrypt(request.default_disposal_type),
                userid: Encrypt(request.userid),
                projectid: Encrypt(request.projectid),
                default_disposal_list: Encrypt(request.default_disposal_list)
            }
        }).then(response => {
            if (response.data.set_user_default_disposal_category_type.error == false) {
                var credential = localStorage.getItem("_user_");
                if (credential) {
                    let finderprint = new fingerprint().get();
                    credential = jwt.verify(credential, finderprint.toString());
                    let storageData = {
                        ...credential,
                        default_disposal_type: request.default_disposal_type,
                        default_disposal_list: request.default_disposal_list
                    }
                    localStorage.setItem("_user_", storeUserData(storageData))
                    dispatch(onLoginSucceed(storageData))
                }
                dispatch(notificationSuccess(default_disposal_category_update))
            } else {
                dispatch(notificationFail(response.data.set_user_default_disposal_category_type.error))
            }
        }).catch((error) => {
            dispatch(notificationFail(error))
        });
    }
}


// get Super Admin Dashboard Data Success
const getAllUsersListDashboardSuccess = (data) => {
    return {
        type: actionTypes.GET_ALL_USERS_LIST_DASHBOARD,
        state: data
    }
}
// get all users list dashboard
export const getAllUsersListDashboard = (client, request) => {
    return dispatch => {
        const query = gql`{
                all_member_list {
                    _id
                    staff_id
                    name
                    firstname
                    lastname
                    email
                    userid
                    firebase_id
                    role_id
                    role_name
                    company_id
                    company_name
                    display_name
                    company_address
                    registration_country
                    contact_number
                    phone_number
                    status
                    approve_reject_by
                    company_strength
                    system_flag
                    other_company
                    confirm_account
                    staffuser
                    newuseraccesspage
                    attachments
                    userattachments
                    owner
                }
            }
            `;
        client.query({
            query: query,
        })
            .then(response => {
                dispatch(getAllUsersListDashboardSuccess(response.data.all_member_list))
            }).catch((error) => {
                dispatch(notificationFail(error))
            });
    }
}

// get all users list dashboard
export const MenuToggle = (request) => {
    return {
        type: actionTypes.MENU_TOGGLE,
        state: request
    }
}