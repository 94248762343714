import * as actionTypes from "../actionTypes";
import notificationMsg  from '../../../App/view/Layout/NotificationMessage';
import * as ChangeAppLanguageFront from '../../../App/view/Layout/ChangeAppLanguageFront';
let tlang = ChangeAppLanguageFront.translateLanguage;
const error_something_wrong = tlang('error_something_wrong') ? tlang('error_something_wrong') : 'Something Went Wrong!';

// notification Success
const notificationSuccess = (msg) => {
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_SUCCESS,
    state: msg,
  }
}

// notification Fail
const notificationFail = err => {
  let msg = error_something_wrong;
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    // msg = err.graphQLErrors[0].message;
    let translateMsg =notificationMsg(err.graphQLErrors[0].statusCode)
    msg = translateMsg;
  } else if (err.message) {
    msg = err.message;
  }
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_FAIL,
    state: msg
  };
};

// get Lang New Master List Success
export const getLangNewMasterListSuccess = data => {
  return {
    type: actionTypes.GET_LANGUAGE_NEW_LABEL_LIST,
    state: data
  };
};
