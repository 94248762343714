import React from 'react';

import { Card, CardBody, Spinner, Collapse } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import NotificationsIcon from 'mdi-react/NotificationsIcon';

import { Grid, Icon } from '@material-ui/core';
import { connect } from 'react-redux';
import JsxParser from 'react-jsx-parser';
import moment from 'moment';
import * as actions from '../../../Store/actions/index';
import { withTranslation } from 'react-i18next';
import Server from '../../../Common/Server';

import * as ChangeAppLanguageFront from '../Layout/ChangeAppLanguageFront';
let tlang = ChangeAppLanguageFront.translateLanguage;

const no_records_found = tlang('client_cmp_no_records_found')
	? tlang('client_cmp_no_records_found')
	: 'No records found';
const notification_lbl = tlang('notification_lbl') ? tlang('notification_lbl') : 'Notification';
const mark_all_as_read = tlang('mark_all_as_read') ? tlang('mark_all_as_read') : 'Mark all as read';
const notification_all_tab = tlang('notification_all_tab') ? tlang('notification_all_tab') : 'All';
const notification_unread_tab = tlang('notification_unread_tab')
	? tlang('notification_unread_tab')
	: 'Unread';
const notification_read_tab = tlang('notification_read_tab')
	? tlang('notification_read_tab')
	: 'Read';
const notification_see_more = tlang('notification_see_more')
	? tlang('notification_see_more')
	: 'See More';

class NotificationComponent extends React.Component {
	constructor(props) {
		super(props);
		// Don't call this.setState() here!
		this.state = {
			notificationList: [],
			unreadNotificationCount: 0,
			skipNotificationCount: 0,
			latestNotificationList: false,
			seeMoreNotificationIsDisabled: true,
			notification_chatid: '',
			loading: false,
			collapseNotify: false,
			selectedType: '1',
			allNotificationCount: 0,
			readNotificationCount: 0,
		};
	}

	componentDidMount() {
		let requestParam = {
			userid: this.props.authReducer.userData.userid,
			skip: this.state.skipNotificationCount,
		};
		this.props.getUserNotificationList(this.props.apolloClient.client, requestParam);

		let requestParamForNotiCount = {
			userid: this.props.authReducer.userData.userid,
		};
		this.props.getUserNotificationUnreadCount(
			this.props.apolloClient.client,
			requestParamForNotiCount
		);

		this.setState({
			latestNotificationList: true,
		});
	}
	componentWillReceiveProps(props) {
		// props.contentManagementReducer.userNotificationUnreadCount
		if (props.contentManagementReducer.userNotificationUnreadCount) {
			let unreadNotificationCount =
				props.contentManagementReducer.userNotificationUnreadCount.unread_count;
			this.setState(
				{
					unreadNotificationCount: unreadNotificationCount,
				},
				() => this.forceUpdate()
			);
		}

		if (props.contentManagementReducer.userNotificationList) {
			let list = props.contentManagementReducer.userNotificationList;
			let notificationList = this.state.notificationList;

			if (list.length > 0 && this.state.latestNotificationList) {
				for (const val of list) {
					val.notifications.data_time = '';
					val.notifications.chatid = val._id;
					val.notifications.profile_pic = val.notifications.profile_pic
						? val.notifications.profile_pic
						: `${process.env.PUBLIC_URL}/img/topbar/ava.png`;

					if (val.created_at) {
						val.notifications.data_time = moment(parseInt(val.created_at)).format(
							'DD/MM/YYYY HH:mm'
						);
					}
					notificationList.push(val);
				}
				this.setState({
					latestNotificationList: false,
				});
			}
			let seeMoreNotificationIsDisabled = true;
			if (list.length === 5) {
				seeMoreNotificationIsDisabled = false;
			}

			this.setState(
				{
					notificationList,
					loading: false,
					seeMoreNotificationIsDisabled: seeMoreNotificationIsDisabled,
				},
				function() {
					this.forceUpdate();
					let notification_chatid = this.state.notification_chatid;

					let allNotificationCount = this.state.notificationList.length;
					let readNotificationCount = this.state.notificationList.filter(
						val => val.notifications.read === true
					).length;
					let unreadNotificationCount = this.state.notificationList.filter(
						val => val.notifications.read === false
					).length;

					this.setState(
						{
							allNotificationCount: allNotificationCount,
							readNotificationCount: readNotificationCount,
							unreadNotificationCount: unreadNotificationCount,
						},
						() => this.forceUpdate()
					);

					if (notification_chatid) {
						let notificationList = this.state.notificationList;
						for (const val of notificationList) {
							if (notification_chatid === val._id) {
								val.notifications.read = true;
							}
						}

						let allNotificationCount = notificationList.length;
						let readNotificationCount = notificationList.filter(
							val => val.notifications.read === true
						).length;
						let unreadNotificationCount = notificationList.filter(
							val => val.notifications.read === false
						).length;

						this.setState(
							{
								notificationList: notificationList,
								notification_chatid: '',
								allNotificationCount: allNotificationCount,
								readNotificationCount: readNotificationCount,
								unreadNotificationCount: unreadNotificationCount,
							},
							() => this.forceUpdate()
						);
					}
				}
			);
		}
	}

	// more notification load
	nextNotificationList = () => {
		let skipCount = this.state.skipNotificationCount + 5;
		let requestParam = {
			userid: this.props.authReducer.userData.userid,
			skip: skipCount,
		};
		this.props.getUserNotificationList(this.props.apolloClient.client, requestParam);
		this.setState(
			{
				skipNotificationCount: skipCount,
				latestNotificationList: true,
			},
			() => this.forceUpdate()
		);
	};

	notificationMarkAllAsRead = (chatid = '') => {
		let request = {
			userid: this.props.authReducer.userData.userid,
			chatid: chatid,
		};
		this.props.notificationMarkAllAsRead(
			this.props.apolloClient.masters,
			this.props.apolloClient.client,
			request
		);
		if (chatid !== '') {
			this.setState({ loading: true, notification_chatid: chatid });
		} else {
			let notificationList = this.state.notificationList;
			for (const val of notificationList) {
				val.notifications.read = true;
			}

			let allNotificationCount = notificationList.length;
			let readNotificationCount = notificationList.filter(val => val.notifications.read === true)
				.length;
			let unreadNotificationCount = notificationList.filter(val => val.notifications.read === false)
				.length;

			this.setState(
				{
					notificationList: notificationList,
					allNotificationCount: allNotificationCount,
					readNotificationCount: readNotificationCount,
					unreadNotificationCount: unreadNotificationCount,
				},
				() => this.forceUpdate()
			);

			setTimeout(
				function() {
					this.toggleNotify();
				}.bind(this),
				1000
			);
		}
	};

	toggleNotify = () => {
		this.setState({ collapseNotify: !this.state.collapseNotify });
	};

	// notification to set specific redirect page
	redirectToPage = (event, notification) => {
		let pathname = this.props.location.pathname;

		let isadmin = false;
		if (this.props.authReducer.userData.email === this.props.authReducer.userData.owner) {
			isadmin = true;
		}
		if (!notification.read) {
			this.notificationMarkAllAsRead(notification.chatid);
		}
		let key = notification.key;
		if (key !== '' && key !== 'CHAT_MESSAGE') {
			if (
				key === 'ADD_IN_PROJECT' ||
				key === 'REMOVE_IN_PROJECT' ||
				key === 'PROJECT_REQ_ACCEPT' ||
				key === 'PROJECT_REQ_REJECT' ||
				key === 'ADD_MEMBER'
			) {
				if (pathname !== '/client/projectList') {
					this.props.history.push('/client/projectList');
				} else {
					this.props.currentNotificationRedirectPageUrl('/client/projectList');
				}
			} else if (
				key === 'PROJECT_MEMBER_REQ' &&
				this.props.authReducer.userData.projectid !== null
			) {
				localStorage.setItem('client_request_inner_tab', '1');
				localStorage.setItem('client_request_tab', 3);
				if (pathname !== '/client/profile') {
					this.props.history.push('/client/profile');
				} else {
					this.props.currentNotificationRedirectPageUrl('/client/profile');
				}
			} else if (
				(key === 'CAMPAIGN_MEMBER_REQ' ||
					key === 'CAMPAIGN_REQ_REJECT' ||
					key === 'CAMPAIGN_REQ_ACCEPT' ||
					key === 'ADD_IN_CAMPAIGN') &&
				this.props.authReducer.userData.projectid !== null
			) {
				if (pathname !== '/client/campaignList') {
					this.props.history.push('/client/campaignList');
				} else {
					this.props.currentNotificationRedirectPageUrl('/client/campaignList');
				}
			} else if (key === 'PROJECT_TOKEN_REQ_ACCEPT' || key === 'PROJECT_TOKEN_REQ_REJECT') {
				if (this.props.authReducer.userData.plan.planid !== 'AXD_PL_01' && isadmin) {
					localStorage.setItem('client_request_tab', '8');
					if (pathname !== '/client/profile') {
						this.props.history.push('/client/profile');
					} else {
						this.props.currentNotificationRedirectPageUrl('/client/profile');
					}
				}
			} else if (
				key === 'LICENSE_RENEW_REQ_ACCEPT' ||
				key === 'LICENSE_RENEW_REQ_REJECT' ||
				key === 'LICENSE_REQ_ACCEPT'
			) {
				if (this.props.authReducer.userData.plan.planid !== 'AXD_PL_01' && isadmin) {
					localStorage.setItem('client_request_tab', '7');

					if (pathname !== '/client/profile') {
						this.props.history.push('/client/profile');
					} else {
						this.props.currentNotificationRedirectPageUrl('/client/profile');
					}
				}
			} else if (key === 'PLAN_RENEW_REQ_ACCEPT' || key === 'PLAN_RENEW_REQ_REJECT') {
				if (isadmin) {
					localStorage.setItem('client_request_tab', '4');

					if (pathname !== '/client/profile') {
						this.props.history.push('/client/profile');
					} else {
						this.props.currentNotificationRedirectPageUrl('/client/profile');
					}
				}
			} else if (key === 'SIGNUP_REQUEST') {
				// this.props.history.push('/admin/request')
				if (pathname !== '/admin/request') {
					this.props.history.push('/admin/request');
				} else {
					this.props.currentNotificationRedirectPageUrl('/admin/request');
				}
			} else if (key === 'PLAN_RENEW_REQUEST') {
				localStorage.setItem('plan_request_tab', '2');

				// this.props.history.push('/admin/planRequest')
				if (pathname !== '/admin/planRequest') {
					this.props.history.push('/admin/planRequest');
				} else {
					this.props.currentNotificationRedirectPageUrl('/admin/planRequest');
				}
			} else if (key === 'PROJECT_TOKEN_REQUEST') {
				// this.props.history.push('/admin/projectToken')
				if (pathname !== '/admin/projectToken') {
					this.props.history.push('/admin/projectToken');
				} else {
					this.props.currentNotificationRedirectPageUrl('/admin/projectToken');
				}
			} else if (key === 'LICENSE_REQUEST') {
				localStorage.setItem('licence_request_tab', '1');
				if (pathname !== '/admin/licenceRequest') {
					this.props.history.push('/admin/licenceRequest');
				} else {
					this.props.currentNotificationRedirectPageUrl('/admin/licenceRequest');
				}
			} else if (key === 'LICENSE_RENEW_REQUEST') {
				localStorage.setItem('licence_request_tab', '4');
				// this.props.history.push('/admin/licenceRequest')
				if (pathname !== '/admin/licenceRequest') {
					this.props.history.push('/admin/licenceRequest');
				} else {
					this.props.currentNotificationRedirectPageUrl('/admin/licenceRequest');
				}
			}
		}
		setTimeout(
			function() {
				this.toggleNotify();
			}.bind(this),
			1000
		);
	};

	RequestType = type => {
		this.setState({ selectedType: type });
	};

	render() {
		let currentLanguage = localStorage.getItem('language');

		const { selectedType, collapseNotify } = this.state;

		const notificationCounter = {
			padding: '2px 3px',
			backgroundColor: 'rgb(230, 72, 35)',
			color: 'white',
			fontSize: '12px',
			position: 'absolute',
			top: '7px',
			right: '2px',
			border: '2px solid white',
			borderRadius: '5px',
		};
		return (
			<>
				<button className="topbar__btn" type="button" onClick={this.toggleNotify}>
					<NotificationsIcon />
					{this.state.unreadNotificationCount !== 0 && (
						<span style={notificationCounter}>{this.state.unreadNotificationCount}</span>
					)}
				</button>
				{collapseNotify && (
					<button className="topbar__back" type="button" onClick={this.toggleNotify} />
				)}
				<Collapse isOpen={collapseNotify} className="topbar__collapse-content">
					<div className="topbar__collapse-title-wrap">
						<p className="topbar__collapse-title">{notification_lbl} </p>
						{this.state.unreadNotificationCount !== 0 && (
							<span>
								<a
									className="markasread topbar__collapse-link"
									href="#"
									onClick={e => e.preventDefault() || this.notificationMarkAllAsRead()}
									disabled={this.state.loading}
								>
									{this.state.loading && (
										<Spinner size="sm" className="btn-spinner" style={{ color: '#E64823' }} />
									)}
									{mark_all_as_read}
								</a>
							</span>
						)}
					</div>
					<div className="notification-size">
						<div>
							<Grid
								container
								className="admin_request"
								style={{ margin: '-10px', textAlign: 'center' }}
							>
								<Grid item xs={12} sm={12} md={12}>
									<Card style={{ paddingBottom: '0px' }}>
										<CardBody>
											<div
												className={`requestTagBadge ${selectedType === '1' ? ' badge-danger' : ''}`}
												onClick={() => this.RequestType('1')}
											>
												{notification_all_tab}
												<span className="requestTag">{this.state.allNotificationCount}</span>
											</div>
											<div
												className={`requestTagBadge ${selectedType === '2' ? ' badge-danger' : ''}`}
												onClick={() => this.RequestType('2')}
											>
												{notification_unread_tab}
												<span className="requestTag">{this.state.unreadNotificationCount}</span>
											</div>
											<div
												className={`requestTagBadge ${selectedType === '3' ? ' badge-danger' : ''}`}
												onClick={() => this.RequestType('3')}
											>
												{notification_read_tab}
												<span className="requestTag">{this.state.readNotificationCount}</span>
											</div>
										</CardBody>
									</Card>
								</Grid>
							</Grid>
						</div>
						{this.state.notificationList.length ? (
							<>
								{this.state.notificationList.map((notification, index) => {
									let msg = '';
									let nData = notification.notifications;
									let tranTags = nData.tags;
									if (
										notification.notification_template.length &&
										notification.notification_template[0].lang_desc &&
										notification.notification_template[0].lang_desc[0]
									) {
										let lang_desc = notification.notification_template[0].lang_desc[0];
										for (var key in lang_desc) {
											if (key === currentLanguage) {
												msg = lang_desc[key];
											}
										}
									} else {
										msg = nData.message;
									}
									msg = msg && msg !== '' ? msg.replace('}}', '').replace('{{', '') : '';
									for (const key in tranTags) {
										msg = msg.replace(key, tranTags[key]);
									}

									msg = msg && msg !== '' ? msg.replace('}}', '').replace('{{', '') : '';

									let url_prefix = Server.IMAGE_URL + '/' + Server.COUCH_DATABASE + '/';
									let defImg = url_prefix + '3358e2d8-5f3c-4b03-9c24-c009d7822cbe/user_pic.jpg';
									let notificationIcon =
										typeof nData.profile_pic !== 'undefined' ? nData.profile_pic : defImg;

									return (
										<div key={`NOTIFICATION-${index}`}>
											{selectedType === '1' ? (
												<div
													className="topbar__collapse-item cursor-pointer"
													key={index}
													style={{ background: nData.read ? '#ffffff' : '#bad0f299' }}
													onClick={event => this.redirectToPage(event, nData)}
												>
													<div className="topbar__collapse-img-wrap">
														<div
															className="topbar__collapse-img"
															style={{
																backgroundImage: 'url(' + notificationIcon + ')',
																backgroundSize: 'cover',
																height: '100%',
															}}
														>
															<img
																src={notificationIcon}
																onError={e => {
																	e.target.onerror = null;
																	e.target.src = defImg;
																}}
																alt=""
															/>
														</div>
													</div>
													<div className="topbar__collapse-message">
														<JsxParser jsx={msg} />
														{nData.key === 'CHAT_MESSAGE' && nData.docurl !== '' && (
															<a href={nData.docurl} target="_blank" rel="noreferrer">
																<Icon>archive</Icon> {nData.filename} <br></br>{' '}
															</a>
														)}
														<span className="topbar__collapse-name">{nData.data_time} </span>
													</div>
												</div>
											) : selectedType === '2' ? (
												<>
													{this.state.unreadNotificationCount > 0
														? !nData.read && (
																<div
																	className="topbar__collapse-item cursor-pointer"
																	key={index}
																	style={{ background: '#bad0f299' }}
																	onClick={event => this.redirectToPage(event, nData)}
																>
																	<div className="topbar__collapse-img-wrap">
																		<div
																			className="topbar__collapse-img"
																			style={{
																				backgroundImage: 'url(' + notificationIcon + ')',
																				backgroundSize: 'cover',
																				height: '100%',
																			}}
																		>
																			<img
																				src={notificationIcon}
																				onError={e => {
																					e.target.onerror = null;
																					e.target.src = defImg;
																				}}
																				alt=""
																			/>
																		</div>
																	</div>
																	<div className="topbar__collapse-message">
																		<JsxParser jsx={msg} />
																		{nData.key === 'CHAT_MESSAGE' && nData.docurl !== '' && (
																			<a href={nData.docurl} target="_blank" rel="noreferrer">
																				<Icon>archive</Icon> {nData.filename} <br></br>{' '}
																			</a>
																		)}
																		<span className="topbar__collapse-name">
																			{nData.data_time}{' '}
																		</span>
																	</div>
																</div>
														  )
														: index === 0 && (
																<p className="m-3 text-center">
																	<b>{no_records_found}</b>
																</p>
														  )}
												</>
											) : selectedType === '3' ? (
												<>
													{this.state.readNotificationCount > 0
														? nData.read && (
																<div
																	className="topbar__collapse-item cursor-pointer"
																	key={index}
																	style={{ background: '#ffffff' }}
																	onClick={event => this.redirectToPage(event, nData)}
																>
																	<div className="topbar__collapse-img-wrap">
																		<div
																			className="topbar__collapse-img"
																			style={{
																				backgroundImage: 'url(' + notificationIcon + ')',
																				backgroundSize: 'cover',
																				height: '100%',
																			}}
																		>
																			<img
																				src={notificationIcon}
																				onError={e => {
																					e.target.onerror = null;
																					e.target.src = defImg;
																				}}
																				alt=""
																			/>
																		</div>
																	</div>
																	<div className="topbar__collapse-message">
																		<JsxParser jsx={msg} />
																		{nData.key === 'CHAT_MESSAGE' && nData.docurl !== '' && (
																			<a href={nData.docurl} target="_blank" rel="noreferrer">
																				<Icon>archive</Icon> {nData.filename} <br></br>{' '}
																			</a>
																		)}
																		<span className="topbar__collapse-name">
																			{nData.data_time}{' '}
																		</span>
																	</div>
																</div>
														  )
														: index === 0 && (
																<p className="m-3 text-center">
																	<b>{no_records_found}</b>
																</p>
														  )}
												</>
											) : (
												<h1>other List</h1>
											)}
										</div>
									);
								})}
								<hr />
								{this.state.seeMoreNotificationIsDisabled ? (
									<p className="m-3 text-center" style={{ cursor: 'pointer' }}>
										<b>{notification_see_more}</b>
									</p>
								) : (
									<p
										className="m-3 text-center"
										style={{ cursor: 'pointer' }}
										onClick={e => this.nextNotificationList()}
									>
										<b>{notification_see_more}</b>
									</p>
								)}
							</>
						) : (
							<p className="m-3 text-center">
								<b>{no_records_found}</b>
							</p>
						)}
					</div>
				</Collapse>
			</>
		);
	}
}

//load reducers
const mapStateToProps = state => {
	return {
		authReducer: state.authReducer,
		apolloClient: state.apolloClient,
		contentManagementReducer: state.contentManagementReducer,
		languagecodeReducer: state.languagecodeReducer,
		masterReducer: state.masterReducer,
		commonReducer: state.commonReducer,
	};
};

//Load actions

const mapDispatchToProps = dispatch => {
	return {
		getUserNotificationList: (client, request) => {
			dispatch(actions.getUserNotificationList(client, request));
		},
		getUserNotificationUnreadCount: (client, request) => {
			dispatch(actions.getUserNotificationUnreadCount(client, request));
		},
		notificationMarkAllAsRead: (master, client, request) => {
			dispatch(actions.notificationMarkAllAsRead(master, client, request));
		},
		currentNotificationRedirectPageUrl: data => {
			dispatch(actions.currentNotificationRedirectPageUrl(data));
		},
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(NotificationComponent))
);
