import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
} from "@react-pdf/renderer";

import { DataTableCell, Table, TableBody, TableCell, TableHeader, Utils } from "@david.kucsai/react-pdf-table"
import moment from "moment";

import * as ChangeAppLanguageFront from '../Layout/ChangeAppLanguageFront';

let tlang = ChangeAppLanguageFront.translateLanguage;
const styles = StyleSheet.create({
    logo:{
        width:"80px",
        marginBottom:10
    },  
    invoiceHeading:{
        fontSize:25,
        // color:"#646777",
        marginBottom:10
    },
    invoiceTexts:{
        fontSize:12,
        lineHeight:1.3,
        // color:"#646777"
    },
    invoiceDate:{
        marginTop:20,
        fontSize:12,
        lineHeight:1.3,
    },
    invoiceTitle:{
        fontSize:15,
        // color:"#646777",
        marginTop:20,
        marginBottom:10,
    },
    row_data_heading: {
        width: "70%",
        flexDirection: "row",
        fontSize: 11,        
        fontWeight:700
    },
    row_price_heading: {
        width: "30%",
        flexDirection: "row",
        fontSize: 11,        
        textAlign:"right",
        fontWeight:700
    },
    row_data: {
        width: "70%",
        flexDirection: "row",
        fontSize: 11,
        color:"#525252",
    },
    row_data_2: {
        width: "35%",
        flexDirection: "row",
        fontSize: 11,
        color:"#525252",
    },
    row_price: {
        width: "30%",
        flexDirection: "row",
        fontSize: 11,        
        textAlign:"right",
        color:"#525252",
    },
    page: {
        backgroundColor: "#ffffff",
        padding: 20 
    },
    coloumn: {
        flexDirection: "row",       
        marginTop:5,
        paddingBottom:2,
        borderBottom:"1 solid #e0e0e0",
    },
    total_coloumn: {
        flexDirection: "row",       
        marginTop:10,
    },
}); 
 
const getTotalAmount = (invoice) => {
    let total = 0;
    let  invoiceDetail = invoice ? invoice.invoice_detail : []
    invoiceDetail.map((row) => {
      if(invoice.category_name === "TOKEN" ){
          total += row.price;
      }else if(invoice.category_name === "SIGNUP"){
        if(row.price){
            total += row.count ? row.count * row.price : row.price; 
        }
        if(row.plan){
            total += row.plan.count ? row.plan.count * row.plan.price : row.plan.price * row.plan.month;
        }
        if(row.licence){
            row.licence.filter((value,index1) => value.count != 0 ).map((val,index)=>{
                total += val.count ? val.count * val.price * val.month : val.price
            })
        }
      }else if(invoice.category_name === "LICENCE"){
        total += row.count || row.count == 0 ? row.count * row.price : row.price;
      }
    })
    return parseInt(total);
}
const client_invoice_txt = tlang('client_invoice_txt') ? tlang('client_invoice_txt') : 'Invoice';
const client_invoice_cmp = tlang('client_invoice_cmp') ? tlang('client_invoice_cmp') : 'Company';
const USERREQUEST_EMAIL_KEY = tlang('user_request_email') ? tlang('user_request_email') : 'Email';
const client_invoice_date = tlang('client_invoice_date') ? tlang('client_invoice_date') : 'Invoice Date';
const signup_wizard_item = tlang('signup_wizard_item') ? tlang('signup_wizard_item') : 'Item';
const client_invoice_cost = tlang('client_invoice_cost') ? tlang('client_invoice_cost') : 'Cost';
const signup_wizard_grand_total = tlang('signup_wizard_grand_total') ? tlang('signup_wizard_grand_total') : 'Grand Total';
const signup_wizard_month = tlang('signup_wizard_month')?tlang('signup_wizard_month'):'month';

export function PdfDocument(invoice) {

    const date = moment(new Date(invoice.data.created_at) / 1000).format("DD/MM/YYYY HH:mm");
    var invoice_date = moment(date, "DD/MM/YYYY HH:mm", true).isValid();
    let dateDispaly = invoice_date ? moment.unix(new Date(invoice.data.created_at) / 1000).format("DD/MM/YYYY HH:mm"): moment.unix(invoice.data.created_at / 1000).format("DD/MM/YYYY HH:mm")

    return (
        <Document>
            <Page size="A4" style={styles.page} wrap>
                    <View fixed>
                        <Image src={`${process.env.PUBLIC_URL}/img/aexdo_logo.png`} alt="images" style={styles.logo} ></Image>
                        <Text style={styles.invoiceHeading}>{client_invoice_txt}</Text>
                    </View>
                    <View fixed>
                        <Text style={styles.invoiceTexts}>{client_invoice_cmp+": "}{invoice.data.company_name}</Text>
                        <Text style={styles.invoiceTexts}>{USERREQUEST_EMAIL_KEY+": "}{invoice.data.company_email}</Text>
                        <Text style={styles.invoiceDate}>{client_invoice_date+": "}{dateDispaly}</Text>
                        <Text style={styles.invoiceTitle}>{invoice.data.title}</Text>
                    </View>
                    {invoice.data.category_name === "SIGNUP" &&
                        <>
                            <View>
                                <View style={styles.coloumn}>
                                    <Text style={styles.row_data_heading}>{signup_wizard_item}</Text>
                                    <Text style={styles.row_price_heading}>{client_invoice_cost}</Text>
                                </View>
                            </View>
                            <View>
                                {
                                    invoice.data.invoice_detail.map((detail,i)=>{
                                        return (
                                            <>
                                            {(detail.plan_name || detail.planname || detail.type) && 
                                                <View style={styles.coloumn} key={i}>
                                                    <Text style={styles.row_data}>
                                                        {detail.planname && detail.planname}
                                                        {detail.plan_name && detail.plan_name}
                                                        {detail.type === "renew" && "Plan Renew"}
                                                        {detail.type === "extend" && "Plan Extend"}
                                                    </Text>
                                                    <Text style={styles.row_price}>{detail.month && detail.month}{" "}{signup_wizard_month}</Text>
                                                    <Text style={styles.row_price}>${detail.price}</Text>
                                                </View>
                                            }
                                            {detail.plan && 
                                                <View style={styles.coloumn}>
                                                    <Text style={styles.row_data}>
                                                        {detail.plan.planname && detail.plan.planname}
                                                        {detail.plan.type === "renew" && "Plan Renew"}
                                                        {detail.plan.type === "extend" && "Plan Extend"}
                                                    </Text>
                                                    <Text style={styles.row_price}>{detail.plan.month && detail.plan.month}{" "}{signup_wizard_month}</Text>
                                                    <Text style={styles.row_price}>${detail.plan.price * detail.plan.month}</Text>
                                                </View>
                                            }
                                            {
                                                detail.licence && detail.licence.filter((value,index1) => value.count != 0 ).map((val,index)=>{
                                                    return (
                                                        <>
                                                            <View style={styles.coloumn}>
                                                                <Text style={styles.row_data_2}>
                                                                    {val.licencename && val.licencename}
                                                                </Text>
                                                                <Text style={styles.row_data_2}>
                                                                    {val.count ? val.count:"1"}
                                                                </Text>
                                                                <Text style={styles.row_data_2}>
                                                                    {val.month ? val.month:"1"} {signup_wizard_month}
                                                                </Text>
                                                                <Text style={styles.row_price}>${val.count ? val.count * val.price * val.month : val.price}</Text>
                                                            </View>
                                                        </>
                                                    )        
                                                })
                                            }
                                            </>
                                        )
                                    })
                                }
                                <View style={styles.total_coloumn}>
                                    <Text style={styles.row_data}>
                                    </Text>
                                    <Text style={styles.row_price}>{signup_wizard_grand_total}: ${getTotalAmount(invoice.data)}</Text>
                                </View>                                
                            </View>
                        </>    
                    }
                    {invoice.data.category_name === "TOKEN" &&
                        <>
                            <View>
                                <View style={styles.coloumn}>
                                    <Text style={styles.row_data_heading}>{"Item"}</Text>
                                    <Text style={styles.row_data_heading}>{"Count"}</Text>
                                    <Text style={styles.row_data_heading}>{"Cost"}</Text>
                                    <Text style={styles.row_price_heading}>{"Amount"}</Text>
                                </View>
                            </View>
                            <View>
                                {
                                    invoice.data.invoice_detail.map(detail=>{
                                        return (
                                            <View style={styles.coloumn}>
                                                <Text style={styles.row_data}>
                                                    {"Token"}
                                                </Text>
                                                <Text style={styles.row_data}>
                                                    {detail.count}
                                                </Text>
                                                <Text style={styles.row_data}>
                                                    ${detail.price / detail.count}
                                                </Text>
                                                <Text style={styles.row_price}>${detail.price}</Text>
                                            </View>
                                        )
                                    })
                                }
                                <View style={styles.total_coloumn}>
                                    <Text style={styles.row_data}>
                                    </Text>
                                    <Text style={styles.row_price}>{signup_wizard_grand_total}: ${getTotalAmount(invoice.data)}</Text>
                                </View>                                
                            </View>
                        </>    
                    }
                    {invoice.data.category_name === "LICENCE" &&
                        <>
                            <View>
                                <View style={styles.coloumn}>
                                    <Text style={styles.row_data_heading}>{"Item"}</Text>
                                    <Text style={styles.row_data_heading}>{"Count"}</Text>
                                    <Text style={styles.row_data_heading}>{"Cost"}</Text>
                                    <Text style={styles.row_price_heading}>{"Amount"}</Text>
                                </View>
                            </View>
                            <View>
                                {
                                    invoice.data.invoice_detail.filter((value,index1) => value.count != 0 ).map(detail=>{
                                        return (
                                            <View style={styles.coloumn}>
                                                <Text style={styles.row_data}>
                                                    {detail.licencename?detail.licencename:"-"}
                                                </Text>
                                                <Text style={styles.row_data}>
                                                    {detail.count ? detail.count :"1"}
                                                </Text>
                                                <Text style={styles.row_data}>
                                                    ${detail.price}
                                                </Text>
                                                <Text style={styles.row_price}>${detail.count ? detail.count * detail.price : detail.price}</Text>
                                            </View>
                                        )
                                    })
                                }
                                <View style={styles.total_coloumn}>
                                    <Text style={styles.row_data}>
                                    </Text>
                                    <Text style={styles.row_price}>{signup_wizard_grand_total}: ${getTotalAmount(invoice.data)}</Text>
                                </View>                                
                            </View>
                        </>    
                    }
            </Page>
        </Document>
    );
}
