import * as actionTypes from "../actionTypes";
import { gql } from "apollo-boost";
import { Encrypt } from "../../shared/encrpt";
import notificationMsg  from '../../../App/view/Layout/NotificationMessage';
import * as ChangeAppLanguageFront from '../../../App/view/Layout/ChangeAppLanguageFront';
let tlang  = ChangeAppLanguageFront.translateLanguage;
const error_something_wrong = tlang('error_something_wrong') ? tlang('error_something_wrong') : 'Something Went Wrong!';

// notification Success
const notificationSuccess = msg => {
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_SUCCESS,
    state: msg
  };
};

// notification Fail
const notificationFail = err => {
  let msg = error_something_wrong;
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    // msg = err.graphQLErrors[0].message;
    let translateMsg =notificationMsg(err.graphQLErrors[0].statusCode)
    msg = translateMsg;
  } else if (err.message) {
    msg = err.message;
  }
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_FAIL,
    state: msg
  };
};

// get Invoice List Success
const getInvoiceListSuccess = data => {
  return {
    type: actionTypes.GET_INVOICE_LIST,
    state: data
  };
};

// get email template list
export const getInvoiceList = (client, request) => {
  return dispatch => {
    const query = gql`{
      invoice_data_list {
            _id
            company_id
            category_name
            title
            invoice_detail
            invoice_html
            invoice_name
            status
            created_at
          }
        }
        `;
    client.query({
      query: query,
      fetchPolicy: 'no-cache',
    }).then(response => {
      dispatch(getInvoiceListSuccess(response.data.invoice_data_list))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// get Company Wise Invoices Success
const getCompanyWiseInvoicesSuccess = data => {
  return {
    type: actionTypes.GET_COMPANY_WISE_INVOICE_LIST,
    state: data
  };
};

// get email template list
export const getCompanyWiseInvoices = (client, request) => {
  return dispatch => {
    const query = gql`
        query get_company_wise_invoice_list(
            $company_id: String 
        ) {
          get_company_wise_invoice_list(
            company_id: $company_id 
        ) {
          _id 
          company_id 
          category_name 
          title 
          invoice_detail
          invoice_html 
          invoice_name 
          status
          created_at
        }
        }
    `;
    client.query({
        query: query,
        fetchPolicy: "no-cache",
        variables: {
          company_id: Encrypt(request.company_id)
        }
    })
    .then(response => {
        dispatch(getCompanyWiseInvoicesSuccess(response.data.get_company_wise_invoice_list))
    }).catch((error) => {
        dispatch(notificationFail(error))
    });
  }
}

