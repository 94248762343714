import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_REQUEST_LIST:
            return updateObject(state, { requestList: action.state })
        case actionTypes.GET_USER_REQUEST_DETAILS:
            return updateObject(state, { requestDetails: action.state })
        case actionTypes.GET_USER_RESEND_REQUEST:
            return updateObject(state, { resendRequest: action.state })

        default:
            return state;
    }
}

export default reducer