import React from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import fileExtension from 'file-extension';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import { Icon } from '@material-ui/core';

const supportExt = [
	'png',
	'jpg',
	'jpeg',
	'gif',
	'pdf',
	'ppt',
	'pptx',
	'doc',
	'docx',
	'xls',
	'xlsx',
];
const tlang = ChangeAppLanguageFront.translateLanguage;

const client_sign_in_up_form_drag_legal_docs =
	tlang('client_sign_in_up_form_drag_legal_docs') || 'Drop Your Legal Documents Here.';
const signup_wizard_remove = tlang('signup_wizard_remove') || 'Remove';
const mapbox_files_is_required_valid =
	tlang('mapbox_files_is_required_valid') || 'files is required';
const mapbox_files_extension_validation =
	tlang('mapbox_files_extension_validation') || 'Only JPG, JPEG, PNG & GIF files are allowed!';

export default function FileDropZone({ initialDocs, onNewDocs, errors, onErrors }) {
	const onDropDocsFile = files => {
		const errs = { ...errors };
		delete errs.files_extension;
		for (const file of files) {
			const fileExt = fileExtension(file.name);

			if (!supportExt.includes(fileExt)) {
				errs.files_extension = true;
				onErrors(errs);
				return;
			}
		}
		onNewDocs(
			initialDocs.concat(files.map(fl => Object.assign(fl, { preview: URL.createObjectURL(fl) })))
		);
	};

	function handleDeleteDoc(doc) {
		const deletedDoc = initialDocs.find(d => d.name === doc.name);
		if (deletedDoc) deletedDoc.delete = true;
		onNewDocs([...initialDocs]);
	}

	return (
		<div className={`dropzone drop-signup-height-full`} style={{ flexDirection: 'column' }}>
			<Dropzone
				accept="image/jpeg, image/png, .pdf, .ppt, .pptx, .doc, .docx, .xls, .xlsx"
				multiple={true}
				onDrop={fileToUpload => {
					onDropDocsFile(fileToUpload, 'project');
				}}
			>
				{({ getRootProps, getInputProps }) => (
					<div {...getRootProps()} className="dropzone__input-2">
						{!initialDocs.length && (
							<div className="dropzone__drop-here">
								<span className="lnr lnr-upload" />
								{client_sign_in_up_form_drag_legal_docs}
							</div>
						)}
						<input {...getInputProps()} />
					</div>
				)}
			</Dropzone>
			<FilesContainer>
				{initialDocs
					.filter(d => !d.delete)
					.map((doc, idx) => (
						<div key={`DOC-${idx}`} className="dropzone__image doc">
							<Icon>file_copy</Icon>
							<p className="img-name">{doc.name}</p>
							<button className="img-delete" type="button" onClick={() => handleDeleteDoc(doc)}>
								{signup_wizard_remove}
							</button>
						</div>
					))}
				{errors.files && <span className="error-message">{mapbox_files_is_required_valid}</span>}
				{errors.files_extension && (
					<span className="error-message">{mapbox_files_extension_validation}</span>
				)}
			</FilesContainer>
		</div>
	);
}

const FilesContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	padding: 1rem;
	isolation: isolate;
	.doc {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 100px;
		padding: 5px;
		p {
			text-overflow: ellipsis;
			max-width: 100%;
			word-wrap: break-word;
			overflow: hidden;
			text-align: center;
		}
		.img-delete {
			transition: all 0.3s;
			position: absolute;
			inset: 0;
			z-index: 10;
			cursor: pointer;
			background: transparent;
			opacity: 0;
			color: white;
			font-size: 0.75rem;
			border: 1px solid white;
			text-transform: uppercase;
			padding: 0.25em 1em;
			line-height: 1;
		}

		&:before {
			position: absolute;
			content: '';
			transition: all 0.3s;
			cursor: default;
			inset: 0;
		}

		&:hover {
			&:before {
				background-color: rgba(25, 25, 25, 0.6);
			}

			.img-delete {
				opacity: 1;
			}

			.img-name {
				opacity: 0.7;
			}
		}
	}
`;
