import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    invoiceList :[]

};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INVOICE_LIST:
            return updateObject(state, { invoiceList: action.state })
        case actionTypes.GET_COMPANY_WISE_INVOICE_LIST:
            return updateObject(state, { companyWiseInvoices: action.state })
        default:
            return state;
    }
}

export default reducer
