import React from 'react';
import { useSelector } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Modal } from 'reactstrap';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import MapComponent from '../components/viewMap';

let tlang = ChangeAppLanguageFront.translateLanguage;
const location_details = tlang('location_details') || 'Location Details';
const common_btn_okay = tlang('common_btn_okay') || 'Okay';

export default function ModalMapProbing({ isOpen, onClose, selectedProbing, onChangeLngLat }) {
	const authReducer = useSelector(state => state.authReducer);

	return (
		<Modal
			size="lg"
			isOpen={isOpen}
			toggle={onClose}
			className={`modal-dialog--success modal-dialog--header`}
			style={{ maxWidth: '80%' }}
		>
			<div className="modal__header">
				<button className="lnr lnr-cross modal__close-btn" type="button" onClick={onClose} />
				<h4 className="bold-text  modal__title">{location_details}</h4>
			</div>
			<div style={{ maxHeight: 'calc(90vh - 54px - 31px)', overflowY: 'auto' }}>
				<Card>
					<CardBody>
						<MapComponent
							projectId={authReducer.userData.projectid}
							highlightedProbing={selectedProbing}
							allProbings
							mapHeight="50vh"
							onChangeLngLat={onChangeLngLat}
						/>
					</CardBody>
				</Card>
			</div>
			<ButtonToolbar className="modal__footer">
				<Button color={'success'} onClick={onClose}>
					{common_btn_okay}
				</Button>
			</ButtonToolbar>
		</Modal>
	);
}
