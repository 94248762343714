
import React, { useEffect, useReducer, useState } from 'react';
import {
	Button,
	ButtonToolbar,
	Col,
	Container,
	Input,
	Label,
	Modal,
	Row,
	Spinner,
} from 'reactstrap';
import styled from 'styled-components';
import __ from 'underscore';
import * as ChangeAppLanguageFront from '../../../../Layout/ChangeAppLanguageFront';
import Form from '../../../components/form';
import RadioButtons from '../../radioButtons';
import Select from 'react-select';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroupRequired from '../../../components/formGroupRequired';

const tlang = ChangeAppLanguageFront.translateLanguage;

const save_btn = tlang('save_btn') || 'Save';
const cancel_btn = tlang('cancel_btn') || 'Cancel';
const threshold_Add_Test_Rule = tlang('threshold_Add_Test_Rule') || 'Add Test Rule';
const threshold_Percentage = tlang('threshold_Percentage') || 'Percentage';
const final_value = tlang('final_value') || 'Final Value';
const threshold_Less_than = tlang('threshold_Less_than') || 'Less than';
const threshold_Greater_than = tlang('threshold_Greater_than') || 'Greater than';
const add_thresholdvalue = tlang('thresholdFormulaModal_title') || 'Add Threshold Value';
const lbl_multiple_pollutants = tlang('lbl_multiple_pollutants') || 'Multiple Pollutants';
const lbl_no_limit = tlang('lbl_no_limit') || 'No Limit';
const warning_required_threshold = tlang('warning_required_threshold') || 'Please enter threshold value';
const warning_select_pollutant = tlang('warning_select_pollutant') || 'Please select a pollutant';
const lbl_any_pollutant_value_will_match = tlang('lbl_any_pollutant_value_will_match') || 'Any pollutant value will match';
const warning_enter_percentage = tlang('warning_enter_percentage') || 'Please enter percentage';
const lbl_threshold_value = tlang('lbl_threshold_value') || 'Threshold Value';
const lbl_select_multiple_pollutants = tlang('lbl_select_multiple_pollutants') || 'Select Multiple Pollutants';
const lbl_condition = tlang('lbl_condition') || 'Condition';
const lbl_percentage = tlang('lbl_percentage') || 'Percentage';
const lbl_select_pollutant = tlang('lbl_select_pollutant') || 'Select a Pollutant';

const condition = [
	{ value: 'less', label: `${threshold_Less_than} (<)` },
	{ value: 'greter', label: `${threshold_Greater_than} (>)` },
]
const ruleDefaultFormula = {
	condition: "less",
	test: [],
	percentage: 0,
	nolimit: false
}
const initialState = {
	selectedOption: '',
	ruleValue: '',
	nolimit_finalvalue: false,
	ruleCondition: '',
	rulePercentage: 0,
	allPollutants: [],
	selectedPollutants: [],
	singleTestRule: {},
};

function reducer(state, action) {
	switch (action.type) {
		default:
			const upd = {};
			for (const key of Object.keys(action)) {
				upd[key] = action[key];
			}
			// console.log(`upd`, { upd });
			return { ...state, ...upd };
	}
}

export default function ModalDisposalThresholdSingleRule({ isOpen, onClose, testRule, allFamilyList, onSave }) {
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [state, localDispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		if (__.isEmpty(testRule)) return;
		if (__.isEmpty(allFamilyList)) return;

		const selectedOption = (__.isEmpty(testRule.option_id)) ? '1' : testRule.option_id;
		const ruleValue = (__.isEmpty(testRule.value)) ? '' : testRule.value;
		const nolimit_finalvalue = testRule?.nolimit_finalvalue ? testRule?.nolimit_finalvalue : false;
		const ruleCondition = testRule.formula.condition;
		const rulePercentage = testRule.formula.percentage;

		let allPollutants = [];
		allFamilyList.map((family) => {
			let myObj = {};
			myObj.label = family.name;
			if (family.children.length > 0) {
				let temparr = [];
				family.children.map((pollutant) => {
					if (pollutant.id != testRule.child_id) {
						temparr.push({ label: pollutant.name, value: pollutant.id, familyId: family._id });
					}
				})
				myObj.options = temparr;
			}
			allPollutants.push(myObj);
		})

		let selectedPollutants = []
		if (!__.isEmpty(testRule.formula.test)) {
			testRule.formula.test.map((test, i) => {
				selectedPollutants.push({ label: test.name, value: test.id, familyId: test.Test_Parent_Id })
			})
		}

		localDispatch({
			selectedOption: selectedOption,
			ruleValue: ruleValue,
			nolimit_finalvalue: nolimit_finalvalue,
			ruleCondition: ruleCondition,
			rulePercentage: rulePercentage,
			allPollutants: allPollutants,
			selectedPollutants: selectedPollutants,
			singleTestRule: testRule
		})

	}, [testRule, allFamilyList]);

	function checkValidation() {
		const errs = {};
		const selectedOption = state.selectedOption;
		if (selectedOption === '1') {
			if (!state.ruleValue) errs.ruleValue = true;
		} else if (selectedOption === '2') {
			if (!state.ruleValue) errs.ruleValue = true;
			if (__.isEmpty(state.selectedPollutants)) errs.selectedPollutants = true;
		} else if (selectedOption === '4') {
			if (!state.rulePercentage) errs.rulePercentage = true;
			if (__.isEmpty(state.selectedPollutants)) errs.selectedPollutants = true;
		}
		setErrors(errs);
		return errs;
	}

	function addValToCollection(val, collection) {
		let newProperties = Object.keys(val).reduce((object) => {
			object['id'] = val['value'];
			object['name'] = val['label'];
			object['Test_Parent_Id'] = val['familyId'];
			return object;
		}, {});
		val = newProperties;
		collection.push(val);
	}

	function handleSave() {
		const errs = checkValidation();
		if (!__.isEmpty(errs)) return;

		const selectedOption = state.selectedOption;
		const ruleValue = state.ruleValue;
		const nolimit_finalvalue = state.nolimit_finalvalue;
		const rulePercentage = state.rulePercentage;
		let selectedPollutants = Array.isArray(state.selectedPollutants) ? state.selectedPollutants : [state.selectedPollutants];
		const ruleCondition = state.ruleCondition;

		let singleTestRule = { ...state.singleTestRule };

		if (selectedOption === '1') {
			singleTestRule.value = ruleValue;
			singleTestRule.option_id = "1";

			//reset all other fields
			singleTestRule.formula = ruleDefaultFormula;
			singleTestRule.nolimit_finalvalue = false;
		} else if (selectedOption === '2') {
			singleTestRule.value = ruleValue;
			const collection = [];
			for (const val of selectedPollutants) addValToCollection(val, collection);
			singleTestRule.formula.test = collection;
			singleTestRule.option_id = "2";

			//reset all other fields
			singleTestRule.nolimit_finalvalue = false;
			singleTestRule.formula.condition = ruleDefaultFormula.condition;
			singleTestRule.formula.nolimit = ruleDefaultFormula.nolimit;
			singleTestRule.formula.percentage = ruleDefaultFormula.percentage;
		} else if (selectedOption === '3') {
			singleTestRule.formula.nolimit = true;
			singleTestRule.nolimit_finalvalue = nolimit_finalvalue;
			singleTestRule.option_id = "3";

			//reset all other fields
			singleTestRule.value = "";
			singleTestRule.formula.condition = ruleDefaultFormula.condition;
			singleTestRule.formula.percentage = ruleDefaultFormula.percentage;
			singleTestRule.formula.test = ruleDefaultFormula.test;
		} else if (selectedOption === '4') {
			singleTestRule.formula.condition = ruleCondition;
			singleTestRule.formula.percentage = rulePercentage;
			const collection = [];
			for (const val of selectedPollutants) addValToCollection(val, collection);
			singleTestRule.formula.test = collection;
			singleTestRule.option_id = "4";

			//reset all other fields
			singleTestRule.value = "";
			singleTestRule.nolimit_finalvalue = false;
			singleTestRule.formula.nolimit = false;
		}
		onSave(singleTestRule);
		onClose()
	}
	function reset() {
		localDispatch({
			ruleValue: '',
			nolimit_finalvalue: false,
			ruleCondition: 'less',
			rulePercentage: 0,
			selectedPollutants: [],
		})
		setErrors({});
	}
	function validate(key, value) {
		localDispatch({ [key]: value });
		if (Array.isArray(value)) {
			if (!value.length) {
				setErrors(e => ({ ...e, [key]: true }));
				return;
			}
		} else {
			if (value === '' || value === null || value === undefined) {
				setErrors(e => ({ ...e, [key]: true }));
				return;
			}
		}
		setErrors(e => ({ ...e, [key]: false }));
	}

	return (
		<Modal
			isOpen={isOpen}
			toggle={() => onClose()}
			className={`modal-dialog--success modal-dialog--header width__70`}
		>
			<div className="modal__header">
				<button
					className="lnr lnr-cross modal__close-btn"
					type="button"
					onClick={() => onClose()}
				/>
				<h4 className="bold-text  modal__title">
					{`${threshold_Add_Test_Rule} of ${testRule.child_name}`}
				</h4>
			</div>
			<div className="modal__body">
				<Form>
					<Container>
						{isLoading ? (
							<Row>
								<Col xs={12} className="margin__top text-center">
									<Spinner size="sm" className="btn-spinner spinner_color spinner_size" />
								</Col>
							</Row>
						) : (
							<>
								<RuleLayout>
									<RuleOptions>
										<div className="option-field">
											<RadioButtons
												value={state.selectedOption}
												onChange={value => {
													validate('selectedOption', value)
													reset()
												}
												}
												options={[
													{ value: '1', label: add_thresholdvalue },
												]}
											/>
										</div>
										<div className="option-field">
											<RadioButtons
												value={state.selectedOption}
												onChange={value => {
													validate('selectedOption', value)
													reset()
												}
												}
												options={[
													{ value: '2', label: lbl_multiple_pollutants },
												]}
											/>
										</div>
										<div className="option-field">
											<RadioButtons
												value={state.selectedOption}
												onChange={value => {
													validate('selectedOption', value)
													reset()
												}
												}
												options={[
													{ value: '3', label: lbl_no_limit },
												]}
											/>
										</div>
										<div className="option-field">
											<RadioButtons
												value={state.selectedOption}
												onChange={value => {
													validate('selectedOption', value)
													reset()
												}
												}
												options={[
													{ value: '4', label: threshold_Percentage },
												]}
											/>
										</div>
									</RuleOptions>
									<RuleOptionData>
										{state.selectedOption === '1' &&
											<FormGroupRequired>
												<Label>{lbl_threshold_value}</Label>
												<Input
													onChange={e => validate('ruleValue', e.target.value)}
													type="number"
													value={state.ruleValue}
													disabled={false}
												/>
												{errors.ruleValue && (
													<span className="error-message">{warning_required_threshold}</span>
												)}
											</FormGroupRequired>
										}

										{state.selectedOption === '2' &&
											<>
												<FormGroupRequired>
													<Label>{lbl_threshold_value}</Label>
													<Input
														onChange={e => validate('ruleValue', e.target.value)}
														type="number"
														value={state.ruleValue}
														disabled={false}
													/>
													{errors.ruleValue && (
														<span className="error-message">{warning_required_threshold}</span>
													)}
												</FormGroupRequired>

												<FormGroupRequired>
													<Label>{lbl_select_multiple_pollutants}</Label>
													<Select
														value={state.selectedPollutants}
														onChange={v =>
															validate('selectedPollutants', v)
														}
														options={state.allPollutants}
														className="basic-multi-select"
														isSearchable={true}
														isMulti={true}
														closeMenuOnSelect={false}
													/>
													{errors.selectedPollutants && (
														<span className="error-message">{warning_select_pollutant}</span>
													)}
												</FormGroupRequired>
											</>
										}
										{state.selectedOption === '3' &&
											<>
												<h4 className="bold-text  modal__title">{lbl_any_pollutant_value_will_match}</h4>
												<FormControlLabel
													label={final_value}
													control={
														<Checkbox color="primary" style={{ color: "#c00" }} />
													}
													checked={state.nolimit_finalvalue}
													disabled={false}
													onChange={e => validate('nolimit_finalvalue', e.target.checked)}
													labelPlacement="end"
													style={{ marginLeft: '7%', marginTop: '-1%' }}
												/>
											</>
										}
										{state.selectedOption === '4' &&
											<>
												<FormGroupRequired>
													<Label>{lbl_condition}</Label>
													<Select
														placeholder="Select"
														value={condition.find(c => c.value === state.ruleCondition)}
														onChange={v =>
															validate('ruleCondition', v.value)
														}
														options={condition}
														className="react-select"
													/>
												</FormGroupRequired>
												<FormGroupRequired>
													<Label>{lbl_percentage}</Label>
													<Input
														onChange={e => validate('rulePercentage', e.target.value)}
														type="number"
														value={state.rulePercentage}
														disabled={false}
													/>
													{errors.rulePercentage && (
														<span className="error-message">{warning_enter_percentage}</span>
													)}
												</FormGroupRequired>
												<FormGroupRequired>
													<Label>{lbl_select_pollutant}</Label>
													<Select
														value={state.selectedPollutants}
														onChange={v =>
															validate('selectedPollutants', v)
														}
														options={state.allPollutants}
														className="basic-multi-select"
														isSearchable={true}
														isMulti={false}
														closeMenuOnSelect={true}
													/>
													{errors.selectedPollutants && (
														<span className="error-message">{warning_select_pollutant}</span>
													)}
												</FormGroupRequired>
											</>
										}
									</RuleOptionData>
								</RuleLayout>
							</>
						)}
					</Container>
				</Form>
			</div>
			<ButtonToolbar className="modal__footer">
				<Button color={'success'} outline={true} onClick={() => onClose()}>
					{cancel_btn}
				</Button>
				<Button color={'success'} onClick={() => handleSave()} disabled={isLoading}>
					{save_btn}
					{isLoading && <Spinner size="sm" className="btn-spinner" />}
				</Button>
			</ButtonToolbar>
		</Modal>
	);
}

const RuleLayout = styled.div`
	display: grid;
	margin-block-start: 0.5rem;
	gap: 0.5rem;
	grid-template-areas:
		'filter filterdata .. ..'
`;
const RuleOptions = styled.div`
	grid-area: filter;
	width: 100%;
	height: 100%;
	.option-field{
		display: flex;
		flex-direction: column;
		padding-block: 5px;
	}
`;
const RuleOptionData = styled.div`
	grid-area: filterdata;
`;
