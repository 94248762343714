import React from 'react';
import * as actionTypes from '../actionTypes';
import { gql } from "apollo-boost";
import { Encrypt } from "../../shared/encrpt";
import * as actions from '../index';
import notificationMsg  from '../../../App/view/Layout/NotificationMessage';
import * as ChangeAppLanguageFront from '../../../App/view/Layout/ChangeAppLanguageFront';
let tlang = ChangeAppLanguageFront.translateLanguage;

const MemberCreate = tlang('notificationSuccess_MemberCreate') ? tlang('notificationSuccess_MemberCreate') : 'User Created Successfully';
const MemberDelete = tlang('notificationSuccess_MemberDelete') ? tlang('notificationSuccess_MemberDelete') : 'Delete User Successfully';
const MemberDeleteFromProject = tlang('notificationSuccess_MemberDeleteFromProject') ? tlang('notificationSuccess_MemberDeleteFromProject') : 'Delete User from project Successfully';
const error_something_wrong = tlang('error_something_wrong') ? tlang('error_something_wrong') : 'Something Went Wrong!';

// notification Success
const notificationSuccess = (msg) => {
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_SUCCESS,
    state: msg,
  }
}

// notification Fail
const notificationFail = (err) => {
  let msg = error_something_wrong;
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    // msg = err.graphQLErrors[0].message;
    let translateMsg =notificationMsg(err.graphQLErrors[0].statusCode)
    msg = translateMsg;
  } else if (err.message) {
    msg = err.message;
  }
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_FAIL,
    state: msg
  }
}

// get Request List Success
const getRequestListSuccess = (data) => {
  return {
    type: actionTypes.GET_REQUEST_MEMBER_LIST,
    state: data
  }
}

// get Request Member List
export const getRequestMemberList = (client, request) => {
  return dispatch => {
    const query = gql`{
      project_invite_users {
        _id
        name
        email
        role_name
        role_id
        company_id
        company_name
        display_name
        company_address
        registration_country
        contact_number
        status
        approve_reject_by
        company_strength
        system_flag
        confirm_account
        staffuser
        plan {
          _id
          planid
          planname
          uploadsize
          usersize
          price
          limit
        }
        last_login
        created_at
        updated_at
        firebase_id
        userid
      }
    }
    `;
    client.query({
      query: query,
      fetchPolicy: 'cache-first',
    }).then(response => {
      dispatch(getRequestListSuccess(response.data.project_invite_users))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// get List Success
const getListSuccess = (data) => {
  return {
    type: actionTypes.GET_MEMBER_LIST,
    state: data
  }
}

// get Member List
export const getMemberList = (client, request) => {
  return dispatch => {
    const query = gql`{
        all_member_list {
          _id
          name
          email
          role_name
          role_id
          company_id
          company_name
          display_name
          company_address
          registration_country
          contact_number
          status
          approve_reject_by
          company_strength
          system_flag
          confirm_account
          staffuser
          plan {
            _id
            planid
            planname
            uploadsize
            usersize
            price
            limit
          }
          last_login
          created_at
          updated_at
          firebase_id
          userid
          licence_details
        }
      }
      `;
    client.query({
      query: query,
      fetchPolicy: 'no-cache',
    }).then(response => {
      dispatch(getListSuccess(response.data.all_member_list))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}


//create staff action
export const createNewMember = (client, request) => {
  return dispatch => {
    const mutation = gql`
          mutation add_member($name: String!, $email:String!, $roleid: String!, $rolename:String!) {
            add_member(name: $name, email: $email, roleid: $roleid, rolename: $rolename) {
              _id
              name
              email
              role_name
              role_id
              company_id
              company_name
              display_name
              company_address
              registration_country
              contact_number
              status
              approve_reject_by
              company_strength
              system_flag
              confirm_account
              plan {
                _id
                planid
                planname
                uploadsize
                usersize
                price
                limit
              }
              last_login
              created_at
              updated_at
            }
          }
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        name: Encrypt(request.name),
        email: Encrypt(request.email),
        roleid: Encrypt(request.roleid),
        rolename: Encrypt(request.rolename)
      }
    }).then(response => {
      dispatch(actions.getMemberList(client, request))
      dispatch(notificationSuccess(MemberCreate))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// delete Member
export const deleteMember = (client, request) => {
  return dispatch => {
    const mutation = gql`
          mutation delete_member($email:String!) {
            delete_member(email: $email) {
              _id
              name
              email
              role_name
              role_id
              company_id
              company_name
              display_name
              company_address
              registration_country
              contact_number
              status
              approve_reject_by
              company_strength
              system_flag
              confirm_account
              plan {
                _id
                planid
                planname
                uploadsize
                usersize
                price
                limit
              }
              last_login
              created_at
              updated_at
            }
          }
        `;
    client.mutate({
      mutation: mutation,
      variables: {
        email: Encrypt(request.email)
      }
    }).then(response => {

      dispatch(actions.getMemberList(client, request))
      dispatch(notificationSuccess(MemberDelete))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}

// get Member Details Success
const getMemberDetailsSuccess = (data) => {
  return {
    type: actionTypes.GET_MEMBER_DETAILS,
    state: data
  }
}

// get member details
export const getMemberDetails = (project, request) => {
  return dispatch => {
    const query = gql`
      query get_member_detail ($userid: String!) {
        get_member_detail(userid: $userid) {
          projectname 
          displayname 
          rolename 
          licencename
          projectid
          licenceid
          companylicenceid
          userid
          company_name
          isclosed
        }
      }
  `;
    project.query({
      query: query,
      variables: {
        userid: Encrypt(request.userid)
      }
    })
      .then(response => {
        dispatch(getMemberDetailsSuccess(response.data.get_member_detail))
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}

// delete delete invited member
export const deleteInvitedMember = (project, request) => {
  return dispatch => {
    const mutation = gql`
          mutation delete_invited_member($userid:String! , $companyname:String! , $projectid:String!, $projectname:String!) {
            delete_invited_member(userid: $userid , companyname: $companyname ,projectid: $projectid, projectname: $projectname) {
              message
              error
              success
            }
          }
        `;
    project.mutate({
      mutation: mutation,
      variables: {
        userid: Encrypt(request.userid),
        companyname: Encrypt(request.companyname),
        projectid: Encrypt(request.projectid),
        //companylicenceid: Encrypt(request.companylicenceid),
        projectname: Encrypt(request.projectname),
      }
    }).then(response => {
      // dispatch(actions.getMemberList(client, request))
      dispatch(notificationSuccess(MemberDeleteFromProject))
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}
