import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    multiplecampaignTestAnalysisData : {},
    availableLicenceList : [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PROJECT_LIST:
            return updateObject(state, { projectList: action.state })
        case actionTypes.GET_COMPANY_LICENCE_LIST:
            return updateObject(state, { LicenceList: action.state })
        case actionTypes.GET_PROJECT_DETAILS:
            return updateObject(state, { projectDetails: action.state })
        case actionTypes.GET_INVITE_USER_LIST:
            return updateObject(state, { projectReqlist: action.state })
        case actionTypes.ALL_MESSAGES:
            return updateObject(state, { allmsgs: action.state })
        case actionTypes.REMOVE_ALL_MESSAGES:
            return updateObject(state, { allmsgs: action.state })
        case actionTypes.GET_PROJECT_LICENCE_LIST:
            return updateObject(state, { projectLicence: action.state })
        case actionTypes.USER_DOCUMENT_LIST: 
            return updateObject(state, {documentList: action.state})
        case actionTypes.GET_STATUS_WISE_PROJECT_LIST:
            return updateObject(state, {statusWiseProjects: action.state})
        case actionTypes.GET_ALL_MEMBERS_PROJECT_LIST:
            return updateObject(state, { allMembersProjectList: action.state })
        case actionTypes.GET_AVAILABLE_LICENCE_LIST:
            return updateObject(state, { availableLicenceList: action.state })
        case actionTypes.GET_MEMBER_PROJECT_DETAILS:
            return updateObject(state, { memberProjectDetail: action.state })
        case actionTypes.GET_CAMPAIGN_TEST_ANALYSIS_DATA:
            return updateObject(state, { campaignTestAnalysisData: action.state })
        case actionTypes.GET_MULTIPLE_CAMPAIGN_TEST_ANALYSIS_DATA:
            return updateObject(state, { multiplecampaignTestAnalysisData: action.state })
        default:
            return state;
    }
}

export default reducer
