import React, { useEffect, useState } from 'react';
import DatePickerComponent from 'react-datepicker';
import styled from 'styled-components';

export default function DatePicker({ startDate, minDate, onChange, disabled }) {
	const [date, setDate] = useState(startDate);

	useEffect(() => {
		setDate(startDate);
	}, [startDate]);

	function handleChange(date) {
		setDate(date);
		onChange(date);
	}

	return (
		<DatePickerContainer>
			<DatePickerComponent
				className="datepicker"
				wrapperClassName="datepicker-wrapper"
				selected={date}
				onChange={handleChange}
				dateFormat="dd/MM/yyyy"
				dropDownMode="select"
				minDate={minDate}
				disabled={disabled}
			/>
		</DatePickerContainer>
	);
}

export function TimePicker({ startTime, minTime, onChange }) {
	const [time, setTime] = useState(startTime);

	useEffect(() => {
		setTime(startTime);
	}, [startTime]);

	function handleChange(time) {
		setTime(time);
		onChange(time);
	}

	return (
		<DatePickerContainer>
			<DatePickerComponent
				className="datepicker"
				wrapperClassName="datepicker-wrapper"
				selected={time}
				onChange={handleChange}
				dropDownMode="select"
				minDate={minTime}
				showTimeSelect
				showTimeSelectOnly
				timeFormat="hh:mm"
				dateFormat="hh:mm"
			/>
		</DatePickerContainer>
	);
}

const DatePickerContainer = styled.div`
	width: 100%;
	.datepicker-wrapper {
		width: 100%;
	}
	.datepicker {
		display: block;
		width: 100%;
		height: calc(1.5em + 0.75rem + 2px);
		/* padding: 0.375rem 0.75rem;
		font-size: 1rem; */
		font-weight: 400;
		line-height: 1.5;
		color: #495057;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid #ced4da;
		border-radius: 0.25rem;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
`;
