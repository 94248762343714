import { Icon, Switch } from '@material-ui/core';
import * as turf from '@turf/turf';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Map, {
	FullscreenControl,
	Layer,
	Marker,
	NavigationControl,
	Popup,
	ScaleControl,
	Source,
} from 'react-map-gl';
import Select from 'react-select';
import { CustomInput } from 'reactstrap';
import styled from 'styled-components';
import Server from '../../../../Common/Server';
import { formatLngLat } from '../../../lib/formatDepth';
import { calculBBox, calculCenter } from '../../../lib/useCalculCentre';
import useGetMapData from '../../../lib/useGetMapData';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import BoxColor from './boxColor';
import { convertedImageUrl } from '../../Common/commonFunctions';

let tlang = ChangeAppLanguageFront.translateLanguage;
const mapbox_boundaries = tlang('mapbox_project_boundaries') || 'Project boundaries';
const mapbox_origins = tlang('mapbox_origins') || 'Origins';
const mapbox_destinations = tlang('mapbox_destinations') || 'Destinations';
const dashboard_stret_view = tlang('dashboard_stret_view') || 'Street View';
const mapbox_images = tlang('mapbox_images') || 'Images';
const client_Close_Probing = tlang('client_Close_Probing') || 'Close Probing';

const POLYGON_NAME = {
	boundary: mapbox_boundaries,
	origin: mapbox_origins,
	destination: mapbox_destinations,
};

function createFeatures(data) {
	if (!data) return;
	const feat = {
		type: 'FeatureCollection',
		features: [data],
	};
	return feat;
}

const MAP_STYLES = ['mapbox://styles/mapbox/satellite-v9', 'mapbox://styles/mapbox/streets-v11'];

export default function MapComponent({
	projectId,
	campaignId,
	mapHeight = '30vh',
	showControl = true,
	filters,
	style = {},
	setMarkers = false,
	markers = [],
	onClickOnMap,
	highlightedProbing,
	allProbings = false,
	allPendingProbings = false,
	onChangeLngLat,
	refresh,
}) {
	const { probings, boundaries, origins, destinations, images, lngLat } = useGetMapData(
		projectId,
		campaignId,
		allProbings,
		allPendingProbings,
		refresh
	);

	const [center, setCenter] = useState();
	const [showBoundaries, setShowBoundaries] = useState(true);
	const [showOrigins, setShowOrigins] = useState(true);
	const [showDestinations, setShowDestinations] = useState(true);
	const [selectedImages, setSelectedImages] = useState([]);
	const [mapStyle, setmapStyle] = useState(MAP_STYLES[0]);
	const [positionData, setPositionData] = useState({ lng: 0, lat: 0, polygons: [] });
	const [idHover, setIdHover] = useState(-1);
	const [showPopup, setShowPopup] = useState(false);
	const [popupData, setPopupData] = useState({});
	const mapRef = useRef();

	useEffect(() => {
		if (!boundaries.length) return;
		console.log('boundaries', boundaries);
		const center = calculCenter(boundaries);
		const [minLng, minLat, maxLng, maxLat] = calculBBox(boundaries);
		setPositionData(p => ({ ...p, lng: center[0], lat: center[1] }));
		setCenter(center);
		if (mapRef.current) {
			mapRef.current.fitBounds(
				[
					[minLng, minLat],
					[maxLng, maxLat],
				],
				{ padding: 40 }
			);
		}
	}, [boundaries.length, mapRef.current]);

	useEffect(() => {
		if (!boundaries.length && Array.isArray(lngLat)) {
			setPositionData(p => ({ ...p, lng: lngLat[0], lat: lngLat[1] }));
			setCenter(lngLat);
		}
	}, [lngLat, boundaries.length]);

	function handleMarkerHover(probId) {
		const p = probings.find(p => p.id === probId);
		if (!p) {
			setShowPopup(false);
			return;
		}
		setPopupData(p);
		setShowPopup(true);
	}

	function handleChangeLngLat(id, lng, lat) {
		const prob = probings.find(p => p.id === id);
		if (prob) {
			prob.longitude = lng;
			prob.latitude = lat;
		}
		if (typeof onChangeLngLat === 'function') onChangeLngLat(id, lng, lat);
	}

	function handleMouseMove(lngLat) {
		const polygons = [];
		const points = turf.points([[lngLat.lng, lngLat.lat]]);

		// search in boundaries
		function checkPoint(filter, features) {
			const filteredPoly = filter ? features.filter(p => filter.includes(p.id)) : features;
			for (const poly of filteredPoly) {
				const searchWithin = turf.polygon([poly.geometry.coordinates[0]]);
				const ptsWithin = turf.pointsWithinPolygon(points, searchWithin);
				if (ptsWithin.features.length > 0) polygons.push({ id: poly.id, ...poly.properties });
			}
		}

		if (showBoundaries) checkPoint(filters?.boundaries, boundaries);
		if (showDestinations) checkPoint(filters?.destinations, destinations);
		if (showOrigins) checkPoint(filters?.origins, origins);
		setPositionData({ ...lngLat, polygons });
	}

	if (!Array.isArray(center)) return <p>loading</p>;
	return (
		<MapContainer style={style}>
			{showControl ? (
				<SwitchContainer>
					<Switch
						checked={showBoundaries}
						onChange={e => setShowBoundaries(e.target.checked)}
						disabled={boundaries.length === 0}
					/>
					<p onClick={() => setShowBoundaries(s => !s)}>{mapbox_boundaries}</p>
					<Switch
						checked={showOrigins}
						onChange={e => setShowOrigins(e.target.checked)}
						disabled={origins.length === 0}
					/>
					<p onClick={() => setShowOrigins(s => !s)}>{mapbox_origins}</p>
					<Switch
						checked={showDestinations}
						onChange={e => setShowDestinations(e.target.checked)}
						disabled={destinations.length === 0}
					/>
					<p onClick={() => setShowDestinations(s => !s)}>{mapbox_destinations}</p>
					<Select
						isClearable
						options={images.map(i => ({ value: i.id, label: i.name }))}
						className="basic-multi-select"
						isMulti={true}
						value={selectedImages}
						onChange={value => setSelectedImages(value)}
						styles={{ container: provided => ({ ...provided, flexGrow: 1 }) }}
						placeholder={mapbox_images}
						disabled={images.length === 0}
					/>
				</SwitchContainer>
			) : (
				<div className="margin__top"></div>
			)}
			<Map
				ref={mapRef}
				mapStyle={mapStyle}
				style={{
					height: mapHeight,
					width: '100%',
					isolation: 'isolate',
					cursor: setMarkers ? 'pointer' : 'grab',
				}}
				initialViewState={{
					longitude: center[0],
					latitude: center[1],
					zoom: 17,
					fitBoundsOptions: {
						animate: false,
					},
				}}
				onClick={e => {
					if (setMarkers && typeof onClickOnMap === 'function') onClickOnMap(e, 'add');
				}}
				onMouseMove={e => handleMouseMove(e.lngLat)}
				mapboxAccessToken={Server.MAPBOX_ACCESS_TOKEN}
			>
				<NavigationControl showZoom position="top-right" />
				<FullscreenControl position="top-right" />
				<ScaleControl position="bottom-left" />
				{showPopup && (
					<Popup
						longitude={popupData.lng}
						latitude={popupData.lat}
						offset={5}
						anchor="bottom"
						onClose={() => setShowPopup(false)}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '5px',
							}}
						>
							<div>
								<strong>{popupData.name}</strong>
							</div>
							{(allProbings || allPendingProbings) &&
								(popupData.status === 'Delete' ? (
									<span className={'statusDeleted'}>Deleted</span>
								) : popupData.status === 'Pending' ? (
									<span className={'statusPending'}>Pending</span>
								) : popupData.endstatus ? (
									<span className={'statusClose'}>Closed</span>
								) : null)}
							{allProbings && popupData.endstatus && (
								<span className={'statusDeleted'}>{client_Close_Probing}</span>
							)}
						</div>
					</Popup>
				)}
				<div className="changeMapStyle">
					<CustomInput
						type="checkbox"
						name="mapstyle"
						value="mapstyle"
						id="mapstyle"
						label={dashboard_stret_view}
						checked={mapStyle === MAP_STYLES[1]}
						onChange={e => setmapStyle(e.target.checked ? MAP_STYLES[1] : MAP_STYLES[0])}
					/>
				</div>
				<MapImages images={images} filter={filters?.images || selectedImages.map(i => i.value)} />
				{showBoundaries && <Polygons polygons={boundaries} filter={filters?.boundaries} />}
				{showOrigins && <Polygons polygons={origins} filter={filters?.origins} />}
				{showDestinations && <Polygons polygons={destinations} filter={filters?.destinations} />}
				<Probings
					probings={probings}
					handleMarkerHover={handleMarkerHover}
					filter={filters?.probings}
					highlightedProbing={highlightedProbing}
					onChangeLngLat={handleChangeLngLat}
				/>
				{markers.map((marker, idx) => (
					<Marker
						key={`MARKER-${idx}`}
						longitude={marker.coords.lng}
						latitude={marker.coords.lat}
						onClick={e => {
							if (!marker.code) onClickOnMap(e, 'remove', idx);
						}}
						anchor="center"
					>
						<div onMouseEnter={() => setIdHover(idx)} onMouseLeave={() => setIdHover(-1)}>
							<Icon
								className="material-symbols-outlined"
								style={{
									color: 'var(--clr-aexdo)',
									fontSize: '50px',
									cursor: 'pointer',
									transform: idx === idHover ? 'none' : 'translateY(-50%)',
								}}
							>
								{idx === idHover && !marker.code ? 'close' : 'place'}
							</Icon>
						</div>
					</Marker>
				))}
				<LngLatBox>
					<p>
						<span className="label">lng:</span>
						{formatLngLat(positionData.lng)}
					</p>
					<p>
						<span className="label">lat:</span>
						{formatLngLat(positionData.lat)}
					</p>
					{positionData.polygons.map(p => (
						<div key={p.id}>
							<span className="label">{POLYGON_NAME[p.type]}:</span>
							{p.label}
							&nbsp;
							<BoxColor properties={p} />
						</div>
					))}
				</LngLatBox>
			</Map>
		</MapContainer>
	);
}

const MapContainer = styled.div`
	grid-area: map;
	display: flex;
	flex-direction: column;
	.tools {
		margin-inline-start: auto;
		display: flex;
		gap: 5px;
		align-items: center;
		margin-block-end: 10px;
	}
`;

export const LngLatBox = styled.div`
	position: absolute;
	right: 5px;
	bottom: 5px;
	background-color: rgb(255, 255, 255, 0.5);
	border-radius: 5px;
	padding: 5px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	p {
		margin: 0;
		color: black;
	}
	.label {
		font-weight: 600;
		margin-inline-end: 1ch;
	}
`;

function MapImages({ images, filter }) {
	if (!images) return null;
	return images
		.filter(i => filter.includes(i.id))
		.map(img => (
			<Fragment key={img.id}>
				<Source type="image" url={convertedImageUrl(img.url)} coordinates={img.coordinates}>
					<Layer type="raster" paint={img.paint} />
				</Source>
			</Fragment>
		));
}

function Polygons({ polygons, filter }) {
	if (!polygons) return null;
	const filteredPoly = filter ? polygons.filter(p => filter.includes(p.id)) : polygons;
	return filteredPoly.map((poly, idx) => (
		<Source
			key={`${poly.id}-${idx}`}
			id={`${poly.id}-${idx}`}
			type="geojson"
			data={createFeatures(poly)}
		>
			{poly.properties.fillstatus !== 'no' && (
				<Layer
					type="fill"
					paint={{
						'fill-color': poly.properties.color || '#FFF',
						'fill-opacity': poly.properties.fillopacity || 0.2,
					}}
				/>
			)}
			<Layer
				type="line"
				paint={{
					'line-color': poly.properties.strokecolor || '#000',
					'line-width': poly.properties.strokewidth || 1,
					'line-opacity': poly.properties.strokeopacity || 1,
				}}
			/>
		</Source>
	));
}

function Probings({ probings, filter, handleMarkerHover, highlightedProbing, onChangeLngLat }) {
	const filteredProb = filter ? probings.filter(p => filter.includes(p.id)) : probings;
	const [dragging, setDragging] = useState(false);
	const [lngLat, setLngLat] = useState({});

	const endDrag = useCallback((e, id) => {
		if (typeof onChangeLngLat === 'function') onChangeLngLat(id, e.lngLat.lng, e.lngLat.lat);
		setDragging(false);
		setLngLat({});
	}, []);

	function startDrag(e) {
		setDragging(true);
		setLngLat(e.lngLat);
	}

	const markers = filteredProb.map(p => (
		<Marker
			key={`PROB-${p.campaign}-${p.id}`}
			longitude={p.lng}
			latitude={p.lat}
			// onClick={() => handleMarkerHover(p.id)}
			anchor="center"
		>
			<div onMouseEnter={() => handleMarkerHover(p.id)} onMouseLeave={() => handleMarkerHover()}>
				<Icon
					className="material-symbols-outlined"
					style={{
						color: p.status === 'Delete' ? 'red' : p.color,
						fontSize: '20px',
						cursor: 'pointer',
					}}
				>
					{p.status === 'Delete'
						? 'close'
						: // : p.endstatus
						  // ? 'lock'
						  p.symbol || 'fiber_manual_record'}
				</Icon>
			</div>
		</Marker>
	));

	if (highlightedProbing?.id) {
		const prob = probings.find(p => p.id === highlightedProbing.id);
		if (prob || highlightedProbing.id === 'new') {
			markers.push(
				<Marker
					key={`HIGHLIGHTED`}
					longitude={dragging ? lngLat.lng : highlightedProbing.lng}
					latitude={dragging ? lngLat.lat : highlightedProbing.lat}
					onDragEnd={e => endDrag(e, highlightedProbing.id)}
					onDragStart={e => startDrag(e)}
					onDrag={e => setLngLat(e.lngLat)}
					draggable
					anchor="center"
				>
					<div>
						<Icon
							style={{
								color: 'var(--clr-aexdo)',
								fontSize: '50px',
								cursor: 'move',
								transform: 'translateY(-50%)',
							}}
						>
							place
						</Icon>
					</div>
				</Marker>
			);
		}
	}

	return markers;
}

const SwitchContainer = styled.div`
	display: flex;
	gap: 0.5rem;
	margin-block-end: 0.5rem;
	p {
		cursor: pointer;
		color: var(--clr-aexdo);
	}
`;
