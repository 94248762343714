import { Icon, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

export default function TodoCheck({ initialValue, onChange, readOnly, style }) {
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		setChecked(initialValue);
	}, [initialValue]);

	function handleClick() {
		if (readOnly) return;
		const newValue = !checked;
		setChecked(newValue);
		onChange(newValue);
	}

	return (
		<Tooltip title={'todo'}>
			<Icon
				onClick={handleClick}
				style={{
					color: checked ? 'var(--clr-aexdo)' : '#888',
					fontSize: 24,
					cursor: readOnly ? 'inherit' : 'pointer',
					...style,
				}}
			>
				{checked ? 'notifications_active' : readOnly ? 'notifications_off' : 'notification_add'}
			</Icon>
		</Tooltip>
	);
}
