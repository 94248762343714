import React, { useReducer, useState } from 'react';
import __ from 'underscore';
import { Button, ButtonToolbar, Col, Input, Label, Modal, Row } from 'reactstrap';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import * as actions from '../../../../Store/actions/index';
import { useDispatch, useSelector } from 'react-redux';
import FormGroupRequired from '../components/formGroupRequired';

const tlang = ChangeAppLanguageFront.translateLanguage;
const mail_to = tlang('mail_to') || 'Send Email To';
const send_btn = tlang('client_send_btn') || 'Send';
const message_txt = tlang('message_txt') || 'Message';
const subject_txt = tlang('subject_txt') || 'Subject';

const initialState = {
	subject: '',
	message: '',
};

function reducer(state, action) {
	switch (action.type) {
		default:
			const upd = {};
			for (const key of Object.keys(action)) {
				upd[key] = action[key];
			}
			// console.log(`upd`, { upd });
			return { ...state, ...upd };
	}
}

export default function ModalSendEmail({ isOpen, onClose, from, to, title }) {
	const [state, localDispatch] = useReducer(reducer, initialState);
	const [errors, setErrors] = useState({});
	const dispatch = useDispatch();
	const apolloClient = useSelector(state => state.apolloClient);

	function validate(fld, value) {
		localDispatch({ [fld]: value });
		if (Array.isArray(value)) {
			if (!value.length) {
				setErrors(e => ({ ...e, [fld]: true }));
				return;
			}
		} else {
			if (!value) {
				setErrors(e => ({ ...e, [fld]: true }));
				return;
			}
		}
		setErrors(e => ({ ...e, [fld]: false }));
	}

	function handleSave() {
		const errs = {};
		if (state.subject === '') errs.subject = true;
		if (state.message === '') errs.message = true;
		setErrors(errs);
		if (__.isEmpty(errs)) {
			const req = {
				from_email: from,
				to_email: to,
				subject_email: state.subject,
				html_email: state.message,
				project_name: title,
			};
			dispatch(actions.sendEmailProjectMembers(apolloClient.project, req));
			onClose();
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			toggle={() => onClose()}
			className={`modal-dialog--success modal-dialog--header width__50`}
		>
			<div className="modal__header">
				<button
					className="lnr lnr-cross modal__close-btn"
					type="button"
					onClick={() => onClose()}
				/>
				<h4 className="bold-text  modal__title">
					{mail_to} {to}
				</h4>
			</div>
			<div className="modal__body">
				<Row>
					<Col xs={12}>
						<FormGroupRequired>
							<Label>{subject_txt}</Label>
							<Input
								onChange={e => validate('subject', e.target.value)}
								type="text"
								value={state.subject}
							/>
							{errors.subject && <span className="error-message">{'subject is required'}</span>}
						</FormGroupRequired>
					</Col>
					<Col xs={12}>
						<FormGroupRequired>
							<Label>{message_txt}</Label>
							<Input
								onChange={e => validate('message', e.target.value)}
								type="textarea"
								value={state.message}
							/>
							{errors.message && <span className="error-message">{'message is required'}</span>}
						</FormGroupRequired>
					</Col>
				</Row>
			</div>
			<ButtonToolbar className="modal__footer">
				<Button color={'success'} onClick={() => handleSave()}>
					{send_btn}
				</Button>
			</ButtonToolbar>
		</Modal>
	);
}
