import { FormGroup } from 'reactstrap';
import styled from 'styled-components';

const FormGroupRequired = styled(FormGroup)`
	label::after {
		content: '*';
		color: red;
		margin-inline-start: 0.5em;
	}
`;

export default FormGroupRequired;
